import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ETabsCreateBot } from 'services/constants/lists-table';

export const CreateBotTabs: FC = () => {
	const params = useParams();

	return (
		<div className="tabs-buttons-nav">
			<Link
				to={`${ELinksName.CREATE_BOT}/${params.name}/${ETabsCreateBot.MAIN}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsCreateBot.MAIN || !params.tab,
				})}
			>
				Main
			</Link>
			{/* 
			<Link
				to={`${ELinksName.CREATE_BOT}/${params.name}/${ETabsCreateBot.STRUCTURE}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsCreateBot.STRUCTURE,
				})}
			>
				Structure
			</Link>

			<Link
				to={`${ELinksName.CREATE_BOT}/${params.name}/${ETabsCreateBot.APPEARANCE}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsCreateBot.APPEARANCE,
				})}
			>
				Appearance
			</Link>

			<Link
				to={`${ELinksName.CREATE_BOT}/${params.name}/${ETabsCreateBot.TEMPLATE}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsCreateBot.TEMPLATE,
				})}
			>
				Template
			</Link> */}
		</div>
	);
};
