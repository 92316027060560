import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface IProps {
	activeBtn: string;
	labels: Array<string>;
	numbers: Array<number>;
	backgroundColor: string;
}

export const ChartBar: FC<IProps> = ({ backgroundColor, activeBtn, labels, numbers }) => {
	const params = useParams();
	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: 'bottom' as const,
			},
			title: {
				display: false,
				text: 'Replenishment schedules',
			},
		},
		scale: {
			ticks: {
				min: 0,
				max: 150,
				stepSize: 10,
			},
		},
		tick: {
			tick: 10,
		},
	};

	const data = {
		labels,
		datasets: [
			{
				label: activeBtn[0].toUpperCase() + activeBtn.slice(1),
				data: numbers,
				backgroundColor: backgroundColor,
				borderWidth: 0,
				borderRadius: 5,
			},
		],
	};

	return (
		<div className="wrapp-bar">
			<Bar options={options} data={data} height={100} />
		</div>
	);
};
