export enum ETypesFile {
	MEDIA_SERVICE = -2, //
	MEDIA_EMPTY = -1, //
	MEDIA_PHOTOVIDEO = 0, // PhotoVideo -> inputMessagesFilterPhotoVideo
	MEDIA_FILE = 1, // File -> inputMessagesFilterDocument
	MEDIA_AUDIO = 2, // Audio(RoundVoiceFile) -> inputMessagesFilterRoundVoice
	MEDIA_URL = 3, // Link -> inputMessagesFilterUrl
	MEDIA_MUSIC = 4, // MusicFile -> inputMessagesFilterMusic
	MEDIA_PHONE_CALL = 5, // inputMessagesFilterPhoneCalls
	MEDIA_GIF = 6, // Gif -> inputMessagesFilterGif`
}

export const type_transaction = {
	'0': 'Paying for a channel subscription',
	'1': 'Purchased online course',
	'2': 'Deposit',
	'3': 'Buying media',
	'4': 'Payment for AI services',
	'5': 'Payment for AI services',
	'6': 'Transferring earnings to a payment wallet',
	'7': 'Payment for AI services',
	'8': 'Payment for AI services',
	'9': 'Payment for AI services',
	'10': 'Payment for AI services',
	'11': 'Channel subscription',
	'12': 'Channel subscription',
};

// CHANNEL_SUBSCRIPTION_TYPE = 0
// CHANNEL_COURSE_TYPE       = 1
// PAYMENT_TYPE              = 2
// MEDIA_TYPE                = 3
// AI_SUBSCRIPTION           = 4
// AI_PACKS                  = 5
// TRANSFER_TYPE             = 6
// AI_TEXT_PACKS             = 7
// AI_IMAGE_PACKS            = 8
// AI_TEXT_SUBSCRIPTION      = 9
// AI_IMAGE_SUBSCRIPTION     = 10
// LOYALTY                   = 11
// LOYALTY_BONUS             = 12

export const typeGraph = ['day', 'week', 'month', 'year'];
