import { endpoint } from 'services/endpoint';
import { http, multipart } from 'services/http';
import { IReactionsApi } from './types';
import {
	IActiveRequest,
	IAddRequest,
	IEditRequest,
	IReactionRequest,
} from 'redux/reducers/reactions/types';

export const reactions: IReactionsApi = {
	getReactions: (payload: IReactionRequest) =>
		http.post(endpoint.reactions.GET_REACTIONS, payload).then((response) => response.data),
	getReaction: (payload: string) =>
		http.get(endpoint.reactions.GET_REACTION(payload)).then((response) => response.data),
	addReaction: (payload: IAddRequest) =>
		multipart.post(endpoint.reactions.ADD_REACTION, payload).then((response) => response.data),
	editReaction: (payload: any) =>
		multipart.post(endpoint.reactions.EDIT_REACTION, payload).then((response) => response.data),
	deleteReaction: (payload: string) =>
		http.delete(endpoint.reactions.DELETE_REACTION(payload)).then((response) => response.data),
	activeReaction: (payload: IActiveRequest) =>
		multipart.post(endpoint.reactions.ACIVE_REACTION, payload).then((response) => response.data),
};
