import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAppStore, IMobileMenu } from './types';

// ==========================================:
const initialState: IAppStore = {
	mobileMenu: false,
	lang: 'EN',
	openSidebar: false,
	chatActive: null,
	status: null,
	userId: null,
	tabsSupport: 0,
	tabNameSupport: '',
};

// ==========================================:
const app = createSlice({
	name: '@@app',
	initialState,
	reducers: {
		changeAppLanguage: (state, action: PayloadAction<string>) => {
			const { payload } = action;
			const appLanguageState = state;

			appLanguageState.lang = payload;
		},
		mobileMenu: (state, action: PayloadAction<IMobileMenu>) => {
			const { payload } = action;
			const mobileMenuState = state;

			mobileMenuState.mobileMenu = payload.open;
		},
		setOpenSidebar: (state, action: PayloadAction<boolean>) => {
			const { payload } = action;
			const sidebarState = state;
			sidebarState.openSidebar = !payload;
		},
		chatOpen: (state, action: PayloadAction<number | null>) => {
			const { payload } = action;
			const chatOpenState = state;
			chatOpenState.chatActive = payload;
		},
		setStatus: (state, action: PayloadAction<number | null>) => {
			const { payload } = action;
			const chatOpenState = state;
			chatOpenState.status = payload;
		},
		setTabSupport: (state, action: PayloadAction<number>) => {
			const { payload } = action;
			const tabsSupportState = state;
			tabsSupportState.tabsSupport = payload;
		},
		setTabNameSupport: (state, action: PayloadAction<string>) => {
			const { payload } = action;
			const tabsSupportState = state;
			tabsSupportState.tabNameSupport = payload;
		},
		setUserId: (state, action: PayloadAction<number | null>) => {
			const { payload } = action;
			const chatOpenState = state;
			chatOpenState.userId = payload;
		},
	},
});

export default app.reducer;
export const {
	changeAppLanguage,
	setUserId,
	mobileMenu,
	setTabSupport,
	setTabNameSupport,
	setOpenSidebar,
	chatOpen,
	setStatus,
} = app.actions;
