import { ArrowDownSvg, PointsSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { useSelect } from 'hooks/useSelect';
import { FC, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { logoutRequest } from 'redux/reducers/auth/reducer';
import { deleteNoteRequest } from 'redux/reducers/support/reducer';
import { INote } from 'redux/reducers/support/types';

export const SupportMenu: FC<{ note: INote; onEdit: (note: INote) => void }> = ({
	note,
	onEdit,
}) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();
	const dispatch = useDispatch();

	const handleButton = (event: MouseEvent) => {
		const { name } = event.target as HTMLButtonElement;
		name === 'edit' ? onEdit(note) : dispatch(deleteNoteRequest(String(note.id)));
		setOpen(false);
	};

	return (
		<>
			<div ref={triggerRef} onClick={toggleOpen} className="points pointer">
				<PointsSvg />
			</div>
			<div className="position-absolute">
				<div className={classNames('select', { active: open })}>
					<div className="select__drop select__position select__drop-custom" ref={dropRef}>
						<div className="select__drop-scroll">
							<div className="select__drop-item">
								<ul>
									<li className="countries-list">
										<button name="edit" onClick={handleButton} type="button">
											Edit
										</button>
									</li>
									<li className="countries-list">
										<button name="delete" onClick={handleButton} type="button">
											Delete
										</button>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
