/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonStub } from 'ui/ButtonStub/ButtonStub';

import { IPropsPagination } from './types';

export const Pagination: FC<IPropsPagination> = ({ currentPage, numberPage, request }) => {
	const navigate = useNavigate();

	const [start, setStart] = useState(currentPage === numberPage ? numberPage - 2 : currentPage);
	const [end, setEnd] = useState(
		numberPage <= 4 ? numberPage : currentPage === numberPage ? numberPage : currentPage + 2,
	);

	const handlePaginationPrev = () => {
		navigate({
			search: String(currentPage - 1),
		});
		request(+currentPage - 1);
		if (end) {
			setEnd((prev) => prev - 1);
			setStart((prev) => prev - 1);
		}
	};

	const handlePaginationNext = () => {
		request(+currentPage + 1);
		navigate({
			search: String(+currentPage + 1),
		});
		if (numberPage !== end) {
			setEnd((prev) => prev + 1);
			setStart((prev) => prev + 1);
		}
	};

	const handlePagination = (page: number) => {
		request(page);
		navigate({
			search: String(page),
		});

		if (page === numberPage) {
			setEnd(numberPage);
			setStart(numberPage - 2);
			return;
		}

		if (page === 1) {
			setEnd(3);
			setStart(1);
			return;
		}

		if (page === end) {
			setEnd(page + 2);
			setStart(page);
			return;
		}

		if (page < start) {
			setEnd(page);
			setStart(page - 2);
		}
	};

	return (
		<div className="pagination-block">
			<ul className="pagination">
				<li>
					<button
						className={classNames('arrow', {
							disabled: currentPage === 1,
						})}
						disabled={currentPage === 1}
						onClick={handlePaginationPrev}
						type="button"
					>
						<svg
							className="stroke"
							width="8"
							height="12"
							viewBox="0 0 8 12"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M6.9999 11.2L1.3999 5.99999L6.9999 0.799988"
								stroke="#9EA2A9"
								strokeLinecap="square"
							/>
						</svg>
					</button>
				</li>

				{start !== 1 && (
					<li>
						<button
							className={classNames({
								active: currentPage === 1,
							})}
							onClick={() => handlePagination(1)}
							type="button"
						>
							{1}
						</button>
					</li>
				)}

				{start > 3 && numberPage > 4 && <ButtonStub />}

				{Array.from(Array(numberPage).keys())
					?.slice(start >= 3 ? start - 2 : start - 1, start >= 3 ? end : end)
					.map((el) => (
						<li key={el}>
							<button
								className={classNames({
									active: el + 1 === currentPage,
								})}
								onClick={() => handlePagination(el + 1)}
								type="button"
							>
								{el + 1}
							</button>
						</li>
					))}

				{numberPage - end > 1 && numberPage > 4 && <ButtonStub />}

				{numberPage > 4 && numberPage - end > 0 && (
					<li>
						<button
							className={classNames({
								active: currentPage === numberPage,
							})}
							onClick={() => handlePagination(numberPage)}
							type="button"
						>
							{numberPage}
						</button>
					</li>
				)}

				<li>
					<button
						className={classNames('arrow', {
							disabled: numberPage === currentPage,
						})}
						onClick={handlePaginationNext}
						disabled={numberPage === currentPage}
						type="button"
					>
						<svg
							className="stroke"
							width="8"
							height="12"
							viewBox="0 0 8 12"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M1 11.2L6.6 5.99999L1 0.799988" stroke="#5BC044" strokeLinecap="square" />
						</svg>
					</button>
				</li>
			</ul>
		</div>
	);
};
