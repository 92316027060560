import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EAiRestrictionSubTabs, EAiSubTabs } from 'services/constants/lists-table';

export const AiSubSettings: FC = () => {
	const params = useParams();

	return (
		<div className="table-tabs table-tabs--type2 table-tabs-custom tab-up">
			<Link
				to={`${ELinksName.AI_SERVICES}/${params.tab}/${EAiRestrictionSubTabs.WORDS}`}
				className={classNames('table-tabs__item', {
					active: params.subTab === EAiRestrictionSubTabs.WORDS || !params.subTab,
				})}
			>
				Words
			</Link>

			{/* <Link
				to={`${ELinksName.AI_SERVICES}/${params.tab}/${EAiRestrictionSubTabs.POSES}`}
				className={classNames('table-tabs__item', {
					active: params.subTab === EAiRestrictionSubTabs.POSES,
				})}
			>
				Poses
			</Link>

			<Link
				to={`${ELinksName.AI_SERVICES}/${params.tab}/${EAiRestrictionSubTabs.LINKS}`}
				className={classNames('table-tabs__item', {
					active: params.subTab === EAiRestrictionSubTabs.LINKS,
				})}
			>
				Links
			</Link> */}
		</div>
	);
};
