import { PayloadAction } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import {
	addBotPromoRequest,
	aiChatBotInitState,
	botPromoCodeListRequest,
	botPromoCodeListSuccess,
	botPromoCodeRequest,
	botPromoCodeRevokeRequest,
	botPromoCodeRevokeSuccess,
	botPromoCodeSuccess,
	createBotRequest,
	editBotRequest,
	editBotSuccess,
	editBotTurnOfAndOnRequest,
	editButtonsRequest,
	editButtonsSuccess,
	editCommandRequest,
	editPricingRequest,
	getAiChatBotsRequest,
	getAiChatBotsSuccess,
	getBotDataRequest,
	getBotDataSuccess,
	getBotInfoRequest,
	getBotInfoSuccess,
	getBotsStatisticsRequest,
	getBotsStatisticsSuccess,
	getBotСategoryRequest,
	getBotСategorySuccess,
	getButtonsRequest,
	getButtonsSuccess,
	getCommandsInfoRequest,
	getCommandsInfoSuccess,
	getCommandsRequest,
	getCommandsSuccess,
	getPricingRequest,
	getPricingSuccess,
	getRecommendedRequest,
	getRecommendedSuccess,
	getTotalStatisticsRequest,
	getTotalStatisticsSuccess,
	listLogoRequest,
	listLogoSuccess,
	removeLogoRequest,
	removeLogoSuccess,
	uploadBackgroundRequest,
	uploadBackgroundSuccess,
	uploadLogoRequest,
	uploadLogoSuccess,
} from './reducer';
import {
	IAddBotDataRequest,
	IAiChatBotsREquest,
	IAiChatBotsResponse,
	IBot,
	IBotInfo,
	IBotInfoResponse,
	IBotPromoCode,
	IBotPromoCodeList,
	IBotPromoCodeListResonse,
	IBotTurnOfAndOn,
	IButtonBot,
	ICategory,
	ICommandAllData,
	ICreateBotRequest,
	IEditBot,
	IListLogo,
	IPricingRequest,
	IPricingResponse,
	IRecommendedReqvest,
	IRecommendedResponse,
	IRemoveRequest,
	IRequestCodeRevoke,
	IStatisticsBot,
	IStatisticsBotRequest,
	ITotalStatistics,
	ITotalStatisticsResponse,
	IUpload,
} from './types';

import { notificationContainer } from 'untils/notificationContainer';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EAiChatBotTabs } from 'services/constants/lists-table';

function* getAiChatBotsWorker({ payload }: PayloadAction<IAiChatBotsREquest>) {
	try {
		const response: { data: IAiChatBotsResponse } = yield call(
			api.aiChatBots.getAiChatBots,
			payload,
		);
		yield put(getAiChatBotsSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getBotsStatisticsWorker({ payload }: PayloadAction<IStatisticsBotRequest>) {
	try {
		const response: { data: IStatisticsBot } = yield call(
			api.aiChatBots.getBotsStatistics,
			payload,
		);
		yield put(getBotsStatisticsSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getTotalStatisticsWorker({ payload }: PayloadAction<ITotalStatistics>) {
	try {
		const response: { data: ITotalStatisticsResponse } = yield call(
			api.aiChatBots.getTotalStatistics,
			payload,
		);
		yield put(getTotalStatisticsSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getPricingWorker({ payload }: PayloadAction<string>) {
	try {
		const response: { data: IPricingResponse } = yield call(api.aiChatBots.getPricing, payload);
		yield put(getPricingSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* editPricingWorker({ payload }: PayloadAction<IPricingRequest>) {
	const { prevState, initial, request } = payload;

	try {
		yield call(api.aiChatBots.editPricing, request);
		notificationContainer('', 'success');
	} catch (error) {
		prevState(initial);
		notificationContainer('', 'error');

		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getBotInfoWorker({ payload }: PayloadAction<string>) {
	try {
		const response: { data: IBotInfo } = yield call(api.aiChatBots.getBotInfo, payload);
		yield put(getBotInfoSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getCommondWorker({ payload }: PayloadAction<string>) {
	try {
		const response: { data: ICommandAllData } = yield call(api.aiChatBots.getBotInfo, payload);
		yield put(getCommandsSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* editCommandWorker({ payload }: PayloadAction<ICommandAllData>) {
	try {
		yield call(api.aiChatBots.editCommand, payload);
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getBotDataWorker({ payload }: PayloadAction<string>) {
	try {
		const response: { data: IBot } = yield call(api.aiChatBots.getBotData, payload);
		yield put(getBotDataSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getBotСategoryWorker() {
	try {
		const response: { data: { categories: Array<ICategory> } } = yield call(
			api.aiChatBots.getBotСategory,
		);
		yield put(getBotСategorySuccess(response.data.categories));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getButtonsWorker({ payload }: PayloadAction<string>) {
	try {
		const response: { data: IButtonBot } = yield call(api.aiChatBots.getButtons, payload);
		yield put(getButtonsSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getCommandsInfoWorker({ payload }: PayloadAction<string>) {
	try {
		const response: { data: ICommandAllData } = yield call(api.aiChatBots.getCommandInfo, payload);
		yield put(getCommandsInfoSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* editBotWorker({ payload }: PayloadAction<IEditBot>) {
	try {
		yield call(api.aiChatBots.editBot, payload);
		yield put(editBotSuccess(payload));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* editButtonWorker({ payload }: PayloadAction<IButtonBot>) {
	try {
		yield call(api.aiChatBots.editButtons, payload);
		yield put(editButtonsSuccess(payload));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* createBotWorker({ payload }: PayloadAction<ICreateBotRequest>) {
	try {
		yield call(api.aiChatBots.createBot, payload.data);
		notificationContainer('New bot created', 'success');
		payload.history(ELinksName.AI_CHAT_BOT);
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
		notificationContainer('The bot was not created', 'error');
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getRecommendedWorker({ payload }: PayloadAction<IRecommendedReqvest>) {
	try {
		const response: { data: IRecommendedResponse } = yield call(
			api.aiChatBots.getRecommended,
			payload,
		);
		yield put(getRecommendedSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* uploadLogoWorker({ payload }: PayloadAction<IUpload>) {
	try {
		yield call(api.aiChatBots.uploadLogo, payload);
		yield put(uploadLogoSuccess());
		notificationContainer('Logo successfully added', 'success');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* uploadBackgroundWorker({ payload }: PayloadAction<IUpload>) {
	try {
		yield call(api.aiChatBots.uploadBackground, payload);
		yield put(uploadBackgroundSuccess());
		notificationContainer('Background successfully added', 'success');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* editBotTurnOfAndOnWorker({ payload }: PayloadAction<IBotTurnOfAndOn>) {
	const { data, toggle } = payload;
	try {
		yield call(api.aiChatBots.editBotTurnOfAndOn, data);
	} catch (error) {
		console.log(false);
		toggle(false);
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* addBotPromoWorker({ payload }: PayloadAction<IAddBotDataRequest>) {
	try {
		const response: { message: string; status: string } = yield call(
			api.aiChatBots.addBotPromo,
			payload.data,
		);
		payload.closePopup(false);
		payload.resetInput();
		payload.param === EAiChatBotTabs.LIST_CODES && window.location.reload();
		payload.navigation(`${ELinksName.AI_CHAT_BOT}/${EAiChatBotTabs.LIST_CODES}`);
		notificationContainer(response.message, response.status);
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* botPromoCodeWorker({ payload }: PayloadAction<IBotPromoCode>) {
	try {
		const response: { data: IBotInfoResponse } = yield call(api.aiChatBots.botPromoCode, payload);
		yield put(botPromoCodeSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* botPromoCodeRevokeWorker({ payload }: PayloadAction<IRequestCodeRevoke>) {
	const { onClose, body, navigation } = payload;

	try {
		yield call(api.aiChatBots.botPromoCodeRevoke, body);
		yield put(botPromoCodeRevokeSuccess(body));
		console.log(`${ELinksName.AI_CHAT_BOT}/${EAiChatBotTabs.LIST_CODES}`);
		yield navigation?.(`${ELinksName.AI_CHAT_BOT}/${EAiChatBotTabs.LIST_CODES}`);
		yield onClose?.();
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* botPromoCodeListWorker({ payload }: PayloadAction<IBotPromoCodeList>) {
	try {
		const response: { data: IBotPromoCodeListResonse } = yield call(
			api.aiChatBots.botPromoCodeList,
			payload,
		);

		yield put(botPromoCodeListSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* logoListWorker({ payload }: PayloadAction<string>) {
	try {
		const response: { data: Array<IListLogo> } = yield call(api.aiChatBots.getLogoList, payload);
		yield put(listLogoSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* deleteLogoWorker({ payload }: PayloadAction<IRemoveRequest>) {
	try {
		yield call(api.aiChatBots.removeLogo, payload);
		yield put(removeLogoSuccess(payload));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

export function* aiChatBotsSaga() {
	yield takeEvery(getAiChatBotsRequest.type, getAiChatBotsWorker);
	yield takeEvery(getBotsStatisticsRequest.type, getBotsStatisticsWorker);
	yield takeEvery(getTotalStatisticsRequest.type, getTotalStatisticsWorker);
	yield takeEvery(getPricingRequest.type, getPricingWorker);
	yield takeEvery(editPricingRequest.type, editPricingWorker);
	yield takeEvery(getBotInfoRequest.type, getBotInfoWorker);
	yield takeEvery(getCommandsRequest.type, getCommondWorker);
	yield takeEvery(editCommandRequest.type, editCommandWorker);
	yield takeEvery(getBotDataRequest.type, getBotDataWorker);
	yield takeEvery(getBotСategoryRequest.type, getBotСategoryWorker);
	yield takeEvery(getButtonsRequest.type, getButtonsWorker);
	yield takeEvery(getCommandsInfoRequest.type, getCommandsInfoWorker);
	yield takeEvery(editBotRequest.type, editBotWorker);
	yield takeEvery(editButtonsRequest.type, editButtonWorker);
	yield takeEvery(createBotRequest.type, createBotWorker);
	yield takeEvery(getRecommendedRequest.type, getRecommendedWorker);
	yield takeEvery(uploadLogoRequest.type, uploadLogoWorker);
	yield takeEvery(uploadBackgroundRequest.type, uploadBackgroundWorker);
	yield takeEvery(editBotTurnOfAndOnRequest.type, editBotTurnOfAndOnWorker);
	yield takeEvery(addBotPromoRequest.type, addBotPromoWorker);
	yield takeEvery(botPromoCodeRequest.type, botPromoCodeWorker);
	yield takeEvery(botPromoCodeRevokeRequest.type, botPromoCodeRevokeWorker);
	yield takeEvery(botPromoCodeListRequest.type, botPromoCodeListWorker);
	yield takeEvery(listLogoRequest.type, logoListWorker);
	yield takeEvery(removeLogoRequest.type, deleteLogoWorker);
}
