import { ArrowBackSvg, LockRedSvg } from 'assets/svg-icon';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Details } from './Details/Details';
import { ManagementTabs } from './ManagementTabs/ManagementTabs';
import { AlbumTable } from 'components/ContentComponents/AlbumTable/AlbumTable';
import { TrackTable } from 'components/ContentComponents/TrackTable/TrackTable';
import { ETabsContent, ETabsManagement } from 'services/constants/lists-table';
import { Fillter } from 'ui/Fillter/Fillter';
import { channelsCategory, channelsTypes } from 'CONST/userFilter';
import { SubTabs } from './SubTabs/SubTabs';

export const ManagementDetails: FC = () => {
	const navigate = useNavigate();
	const params = useParams();

	const defineTab = () => {
		switch (params.subTab) {
			case ETabsContent.ALBUM:
				return <AlbumTable />;
			default:
				return <TrackTable />;
		}
	};

	const definePage = () => {
		switch (params.tab) {
			case ETabsManagement.CONTENT:
				return (
					<>
						<div className="table-block">
							<div className="ai-settings">
								<SubTabs />
							</div>

							<Fillter
								type={channelsTypes}
								status={channelsCategory}
								typesName="Type"
								statusName="Status"
								keyStatus="status"
								keyType="types"
							/>
							{/* <div className="filter-custom"> */}
							<div className="table-wrapper">{defineTab()}</div>
							{/* </div> */}
						</div>
					</>
				);
			default:
				return <Details />;
		}
	};

	return (
		<div className="main-content">
			<div className="url color-grey big-first-letter">
				{location.pathname.slice(1).replaceAll('/', ' / ')}
			</div>
			<div className="title-block-wrap flex-content">
				<div className="title-block">
					<div className="back-button pointer" onClick={() => navigate(-1)}>
						<ArrowBackSvg />
					</div>
					<p className="title big-first-letter">Partnership Request</p>
				</div>
				<div className="request-btn">
					<button
						style={{ display: 'flex', gap: '5px' }}
						className="request-btn__btn-reject"
						type="button"
					>
						<LockRedSvg />
						Block
					</button>
				</div>
			</div>
			<ManagementTabs />

			{definePage()}
		</div>
	);
};
