import { EmailBlueSvg } from 'assets/svg-icon';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';

export const CheckYourEmail: FC<{ email: string }> = ({ email }) => {
	return (
		<div className="authorization-form">
			<div className="authorization-form__logo">
				<EmailBlueSvg />
			</div>
			<p className="form-title">Check your email</p>
			<p className="form-subtitle">
				We&apos;ve sent password reset instructions to:
				<span>{email}</span>
			</p>
			<div className="authorization-form__body">
				<div className="form-submit">
					<Link to={ELinksName.LOGIN} className="button button--full-width">
						Back to Login
					</Link>
				</div>
			</div>
			<p className="form-subtitle form-subtitle-custom">
				If it doesn’t arrive soon, check your spam folder or <strong>send the email again</strong>.
			</p>
		</div>
	);
};
