import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import {
	ESubTabsFinance,
	ETabsBalances,
	ETabsFinanceWithdrawal,
} from 'services/constants/lists-table';

export const SubTabs: FC = () => {
	const params = useParams();

	return (
		<div className="table-tabs table-tabs--type2 tab-up">
			<Link
				to={`${ELinksName.FINANCE}/${ESubTabsFinance.PLATFORM}/${ETabsBalances.DEPOSIT}`}
				className={classNames('table-tabs__item', {
					active: params.subTab === ETabsBalances.DEPOSIT || !params.subTab,
				})}
			>
				Deposit
			</Link>

			<Link
				to={`${ELinksName.FINANCE}/${ESubTabsFinance.PLATFORM}/${ETabsBalances.WITHDRAWAL}`}
				className={classNames('table-tabs__item', {
					active: params.subTab === ETabsBalances.WITHDRAWAL,
				})}
			>
				Withdrawal
			</Link>
		</div>
	);
};
