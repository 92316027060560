import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ETabsUsers } from 'services/constants/lists-table';

export const UsersTabs: FC = () => {
	const params = useParams();

	return (
		<div className="tabs-buttons-nav">
			<Link
				to={`${ELinksName.USERS}/${ETabsUsers.REGISTRED}/${params.subTab || ''}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsUsers.REGISTRED || !params.tab,
				})}
			>
				Registered
			</Link>
			<Link
				to={`${ELinksName.USERS}/${ETabsUsers.BLOCKED_USERS}/${params.subTab || ''}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsUsers.BLOCKED_USERS,
				})}
			>
				Blocked users
			</Link>
			<Link
				to={`${ELinksName.USERS}/${ETabsUsers.DELETE_USERS}/${params.subTab || ''}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsUsers.DELETE_USERS,
				})}
			>
				Delete users
			</Link>
		</div>
	);
};
