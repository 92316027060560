export enum EChannelUpgradeType {
	BLOCK = 'block',
	UNBLOCK = 'unblock',
	RECOMMEND = 'recommend',
	UNRECOMMEND = 'unrecommend',
	VERIFY = 'verify',
	UNVERIFY = 'unverify',
	GROUPS = 'groups',
	CHANNELS = 'channels',
}

export enum EReportsUpgradeType {
	BLOCK = 'block',
	DECLINE = 'decline',
	MESSAGE_BLOCK = 'message_block',
}
