import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ImageList } from './ImageList/ImageList';
import { ICountry, IPhotos, IUser } from 'redux/reducers/users/types';
import moment from 'moment';
import { findCountry } from 'untils/findCountry';

interface IProps {
	userDetails: IUser | undefined;
	photos: undefined | Array<IPhotos>;
	countriesList: null | Array<ICountry>;
}

export const DetailsUser: FC<IProps> = ({ userDetails, photos, countriesList }) => {
	return (
		<section className="user-data__details-user details-user">
			<ul className="details-user__box-text">
				<li className="details-user__item ">
					<span>ID</span>
					<span className="blue">{userDetails?.id}</span>
				</li>
				<li className="details-user__item big-first-letter">
					<span>Type</span>
					<span>{userDetails?.account_type}</span>
				</li>
				<li className="details-user__item big-first-letter">
					<span>Name</span>
					<span>{`${userDetails?.first_name}`}</span>
				</li>
				<li className="details-user__item big-first-letter">
					<span>Last name</span>
					<span>{`${userDetails?.last_name}`}</span>
				</li>
				<li className="details-user__item big-first-letter">
					<span>Username</span>
					<span className="details-user__link blue">{userDetails?.username}</span>
				</li>
				<li className="details-user__item">
					<span>Email</span>
					<span className="details-user__link blue">{userDetails?.email}</span>
				</li>
				<li className="details-user__item">
					<span>Registration date</span>
					<span>{moment(userDetails?.register_at).format('MMM DD, YYYY h:mm a')}</span>
				</li>
				<li className="details-user__item">
					<span>Last login</span>
					<span>
						{(userDetails?.last_login &&
							moment(userDetails?.last_login).format('MMM DD, YYYY h:mm a')) ||
							''}
					</span>
				</li>
				<li className="details-user__item">
					<span>Register Country</span>
					{userDetails?.country_code ? (
						<span>
							{userDetails.country_code.length <= 3
								? findCountry(countriesList, userDetails.country_code)
								: null}
						</span>
					) : null}
				</li>
				<li className="details-user__item">
					<span>IP country</span>
					<span>{userDetails?.ip_country}</span>
				</li>
				<li className="details-user__item big-first-letter">
					<span>Profile</span>
					<span>{userDetails?.account_kind}</span>
				</li>
				<li className="details-user__item">
					<span>Gender</span>
					<span>{userDetails?.gender}</span>
				</li>
				<li className="details-user__item2">
					<span>User bio</span>
				</li>
				<li className="details-user__item2">
					<span>{userDetails?.bio}</span>
				</li>
			</ul>
			<div className="details-user__img-wrapper">
				<ImageList photos={photos} />
			</div>
		</section>
	);
};
