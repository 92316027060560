import { LoginForm } from 'components/Forms/LoginForm/LoginForm';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginRequest } from 'redux/reducers/auth/reducer';
import { ILoginSubmitValue } from './types';
import { QRCodePopup } from 'components/QRCode/QRCode';

const Login: FC = () => {
	const dispatch = useDispatch();
	const [loginData, setLoginData] = useState<ILoginSubmitValue | null>(null);
	const [openQr, setOpenQr] = useState<string>('');

	const onCloseQr = () => {
		setOpenQr('');
	};

	const handleLoginSubmit = (value: ILoginSubmitValue) => {
		setLoginData(value);
		dispatch(loginRequest({ paramsApi: value, setOpenQr }));
	};

	return (
		<div className="wrapper">
			<main className="main">
				<section className="authorization-section">
					{openQr && loginData?.email ? (
						<QRCodePopup
							onCloseQr={onCloseQr}
							email={loginData.email}
							loginData={loginData}
							openQr={openQr}
							setOpenQr={setOpenQr}
						/>
					) : (
						<LoginForm loginSubmit={handleLoginSubmit} />
					)}
				</section>
			</main>
		</div>
	);
};

export default Login;
