import { indexOf } from 'lodash';

interface IComments {
	discussion_hash: {
		value: number;
	};
}

export interface IContent {
	webpage: {
		display_url: string;
		url: string;
	};

	document: {
		dc_id: number;
		id: number;
		access_hash: number;
		mime_type: string;
		size2_INT32: number;
		size2_INT64: number;
		sizes: number;
		predicate_name: string;
		attributes: Array<{ file_name: string }>;
	};
	photo_FLAGPHOTO: {
		dc_id: number;
		id: number;
		access_hash: number;
		mime_type: string;
		size2_INT32: number;
		size2_INT64: number;
		sizes: Array<{
			size2: number;
		}>;
		predicate_name: string;
		attributes: Array<{ file_name: string }>;
	};
	predicate_name: string;
	media_access_hash: string;
	messageId: number;
	outMessage: {
		message: string;
	};
	peer: {
		access_hash: number;
	};
	input: string;
	admin_id: string | number;
	media: {
		photo_FLAGPHOTO: {
			access_hash: string | number;
			predicate_name: string;
		};
		document: {
			access_hash: number;
			mime_type: string;
			predicate_name: string;
		};
	};
}

export const onJsonParse = (json: string) => {
	const object: IComments = JSON.parse(json);
	return object.discussion_hash.value;
};

export const onJsonParseContent = (jsonStr: string): IContent => {
	const result = JSON.parse(jsonStr, (key, value) => {
		if (typeof value === 'number' && !Number.isSafeInteger(value)) {
			//@ts-ignore
			const strBig = jsonStr.match(new RegExp(`(?:"${key}":)(.*?)(?:,)`))[1];
			return strBig;
		}
		return value;
	});

	return result;
};

const parseMinusNumber = (key: string, json: string) => {
	const findKey = json.slice(json.lastIndexOf(key));
	const number = findKey.slice(findKey.indexOf('-'), findKey.indexOf(','));
	return number;
};

export const onJsonParseBigin = (json: string): IContent => {
	function reviver(key: string, value: any) {
		if (value < 0) {
			return parseMinusNumber(key, json);
		}
		if (typeof value !== 'number' || Number.MAX_SAFE_INTEGER > value) {
			// we ignore non numbers and numbers that are lower than the safe integer value.
			return value;
		}
		//  first lets find out how many digits the max integer has.
		//  From there we subtract one digit to be sure that we won't get the parsed value that has been rounded.
		const maxLen = Number.MAX_SAFE_INTEGER.toString().length - 1;
		// Next we convert the current value to string and we are getting just the potion of it that has the safe integer max size.
		const needle = String(value).substr(0, maxLen);
		// Now we build a regular expression that has the needle plus some digits after it. This is the number that we are looking for
		// in the original string. Note, if there are many numbers that starts with the same digits this will only find the first one.
		// This can be extended to cache the reular expressions and reuse already executed ones to continue searching for the
		// next occurence of the number.
		const re = new RegExp(`${needle}\\d+`);
		const matches = json.match(re);
		if (matches) {
			// since we have a match we return it as a BigInt.

			return BigInt(matches[0]);
		}
		// otherwise we returns the value that would be returned anyway.

		return value;
	}
	const parsed = JSON.parse(json, reviver);
	return parsed;
};
