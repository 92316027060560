import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ETabsChannel } from 'services/constants/lists-table';

export const TabsChannels: FC = () => {
	const params = useParams();

	return (
		<div className="tabs-buttons-nav">
			<Link
				to={`${ELinksName.CHANNELS}/${ETabsChannel.ALL_CHANNELS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsChannel.ALL_CHANNELS || !params.tab,
				})}
			>
				All
			</Link>
			<Link
				to={`${ELinksName.CHANNELS}/${ETabsChannel.BLOCKED_CHANNELS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsChannel.BLOCKED_CHANNELS,
				})}
			>
				Blocked
			</Link>
			<Link
				to={`${ELinksName.CHANNELS}/${ETabsChannel.RECOMMENDED_CHANNELS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsChannel.RECOMMENDED_CHANNELS,
				})}
			>
				Recommended
			</Link>
			<Link
				to={`${ELinksName.CHANNELS}/${ETabsChannel.DELETE_CHANNELS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsChannel.DELETE_CHANNELS,
				})}
			>
				Delete
			</Link>
		</div>
	);
};
