import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IReactionResponse, IReactions, IReactionsState } from './types';

const getReactionsState = (state: IStoreState): IReactionsState => state.reactions;

export const getReactionsSelector = createSelector(
	[getReactionsState],
	(reaction: IReactionsState): null | Array<IReactions> => reaction.reactions,
);

export const getReactionSelector = createSelector(
	[getReactionsState],
	(reaction: IReactionsState): null | IReactionResponse => reaction.reaction,
);
