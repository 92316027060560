import {
	ArrowBackSuportSvg,
	ArrowProfileSvg,
	CloseBlueSvg,
	HideSvg,
	SearchBlueSvg,
	SearchSvg,
	UnfoldSvg,
} from 'assets/svg-icon';
import classNames from 'classnames';
import { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { setTabNameSupport } from 'redux/reducers/app/reducer';
import { getTabs } from 'redux/reducers/app/selectors';
import { ticketsRequest } from 'redux/reducers/support/reducer';

import { getTickets, getUsersSelector } from 'redux/reducers/support/selectors';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ETabsUsersDetails } from 'services/constants/lists-table';

interface IProps {
	openUser: number;
	id_user: number;
	hideRight: boolean;
	hideLeft: boolean;
	setHideLeft: (value: boolean) => void;
	setHideRight: (value: boolean) => void;
}

export const HeaderSuport: FC<IProps> = ({
	openUser,
	hideLeft,
	hideRight,
	setHideLeft,
	setHideRight,
	id_user,
}) => {
	const dispatch = useDispatch();
	const [openSearch, setOpenSerach] = useState<boolean>(false);
	const width = document.querySelector('.suport__header-center');
	const userId = useSelector(getUsersSelector);
	const tickets = useSelector(getTickets);
	const openTabs = useSelector(getTabs);
	const [search, setSearch] = useState<string>('');
	const location = useLocation();
	const [active, setActive] = useState<string>('Current');

	const onHandleSearch = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		setSearch(value);

		dispatch(
			ticketsRequest({
				current_page: 1,
				per_page: 15,
				previous: active === 'Previos' ? true : false,
				user_id: Number(location.search.slice(1)),
				search: value,
			}),
		);
	};

	const handleButton = (event: MouseEvent) => {
		const { name } = event.target as HTMLButtonElement;
		setActive(name);
		dispatch(setTabNameSupport(name));
		userId &&
			dispatch(
				ticketsRequest({
					current_page: 1,
					per_page: 15,
					previous: name === 'Previos' ? true : false,
					user_id: Number(location.search.slice(1)),
				}),
			);
	};

	return (
		<header className="suport">
			<section
				className={classNames('suport__header', {
					show: !openUser,
					'suport__header-grid-left': hideLeft && openUser,
					'suport__header-grid-two-column-left': hideLeft && !openUser,
					'suport__header-grid-right-left-sidebar': hideRight && hideLeft,
					'suport__header-grid-right-sidebar': hideRight && !hideLeft,
				})}
			>
				<div className="suport__header-left">
					<div className="suport__header-left-between">
						<Link to={ELinksName.HOME} className="suport__btn-back">
							<ArrowBackSuportSvg />
						</Link>

						{!hideLeft ? <span className="suport__title">Support</span> : null}
					</div>

					{!hideLeft && (
						<div onClick={() => setHideLeft(true)} className="pointer">
							<HideSvg />
						</div>
					)}
				</div>
				{tickets && openTabs ? (
					<div className="suport__header-center">
						<div className="suport__tab-wrapper">
							<button
								onClick={handleButton}
								name="Current"
								type="button"
								className={classNames('suport__title suport__tab', {
									active__tab: active === 'Current' || !active,
								})}
							>
								Current
							</button>
							<button
								onClick={handleButton}
								name="Previos"
								type="button"
								className={classNames('suport__title suport__tab', {
									active__tab: active === 'Previos',
								})}
							>
								Previos
							</button>
						</div>
						<div className="suport__input-wrap">
							<div
								style={{ maxWidth: `${Number(width?.clientWidth) - 72}px` }}
								className={`input input-custom input-search input-opacity suport__input ${
									openSearch ? '' : 'suport__input-opacity'
								}`}
							>
								<div className="input-wrapper">
									<DebounceInput
										className="input-item input-item--left-icon"
										type="text"
										name="search"
										placeholder="Search"
										debounceTimeout={500}
										value={search || ''}
										onChange={onHandleSearch}
									/>
									<span className="input-icon">
										<SearchSvg />
									</span>
								</div>
							</div>
							<div className="pointer" onClick={() => setOpenSerach(!openSearch)}>
								{openSearch ? <CloseBlueSvg /> : <SearchBlueSvg />}
							</div>
						</div>
					</div>
				) : (
					<div></div>
				)}
				{openUser ? (
					<div
						className={classNames('suport__header-right', {
							'suport__header-right-heid': hideRight,
						})}
					>
						<div className="suport__header-left-between">
							<div onClick={() => setHideRight(!hideRight)} className="pointer">
								{hideRight ? <UnfoldSvg /> : <HideSvg />}
								{hideRight && <span className="sidebar-right__user-info">User info</span>}
							</div>
							{!hideRight && <span className="suport__title">User info</span>}
						</div>

						{!hideRight && (
							<Link
								to={`${ELinksName.USER_DETAILS}/${id_user}/registered/${String(
									ETabsUsersDetails.USER_DATA,
								)}`}
								className="suport__flex pointer"
							>
								<span className="suport__title suport__blue">Profile</span>
								<ArrowProfileSvg />
							</Link>
						)}
					</div>
				) : null}
			</section>
		</header>
	);
};
