import { DeleteSvg } from 'assets/svg-icon';
import { ChangeEvent, MouseEvent, FC, useState, useEffect } from 'react';
import { IChangeAdmin } from 'ui/AddNewAdmins/type';
import { BoxFirst } from '../BoxFirst/BoxFirst';
import { BoxSecond } from '../BoxSecond/BoxSecond';
import { BoxThird } from '../BoxThird/BoxThird';
import { BoxFooter } from '../BoxFooter/BoxFooter';
import { IAdminsResponse } from 'redux/reducers/admins/types';
import { useDispatch } from 'react-redux';
import { deleteAdminRequest, enableOrDisable2FaAdmin } from 'redux/reducers/admins/reducer';
import { useNavigate } from 'react-router-dom';
import { PopUpAsk } from 'ui/PopUpAsk/PopUpAsk';

interface IProps {
	admin: IAdminsResponse;
	setChangeAdmin: (value: IChangeAdmin | {}) => void;
	changeAdmin: IChangeAdmin | {};
}

export const AdminInfo: FC<IProps> = ({ admin, changeAdmin, setChangeAdmin }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [open, setOpen] = useState<string>('');

	const onChangeAdmin = (event: ChangeEvent | MouseEvent) => {
		const { name, checked, value, type, id } = event.target as HTMLInputElement;

		if (type === 'radio' && name === 'blocked') {
			setChangeAdmin({
				...changeAdmin,
				[name]: id === 'active' ? false : true,
			});
		}

		if (type === 'radio' && name === 'role') {
			setChangeAdmin({
				...changeAdmin,
				[name]: id,
			});
		}

		if (type === 'checkbox') {
			setChangeAdmin({
				...changeAdmin,
				need_change_password: checked,
			});
		}

		if (type === 'text') {
			setChangeAdmin({
				...changeAdmin,
				first_name: value.split(' ')[0],
				last_name: value.split(' ')[1],
			});
		}
	};

	const onRemoveUser = () => {
		dispatch(
			deleteAdminRequest({
				paramsApi: {
					email: admin.email || '',
					delete: true,
				},
				navigate,
			}),
		);
	};

	useEffect(() => {
		setChangeAdmin({
			blocked: admin.blocked,
			email: admin.email,
			first_name: admin.first_name,
			last_name: admin.last_name,
			need_change_password: false,
			role: admin.role,
		});

		return () => {
			setChangeAdmin({});
		};
	}, [admin]);

	const onEnableOrDisable2Fa = (email: string, disable: boolean) => {
		dispatch(enableOrDisable2FaAdmin({ email, disable }));
	};

	return (
		<div className="admin-info">
			{open && (
				<PopUpAsk
					setText={setOpen}
					title={`Are you sure you want to delete this admin?`}
					btnNameOne="Yes, I’m sure"
					btnNameTwo="Cancel"
					onClick={onRemoveUser}
				/>
			)}
			<div className="admin-info__header">
				<div onClick={() => setOpen('true')} className="admin-info__btn-del">
					<DeleteSvg />
					<span className="user-data__toggle-text red">Delete administrator</span>
				</div>
			</div>
			<div className="admin-info__box-first">
				{admin && (
					<BoxFirst
						admin={admin}
						changeAdmin={changeAdmin as IChangeAdmin}
						onChangeAdmin={onChangeAdmin}
					/>
				)}
			</div>
			<div className="admin-info__box-first">
				<BoxSecond changeAdmin={changeAdmin as IChangeAdmin} onChangeAdmin={onChangeAdmin} />
			</div>
			<div className="admin-info__box-first">
				<BoxThird changeAdmin={changeAdmin as IChangeAdmin} onChangeAdmin={onChangeAdmin} />
			</div>
			<div className="admin-info__box-last">
				<BoxFooter admin={admin} onEnableOrDisable2Fa={onEnableOrDisable2Fa} />
			</div>
		</div>
	);
};
