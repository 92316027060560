import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Image } from './Image/Image';

export const GroupsChannels: FC = () => {
	return (
		<section className="user-data__details-user details-user">
			<ul className="details-user__box-text">
				<li className="details-user__item">
					<span>Type</span>
					<span>Paid</span>
				</li>
				<li className="details-user__item">
					<span>Group Name</span>
					<span>Motorists</span>
				</li>
				<li className="details-user__item">
					<span>Group URL</span>
					<Link className="details-user__link" to="/">
						/Motorists
					</Link>
				</li>
				<li className="details-user__item">
					<span>Created date</span>
					<span>Sep 24, 2022, 3:06 PM</span>
				</li>
				<li className="details-user__item">
					<span>Owner</span>
					<Link className="details-user__link" to="/">
						Cameron Williamson
					</Link>
				</li>
				<li className="details-user__item">
					<span>Age restrictions</span>
					<span>-</span>
				</li>
				<li className="details-user__item2">
					<span>Channel Description</span>
				</li>
				<li className="details-user__item2">
					<span>
						Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
						consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
					</span>
				</li>
			</ul>
			<div className="details-user__img-wrapper">
				<Image />
			</div>
		</section>
	);
};
