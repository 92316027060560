import { IChannelsRequest } from 'redux/reducers/reports/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IFinanceApi } from './types';
import {
	ICommissionsResonse,
	IFiatRequest,
	IMethods,
	IStatisticsRequest,
	ITransactionGraphRequest,
	IWithdrawalUpdate,
} from 'redux/reducers/finance/finance-types';

export const finance: IFinanceApi = {
	getFiat: (payload: IFiatRequest) =>
		http.post(endpoint.finance.WITHDRAW_REQUEST, payload).then((response) => response.data),
	getFiatDetails: (payload: string) =>
		http.get(endpoint.finance.WITHDRAW_REQUEST_ID(payload)).then((response) => response.data),
	getWithdrawalUpdate: (payload: IWithdrawalUpdate) =>
		http.post(endpoint.finance.WITHDRAW_REQUEST_UPDATE, payload).then((response) => response.data),
	getCommissions: () => http.get(endpoint.finance.COMMISSIONS).then((response) => response.data),
	changeCommission: (payload: ICommissionsResonse) =>
		http.post(endpoint.finance.COMMISSIONS, payload).then((response) => response.data),
	getMethods: () => http.get(endpoint.finance.PAYMENT_LIMITS).then((response) => response.data),
	changeMethods: (payload: IMethods) =>
		http.post(endpoint.finance.PAYMENT_LIMITS, payload).then((response) => response.data),
	getTransactions: (payload: IStatisticsRequest) =>
		http.post(endpoint.finance.TRANSACTIONS, payload).then((response) => response.data),
	getBalancesDeposit: () =>
		http.get(endpoint.finance.DEPOSIT_BALANCES).then((response) => response.data),
	getBalancesWithdrawal: () =>
		http.get(endpoint.finance.WITHDRAW_BALANCES).then((response) => response.data),
	getTransactionGraph: (payload: ITransactionGraphRequest) =>
		http.post(endpoint.finance.TRANSACTIONS_GRAPH, payload).then((response) => response.data),
	getPDF: (payload: string) =>
		http.get(endpoint.finance.GET_PDF(payload)).then((response) => response.data),
};
