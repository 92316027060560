import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'redux/store';
import { ToastContainer } from 'react-toastify';
import App from 'components/App';

import 'react-toastify/dist/ReactToastify.css';
import 'assets/styles/scss/style.scss';
import 'assets/styles/custom/styles.scss';
import 'react-datepicker/dist/react-datepicker.css';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
	<ReduxProvider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<App />
			<ToastContainer />
		</PersistGate>
	</ReduxProvider>,
);
