import { channelsTabs, mediaTabs } from 'CONST/channels-tabs';
import { ArrowBackSvg, SearchSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { ChannelTabs } from 'components/ChannelsDetailsComponents/ChannelTabs/ChannelTabs';
import { ChannelsAdmins } from 'components/ChannelsDetailsComponents/ChannelsAdmins/ChannelsAdmins';
import { ChannelsData } from 'components/ChannelsDetailsComponents/ChannelsData/ChannelsData';
import { MediaAndFiles } from 'components/ChannelsDetailsComponents/MediaAndFiles/MediaAndFiles';
import { MediaSubtabs } from 'components/ChannelsDetailsComponents/MediaAndFiles/MediaSubtabs/MediaSubtabs';
import { MediaSales } from 'components/ChannelsDetailsComponents/MediaSales/MediaSales';
import { Members } from 'components/ChannelsDetailsComponents/Members/Members';
import { Posts } from 'components/ChannelsDetailsComponents/Posts/Posts';
import { SubTabsDetailsUser } from 'components/UserDetailsComponents/SubTabsDetailsUser';
import { SubTabsConversations } from 'components/UserDetailsComponents/SubTabsGroups';
import { ChangeEvent, FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
	getAdminsChannelsRequest,
	getDetailsChannelRequest,
	getMediaSalesRequest,
	getMembersRequest,
	getPostsRequest,
	postMediaFilesRequest,
} from 'redux/reducers/channels/reducer';
import {
	getAdminsSelector,
	getChannelsLoaderSelector,
	getDetailsSelector,
	getListMediaFilesSelector,
	getMediaSalesSelector,
	getMembersSelector,
	getPostsSelector,
} from 'redux/reducers/channels/selectors';
import {
	IAdminsChannelsResponse,
	IChannelsResponse,
	IDetailsChannel,
	IMediaFilesListResponse,
	IMembersResponse,
	IPostsResponse,
} from 'redux/reducers/channels/types';
import { putCounties } from 'redux/reducers/users/reducer';
import { getAllCountries } from 'redux/reducers/users/selectors';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EChannelsTabs, ETabsUsersDetails } from 'services/constants/lists-table';
import { IDataFiltter } from '../Channels';
import { Loader } from 'components/Loader';
import { Pagination } from 'ui/Pagination';
import { Refunds } from 'components/ChannelsDetailsComponents/Refunds/Refunds';

const ChannelsDetailsUser: FC = () => {
	const params = useParams();
	const location = useLocation();
	const dispatch = useDispatch();
	const countriesList = useSelector(getAllCountries);
	const [search, setSearch] = useState<string>('');
	const [type, setType] = useState<string>('media');
	const [dataFiltter, setDataFiltter] = useState<IDataFiltter>({});
	const [currentPage, setCurrentPage] = useState<number>(1);
	const nameChannel = useSelector(getDetailsSelector);
	const loader = useSelector(getChannelsLoaderSelector);

	const onPagination = (value: number) => {
		setCurrentPage(value);
	};

	const onSetType = (value: string) => {
		setType(value);
	};

	const onRestTab = useCallback(() => {
		setType('media');
	}, []);

	const defineSelector = () => {
		if (params.tab === EChannelsTabs.ADMINS) {
			return getAdminsSelector;
		}
		if (params.tab === EChannelsTabs.MEMBERS) {
			return getMembersSelector;
		}
		if (params.tab === EChannelsTabs.MEDIA_SALES) {
			return getMediaSalesSelector;
		}
		if (params.tab === EChannelsTabs.POST) {
			return getPostsSelector;
		}
		if (params.tab === EChannelsTabs.MEDIA_AND_FILES) {
			return getListMediaFilesSelector;
		}
		return getDetailsSelector;
	};

	// @ts-ignore
	const tableDate: any = useSelector(defineSelector());

	const definePage = () => {
		switch (params.tab) {
			case EChannelsTabs.ADMINS:
				return (
					<ChannelsAdmins
						admins={tableDate as IAdminsChannelsResponse}
						onPagination={onPagination}
					/>
				);
			case EChannelsTabs.MEMBERS:
				return <Members members={tableDate as IMembersResponse} setDataFiltter={setDataFiltter} />;
			case EChannelsTabs.MEDIA_SALES:
				return <MediaSales />;
			case EChannelsTabs.POST:
				return <Posts posts={tableDate as IPostsResponse} loader={loader} />;
			case EChannelsTabs.MEDIA_AND_FILES:
				return <MediaAndFiles onRestTab={onRestTab} media={tableDate as IMediaFilesListResponse} />;
			case EChannelsTabs.REFUNDS:
				return <Refunds members={tableDate as IMembersResponse} setDataFiltter={setDataFiltter} />;
			default:
				return (
					tableDate && (
						<ChannelsData channel={tableDate as IDetailsChannel} countriesList={countriesList} />
					)
				);
		}
	};

	const onSearch = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		setSearch(value);
		setDataFiltter?.((prev: IDataFiltter) => {
			return {
				...prev,
				search: value,
			};
		});
	};

	useEffect(() => {
		dispatch(getDetailsChannelRequest(params.id || ''));
		fetch('https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code')
			.then((response) => response.json())
			.then((data) => {
				dispatch(putCounties(data.countries));
				// setSelectedCountry(data.userSelectValue);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	useEffect(() => {
		if (params.tab === EChannelsTabs.ADMINS) {
			dispatch(
				getAdminsChannelsRequest({
					paramsApi: {
						current_page: currentPage,
						per_page: 15,
						search: search,
					},
					id: params.id as string,
				}),
			);
		}
		if (params.tab === EChannelsTabs.MEMBERS) {
			dispatch(
				getMembersRequest({
					paramsApi: {
						...dataFiltter,
						current_page: currentPage,
						per_page: 15,
						is_blocked: false,
						account_type: params.subTab === 'business-profile' ? 'business' : 'personal',
					},
					id: params.id as string,
				}),
			);
		}
		if (params.tab === EChannelsTabs.MEDIA_SALES) {
			// dispatch(
			// 	getMediaSalesRequest({
			// 		paramsApi: {
			// 			current_page: currentPage,
			// 			per_page: 15,
			// 		},
			// 		id: params.id as string,
			// 	}),
			// );
		}
		if (params.tab === EChannelsTabs.POST) {
			dispatch(
				getPostsRequest({
					paramsApi: {
						current_page: currentPage,
						per_page: 15,
						search: search,
					},
					id: params.id as string,
				}),
			);
		}
		if (params.tab === EChannelsTabs.MEDIA_AND_FILES) {
			dispatch(
				postMediaFilesRequest({
					body: {
						current_page: currentPage,
						per_page: 15,
						search: search,
						type,
					},
					channel_id: params.id as string,
				}),
			);
		}
	}, [params.tab, params.subTab, currentPage, dataFiltter, search, type]);

	return (
		<div className="main-content">
			<div className="url color-grey big-first-letter">
				{location.pathname.slice(1).replaceAll('/', ' / ')}
			</div>
			<div className="title-block-wrap">
				<div className="title-block">
					<Link className="back-button" to={ELinksName.CHANNELS}>
						<ArrowBackSvg />
					</Link>
					<p className="title big-first-letter">{nameChannel?.title}</p>
				</div>
			</div>
			<ChannelTabs />
			<div className="table-block">
				<div className="filter-custom">
					{!channelsTabs.includes(params.tab || '') && (
						<div
							className={classNames(
								'input input-item-margin input-custom input-search input-search-custom',
								{
									'input-search-wrapper': mediaTabs.includes(params.tab || ''),
								},
							)}
						>
							{mediaTabs.includes(params.tab || '') && (
								<MediaSubtabs onRestTab={onRestTab} onSetType={onSetType} />
							)}
							{params.tab && (
								<div className="input-wrapper input-wrapper-custom">
									<DebounceInput
										className="input-item input-item--left-icon"
										type="text"
										debounceTimeout={500}
										name="search"
										placeholder={
											params.tab === 'posts' ? 'Author' : 'Search by user, username, email'
										}
										value={search || ''}
										onChange={onSearch}
									/>
									<span className="input-icon">
										<SearchSvg />
									</span>
								</div>
							)}
						</div>
					)}
					{params.tab === ETabsUsersDetails.CHANNELS && <SubTabsDetailsUser />}
					{params.tab === ETabsUsersDetails.CONVERSATION && <SubTabsConversations />}
					<div className="table-wrapper">{loader ? <Loader /> : definePage()}</div>
				</div>
			</div>
			{!loader && tableDate && tableDate.total > 15 ? (
				<Pagination
					request={onPagination}
					currentPage={currentPage}
					numberPage={Math.ceil(tableDate.total / 15)}
				/>
			) : null}
		</div>
	);
};

export default ChannelsDetailsUser;
