import { store } from 'redux/store';
import { getAuth } from 'redux/reducers/auth/selectors';
import { IAxiosRequestConfig } from './types';

// ==========================================:
const onSuccess = (config: IAxiosRequestConfig) => {
	const requestConfig = config;
	const state: any = store.getState();
	const auth = getAuth(state);

	if (auth && auth.accessToken) {
		requestConfig.headers = {
			...config.headers,
			Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
		};
	}

	return requestConfig;
};

export default onSuccess;
