import { FC } from 'react';

import { Checkbox } from 'ui/Checkbox/Checkbox';
import { IBoxFirstProps } from '../types';

export const BoxThird: FC<IBoxFirstProps> = ({ changeAdmin, onChangeAdmin }) => {
	return (
		<ul className="admin-info__list">
			<li className="admin-info__item">
				<span className="admin-info__first-column">Role</span>
				<span className="admin-info__second-column">
					<Checkbox
						id="admin"
						name="role"
						type="radio"
						checked={
							changeAdmin?.role?.toLowerCase() === 'admin' ||
							changeAdmin?.role?.toLowerCase() === 'owner'
						}
						onChange={onChangeAdmin}
						title="Admin"
						subtitle="Admins can add and remove users and manage channel-level settings."
					/>
					<Checkbox
						id="user"
						name="role"
						type="radio"
						checked={changeAdmin?.role?.toLowerCase() === 'user'}
						onChange={onChangeAdmin}
						title="User Manager"
						subtitle="Admins can add and remove users and manage channel-level settings."
					/>
					<Checkbox
						id="help"
						name="role"
						type="radio"
						checked={changeAdmin?.role?.toLowerCase() === 'help'}
						onChange={onChangeAdmin}
						title="Help Desk"
						subtitle="Admins can add and remove users and manage channel-level settings."
					/>
					<Checkbox
						id="manager"
						name="role"
						type="radio"
						checked={changeAdmin?.role?.toLowerCase() === 'manager'}
						onChange={onChangeAdmin}
						title="Billing manager"
						subtitle="Admins can add and remove users and manage channel-level settings."
					/>
				</span>
			</li>
		</ul>
	);
};
