import { DownloadUpSvg } from 'assets/svg-icon';
import { FC, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';

const fileTypes = ['CSV'];

interface IProps {
	handleChange: (file: FileList) => void;
	value?: string;
	title: string;
	typeFile?: string;
}

export const Drop: FC<IProps> = ({ handleChange, title, typeFile }) => {
	const stack2 = (
		<div className="drop-container">
			<DownloadUpSvg />
			<div className="drop-container__type-title">{title}</div>
			<div className="drop-container__types">{typeFile}</div>
			<button className="drop-container__btn" type="button">
				Browse file
			</button>
		</div>
	);

	return (
		<div className="drop">
			<FileUploader
				classes="drop-custom"
				multiple={false}
				handleChange={handleChange}
				name="file"
				types={fileTypes}
			>
				{stack2}
			</FileUploader>
		</div>
	);
};
