import { SearchSvg } from 'assets/svg-icon';
import { ChangeEvent, FC } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Button } from 'ui/Button/Button';
import { CheckBoxTwo } from 'ui/CheckBoxTwo/CheckBoxTwo';

interface IProps {
	title: string;
	subtitle?: string;
	data: Array<{ ava: string; title: string; subtitle: string }>;
	onModal: () => void;
	onNextModal?: () => void;
	onHandleSearch: (event: ChangeEvent) => void;
	search: string;
}

export const Modal: FC<IProps> = ({
	title,
	subtitle,
	data,
	onModal,
	onNextModal,
	onHandleSearch,
	search,
}) => {
	return (
		<div className="modal">
			<div className="modal__wrap">
				<div className="modal__title">{title}</div>
				{subtitle ? (
					<div className="modal__subtitle">{subtitle}</div>
				) : (
					<div className="input input-custom input-search input-search-custom input-search-width380">
						<div className="input-wrapper input-wrapper-custom input-wrapper-custom100">
							<DebounceInput
								className="input-item input-item--left-icon"
								type="text"
								name="search"
								placeholder="Search channel by name"
								value={search || ''}
								onChange={onHandleSearch}
								debounceTimeout={500}
							/>
							<span className="input-icon">
								<SearchSvg />
							</span>
						</div>
					</div>
				)}
				<div className="modal__list">
					{data.map((file, index) => (
						<div key={index} className="modal__item">
							<CheckBoxTwo
								type={subtitle ? '' : 'checkbox'}
								// checked
								title={file.title}
								subtitle={file.subtitle}
								photo={file.ava}
							/>
						</div>
					))}
				</div>
				<div className="modal__btn-wrap">
					<Button className="button-border" onClick={onModal} type={'button'} name={'Cancel'} />
					<Button
						className="button-wrapper"
						onClick={onNextModal}
						type={'button'}
						name={subtitle ? 'Yes, I’m sure' : 'Subscribe'}
					/>
				</div>
			</div>
		</div>
	);
};
