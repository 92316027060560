import { FC } from 'react';

import { IToastifyComponent } from './types';
// ==========================================:
const ToastifyComponent: FC<IToastifyComponent> = ({ message, toastProps }) => {
	return (
		<div className="toastify-component">
			<div className="notification__info">
				<div className="notification__title">
					<p>
						{toastProps?.type === 'error' && 'Error'}
						{toastProps?.type === 'success' && 'Successful'}
						{toastProps?.type === 'info' && 'Information'}
					</p>
				</div>
				<div className="notification__text">
					<p className="notification__text--custom">{message}</p>
				</div>
			</div>
		</div>
	);
};

export default ToastifyComponent;
