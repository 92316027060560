import { FC } from 'react';
import { ChannelsHeader } from './ChannelsHeader/ChannelsHeader';
import { ChannelsBody } from './ChannelsBody/ChannelsBody';
import { IAdminsChannelsResponse } from 'redux/reducers/channels/types';
import { NotFound } from 'ui/NotFound/NotFound';

interface IPropsChannelsAdmins {
	admins: IAdminsChannelsResponse;
	onPagination: (value: number) => void;
}

export const ChannelsAdmins: FC<IPropsChannelsAdmins> = ({ admins, onPagination }) => {
	console.log(admins?.channel_admins);
	return (
		<div className="table table--channels-private-admins margin-top">
			<ChannelsHeader />
			{admins?.channel_admins?.length ? (
				<ChannelsBody admins={admins} />
			) : (
				<NotFound name={'Channel Admins'} />
			)}
		</div>
	);
};
