import { FC } from 'react';
import { ColumnChart } from 'ui/ColumnChart/ColumnChart';

interface IProps {
	balance: Array<{ name: string; amount: string; id: number }>;
}

const data = [
	['', '', { role: 'style' }],
	['2021', 100, '#27AE60'],
	['2022', 4000, '#27AE60'],
	['2023', 3000, '#27AE60'],
	['2024', 150, '#27AE60'],
	['2025', 1200, 'color: #e5e4e2'],
	['2026', 170, 'color: #e5e4e2'],
];

const options = {
	legend: { position: 'none' },
};

export const CardBalance: FC<IProps> = ({ balance }) => {
	return (
		<section className="card-crypto card-balance">
			{balance.map((el) => (
				<div key={el.id} className="card-crypto__wrapper border-none">
					<div className="card-balance__header">
						<div className="card-crypto__title-wrapper">
							<div className="card-balance__name">{el.name}</div>
							<div className="card-balance__subname">{el.amount}</div>
						</div>
					</div>
					<ColumnChart data={data} options={options} />
				</div>
			))}
		</section>
	);
};
