import { IDataFiltter } from 'pages/Dashboard/Channels';
import { FC, useState, MouseEvent } from 'react';
import { SvgArrowDownHeader, SvgArrowUpHeader } from 'ui/svg-image/SvgAllChannels';

interface IProps {
	dataFiltter: IDataFiltter;
	setDataFiltter: (value: IDataFiltter) => void;
}

export const CodesInfoHeader: FC<IProps> = ({ setDataFiltter }) => {
	const [sort, setSort] = useState(0);
	const [prev, setPrev] = useState<string>('');

	const sortTable = (event: MouseEvent) => {
		const target = event.currentTarget as HTMLButtonElement;

		if (prev !== target.id && sort !== 0) {
			setSort(1);
			setPrev(target.id);
			setDataFiltter({ order_by: target.id, asc: true });
			return;
		}

		switch (sort + 1) {
			case 1:
				setDataFiltter({ order_by: target.id, asc: true });

				break;
			case 2:
				setDataFiltter({ order_by: target.id, asc: false });

				break;
			default:
				setDataFiltter({ order_by: target.id });

				break;
		}

		if (sort === 2) {
			setSort(0);
			setPrev(target.id);
			return;
		}
		setSort((prevNum) => prevNum + 1);
		setPrev(target.id);
	};
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Code</p>
						<div className="sort-td">
							<button onClick={sortTable} id="code" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="code" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p> Status </p>
						<div className="sort-td">
							<button onClick={sortTable} id="status" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="status" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>Activation date</p>
						<div className="sort-td">
							<button onClick={sortTable} id="date" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="date" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>User ID</p>
						<div className="sort-td">
							<button onClick={sortTable} id="user_id" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="user_id" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>

				<div className="td td--right">
					<div className="td-name">
						<p>Action</p>
					</div>
				</div>
			</div>
		</div>
	);
};
