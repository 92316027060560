import axios, { AxiosInstance } from 'axios';
import { WEB_API_URL, WEB_API_URL_STAGE } from 'services/constants/env';
import onError from './interceptor/onError';
import onSuccess from './interceptor/onSuccess';
import onSuccessMultipart from './interceptor/onSuccessMultipart';
import { onHttp } from 'untils/onHttp';
// import { IAuthResponse } from 'redux/reducers/auth/types';
// import { endpoint } from 'services/endpoint';
// import { refreshToken } from 'redux/reducers/auth/reducer';

const http: AxiosInstance = axios.create({
	baseURL: onHttp(),
	withCredentials: false,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
});

// http.interceptors.request.use((config) => {
// 	config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

// 	return config;
// });

// http.interceptors.response.use(
// 	(config) => {
// 		return config;
// 	},
// 	async (error) => {
// 		const originalRequest = error.config;
// 		console.log(originalRequest);
// 		if (error.response.status === 401 && error.config && !error.config._isRetry) {
// 			originalRequest._isRetry = true;
// 			try {
// 				refreshToken(localStorage.getItem('refresh_token') || '');
// 				return http.request(originalRequest);
// 			} catch (error) {
// 				console.log(error);
// 			}
// 		}

// 		throw error;
// 	},
// );

// @ts-ignore
http.interceptors.request.use(onSuccess);
http.interceptors.response.use((response) => response, onError);

export { http };

// const httpSoket: AxiosInstance = axios.create({
// 	baseURL: WEB_SOKET_API_URL,
// 	headers: {
// 		'Access-Control-Allow-Origin': '*',
// 		'Content-Type': 'application/json',
// 		Accept: 'application/json',
// 	},
// });
// // @ts-ignore
// http.interceptors.request.use(onSuccess);
// http.interceptors.response.use((response) => response, onError);

// export { httpSoket };

// const multipart: AxiosInstance = axios.create({
// 	baseURL: WEB_API_URL,
// 	withCredentials: false,
// 	responseType: 'blob',
// 	headers: {
// 		'Access-Control-Allow-Origin': '*',
// 		'Content-Type': 'multipart/form-data',
// 	},
// });

// // @ts-ignore
// multipart.interceptors.request.use(onSuccess);
// multipart.interceptors.response.use((response) => response, onError);

// export { multipart };

const multipart: AxiosInstance = axios.create({
	baseURL: onHttp(),
	withCredentials: false,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'multipart/form-data',
	},
});
// @ts-ignore
multipart.interceptors.request.use(onSuccessMultipart);
// multipart.interceptors.response.use((response) => response, onError);

export { multipart };
