import { FC, MouseEvent, useEffect } from 'react';
import { MediaHeader } from './MediaHeader/MediaHeader';
import { MediaBody } from './MediaBody/MediaBody';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { EMediaSubtabs } from 'services/constants/lists-table';
import { IMediaFilesListResponse } from 'redux/reducers/channels/types';
import { NotFound } from 'ui/NotFound/NotFound';

interface IProps {
	media: IMediaFilesListResponse;
	onRestTab?: () => void;
}

export const MediaAndFiles: FC<IProps> = ({ onRestTab, media }) => {
	const params = useParams();

	return (
		<div
			className={classNames('table table--channels-private-media margin-top', {
				'table--channels-private-media-link': EMediaSubtabs.LINKS === params.subTab,
			})}
		>
			<MediaHeader />
			{media?.files?.length ? (
				<MediaBody media={media.files} />
			) : (
				<NotFound name={'media and files'} />
			)}
		</div>
	);
};
