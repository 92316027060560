import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IAuthStore } from 'redux/reducers/auth/types';

const getAuthState = (state: IStoreState): IAuthStore => state.auth;
export const getAuth = createSelector([getAuthState], (auth: IAuthStore) => auth);

export const getIsAuth = createSelector([getAuth], (auth: IAuthStore): boolean => {
	return auth.isAuthenticated;
});

export const getAuthIsAuthenticated = createSelector([getAuth], (auth: IAuthStore): boolean => {
	return !!(auth.isAuthenticated && auth.accessToken);
});

export const getAuthIsNotAuthenticated = createSelector([getAuth], (auth: IAuthStore): boolean => {
	return !auth.isAuthenticated && !auth.accessToken;
});

export const getToken = createSelector(
	[getAuth],
	(auth: IAuthStore): string | null => auth.accessToken,
);

export const getUserData = createSelector([getAuthState], (auth) => auth.userData);
export const getQr = createSelector([getAuthState], (auth) => auth.qrCode);
export const getLoader = createSelector([getAuthState], (auth) => auth.loginLoader);
export const getRfreshToken = createSelector(
	[getAuthState],
	(auth) => auth.userData?.refresh_token,
);
