import { TTopCard } from 'pages/Dashboard/Dashboard/data';
import { FC } from 'react';
import './CardTop.scss';

export const CardTop: FC<{ item: TTopCard }> = ({ item }) => {
	return (
		<div className="card-top">
			<div className="card-top__header">
				<div className="card-top__svg">{item.svg}</div>
				<div className="card-top__title">{item.title}</div>
			</div>
			<div className="card-top__body">
				<ul className="card-top__list">
					{item.top.map((top, index) => (
						<li key={index} className="card-top__item">
							<span className="card-top__td black">{index + 1}</span>
							<span className="card-top__td">
								<img src={top.ava} alt="" />
							</span>
							<span className="card-top__td blue">{top.name}</span>
							<span className="card-top__td">
								{top.amount}
								{top.svg}
							</span>
						</li>
					))}
				</ul>
			</div>
			<button className="card-top__btn">Show More</button>
		</div>
	);
};
