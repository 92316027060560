import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLoader, getUsersList } from 'redux/reducers/users/selectors';
import { IDataFiltter } from '../Channels';
import { Fillter } from 'ui/Fillter/Fillter';
import { Loader } from 'components/Loader';
import { Pagination } from 'components/Pagination';
import { mockDataUsers } from 'components/UsersComponents/UsersTableBody/mockDataUsers';
import { typeFilter, typeStatus } from 'CONST/userFilter';
import { GroupsHeader } from 'components/GroupsComponents/GroupsHeader/GroupsHeader';
import { GroupsBody } from 'components/GroupsComponents/GroupsBody/GroupsBody';
import { GroupsTabs } from 'components/GroupsComponents/GroupsTabs/GroupsTabs';

export const Groups: FC = () => {
	const loader = useSelector(getLoader);
	const usersList = useSelector(getUsersList);
	const params = useParams();

	const [dataFiltter, setDataFiltter] = useState<any>({
		order_by: '',
		sort: '',
	});

	const submitFilter = (value: number) => {};
	return (
		<div className="main-content">
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title">Groups</p>
				</div>
			</div>
			<GroupsTabs />
			<div className="table-block">
				<Fillter
					keyStatus="status"
					keyType="types"
					type={typeFilter}
					status={typeStatus}
					typesName="Types"
					statusName="Status"
				/>
				<div className="filter-custom__info-card">
					{['Total', 'Private', 'Public'].map((el) => (
						<div key={el} className="filter-custom__card">
							<p className="filter-custom__title">{el}</p>
							<p className="filter-custom__subtitle">2 568</p>
						</div>
					))}
				</div>
				<div className="table-wrapper">
					<div className="table table--users-private-groups">
						<GroupsHeader />
						{loader ? <Loader /> : (usersList?.data.users || mockDataUsers) && <GroupsBody />}
					</div>
				</div>
			</div>
			{usersList?.page_count && usersList?.page_count > 15 ? (
				<Pagination
					request={submitFilter}
					currentPage={usersList?.page}
					numberPage={Math.ceil(usersList?.page_count / 15)}
				/>
			) : null}
		</div>
	);
};
