import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ESubscriptionTabs, ESubtabsUsersDetails } from 'services/constants/lists-table';
import { SvgArrowDownHeader, SvgArrowUpHeader } from 'ui/svg-image/SvgAllChannels';

export const SubscriptionsHeader: FC = () => {
	const { subTab } = useParams();

	const onSwitchAvatar = () => {
		switch (subTab) {
			// case ESubscriptionTabs.COURSE_CHANNEL:
			// 	return 'Subscription date';
			case ESubscriptionTabs.BACKGROUND:
				return 'Content';
			default:
				return 'Avatar';
		}
	};

	const onSwitchChannelName = () => {
		switch (subTab) {
			// case ESubscriptionTabs.COURSE_CHANNEL:
			// 	return 'Subscription date';
			case ESubscriptionTabs.BACKGROUND:
				return 'Price';
			case ESubscriptionTabs.AI:
				return 'Subscription cost';
			default:
				return 'Cost';
		}
	};

	const onSwitchCost = () => {
		switch (subTab) {
			// case ESubscriptionTabs.COURSE_CHANNEL:
			// 	return 'Subscription date';
			case ESubscriptionTabs.BACKGROUND:
				return 'Name';
			case ESubscriptionTabs.AI:
				return 'AI name';
			default:
				return 'Channel name';
		}
	};

	const onSwitchUrl = () => {
		switch (subTab) {
			// case ESubscriptionTabs.COURSE_CHANNEL:
			// 	return 'Subscription date';
			case ESubscriptionTabs.BACKGROUND:
				return 'Description';
			default:
				return 'Channel URL';
		}
	};

	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>{onSwitchAvatar()}</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>{onSwitchChannelName()}</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				{subTab !== ESubscriptionTabs.AI && (
					<div className="td">
						<div className="td-name">
							<p>{onSwitchUrl()}</p>
							<div className="sort-td">
								<button type="button">
									<SvgArrowUpHeader />
								</button>
								<button type="button">
									<SvgArrowDownHeader />
								</button>
							</div>
						</div>
					</div>
				)}
				<div className="td">
					<div className="td-name">
						<p>Type</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Start date</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Due date</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>{onSwitchCost()}</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Status</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Action</p>
					</div>
				</div>
			</div>
		</div>
	);
};
