import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ETabsContent, ETabsManagement } from 'services/constants/lists-table';

export const SubTabs: FC = () => {
	const params = useParams();

	return (
		<div className="table-tabs table-tabs--type2 table-tabs-custom tab-up">
			<Link
				to={`${ELinksName.MANAGEMENT_DETAILS_PERSONAL_DATA}/${ETabsManagement.CONTENT}/${ETabsContent.TRACK}`}
				className={classNames('table-tabs__item', {
					active: params.subTab === ETabsContent.TRACK || !params.subTab,
				})}
			>
				Track
			</Link>
			<Link
				to={`${ELinksName.MANAGEMENT_DETAILS_PERSONAL_DATA}/${ETabsManagement.CONTENT}/${ETabsContent.ALBUM}`}
				className={classNames('table-tabs__item', {
					active: params.subTab === ETabsContent.ALBUM,
				})}
			>
				Album
			</Link>
		</div>
	);
};
