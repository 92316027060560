import { SearchPeopleSvg } from 'assets/svg-icon';
import { FC } from 'react';

export const NotFound: FC<{ name: string }> = ({ name }) => {
	return (
		<div className="wrapper-not-found">
			<SearchPeopleSvg />
			<p>
				We couldn&apos;t find anything for <span>{name}</span>. Please try another query.
			</p>
		</div>
	);
};
