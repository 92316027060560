import { DownloadSvg } from 'assets/svg-icon';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from 'ui/Checkbox/Checkbox';
import zip from '../../../../assets/images/zip.png';

export const Details: FC = () => {
	const onChangeAdmin = () => {};

	return (
		<div className="table-block request">
			<h3 className="request__title request__title--margin-bottom">Request data</h3>
			<div className="line-bottom gap-list">
				<div className="request__list">
					<span className="request__subtitle">Request ID</span>
					<Link to={'/'} className="request__text blue">
						123456
					</Link>
				</div>
				<div className="request__list">
					<span className="request__subtitle">Request from</span>
					<span className="request__text">App</span>
				</div>
			</div>

			<h3 className="request__title">Personal data</h3>
			<div className="line-bottom gap-list">
				<div className="request__list">
					<span className="request__subtitle">Fullname</span>
					<span className="request__text">Albert Flores</span>
				</div>
				<div className="request__list">
					<span className="request__subtitle">Date of Birth</span>
					<span className="request__text">May 29, 2007</span>
				</div>
			</div>

			<h3 className="request__title">Contact Information</h3>
			<div className="line-bottom gap-list">
				<div className="request__list">
					<span className="request__subtitle">Email</span>
					<Link to={'/'} className="request__text blue">
						debbieker@example.com
					</Link>
				</div>
				<div className="request__list">
					<span className="request__subtitle">Type</span>
					<span className="request__text">(406) 555-0120</span>
				</div>
			</div>

			<h3 className="request__title">Company Information</h3>
			<div className="line-bottom gap-list">
				<div className="request__list">
					<span className="request__subtitle">Company name</span>
					<span className="request__text">Zotware</span>
				</div>
				<div className="request__list">
					<span className="request__subtitle">Address</span>
					<span className="request__text">Thornridge Cir. Syracuse, Connecticut</span>
				</div>
				<div className="request__list">
					<span className="request__subtitle">Website URL</span>
					<Link to={'/'} className="request__text blue">
						http://www.zotware.com
					</Link>
				</div>
			</div>

			<h3 className="request__title">Other Information</h3>
			<div className="line-bottom gap-list">
				<div className="request__list">
					<span className="request__subtitle">Description</span>
					<span className="request__text">Marketing Coordinator</span>
				</div>
			</div>

			<h3 className="request__title">Service</h3>
			<div className="line-bottom gap-list">
				<div className="request__list">
					<span className="admin-info__second-column">
						<Checkbox
							id="admin"
							name="role"
							type="radio"
							checked
							onChange={onChangeAdmin}
							title="Channel management"
							// subtitle="Admins can add and remove users and manage channel-level settings."
						/>
						<Checkbox
							id="user"
							name="role"
							type="radio"
							onChange={onChangeAdmin}
							title="Content ID"
							// subtitle="Admins can add and remove users and manage channel-level settings."
						/>
						<Checkbox
							id="help"
							name="role"
							type="radio"
							onChange={onChangeAdmin}
							title="Music distribution"
							// subtitle="Admins can add and remove users and manage channel-level settings."
						/>
						<Checkbox
							id="manager"
							name="role"
							type="radio"
							onChange={onChangeAdmin}
							title="Streaming"
							// subtitle="Admins can add and remove users and manage channel-level settings."
						/>
						<Checkbox
							id="manager"
							name="role"
							type="radio"
							onChange={onChangeAdmin}
							title="Billing manager"
							// subtitle="Admins can add and remove users and manage channel-level settings."
						/>
					</span>
				</div>
			</div>

			<h3 className="request__title">Documents confirming identity and legal activity</h3>
			{[1, 2, 3].map((el) => (
				<div key={el} className="request__file-wrap">
					<div className="request__file-inner">
						<img src={zip} alt="zip file" className="request__img" />
						<div className="request__name">Name example.zip</div>
					</div>
					<DownloadSvg />
				</div>
			))}
		</div>
	);
};
