import { AiManagement } from 'components/AiServicesComponent/AiManagement/AiManagement';
import { AiManagementSubTabs } from 'components/AiServicesComponent/AiManagementSubTabs/AiManagementSubTabs';
import { AiServicesTabs } from 'components/AiServicesComponent/AiServicesTabs/AiServicesTabs';
import { AiSettings } from 'components/AiServicesComponent/AiSettings/AiSettings';
import { AiTopics } from 'components/AiServicesComponent/AiTopics/AiTopics';
import { OfAiIncome } from 'components/AiServicesComponent/OfAiIncome/OfAiIncome';
import { UsersViolations } from 'components/AiServicesComponent/UsersViolations/UsersViolations';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	addBadWordRequest,
	deleteBadWordRequest,
	getBadWordsRequest,
} from 'redux/reducers/ai-services/reducer';
import { getForbiddenWordsSelector } from 'redux/reducers/ai-services/selectors';
import { EAiServicesTabs } from 'services/constants/lists-table';
import { Pagination } from 'ui/Pagination';
import { PopUpWrapper } from 'ui/PopUpWrapper/PopUpWrapper';

export const AiServices: FC = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const forbiddenWords = useSelector(getForbiddenWordsSelector);
	const [word, setWord] = useState<string>('');
	const [popup, setPopup] = useState<boolean>(false);
	const [open, setOpen] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [wordId, setWordId] = useState<number | null>(null);

	const isOpenModal = () => {
		setPopup(!popup);
	};

	const getId = (id?: number) => {
		setOpen(!open);
		id && setWordId(id);
	};

	const removeWord = () => {
		dispatch(
			deleteBadWordRequest({
				id: String(wordId),
				onClose: () => {
					getId();
				},
				body: {
					current_page: currentPage,
					per_page: 15,
				},
			}),
		);
	};

	const createWord = () => {
		word &&
			dispatch(
				addBadWordRequest({
					body: { word },
					onClose: () => {
						isOpenModal();
					},
				}),
			);
	};

	const handlInput = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		setWord(value);
	};

	const definePage = () => {
		switch (params.tab) {
			// case EAiServicesTabs.OF_AI_INCOME:
			// 	return <OfAiIncome />;
			// case EAiServicesTabs.AI_MANAGEMENT:
			// 	return <AiManagement />;
			// case EAiServicesTabs.AI_MONITORING_TOPICS:
			// 	return <AiTopics />;
			// case EAiServicesTabs.USERS_VIOLATIONS:
			// 	return <UsersViolations />;
			default:
				return (
					<AiSettings
						handlInput={handlInput}
						createWord={createWord}
						isOpenModal={isOpenModal}
						word={word}
						getId={getId}
						popup={popup}
						forbiddenWords={forbiddenWords}
					/>
				);
		}
	};

	const submitFilter = (value: number) => {
		setCurrentPage(value);
	};

	useEffect(() => {
		dispatch(
			getBadWordsRequest({
				current_page: currentPage,
				per_page: 15,
			}),
		);
	}, [currentPage]);

	return (
		<div className="main-content">
			{open && (
				<PopUpWrapper
					value={String(wordId)}
					handleButton={removeWord}
					isOpenModal={getId}
					title="Are you sure you want to delete this word?"
					btnName="Yes, I’m sure"
					disablet={word}
				/>
			)}

			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title">Ai Services</p>
				</div>
				<AiServicesTabs />
			</div>
			<div className="table-block">{definePage()}</div>
			{forbiddenWords?.words && forbiddenWords?.total > 15 ? (
				<Pagination
					request={submitFilter}
					currentPage={currentPage}
					numberPage={Math.ceil(forbiddenWords.total / 15)}
				/>
			) : null}
		</div>
	);
};
