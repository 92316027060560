export const SvgSmallSidebar = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M4 14L10 14L10 20" stroke="#070708" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M10 14L3 21" stroke="#070708" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M20 10L14 10L14 4" stroke="#070708" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M21 3L14 10" stroke="#070708" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
);

export const SvgBigSidebar = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M21 9V3H15" stroke="#070708" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M3 15V21H9" stroke="#070708" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M21 3L13.5 10.5" stroke="#070708" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M10.5 13.5L3 21" stroke="#070708" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
);

export const SvgChannels = () => (
	<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M4.5374 1.75H10.4561C10.8624 1.75 11.2249 1.76252 11.5499 1.80627C13.2811 2.00002 13.7499 2.81251 13.7499 5.03751V8.98751C13.7499 11.2125 13.2811 12.025 11.5499 12.2188C11.2249 12.2625 10.8686 12.275 10.4561 12.275H4.5374C4.13115 12.275 3.76865 12.2625 3.44365 12.2188C1.7124 12.025 1.24365 11.2125 1.24365 8.98751V5.03751C1.24365 2.81251 1.7124 2.00002 3.44365 1.80627C3.76865 1.76252 4.13115 1.75 4.5374 1.75Z"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M8.4873 5.69995H10.7873" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M4.2124 9.31873H4.2249H10.7937"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M4.375 14.25H10.625" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M4.49657 5.6875H4.50218"
			stroke="white"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.55907 5.6875H6.56468"
			stroke="white"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const SvgUsersSidebar = () => (
	<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.5 7C8.22589 7 9.625 5.60089 9.625 3.875C9.625 2.14911 8.22589 0.75 6.5 0.75C4.77411 0.75 3.375 2.14911 3.375 3.875C3.375 5.60089 4.77411 7 6.5 7Z"
			stroke="#070708"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11.8688 13.25C11.8688 10.8313 9.4626 8.875 6.5001 8.875C3.5376 8.875 1.13135 10.8313 1.13135 13.25"
			stroke="#070708"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const SvgTransactionsSidebar = () => (
	<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M2.45605 10.4246L9.9248 2.95581"
			stroke="#070708"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.93823 11.9243L7.68823 11.1743"
			stroke="#070708"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.62061 10.243L10.1144 8.74927"
			stroke="#070708"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M2.25079 6.89944L6.40079 2.74944C7.72579 1.42444 8.38829 1.41819 9.70079 2.73069L12.7695 5.79944C14.082 7.11194 14.0758 7.77444 12.7508 9.09944L8.60079 13.2494C7.27579 14.5744 6.61329 14.5807 5.30079 13.2682L2.23204 10.1994C0.919544 8.88694 0.919544 8.23069 2.25079 6.89944Z"
			stroke="#070708"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M1.25 14.249H13.75"
			stroke="#070708"
			strokeWidth="1.1"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const SvgReportsSidebar = () => (
	<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10.3251 3.03125L8.38758 1.53125C8.13758 1.28125 7.57508 1.15625 7.20008 1.15625H4.82508C4.07508 1.15625 3.26258 1.71875 3.07508 2.46875L1.57508 7.03125C1.26258 7.90625 1.82508 8.65625 2.76258 8.65625H5.26258C5.63758 8.65625 5.95008 8.96875 5.88758 9.40625L5.57508 11.4062C5.45008 11.9687 5.82508 12.5938 6.38758 12.7813C6.88758 12.9688 7.51258 12.7187 7.76258 12.3437L10.3251 8.53125"
			stroke="#070708"
			strokeMiterlimit="10"
		/>
		<path
			d="M13.5125 3.03125V9.15625C13.5125 10.0312 13.1375 10.3438 12.2625 10.3438H11.6375C10.7625 10.3438 10.3875 10.0312 10.3875 9.15625V3.03125C10.3875 2.15625 10.7625 1.84375 11.6375 1.84375H12.2625C13.1375 1.84375 13.5125 2.15625 13.5125 3.03125Z"
			stroke="#070708"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
