import { PayloadAction } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'untils/notificationContainer';
import {
	deactivateChannelRequest,
	deactivateGroupRequest,
	deactivateMessageRequest,
	rejectMessageRequest,
	rejectMessageSuccess,
	getReportPreviousRequest,
	getReportPreviousSuccess,
	getReportsChannelRequest,
	getReportsChannelSuccess,
	getReportsDetailsRequest,
	getReportsDetailsSuccess,
	getReportsGroupSuccess,
	getReportsGroupsRequest,
	getReportsMessagesRequest,
	getReportsMessagesSuccess,
	getReportsPostRequest,
	getReportsPostSuccess,
	getTypesRequest,
	getTypesSuccess,
	reportsInitState,
} from './reducer';
import {
	IChannelsRequest,
	IChannelsResponse,
	IListTypes,
	IPrivious,
	IRejectRequest,
	IReportDetails,
	IReportsPriviousSaga,
} from './types';
import { EReportsTabs } from 'services/constants/lists-table';

function* getChannelsWorker(action: PayloadAction<IChannelsRequest>) {
	const { payload } = action;

	try {
		const response: { data: IChannelsResponse } = yield call(api.reports.getChannels, payload);
		if (payload.export && typeof response === 'string') {
			const blob: Blob = response as Blob;
			const a = document.createElement('a');
			const file = new Blob([blob], { type: 'application/csv' });
			a.href = URL.createObjectURL(file);
			a.download = 'Reports.csv';
			a.click();
		} else {
			yield put(getReportsChannelSuccess(response.data));
		}
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* getPostsWorker(action: PayloadAction<IChannelsRequest>) {
	const { payload } = action;

	try {
		const response: { data: IChannelsResponse } = yield call(api.reports.getChannelPosts, payload);
		yield put(getReportsPostSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* getGroupsWorker(action: PayloadAction<IChannelsRequest>) {
	const { payload } = action;

	try {
		const response: { data: IChannelsResponse } = yield call(api.reports.getGroups, payload);
		yield put(getReportsGroupSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* getMessagesWorker(action: PayloadAction<IChannelsRequest>) {
	const { payload } = action;

	try {
		const response: { data: IChannelsResponse } = yield call(api.reports.getMessages, payload);
		yield put(getReportsMessagesSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* getReportsDetailsWorker(action: PayloadAction<string>) {
	const { payload } = action;

	try {
		const response: { data: IReportDetails } = yield call(api.reports.getReportsDetails, payload);
		yield put(getReportsDetailsSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* getReportPreviousWorker(action: PayloadAction<IReportsPriviousSaga>) {
	const { id, payload, page } = action.payload;

	try {
		let response: { data: IPrivious } | undefined;

		if (page === EReportsTabs.CHANNELS) {
			response = yield call(api.reports.getReportPreviousMessages, {
				id,
				payload,
			});
		}

		if (page === EReportsTabs.GROUPS) {
			response = yield call(api.reports.getReportPreviousGroups, {
				id,
				payload,
			});
		}

		if (page === EReportsTabs.CHANNEL_POSTS) {
			response = yield call(api.reports.getReportPreviousChannelMessages, {
				id,
				payload,
			});
		}

		if (page === EReportsTabs.MESSAGES_IN_GROUPS) {
			response = yield call(api.reports.getReportPreviousChannel, {
				id,
				payload,
			});
		}

		if (response) yield put(getReportPreviousSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* getTypesWorker() {
	try {
		const response: { data: Array<IListTypes> } = yield call(api.reports.getTypes);
		yield put(getTypesSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* rejectMessageWorker(action: PayloadAction<IRejectRequest>) {
	const { id, navigate } = action.payload;

	try {
		yield call(api.reports.rejectMessages, id);
		yield put(rejectMessageSuccess(id));
		navigate();
		notificationContainer('Reject is successfully', 'success');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* deactivateChannelWorker(action: PayloadAction<string>) {
	const { payload } = action;

	try {
		yield call(api.reports.deactivateChannel, payload);
		yield put(rejectMessageSuccess(payload));
		notificationContainer('The channel is successfully blocked', 'success');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* deactivateGroupWorker(action: PayloadAction<string>) {
	const { payload } = action;

	try {
		yield call(api.reports.deactivateGroupWorker, payload);
		yield put(rejectMessageSuccess(payload));
		notificationContainer('The post is successfully blocked', 'success');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* deactivateMessageWorker(action: PayloadAction<string>) {
	const { payload } = action;

	try {
		yield call(api.reports.deactivateMessageWorker, payload);
		yield put(rejectMessageSuccess(payload));
		notificationContainer('The post is successfully blocked', 'success');
	} catch (error) {
		notificationContainer('The post is successfully blocked', 'error');
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

export function* reportsSaga() {
	yield takeEvery(getReportsChannelRequest.type, getChannelsWorker);
	yield takeEvery(getReportsPostRequest.type, getPostsWorker);
	yield takeEvery(getReportsGroupsRequest.type, getGroupsWorker);
	yield takeEvery(getReportsMessagesRequest.type, getMessagesWorker);
	yield takeEvery(getReportsDetailsRequest.type, getReportsDetailsWorker);
	yield takeEvery(getReportPreviousRequest.type, getReportPreviousWorker);
	yield takeEvery(getTypesRequest.type, getTypesWorker);
	yield takeEvery(rejectMessageRequest.type, rejectMessageWorker);
	yield takeEvery(deactivateChannelRequest.type, deactivateChannelWorker);
	yield takeEvery(deactivateGroupRequest.type, deactivateGroupWorker);
	yield takeEvery(deactivateMessageRequest.type, deactivateMessageWorker);
}
