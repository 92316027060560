import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ETabsGroups } from 'services/constants/lists-table';

export const GroupsTabs: FC = () => {
	const params = useParams();

	return (
		<div className="tabs-buttons-nav">
			<Link
				to={`${ELinksName.GROUPS}/${ETabsGroups.ALL_GROUPS}/${params.subTab || ''}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsGroups.ALL_GROUPS || !params.tab,
				})}
			>
				All groups
			</Link>
			<Link
				to={`${ELinksName.GROUPS}/${ETabsGroups.BLOCKED_GROUPS}/${params.subTab || ''}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsGroups.BLOCKED_GROUPS,
				})}
			>
				Blocked groups
			</Link>
		</div>
	);
};
