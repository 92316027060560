import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import avatar from 'assets/images/avatar-square.png';
import { SvgVerifiedAccount } from 'ui/svg-image/SvgAllChannels';

export const GroupsBody: FC = () => {
	const params = useParams();

	return (
		<div className="table-body">
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name"> Avatar</p>
					<div className="avatar-wrapper">
						<div className="avatar avatar--circle">
							<img src={avatar} alt="" />
						</div>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name"> Groups name</p>
					<p>Gal Gadot - Offi...</p>
				</div>
				<div className="td">
					<p className="td-hidden-name"> Groups URL </p>
					<div>
						<Link
							to={`${ELinksName.GROUPS_DETAILS}/${params.id || ''}`}
							className="link link--type2"
						>
							elloapp.org/GalGadot
						</Link>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name"> Owner</p>
					<p>925 000</p>
				</div>
				<div className="td">
					<p className="td-hidden-name"> # Members</p>
					<p>925 000</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Type</p>
					<p>15</p>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Subscription date</p>
					<div>
						<div>Sep 24, 2022</div> <div className="gray">3:06 PM</div>
					</div>
				</div>
			</div>
		</div>
	);
};
