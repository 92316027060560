export const downloadFile = (file: any, type: string, name_file: string) => {
	const url = window.URL.createObjectURL(new Blob([file], { type }));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', name_file.slice(name_file.indexOf('=') + 1).replaceAll(`"`, ''));

	document.body.appendChild(link);

	// Start download
	link.click();
	// link.remove();
};
