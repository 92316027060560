import {
	BankSvg,
	BusdSvg,
	EtnSvg,
	PaySvg,
	StripeSvg,
	TransferSvg,
	UsdtSvg,
	WalletSvg,
} from 'assets/svg-icon';
import { ChangeEvent, KeyboardEvent, FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCommissionRequest } from 'redux/reducers/finance/finance-reducer';
import { ICommissionsResonse } from 'redux/reducers/finance/finance-types';

interface IProps {
	commissions: null | ICommissionsResonse;
}

export const Commissions: FC<IProps> = ({ commissions }) => {
	const dispatch = useDispatch();

	const [commission, setCommission] = useState<ICommissionsResonse>(
		commissions as ICommissionsResonse,
	);

	const defaultCommission = () => {
		commissions && setCommission(commissions);
	};

	const handleInput = (event: ChangeEvent) => {
		const { name, value } = event.target as HTMLInputElement;
		setCommission((prev) => ({
			...prev,
			[name]: 0 === +value ? +value : +value.replaceAll(/\D/g, '') || ' ',
		}));
	};

	const onSubmitCommission = (event: KeyboardEvent) => {
		if (event.code === 'Enter') {
			dispatch(setCommissionRequest(commission));
			setTimeout(() => {
				//@ts-ignore
				event.target.blur();
			}, 1000);
		}
	};

	useEffect(() => {
		commissions && setCommission(commissions);
	}, [commissions]);

	return (
		<div className="commissions">
			<div className="commissions__wrapper">
				<div className="commissions__title">PayPal</div>

				<div className="commissions__box-inner commissions__box-inner--line">
					<div className="commissions__name-inner">
						<div className="commissions__svg">
							<PaySvg />
						</div>
						<div className="commissions__name">PayPal withdrawal fee from Earn balance</div>
					</div>
					<label htmlFor="" className="commissions__label ">
						<input
							title="After entering the new fee, press enter to change the data"
							name="paypal_earn_wallet_withdraw_fee"
							onChange={handleInput}
							onBlur={defaultCommission}
							onKeyDown={onSubmitCommission}
							value={commission?.paypal_earn_wallet_withdraw_fee}
							type="text"
							className="commissions__percent"
						/>
						<span className="commissions__percent-title">Percentage (%)</span>
					</label>
				</div>

				{/* <div className="commissions__box-inner commissions__box-inner--bottom">
					<div className="commissions__name-inner">
						<div className="commissions__svg">
							<PaySvg />
						</div>
						<div className="commissions__name">PayPal deposit fee</div>
					</div>
					<label htmlFor="" className="commissions__label">
						<input
							title="After entering the new fee, press enter to change the data"
							name="paypal_deposit_fee"
							onChange={handleInput}
							onBlur={defaultCommission}
							onKeyDown={onSubmitCommission}
							value={commission?.paypal_deposit_fee}
							type="text"
							className="commissions__percent"
						/>
						<span className="commissions__percent-title">Percentage (%)</span>
					</label>
				</div>

				<div className="commissions__box-inner commissions__box-inner--line">
					<div className="commissions__name-inner">
						<div className="commissions__svg">
							<PaySvg />
						</div>
						<div className="commissions__name">Paypal Withdrawal from Earn balance</div>
					</div>
					<label htmlFor="" className="commissions__label">
						<input
							title="After entering the new fee, press enter to change the data"
							name="paypal_earn_wallet_withdraw_fee"
							onChange={handleInput}
							onBlur={defaultCommission}
							onKeyDown={onSubmitCommission}
							value={commission?.paypal_earn_wallet_withdraw_fee}
							type="text"
							className="commissions__percent"
						/>
						<span className="commissions__percent-title">Percentage (%)</span>
					</label>
				</div> */}
			</div>
			{/* <div className="commissions__wrapper">
				<div className="commissions__title">Stripe</div>

				<div className="commissions__box-inner commissions__box-inner--line">
					<div className="commissions__name-inner">
						<div className="commissions__svg">
							<StripeSvg />
						</div>
						<div className="commissions__name">Stripe deposit fee</div>
					</div>
					<label htmlFor="" className="commissions__label">
						<input
							title="After entering the new fee, press enter to change the data"
							name="stripe_deposit_fee"
							value={commission?.stripe_deposit_fee}
							onChange={handleInput}
							onBlur={defaultCommission}
							onKeyDown={onSubmitCommission}
							type="text"
							className="commissions__percent"
						/>
						<span className="commissions__percent-title">Percentage (%)</span>
					</label>
				</div>
			</div> */}
			<div className="commissions__wrapper">
				<div className="commissions__title">Bank Transfer</div>

				{/* <div className="commissions__box-inner commissions__box-inner--bottom">
					<div className="commissions__name-inner">
						<div className="commissions__svg">
							<BankSvg />
						</div>
						<div className="commissions__name">Bank Transfer withdrawal fee from Main balance</div>
					</div>
					<label htmlFor="" className="commissions__label">
						<input
							title="After entering the new fee, press enter to change the data"
							name="bank_withdraw_fee"
							value={commission?.bank_withdraw_fee}
							onChange={handleInput}
							onBlur={defaultCommission}
							onKeyDown={onSubmitCommission}
							type="text"
							className="commissions__percent"
						/>
						<span className="commissions__percent-title">Percentage (%)</span>
					</label>
				</div> */}

				<div className="commissions__box-inner commissions__box-inner--line">
					<div className="commissions__name-inner">
						<div className="commissions__svg">
							<BankSvg />
						</div>
						<div className="commissions__name">Bank transfer Withdrawal from Earn balance</div>
					</div>
					<label htmlFor="" className="commissions__label">
						<input
							title="After entering the new fee, press enter to change the data"
							name="bank_earn_wallet_withdraw_fee"
							value={commission?.bank_earn_wallet_withdraw_fee}
							onChange={handleInput}
							onBlur={defaultCommission}
							onKeyDown={onSubmitCommission}
							type="text"
							className="commissions__percent"
						/>
						<span className="commissions__percent-title">Percentage (%)</span>
					</label>
				</div>

				{/* <div className="commissions__box-inner commissions__box-inner--line">
					<div className="commissions__name-inner">
						<div className="commissions__svg">
							<BankSvg />
						</div>
						<div className="commissions__name">Bank Transfer deposit fee</div>
					</div>
					<label htmlFor="" className="commissions__label">
						<input
							name="bank_deposit_fee"
							value={commission?.bank_deposit_fee}
							onChange={handleInput}
							onBlur={defaultCommission}
							onKeyDown={onSubmitCommission}
							type="text"
							className="commissions__percent"
						/>
						<span className="commissions__percent-title">Percentage (%)</span>
					</label>
				</div> */}
			</div>
			<div className="commissions__wrapper">
				<div className="commissions__title">Main wallet</div>

				<div className="commissions__box-inner--line">
					<div className="commissions__box-inner">
						<div className="commissions__name-inner">
							<div className="commissions__svg">
								<WalletSvg />
							</div>
							<div className="commissions__name">
								Withdrawal fee from Transfer from Earn balance to Main balance
							</div>
						</div>
						<label htmlFor="" className="commissions__label">
							<input
								title="After entering the new fee, press enter to change the data"
								name="transfer_from_earn_to_main_fee"
								value={commission?.transfer_from_earn_to_main_fee}
								onChange={handleInput}
								onBlur={defaultCommission}
								onKeyDown={onSubmitCommission}
								type="text"
								className="commissions__percent"
							/>
							<span className="commissions__percent-title">Percentage (%)</span>
						</label>
					</div>
					{commission?.transfer_from_earn_to_main_fee ? null : (
						<div className="commissions__text-gray">
							No commission will be charged for this type of transaction.
						</div>
					)}
				</div>

				{/* 
				<div className="commissions__box-inner commissions__box-inner--line">
					<div className="commissions__name-inner">
						<div className="commissions__svg">
							<WalletSvg />
						</div>
						<div className="commissions__name">Transferring cashback to the main wallet</div>
					</div>
					<label htmlFor="" className="commissions__label">
						<input
							name="transfer_cashback_to_main_fee"
							value={commission?.transfer_cashback_to_main_fee}
							onChange={handleInput}
							onBlur={defaultCommission}
							onKeyDown={onSubmitCommission}
							type="text"
							className="commissions__percent"
						/>
						<span className="commissions__percent-title">Percentage (%)</span>
					</label>
				</div> */}
			</div>

			<div className="commissions__wrapper">
				<div className="commissions__title">Channel Comission</div>

				<div className="commissions__box-inner commissions__box-inner--bottom">
					<div className="commissions__name-inner">
						<div className="commissions__svg">
							<WalletSvg />
						</div>
						<div className="commissions__name">Paid Channel</div>
					</div>
					<label htmlFor="" className="commissions__label">
						<input
							title="After entering the new fee, press enter to change the data"
							name="paid_channel_fee"
							value={commission?.paid_channel_fee}
							onChange={handleInput}
							onBlur={defaultCommission}
							onKeyDown={onSubmitCommission}
							type="text"
							className="commissions__percent"
						/>
						<span className="commissions__percent-title">Percentage (%)</span>
					</label>
				</div>

				<div className="commissions__box-inner commissions__box-inner--line">
					<div className="commissions__name-inner">
						<div className="commissions__svg">
							<WalletSvg />
						</div>
						<div className="commissions__name">Online Course subscription</div>
					</div>
					<label htmlFor="" className="commissions__label">
						<input
							name="subscribe_course_fee"
							value={commission?.subscribe_course_fee}
							onChange={handleInput}
							onBlur={defaultCommission}
							onKeyDown={onSubmitCommission}
							type="text"
							className="commissions__percent"
						/>
						<span className="commissions__percent-title">Percentage (%)</span>
					</label>
				</div>
			</div>
			{/* <div className="commissions__wrapper">
				<div className="commissions__title">USDT</div>

				<div className="commissions__box-inner commissions__box-inner--bottom">
					<div className="commissions__name-inner">
						<div className="commissions__svg">
							<UsdtSvg />
						</div>
						<div className="commissions__name">USDT Deposit Fee</div>
					</div>
					<label htmlFor="" className="commissions__label">
						<input
							name="stripe_deposit_fee"
							value={commission?.stripe_deposit_fee}
							onChange={handleInput}
							onBlur={defaultCommission}
							onKeyDown={onSubmitCommission}
							type="text"
							className="commissions__percent"
						/>
						<span className="commissions__percent-title">Percentage (%)</span>
					</label>
				</div>

				<div className="commissions__box-inner commissions__box-inner--line">
					<div className="commissions__name-inner">
						<div className="commissions__svg">
							<UsdtSvg />
						</div>
						<div className="commissions__name">USDT withdrawal fee</div>
					</div>
					<label htmlFor="" className="commissions__label">
						<input
							name="stripe_withdraw_fee"
							value={commission?.stripe_withdraw_fee}
							onChange={handleInput}
							onBlur={defaultCommission}
							onKeyDown={onSubmitCommission}
							type="text"
							className="commissions__percent"
						/>
						<span className="commissions__percent-title">Percentage (%)</span>
					</label>
				</div>
			</div>
			<div className="commissions__wrapper">
				<div className="commissions__title">ETH</div>

				<div className="commissions__box-inner commissions__box-inner--bottom">
					<div className="commissions__name-inner">
						<div className="commissions__svg">
							<EtnSvg />
						</div>
						<div className="commissions__name">ETH Deposit Fee</div>
					</div>
					<label htmlFor="" className="commissions__label">
						<input
							name="stripe_deposit_fee"
							value={commission?.stripe_deposit_fee}
							onChange={handleInput}
							onBlur={defaultCommission}
							onKeyDown={onSubmitCommission}
							type="text"
							className="commissions__percent"
						/>
						<span className="commissions__percent-title">Percentage (%)</span>
					</label>
				</div>

				<div className="commissions__box-inner commissions__box-inner--line">
					<div className="commissions__name-inner">
						<div className="commissions__svg">
							<EtnSvg />
						</div>
						<div className="commissions__name">ETH withdrawal fee</div>
					</div>
					<label htmlFor="" className="commissions__label">
						<input
							name="stripe_withdraw_fee"
							value={commission?.stripe_withdraw_fee}
							onChange={handleInput}
							onBlur={defaultCommission}
							onKeyDown={onSubmitCommission}
							type="text"
							className="commissions__percent"
						/>
						<span className="commissions__percent-title">Percentage (%)</span>
					</label>
				</div>
			</div>
			<div className="commissions__wrapper">
				<div className="commissions__title">BUSD</div>

				<div className="commissions__box-inner commissions__box-inner--bottom">
					<div className="commissions__name-inner">
						<div className="commissions__svg">
							<BusdSvg />
						</div>
						<div className="commissions__name">BUSD Deposit Fee</div>
					</div>
					<label htmlFor="" className="commissions__label">
						<input
							name="stripe_deposit_fee"
							value={commission?.stripe_deposit_fee}
							onChange={handleInput}
							onBlur={defaultCommission}
							onKeyDown={onSubmitCommission}
							type="text"
							className="commissions__percent"
						/>
						<span className="commissions__percent-title">Percentage (%)</span>
					</label>
				</div>

				<div className="commissions__box-inner commissions__box-inner--line">
					<div className="commissions__name-inner">
						<div className="commissions__svg">
							<BusdSvg />
						</div>
						<div className="commissions__name">BUSD withdrawal fee</div>
					</div>
					<label htmlFor="" className="commissions__label">
						<input
							name="stripe_withdraw_fee"
							value={commission?.stripe_withdraw_fee}
							onChange={handleInput}
							onBlur={defaultCommission}
							onKeyDown={onSubmitCommission}
							type="text"
							className="commissions__percent"
						/>
						<span className="commissions__percent-title">Percentage (%)</span>
					</label>
				</div>
			</div> */}

			{/* <div className="commissions__wrapper">
				<div className="commissions__title">
					Commission for the transfer of crypto funds between users
				</div>

				<div className="commissions__box-inner">
					<div className="commissions__name-inner">
						<div className="commissions__svg">
							<TransferSvg />
						</div>
						<div className="commissions__name">Transfer of cryptocurrency between users</div>
					</div>
					<label htmlFor="" className="commissions__label">
						<input
							name="transfer_crypto_fee"
							value={commission?.transfer_crypto_fee}
							onChange={handleInput}
							onBlur={defaultCommission}
							onKeyDown={onSubmitCommission}
							type="text"
							className="commissions__percent"
						/>
						<span className="commissions__percent-title">Percentage (%)</span>
					</label>
				</div>
			</div> */}
		</div>
	);
};
