import { FC, useState } from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

export const Emoji: FC<{ addEmoji: (value: any) => void }> = ({ addEmoji }) => {
	const [file, setFile] = useState(null);
	// const handleChange = (file: any) => {
	// 	console.log(file);
	// };
	return <Picker data={data} onEmojiSelect={(e: any) => addEmoji(e)} />;
};
