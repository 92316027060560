import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	IChannelsResponse,
	ICountry,
	IGroupsResponse,
	IPhotosResponse,
	ITransactionResponse,
	IUserResponse,
	IUsersResponse,
	IUsersState,
} from './types';

const getUsersState = (state: IStoreState): IUsersState => state.users;

export const getUsersList = createSelector(
	[getUsersState],
	(state: IUsersState): null | IUsersResponse => state.usersList,
);

export const getUserDetails = createSelector(
	[getUsersState],
	(state: IUsersState): null | IUserResponse => state.userDetails,
);

export const getPhotos = createSelector(
	[getUsersState],
	(state: IUsersState): null | IPhotosResponse => state.photos,
);

export const getChannels = createSelector(
	[getUsersState],
	(state: IUsersState): null | IChannelsResponse => state.channels,
);

export const getGroups = createSelector(
	[getUsersState],
	(state: IUsersState): null | IGroupsResponse => state.groups,
);

export const getTransaction = createSelector(
	[getUsersState],
	(state: IUsersState): null | ITransactionResponse => state.transaction,
);

export const getLoader = createSelector(
	[getUsersState],
	(state: IUsersState): boolean => state.usersLoader,
);

export const getAllCountries = createSelector(
	[getUsersState],
	(state: IUsersState): null | Array<ICountry> => state.countries,
);
