import { IApi } from './types';
import { auth } from './auth';
import { channels } from './channels';
import { users } from './users';
import { admins } from './admins';
import { transaction } from './transactions';
import { reports } from './reports';
import { support } from './support';
import { aiServices } from './aiServicese';
import { reserved } from './reserved';
import { utils } from './utils';
import { finance } from './finance';
import { referral } from './referral';
import { apkFiles } from './apkFiles';
import { aiChatBots } from './aiChatBots';
import { reactions } from './reactions';

const api: IApi = {
	auth,
	channels,
	users,
	transaction,
	reports,
	admins,
	support,
	aiServices,
	reserved,
	utils,
	finance,
	referral,
	apkFiles,
	aiChatBots,
	reactions,
};

export { api };
