/* eslint-disable no-nested-ternary */
import { Block } from 'components/Popups/Block';
import { FC, MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CheckSmallSvg, CloseRedSvg } from 'assets/svg-icon';
import { IFiat } from 'redux/reducers/finance/finance-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ETabsUsersDetails } from 'services/constants/lists-table';

export interface IOpenPopup {
	id: string;
	name: string;
}

interface IProps {
	fiat: undefined | Array<IFiat>;
	handlePopup: (event: MouseEvent) => void;
	onDetails: (value: string) => void;
}

export const WithdrawalBody: FC<IProps> = ({ onDetails, fiat, handlePopup }) => {
	const [onChangeVerification, setOnChangeVerification] = useState<number | null>(null);
	const dispatch = useDispatch();
	const [isOpenPopup, setIsOpenPopup] = useState<IOpenPopup | null>(null);

	const createAvataar = (title: string): string => {
		const array = title.trim().split(' ');
		return array.length >= 2
			? array[0][0].toUpperCase() + array[1][0].toUpperCase()
			: title.slice(0, 2).toUpperCase();
	};

	const upgradeChannel = (event: MouseEvent) => {
		const target = event.currentTarget as HTMLButtonElement;
		// (isOpenPopup || target.name !== EChannelUpgradeType.BLOCK) &&
		// 	dispatch(
		// 		upgradeChannelRequest({
		// 			body: { type: target.name },
		// 			chat_id: String(target.id),
		// 		}),
		// 	);
		// setOnChangeVerification(null);
	};

	const onStatusUser = () => {
		const block = false;
		const deleted = false;
		if (block) {
			return 'blocked-users';
		} else if (deleted) {
			return 'delete-users';
		} else {
			return 'registered';
		}
	};

	return (
		<div className="table-body">
			{isOpenPopup && (
				<Block
					isOpenPopup={isOpenPopup}
					upgradeChannel={upgradeChannel}
					setIsOpenPopup={setIsOpenPopup}
				/>
			)}
			{fiat?.map((item) => (
				<div key={item.tx_id} className="tr">
					<div className="td">
						<p className="td-hidden-name">Tx ID</p>
						<Link
							to={`${ELinksName.USER_DETAILS}/${item.user_id}/${
								onStatusUser() || 'all-channels'
							}/${String(ETabsUsersDetails.USER_DATA)}`}
							className="link-color"
						>
							{item.tx_id}
						</Link>
					</div>
					<div className="td">
						<p className="td-hidden-name">Full name</p>
						<Link
							to={`${ELinksName.USER_DETAILS}/${item.user_id}/${
								onStatusUser() || 'all-channels'
							}/${String(ETabsUsersDetails.USER_DATA)}`}
							className="link-color"
						>
							{item.full_name}
						</Link>
					</div>
					<div className="td">
						<p className="td-hidden-name">Username</p>
						<Link
							to={`${ELinksName.USER_DETAILS}/${item.user_id}/${
								onStatusUser() || 'all-channels'
							}/${String(ETabsUsersDetails.USER_DATA)}`}
							className="link-color"
						>
							{item.username ? `@${item.username}` : '--'}
						</Link>
					</div>
					<div className="td">
						<p className="td-hidden-name">Email</p>
						<Link
							to={`${ELinksName.USER_DETAILS}/${item.user_id}/${
								onStatusUser() || 'all-channels'
							}/${String(ETabsUsersDetails.USER_DATA)}`}
							className="link-color"
						>
							{item.email}
						</Link>
					</div>
					<div className="td">
						<p className="td-hidden-name">Date</p>
						<p>{moment(item.date).format('MMM DD, YYYY')}</p>
						<p className="gray">{moment(item.date).format('h:mm A')}</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Amount</p>
						<Link
							to={`${ELinksName.USER_DETAILS}/${item.user_id}/${
								onStatusUser() || 'all-channels'
							}/${String(ETabsUsersDetails.USER_DATA)}`}
							className="upper-text-custom link-color"
						>{`$${item.amount}`}</Link>
					</div>
					<div className="td">
						<p className="td-hidden-name">Fee</p>
						<p className="upper-text-custom">{`$${item.fee}`}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Wallet balance</p>
						<Link
							to={`${ELinksName.USER_DETAILS}/${item.user_id}/${
								onStatusUser() || 'all-channels'
							}/${String(ETabsUsersDetails.USER_DATA)}`}
							className="upper-text-custom link-color"
						>{`$${item.wallet_balance}`}</Link>
					</div>
					<div className="td">
						<p className="td-hidden-name">Wallet type</p>
						<p className="upper-text-custom">{item.wallet_type}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Method</p>
						<p>{item.method}</p>
						<p onClick={() => onDetails(String(item.tx_id))} className="link-color">
							More info
						</p>
					</div>
					{item.status.includes('admin_check') ? (
						<div className="td action-flex td--left">
							<p className="td-hidden-name">Action</p>
							<button
								id={String(item.tx_id)}
								name="approve"
								type="button"
								onClick={handlePopup}
								className="upper-text-custom"
							>
								<CheckSmallSvg />
							</button>
							<button
								id={String(item.tx_id)}
								name="reject"
								type="button"
								onClick={handlePopup}
								className="upper-text-custom"
							>
								<CloseRedSvg />
							</button>
						</div>
					) : (
						<div className="td action-flex td--left">-</div>
					)}
				</div>
			))}
		</div>
	);
};
