import { FC, MouseEvent } from 'react';

interface IProps {
	data: Array<string>;
	onPopup: (value: number, index?: number) => void;
	isOpenModal?: (event?: MouseEvent) => void;
	className?: string;
	svg?: string;
	index?: number;
}

export const Popup: FC<IProps> = ({ index, svg, data, onPopup, isOpenModal, className }) => {
	return (
		<div className={`dropdown dropdown--header-user-nav ${className ? className : ''}`}>
			<ul className="dropdown-list">
				{data.map((element) => (
					<li key={element}>
						<button
							id={element}
							onClick={(event: MouseEvent) => {
								onPopup(0, index);
								isOpenModal?.(event);
							}}
							type="button"
						>
							{svg && <img src={svg} alt="arrow" />}
							{element}
						</button>
					</li>
				))}
			</ul>
		</div>
	);
};
