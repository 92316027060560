/* eslint-disable import/no-unresolved */
import { FC, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { IPropsSwiper } from './types';
import SlideNextButton from './SlideNextButton/SlideNextButton';
import SliderPreviousButton from './SliderPreviousButton/SliderPreviousButton';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { GET_PHOTO } from 'services/constants/env';
import { Avatar } from 'ui/Avatar/Avatar';

export const SwiperSellPhoto: FC<IPropsSwiper> = ({ images, setOpenAllPhoto, numPhoto }) => {
	const arrayImage = images.map((img) => img.photo_access_hash);
	const [startImg, setStartImg] = useState(1);
	const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

	const nextImg = () => {
		setStartImg((prev: number) => prev + 1);
	};

	const prevImg = () => {
		setStartImg((prev: number) => prev - 1);
	};

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = '';
		};
	}, []);

	return (
		<div className="popup-window popup-window-dark">
			<div className="popup-window__inside">
				<div className="slider-header">
					<button
						onClick={() => setOpenAllPhoto(false)}
						type="button"
						className="slider-header__close"
					>
						<svg
							width="18"
							height="19"
							viewBox="0 0 18 19"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M0.46967 0.96967C0.762563 0.676777 1.23744 0.676777 1.53033 0.96967L17.5303 16.9697C17.8232 17.2626 17.8232 17.7374 17.5303 18.0303C17.2374 18.3232 16.7626 18.3232 16.4697 18.0303L0.46967 2.03033C0.176777 1.73744 0.176777 1.26256 0.46967 0.96967Z"
								fill="white"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M17.5303 0.96967C17.8232 1.26256 17.8232 1.73744 17.5303 2.03033L1.53033 18.0303C1.23744 18.3232 0.762563 18.3232 0.46967 18.0303C0.176777 17.7374 0.176777 17.2626 0.46967 16.9697L16.4697 0.96967C16.7626 0.676777 17.2374 0.676777 17.5303 0.96967Z"
								fill="white"
							/>
						</svg>
					</button>
				</div>
				<div className="photo-slider-box">
					<Swiper
						loop={true}
						spaceBetween={6}
						navigation={true}
						thumbs={{ swiper: thumbsSwiper }}
						modules={[FreeMode, Navigation, Thumbs]}
						className="photo-slider"
						// initialSlide={startImg - 1}
						// slidesPerView={1}
					>
						<SliderPreviousButton prevImg={prevImg} startImg={startImg} />

						{images.map((image) => (
							<SwiperSlide className="photo-slide" key={image.id}>
								<div className="photo-slide">
									<Avatar hash={String(image.photo_access_hash)} />
								</div>
							</SwiperSlide>
						))}
						<SlideNextButton nextImg={nextImg} startImg={startImg} numberImg={images.length} />
					</Swiper>
					<Swiper
						onSwiper={(swiper) => {
							setThumbsSwiper(swiper);
						}}
						loop={true}
						spaceBetween={6}
						slidesPerView={5}
						freeMode={true}
						watchSlidesProgress={true}
						modules={[FreeMode, Navigation, Thumbs]}
						className={'mySwiper'}
					>
						{images.map((image) => (
							<SwiperSlide className="photo-slide" key={image.id}>
								<div className="photo-slide">
									<Avatar hash={String(image.photo_access_hash)} />
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</div>
	);
};
