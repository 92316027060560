import { FC, useState } from 'react';
import reactions from './reaction.json';
import parse from 'html-react-parser';
import Toggle from 'react-toggle';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { Link } from 'react-router-dom';
import { IReactions } from 'redux/reducers/reactions/types';
import { finedReactionActive } from 'untils/finedREactionActive';
import { useDispatch } from 'react-redux';
import { activeReactionRequest } from 'redux/reducers/reactions/reducer';

interface IProps {
	reactions: Array<IReactions>;
}

export const ReactionsList: FC<IProps> = ({ reactions }) => {
	const [toggles, setToggles] = useState<Array<number | null>>(finedReactionActive(reactions));
	const dispatch = useDispatch();

	const handleToggleSecondChange = (index: number) => {
		const formData = new FormData();
		formData.append('id', String(index));
		if (!toggles.includes(index)) {
			formData.append('on', 'false');

			dispatch(
				activeReactionRequest({
					setToggles,
					data: formData,
				}),
			);
			setToggles((prev) => [...prev, index]);
		} else {
			formData.append('on', 'true');

			dispatch(
				activeReactionRequest({
					setToggles,
					data: formData,
				}),
			);
			setToggles(toggles.filter((el) => el !== index));
		}
	};

	return (
		<ul className="reactions__list">
			{reactions?.map((reaction, index) => (
				<li key={reaction.id} className="reactions__item">
					<div className="reactions__left-box">
						<div className="reactions__svg">{parse(reaction.icon)}</div>
						<div className="reactions__name">{reaction.title}</div>
					</div>

					<div className="reactions__right-box">
						<div className="user-data__toggle-wrapper left">
							<span className="user-data__toggle-text payment-methods__width">
								{toggles.includes(reaction.id) ? 'Turned ON' : 'Turned OFF'}
							</span>
							<span className="toggle-span">
								<Toggle
									defaultChecked={toggles.includes(reaction.id)}
									checked={toggles.includes(reaction.id)}
									icons={true}
									onChange={() => handleToggleSecondChange(reaction.id)}
								/>
							</span>
						</div>
						<Link to={ELinksName.REACTIONS_EDIT + '/' + reaction.id}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
							>
								<path
									d="M8 20L17 12L8 4"
									stroke="#4D4D50"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</Link>
					</div>
				</li>
			))}
		</ul>
	);
};
