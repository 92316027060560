import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ListFiles } from '../ListFiles/ListFiles';

export const NameExempleDetails: FC = () => {
	return (
		<section className="user-data__details-user details-exemple">
			<ul className="details-user__box-text details-user__box-text-custom">
				<li className="details-user__item">
					<span>Type</span>
					<span>Paid</span>
				</li>
				<li className="details-user__item">
					<span>Price</span>
					<span>$15.00</span>
				</li>
				<li className="details-user__item">
					<span>Available quantity</span>
					<span>1345</span>
				</li>
				<li className="details-user__item">
					<span>Sales</span>
					<span>456</span>
				</li>
				<li className="details-user__item">
					<span>Amount of earnings</span>
					<span>$6,840.00</span>
				</li>
				<li className="details-user__item">
					<span>Created date</span>
					<span>Sep 24, 2022, 3:06 PM</span>
				</li>
				<li className="details-user__item">
					<span>Owner</span>
					<Link className="details-user__link" to="/">
						Cameron Williamson
					</Link>
				</li>
				<li className="details-user__item">
					<span>Category</span>
					<span>Fashion</span>
				</li>
				<li className="details-user__item">
					<span>Type</span>
					<span>Video</span>
				</li>
				<li className="details-user__item2">
					<span>Post text</span>
				</li>
				<li className="details-user__item2">
					<span>
						Gal Gadot REVEALS if her daughters will make a cameo again in Wonder Woman 3. It was
						recently confirmed that Gal Gadot will be returning as Wonder Woman for a third film
						during the DC FanDome event.
					</span>
				</li>
			</ul>
			<div className="details-user__img-wrapper details-user__img-wrapper-custom">
				{/* <ListFiles content={[]} /> */}
			</div>
		</section>
	);
};
