import { PlusSvg } from 'assets/svg-icon';
import { ActiveTab } from 'components/AdminsComponent/ActiveTab/ActiveTab';
import { AdminsBlocked } from 'components/AdminsComponent/AdminsBlocked/AdminsBlocked';
import { TabsAdmins } from 'components/AdminsComponent/TabsAdmins';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	createAdminRequest,
	getAdminsListRequest,
	getAdminsListSuccess,
} from 'redux/reducers/admins/reducer';
import { getAdminsListSelector } from 'redux/reducers/admins/selectors';
import { EAdminsTabs } from 'services/constants/lists-table';
import { AddNewAdmins } from 'ui/AddNewAdmins/AddNewAdmins';
import { IAddNewUserSubmit } from 'ui/AddNewAdmins/type';
import { Button } from 'ui/Button/Button';

export const Admins: FC = () => {
	const params = useParams<{ tab: string }>();
	const dispatch = useDispatch();
	const admins = useSelector(getAdminsListSelector);
	const [search, setSearch] = useState<string>('');
	const [open, setOpen] = useState<boolean>(false);

	const onAddAdmin = () => {
		open === true &&
			dispatch(
				getAdminsListRequest({
					search: search.trim() || undefined,
					blocked: params.tab === 'blocked' ? true : false,
				}),
			);
		setOpen(!open);
	};

	const handleAddAdminSubmit = (value: IAddNewUserSubmit) => {
		dispatch(createAdminRequest({ paramsApi: value, onAddAdmin }));
	};

	const onSearch = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;

		setSearch(value);
	};

	useEffect(() => {
		dispatch(getAdminsListSuccess(null));
		dispatch(
			getAdminsListRequest({
				search: search.trim() || undefined,
				blocked: params.tab === 'blocked' ? true : false,
			}),
		);
	}, [params.tab, search]);

	return (
		<div className="main-content">
			{open && (
				<div className="blur-wrapper-popup blur-wrapper-popup--center">
					<AddNewAdmins onAddAdmin={onAddAdmin} addAdminSubmit={handleAddAdminSubmit} />
				</div>
			)}

			<div className="title-block-wrap">
				<div className="title-block">
					<div className="header-title">
						<p className="title">Admins</p>
						<Button
							className="button-wrapper"
							onClick={onAddAdmin}
							type={'button'}
							name={'Add new admin'}
						>
							<PlusSvg />
						</Button>
					</div>
				</div>
			</div>
			<TabsAdmins />
			<div className="table-block">
				{params.tab === EAdminsTabs.BLOCKED ? (
					<AdminsBlocked admins={admins} onSearch={onSearch} search={search} />
				) : (
					<ActiveTab admins={admins} onSearch={onSearch} search={search} />
				)}
			</div>
		</div>
	);
};
