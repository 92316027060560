import { PayloadAction } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'untils/notificationContainer';
import {
	financeInitState,
	getBalancesDepositRequest,
	getBalancesDepositSuccess,
	getBalancesWithdrawalRequest,
	getBalancesWithdrawalSuccess,
	getCommissionsRequest,
	getCommissionsSuccess,
	getFinanceDetailsRequest,
	getFinanceDetailsSuccess,
	getFinanceRequest,
	getFinanceSuccess,
	getMethodsRequest,
	getMethodsSuccess,
	getPDFRequest,
	getTransactionGraphRequest,
	getTransactionGraphSuccess,
	getTransactionRequest,
	getTransactionSuccess,
	setCommissionRequest,
	setCommissionSuccess,
	setMethodsRequest,
	setMethodsSuccess,
	setWithdrawalRequest,
	setWithdrawalSuccess,
} from './finance-reducer';
import {
	IBalancesDeposit,
	IBalancesWithdrawl,
	ICommissionsResonse,
	IFiatRequest,
	IFiatResponse,
	IMethods,
	IRequestIWithdrawalUpdate,
	IStatisticsRequest,
	IStatisticsResponse,
	ITransactionGraphRequest,
	ITransactionGraphResponse,
	IWithdrawalDetails,
	IWithdrawalUpdate,
} from './finance-types';

function* getFiatWorker(action: PayloadAction<IFiatRequest>) {
	const { payload } = action;
	try {
		const response: { data: IFiatResponse } = yield call(api.finance.getFiat, payload);
		yield put(getFinanceSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(financeInitState());
	}
}

function* getFiatDetailsWorker({ payload }: PayloadAction<string>) {
	try {
		const response: { data: IWithdrawalDetails } = yield call(api.finance.getFiatDetails, payload);
		yield put(getFinanceDetailsSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(financeInitState());
	}
}

function* setWithdrawalWorker(action: PayloadAction<IRequestIWithdrawalUpdate>) {
	const { data, onClose } = action.payload;

	try {
		yield call(api.finance.getWithdrawalUpdate, data);
		yield put(setWithdrawalSuccess(data.transaction_id as number));
		yield onClose(false);
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(financeInitState());
	}
}

function* getCommissionsWorker() {
	try {
		const response: { data: ICommissionsResonse } = yield call(api.finance.getCommissions);
		yield put(getCommissionsSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(financeInitState());
	}
}

function* changeCommissionsWorker(action: PayloadAction<ICommissionsResonse>) {
	const payload = action.payload;

	try {
		yield call(api.finance.changeCommission, payload);
		yield put(setCommissionSuccess(payload));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(financeInitState());
	}
}

function* getMethodsWorker() {
	try {
		const response: { data: IMethods } = yield call(api.finance.getMethods);
		yield put(getMethodsSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(financeInitState());
	}
}

function* changeMethodWorker(action: PayloadAction<IMethods>) {
	const payload = action.payload;

	try {
		yield call(api.finance.changeMethods, payload);
		if (Object.entries(payload).length > 1) yield put(setMethodsSuccess(payload));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(financeInitState());
	}
}

function* getTransactionsWorker(action: PayloadAction<IStatisticsRequest>) {
	const payload = action.payload;

	try {
		const response: { data: IStatisticsResponse } = yield call(
			api.finance.getTransactions,
			payload,
		);
		yield put(getTransactionSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(financeInitState());
	}
}

function* getBalancesDepositWorker() {
	try {
		const response: { data: IBalancesDeposit } = yield call(api.finance.getBalancesDeposit);
		yield put(getBalancesDepositSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(financeInitState());
	}
}

function* getBalancesWithdrawalWorker() {
	try {
		const response: { data: IBalancesWithdrawl } = yield call(api.finance.getBalancesWithdrawal);
		yield put(getBalancesWithdrawalSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(financeInitState());
	}
}

function* getTransactionGraphWorker(action: PayloadAction<ITransactionGraphRequest>) {
	const payload = action.payload;

	try {
		const response: { data: { data: Array<ITransactionGraphResponse> } } = yield call(
			api.finance.getTransactionGraph,
			payload,
		);
		yield put(getTransactionGraphSuccess(response.data.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(financeInitState());
	}
}

function* getPDFWorker(action: PayloadAction<string>) {
	const payload = action.payload;

	try {
		const response: Blob = yield call(api.finance.getPDF, payload);
		const a = document.createElement('a');
		const file = new Blob([response], { type: 'application/pdf' });
		a.href = URL.createObjectURL(file);
		a.download = 'Transaction.pdf';
		a.click();
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(financeInitState());
	}
}

export function* financeSaga() {
	yield takeEvery(getFinanceRequest.type, getFiatWorker);
	yield takeEvery(getFinanceDetailsRequest.type, getFiatDetailsWorker);
	yield takeEvery(setWithdrawalRequest.type, setWithdrawalWorker);
	yield takeEvery(getCommissionsRequest.type, getCommissionsWorker);
	yield takeEvery(setCommissionRequest.type, changeCommissionsWorker);
	yield takeEvery(getMethodsRequest.type, getMethodsWorker);
	yield takeEvery(setMethodsRequest.type, changeMethodWorker);
	yield takeEvery(getTransactionRequest.type, getTransactionsWorker);
	yield takeEvery(getBalancesDepositRequest.type, getBalancesDepositWorker);
	yield takeEvery(getBalancesWithdrawalRequest.type, getBalancesWithdrawalWorker);
	yield takeEvery(getTransactionGraphRequest.type, getTransactionGraphWorker);
	yield takeEvery(getPDFRequest.type, getPDFWorker);
}
