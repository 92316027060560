import { FC } from 'react';
import notFoundImg from '../../assets/images/404.png';
import { Button } from 'ui/Button/Button';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';

export const NotFound404: FC = () => {
	return (
		<section className="not-found">
			<div className="not-found__wrap">
				<img src={notFoundImg} alt="" className="not-found__img" />
				<div className="not-found__title">Page not Found</div>
				<div className="not-found__subtitle">
					The page you are looking for doesn&apos;t exist or an other error occurred.
				</div>

				<Link className="not-found__btn" to={ELinksName.DASHBOARD}>
					Go to Home page
				</Link>
			</div>
		</section>
	);
};
