import { ChangeEvent, FC } from 'react';

export const Checkbox: FC<{
	onChange?: (event: ChangeEvent) => void;
	title: string;
	subtitle?: string;
	status?: string;
	checked?: boolean;
	type?: string;
	name?: string;
	id?: string;
}> = ({ title, name, subtitle, onChange, checked, type, id }) => {
	return (
		<div className="checkbox-two checkbox--mb-0 ">
			<label className="checkbox-two__label">
				<input
					id={id}
					onChange={onChange}
					type={type}
					className="hidden"
					defaultChecked={checked}
					checked={checked}
					name={name}
				/>
				<span className="checkbox-two__item round">
					<span className="checkbox-two__item-icon small">{/* <CheckSvg /> */}</span>
				</span>
				<div className="admin-info__checkbox">
					<span className={`checkbox-two__text ${title === 'Blocked' ? 'red' : ''}`}>{title}</span>
					<span className="checkbox-two__text gray">{subtitle}</span>
				</div>
			</label>
		</div>
	);
};
