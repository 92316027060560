import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApkFilesStore, IApkListRequest, IApkListResponse, IFile, IUploadFile } from './types';

export const initialState: IApkFilesStore = {
	listApk: null,
	apkLoader: false,
};

const apkFiles = createSlice({
	name: '@@apkFiles',
	initialState,
	reducers: {
		getListApkRequest: (state, action: PayloadAction<IApkListRequest>) => {
			const servicesState = state;
			servicesState.apkLoader = true;
		},
		getListApkSuccess: (state, action: PayloadAction<IApkListResponse>) => {
			const { payload } = action;
			const servicesState = state;
			servicesState.listApk = payload;
		},

		addApkRequest: (state, action: PayloadAction<IUploadFile>) => {
			const servicesState = state;
			servicesState.apkLoader = true;
		},
		addApkSuccess: (state, action: PayloadAction<IFile>) => {
			const { payload } = action;
			const servicesState = state;
			console.log(payload);
			if (servicesState.listApk?.total) servicesState.listApk.files.unshift(payload);
			if (!servicesState.listApk?.total)
				servicesState.listApk = {
					files: [payload],
					total: 1,
				};
		},

		deleteApkRequest: (state, action: PayloadAction<string>) => {
			const servicesState = state;
			servicesState.apkLoader = true;
		},
		deleteApkSuccess: (state, action: PayloadAction<string>) => {
			const { payload } = action;
			const servicesState = state;
			if (servicesState.listApk)
				servicesState.listApk.files = servicesState.listApk.files.filter(
					(item) => item.id !== +payload,
				);

			if (servicesState.listApk?.total)
				servicesState.listApk.total = servicesState.listApk.total - 1;
		},

		apkFilesState: (state) => {
			const servicesInitStatee = state;
			servicesInitStatee.apkLoader = false;
		},
	},
});

export default apkFiles.reducer;
export const {
	getListApkRequest,
	getListApkSuccess,
	addApkRequest,
	addApkSuccess,
	deleteApkRequest,
	deleteApkSuccess,
	apkFilesState,
} = apkFiles.actions;
