import classNames from 'classnames';
import { FC } from 'react';
import { IUsersRequest } from 'redux/reducers/support/types';

interface IPropsSidebarTabs {
	onCheckBtn: (value: null | number, name: string) => void;
	checkBtn: number | null;
}

export const SidebarTabs: FC<IPropsSidebarTabs> = ({ checkBtn, onCheckBtn }) => {
	return (
		<div className="sidebar-left__tabs-wrap">
			<ul className="sidebar-left__list">
				<li
					onClick={() => onCheckBtn(null, 'All')}
					className={classNames('sidebar-left__item', { 'active-tab': checkBtn === null })}
				>
					All
				</li>
				<li
					onClick={() => onCheckBtn(0, 'New')}
					className={classNames('sidebar-left__item', { 'active-tab': checkBtn === 0 })}
				>
					New
				</li>
				<li
					onClick={() => onCheckBtn(1, 'In progress')}
					className={classNames('sidebar-left__item', { 'active-tab': checkBtn === 1 })}
				>
					In progress
				</li>
				<li
					onClick={() => onCheckBtn(2, 'All')}
					className={classNames('sidebar-left__item', { 'active-tab': checkBtn === 2 })}
				>
					Completed
				</li>
			</ul>
		</div>
	);
};
