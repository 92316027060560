import { ResetPassSvg } from 'assets/svg-icon';
import ErrorMsg from 'components/ErrorMsg';
import { textForValidation } from 'CONST/textForValidation';
import { Field, Form, Formik } from 'formik';
import { FC, useState } from 'react';
import { SuccessfullyPopup } from 'ui/SuccessfullyPopup/SuccessfullyPopup';
import { SvgPass, SvgShowPass } from 'ui/svg-image/SvgLogin';
import * as yup from 'yup';
import { confirm_password, new_password } from '../../../untils/formValidation';
import { PasswordStrength } from './PasswordStrength/PasswordStrength';
import { IPropsResetPass, IResetPass } from './types';
import { useLocation } from 'react-router-dom';

const initialValues = {
	confirm_password: '',
	new_password: '',
};

const validationSchema = yup.object().shape({
	new_password,
	confirm_password,
});

const title = 'Successfully';
const subtitle = 'Password has been changed successfully.';
const btnName = 'Back to Login';

export const ResetPasswordForm: FC<IPropsResetPass> = ({ resetPassSubmit, successfully }) => {
	const [isShowPass, setIsShowPass] = useState(false);
	const [isShowPassConfirm, setIsShowPassConfirm] = useState(false);
	const { pathname } = useLocation();

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	const handlePassConfirmDisplay = (): void => {
		setIsShowPassConfirm(!isShowPassConfirm);
	};

	return (
		<div className="authorization-form__body">
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values: IResetPass, { setSubmitting }) => {
					resetPassSubmit(values);
					setSubmitting(false);
				}}
				validateOnBlur
				enableReinitialize
			>
				{({ isSubmitting, isValid, values, errors }) => {
					return (
						<Form>
							{successfully ? (
								<SuccessfullyPopup title={title} subtitle={subtitle} btnName={btnName} />
							) : (
								<div className="authorization-form">
									<div className="authorization-form__logo">
										<ResetPassSvg />
									</div>
									<p className="form-title">
										{pathname.includes('invite_link') ? 'Create password' : ' Password recovery'}
									</p>
									<p className="form-subtitle">
										{pathname.includes('invite_link')
											? 'Enter password for your admin account'
											: 'Enter a new password for your account'}
									</p>
									<div className="authorization-form__body">
										<div className="input">
											<p className="input__name">Password</p>
											<div className="input-wrapper">
												<Field
													className="input-item input-item--left-icon"
													type={isShowPass ? 'text' : 'password'}
													name="new_password"
													placeholder="Password"
												/>
												<div
													onClick={handlePassDisplay}
													className="input-icon input-icon--bigger input-icon--right"
												>
													<SvgShowPass />
												</div>
												<span className="input-icon">
													<SvgPass />
												</span>
											</div>
										</div>
										<PasswordStrength password={values.new_password} />
										<div className="input">
											<p className="input__name">Confirm password</p>
											<div className="input-wrapper">
												<Field
													className="input-item input-item--left-icon"
													type={isShowPassConfirm ? 'text' : 'password'}
													name="confirm_password"
													placeholder="Password"
												/>
												<div
													onClick={handlePassConfirmDisplay}
													className="input-icon input-icon--bigger input-icon--right"
												>
													<SvgShowPass />
												</div>
												<span className="input-icon">
													<SvgPass />
												</span>
											</div>
											{errors?.confirm_password ? <ErrorMsg msg={errors.confirm_password} /> : null}
										</div>
										<div className="form-submit">
											<button
												disabled={
													!(isValid && values.new_password && values.confirm_password) ||
													isSubmitting
												}
												type="submit"
												className="button button--full-width"
											>
												Save
											</button>
										</div>
									</div>
								</div>
							)}
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};
