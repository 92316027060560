import { ArrowBackSvg, DownloadSvg } from 'assets/svg-icon';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import zip from '../../../assets/images/zip.png';

export const RegistrationRequests: FC = () => {
	return (
		<div className="main-content">
			<div className="url color-grey big-first-letter">Admins / Cooper Bator</div>

			<div className="title-block-wrap request-wrapper">
				<div className="title-block">
					<Link className="back-button" to={`${ELinksName.RESERVED_USERNAMES}`}>
						<ArrowBackSvg />
					</Link>
					<p className="title big-first-letter">Registration requests</p>
				</div>
				<div className="request-btn">
					<button className="request-btn__btn-reject" type="button">
						Reject
					</button>
					<button className="request-btn__btn-confirm" type="button">
						Confirm
					</button>
				</div>
			</div>
			<div className="table-block request">
				<h3 className="request__title">Requests</h3>
				<div className="request__list line-bottom">
					<span className="request__subtitle">Company</span>
					<span className="request__text">CompanyNameInc</span>
				</div>
				<h3 className="request__title">Requests text</h3>
				<div className="line-bottom">
					Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
					consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
				</div>
				<h3 className="request__title">Information</h3>
				<div className="request__list line-bottom">
					<ul className="request__list-ul">
						<li className="request__subtitle">Request ID</li>
						<li className="request__subtitle">Type</li>
						<li className="request__subtitle">Name</li>
						<li className="request__subtitle">Last name</li>
						<li className="request__subtitle">Requested username</li>
						<li className="request__subtitle">Email</li>
					</ul>
					<ul className="request__list-ul line-right">
						<li className="request__text">123456</li>
						<li className="request__text">Personal</li>
						<li className="request__text">Cooper</li>
						<li className="request__text">Bator</li>
						<li className="request__text">@Cooper</li>
						<li className="request__text">debbieker@example.com</li>
					</ul>
					<ul className="request__list-ul">
						<li className="request__subtitle">Request date</li>
						<li className="request__subtitle">Register Country</li>
						<li className="request__subtitle">IP country</li>
						<li className="request__subtitle">Profile</li>
						<li className="request__subtitle">Gender</li>
					</ul>
					<ul className="request__list-ul">
						<li className="request__text">Sep 24, 2022, 3:06 PM </li>
						<li className="request__text">United Kingdom</li>
						<li className="request__text">United Kingdom</li>
						<li className="request__text">Private</li>
						<li className="request__text">Male</li>
					</ul>
				</div>
				<h3 className="request__title">Confirming the ownership of the TM</h3>
				{[1, 2, 3].map((el) => (
					<div key={el} className="request__file-wrap">
						<div className="request__file-inner">
							<img src={zip} alt="zip file" className="request__img" />
							<div className="request__name">Name example.zip</div>
						</div>
						<DownloadSvg />
					</div>
				))}
			</div>
		</div>
	);
};
