import { FC, useRef, useState } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { SvgEmail, SvgPass, SvgShowPass } from 'ui/svg-image/SvgLogin';
import ErrorMsg from 'components/ErrorMsg';
import { password, email } from '../../../untils/formValidation';
import logo from '../../../assets/images/login-icon.svg';
import { ILoginForm, ILoginSubmitValue } from 'pages/Auth/Login/types';
import { Link } from 'react-router-dom';
import { CaptchaWrapper } from 'ui/CaptchaWrapper/CaptchaWrapper';
import type HCaptcha from '@hcaptcha/react-hcaptcha';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { useSelector } from 'react-redux';
import { getLoader } from 'redux/reducers/auth/selectors';
import { SmallLoader } from 'ui/SmallLoader';

export const LoginForm: FC<ILoginForm> = ({ loginSubmit }) => {
	const [isShowPass, setIsShowPass] = useState(false);
	const captchaRef = useRef<HCaptcha>(null);
	const loader = useSelector(getLoader);

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	const initialValues = {
		captcha_code: '',
		email: '',
		password: '',
	};

	// const validationSchema = yup.object().shape({
	// 	email,
	// });

	return (
		<div className="authorization-form__body">
			<Formik
				initialValues={initialValues}
				// validationSchema={validationSchema}
				onSubmit={(values: ILoginSubmitValue, { setSubmitting }) => {
					loginSubmit(values);
					setSubmitting(false);
				}}
				validateOnBlur
				enableReinitialize
			>
				{({ isSubmitting, isValid, values, errors, setFieldValue }) => {
					const onActiveCaptcha = (value: boolean) => {
						setFieldValue('captcha_code', '000000');
					};

					return (
						<Form>
							<div className="authorization-form">
								<div className="authorization-form__logo">
									<img src={logo} alt="" />
								</div>
								<p className="form-title">Login </p>
								<div className="authorization-form__body">
									<div className="input">
										<p className="input__name">Email</p>
										<div className="input-wrapper">
											<Field
												className="input-item input-item--left-icon"
												type="email"
												name="email"
												placeholder="Email"
											/>
											<span className="input-icon">
												<SvgEmail />
											</span>
										</div>
										{errors?.email ? <ErrorMsg msg={errors.email} /> : null}
									</div>
									<div className="input">
										<p className="input__name">Confirm password</p>
										<div className="input-wrapper">
											<Field
												className="input-item input-item--left-icon"
												type={isShowPass ? 'text' : 'password'}
												name="password"
												placeholder="Password"
											/>
											<div
												onClick={handlePassDisplay}
												className="input-icon input-icon--bigger input-icon--right"
											>
												<SvgShowPass />
											</div>
											<span className="input-icon">
												<SvgPass />
											</span>
										</div>
										{errors?.password ? <ErrorMsg msg={errors.password} /> : null}
									</div>
									<div className="wrapper-checkbox">
										<div className="checkbox-label">
											<input className="check__input" id="agreement" type="checkbox" />
											<label className="check__label" htmlFor="agreement">
												<span>Remember me</span>
											</label>
										</div>
										<Link to={ELinksName.RESET_PASSWORD}>Forgot Password?</Link>
									</div>
									<CaptchaWrapper
										onActiveCaptcha={onActiveCaptcha}
										errorCaptcha={errors.captcha_code}
										captchaRef={captchaRef}
									/>

									<div className="form-submit">
										<button
											disabled={!(isValid && values.email && values.password) || isSubmitting}
											type="submit"
											className="button button--full-width"
										>
											{loader ? <SmallLoader /> : 'Log in'}
										</button>
									</div>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};
