import avatar from '../../../assets/images/avatar.png';

export const mockDataChanel = [
	{
		id: 1,
		avatar: avatar,
		title: 'Gal Gadot - Official  ...',
		invite_link: '/GalGadot',
		verified: true,
		recommended: true,
		blocked: false,
		members: 925000,
		type: 'Private',
		created_date: 'Sep 24, 2022 3:06 PM',
		subscription_cost: '$15.00',
		country: 'USA',
		category: 'Music',
		age: '18+',
	},
];
