import { channelsCategory, channelsTypes } from 'CONST/userFilter';
import { AlbumTable } from 'components/ContentComponents/AlbumTable/AlbumTable';
import { Tabs } from 'components/ContentComponents/Tabs/Tabs';
import { TrackTable } from 'components/ContentComponents/TrackTable/TrackTable';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ETabsContent } from 'services/constants/lists-table';
import { Fillter } from 'ui/Fillter/Fillter';

export const Content: FC = () => {
	const params = useParams();

	const definePage = () => {
		switch (params.tab) {
			case ETabsContent.ALBUM:
				return <AlbumTable />;
			default:
				return <TrackTable />;
		}
	};

	return (
		<div className="main-content">
			<div className="title-block-wrap">
				<div className="title-block title-block--space-between">
					<p className="title"> Content </p>
				</div>
			</div>
			<Tabs />
			<div className="table-block">
				<Fillter
					type={channelsTypes}
					status={channelsCategory}
					typesName="Types"
					statusName="Status"
					keyStatus="status"
					keyType="types"
				/>
				{/* <div className="filter-custom"> */}
				<div className="table-wrapper">{definePage()}</div>
				{/* </div> */}
			</div>
		</div>
	);
};
