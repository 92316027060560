import {
	CallsSvg,
	ChackGreenSvg,
	ChannelSvg,
	ClockSvg,
	IcGroupSvg,
	ProgressSvg,
	UserSmallSvg,
	WithdrawSvg,
} from 'assets/svg-icon';
import ava from '../../../assets/images/ava.png';
import ava2 from '../../../assets/images/ava2.png';
import ava3 from '../../../assets/images/avatar.png';

export const topCard = [
	{
		svg: <WithdrawSvg />,
		title: 'TOP 3 paid channels',
		top: [
			{
				ava: ava,
				name: 'Gal Gadot - Officialcial',
				amount: '47 256 256',
				svg: <UserSmallSvg />,
			},
			{
				ava: ava2,
				name: 'Apple',
				amount: '32 002 236',
				svg: <UserSmallSvg />,
			},
			{
				ava: ava3,
				name: 'Drawing lessons',
				amount: '11 235 567',
				svg: <UserSmallSvg />,
			},
		],
	},

	{
		svg: <ChannelSvg />,
		title: 'TOP 3 free channels',
		top: [
			{
				ava: ava,
				name: 'Gal Gadot - Officialcial',
				amount: '47 256 256',
				svg: <UserSmallSvg />,
			},
			{
				ava: ava2,
				name: 'Apple',
				amount: '32 002 236',
				svg: <UserSmallSvg />,
			},
			{
				ava: ava3,
				name: 'Drawing lessons',
				amount: '11 235 567',
				svg: <UserSmallSvg />,
			},
		],
	},

	{
		svg: <IcGroupSvg />,
		title: 'TOP 3 public groups',
		top: [
			{
				ava: ava,
				name: 'Gal Gadot - Officialcial',
				amount: '47 256 256',
				svg: <UserSmallSvg />,
			},
			{
				ava: ava2,
				name: 'Apple',
				amount: '32 002 236',
				svg: <UserSmallSvg />,
			},
			{
				ava: ava3,
				name: 'Drawing lessons',
				amount: '11 235 567',
				svg: <UserSmallSvg />,
			},
		],
	},
];

export type TTopCard = {
	svg: JSX.Element;
	title: string;
	top: Array<{
		ava: string;
		name: string;
		amount: string;
		svg: JSX.Element;
	}>;
};

export const card = [
	{
		svg: <CallsSvg />,
		text: 'Total number of calls',
		number: '49 268',
		color: '#FFFFFF',
	},
	{
		svg: <ChackGreenSvg />,
		text: 'Users are calling at the moment',
		number: '49 268',
		color: 'rgba(39, 174, 96, 0.20)',
	},
	{
		svg: <ClockSvg />,
		text: 'Average call duration',
		number: '1h 35 min',
		color: 'rgba(242, 153, 74, 0.20)',
	},
	{
		svg: <ProgressSvg />,
		text: 'Average active screen time',
		number: '48 min',
		color: 'rgba(10, 73, 165, 0.20)',
	},
];
