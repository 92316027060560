export const channelsTabs = [
	'members',
	'refunds',
	'media-sales',
	'channel-data',
	'user-data',
	'groups-data',
	'groups-members',
	'groups-messages',
];
export const mediaTabs = ['media-and-files', 'groups-media-and-files'];

export const mocData = {
	about: '',
	adult: false,
	category: '',
	cost: '',
	country: '',
	created_at: '',
	id: 1,
	link: '',
	owner_first_name: '',
	owner_last_name: '',
	participant_count: 0,
	pay_type: 0,
	peer_type: '',
	photo: '',
	recommended: false,
	photo_access_hash: 0,
	title: '',
	type: '',
	verified: false,
};

export const arrayId = [
	'empty1',
	'empty2',
	'empty3',
	'empty4',
	'empty5',
	'empty6',
	'empty7',
	'empty8',
	'empty9',
	'empty10',
];
