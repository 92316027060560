import moment from 'moment';
import { FC } from 'react';
import { ITypes, IUsersRequest } from 'redux/reducers/support/types';
import { Calendar } from 'ui/Calendar/Calendar';
import { SelectList } from 'ui/SelectList/SelectList';

export interface IProps {
	onFilter: (values: IUsersRequest) => void;
	types: null | Array<ITypes>;
	onSaveChange: () => void;
	forFilter: (value: string) => void;
}

export const Filter: FC<IProps> = ({ onFilter, types, onSaveChange, forFilter }) => {
	const addDate = (date: Date, index?: number) => {
		onFilter({ [index === 0 ? 'date_from' : 'date_to']: moment(date).format('YYYY-MM-DD') });
	};

	const listTypes = types?.map((type) => type.Title) || [];
	listTypes.unshift('All');
	return (
		<div className="sidebar-left__users-wrap users">
			<div className="users__list county-lan-height filter-user">
				<div className="sidebar-left__subtitle">Filter</div>
				<div className="select-wrap select-wrap--zindex">
					<SelectList
						nameKey={'type_id'}
						onFilter={onFilter}
						placeholder="Types"
						data={listTypes}
					/>
				</div>
				<div className="culendar-wrap-filter">
					<Calendar addDate={addDate} index={0} />
					<Calendar addDate={addDate} index={1} />
				</div>
				<div className="select-wrap">
					<SelectList
						nameKey={'status'}
						onFilter={onFilter}
						placeholder="Status"
						data={['New', 'In progress', 'Completed']}
					/>
				</div>
			</div>
			<button
				onClick={() => {
					onSaveChange();
					forFilter('');
				}}
				className="county-lan__button"
			>
				Save
			</button>
		</div>
	);
};
