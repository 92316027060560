import { isAxiosError } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
// import L from 'i18n-react';
import { api, responseErrors } from 'services';
import { notificationContainer } from '../../../untils/notificationContainer';
import {
	addTopChannelsRequest,
	addTopChannelsSuccess,
	blockChannelsRequest,
	changeRecommendedRequest,
	changeRecommendedSuccess,
	changeVerifiedRequest,
	changeVerifiedSuccess,
	channelsInitState,
	channelsListRequest,
	channelsListSuccess,
	deleteChannelsRequest,
	deletePostRequest,
	deletePostSuccess,
	getAdminsChannelsRequest,
	getAdminsChannelsSuccess,
	getDetailsChannelRequest,
	getDetailsChannelSuccess,
	getMediaFilesRequest,
	getMediaFilesSuccess,
	getMediaSalesRequest,
	getMediaSalesSuccess,
	getMembersRequest,
	getMembersSuccess,
	getPostCommentsRequest,
	getPostCommentsSuccess,
	getPostRequest,
	getPostSuccess,
	getPostsRequest,
	getPostsSuccess,
	getStatisticRequest,
	getStatisticSuccess,
	getTopChannelsRequest,
	getTopChannelsSuccess,
	moveTopChannelsRequest,
	moveTopChannelsSuccess,
	postMediaFilesRequest,
	postMediaFilesSuccess,
	removeTopChannelsRequest,
	removeTopChannelsSuccess,
} from './reducer';
import {
	IAdminsChannelsResponse,
	IAdminsChannelsSaga,
	IChannelsRequest,
	IChannelsResponse,
	ICommentsResponse,
	ICommentsSagaRequest,
	IDeactivateSagaRequest,
	IDeletePost,
	IDeleteSagaRequest,
	IDetailsChannel,
	IMediaFilesListRequest,
	IMediaFilesListResponse,
	IMediaFilesRequest,
	IMediaFilesResponse,
	IMediaSalesResponse,
	IMediaSalesSaga,
	IMembersResponse,
	IMembersSaga,
	IMoveTopRequest,
	IPostRequest,
	IPostResponse,
	IPostsResponse,
	IPostsSaga,
	IRecommendedRequest,
	IStatisticRequest,
	IStatisticResponse,
	ITopCannelRequest,
	IVerifiedREquest,
} from './types';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EChannelsTabs, ETabsChannel } from 'services/constants/lists-table';

function* channelsListWorker(action: PayloadAction<IChannelsRequest>) {
	const { payload } = action;

	try {
		const response: { data: IChannelsResponse } = yield call(api.channels.channelsList, payload);
		yield put(channelsListSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(channelsInitState());
	}
}

function* getStatisticWorker(action: PayloadAction<IStatisticRequest>) {
	const { payload } = action;

	try {
		const response: IStatisticResponse = yield call(api.channels.getStatistic, payload);

		yield put(getStatisticSuccess(response));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(channelsInitState());
	}
}

function* getDetailsChannelWorker(action: PayloadAction<string>) {
	const { payload } = action;

	try {
		const response: { data: IDetailsChannel } = yield call(api.channels.getDetailsChanel, payload);
		yield put(getDetailsChannelSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(channelsInitState());
	}
}

function* postVerifiedAccountWorker(action: PayloadAction<IVerifiedREquest>) {
	const { payload } = action;

	try {
		yield call(api.channels.postVerifiedAccountChanel, payload);
		yield put(changeVerifiedSuccess(payload));
		notificationContainer(
			payload.verify ? 'Account verification successful' : 'Verification is disabled',
			'success',
		);
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(channelsInitState());
	}
}

function* changeRecommendedWorker(action: PayloadAction<IRecommendedRequest>) {
	const { payload } = action;

	try {
		yield call(api.channels.postChangeRecommended, payload);
		yield put(changeRecommendedSuccess(payload));
		notificationContainer(
			payload.recommend ? 'The channel is recommended' : 'The channel is not recommended',
			'success',
		);
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(channelsInitState());
	}
}

function* blockChannelWorker(action: PayloadAction<IDeactivateSagaRequest>) {
	const { paramsApi, navigate } = action.payload;

	try {
		yield call(api.channels.blockChannel, paramsApi);
		// yield notificationContainer('User deleted successfully', 'success');
		yield navigate(`${ELinksName.CHANNELS}/${ETabsChannel.ALL_CHANNELS}`);
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(channelsInitState());
	}
}

function* deleteChannelWorker(action: PayloadAction<IDeleteSagaRequest>) {
	const { paramsApi, navigate } = action.payload;

	try {
		yield call(api.channels.deleteChannel, paramsApi);
		yield notificationContainer('User deleted successfully', 'success');
		yield navigate(`${ELinksName.CHANNELS}/${ETabsChannel.ALL_CHANNELS}`);
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(channelsInitState());
	}
}

function* getAdminsWorker(action: PayloadAction<IAdminsChannelsSaga>) {
	const { payload } = action;

	try {
		const response: { data: IAdminsChannelsResponse } = yield call(api.channels.getAdmins, payload);
		yield put(getAdminsChannelsSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(channelsInitState());
	}
}

function* getMembersWorker(action: PayloadAction<IMembersSaga>) {
	const { payload } = action;

	try {
		const response: { data: IMembersResponse } = yield call(api.channels.getMembers, payload);
		yield put(getMembersSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(channelsInitState());
	}
}

function* getMediaSalesWorker(action: PayloadAction<IMediaSalesSaga>) {
	const { payload } = action;

	try {
		const response: { data: IMediaSalesResponse } = yield call(api.channels.getMediaSails, payload);
		yield put(getMediaSalesSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(channelsInitState());
	}
}

function* getPostsWorker(action: PayloadAction<IPostsSaga>) {
	const { payload } = action;

	try {
		const response: { data: IPostsResponse } = yield call(api.channels.getPosts, payload);
		yield put(getPostsSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(channelsInitState());
	}
}

function* getPostWorker(action: PayloadAction<IPostRequest>) {
	const { payload } = action;

	try {
		const response: { data: IPostResponse } = yield call(api.channels.getPost, payload);
		yield put(getPostSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(channelsInitState());
	}
}

function* getPostCommentsWorker(action: PayloadAction<ICommentsSagaRequest>) {
	const { payload } = action;

	try {
		const response: { data: ICommentsResponse } = yield call(api.channels.getPostComments, payload);
		yield put(getPostCommentsSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(channelsInitState());
	}
}

function* deletePostWorker(action: PayloadAction<IDeletePost>) {
	const { payload, type, navigate } = action.payload;

	try {
		yield call(api.channels.deletePost, payload);
		yield type === 'removeAll'
			? notificationContainer('Post deleted', 'success')
			: put(deletePostSuccess(payload));
		type === 'removeAll' &&
			navigate(
				`${ELinksName.CHANNEL_DETAIL_USER}/${payload.channel_id}/all-channels/${EChannelsTabs.POST}`,
			);
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(channelsInitState());
	}
}

function* postMediaFilesWorker(action: PayloadAction<IMediaFilesListRequest>) {
	const { payload } = action;

	try {
		const response: { data: IMediaFilesListResponse } = yield call(
			api.channels.postMediaFiles,
			payload,
		);
		yield put(postMediaFilesSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(channelsInitState());
	}
}

function* getMediaFilesWorker(action: PayloadAction<IMediaFilesRequest>) {
	const { payload } = action;

	try {
		const response: { data: IMediaFilesResponse } = yield call(api.channels.getMediaFile, payload);
		yield put(getMediaFilesSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(channelsInitState());
	}
}

function* getTopChannelsWorker() {
	try {
		const response: { data: IChannelsResponse } = yield call(api.channels.getTopChannels);
		console.log(response);
		yield put(getTopChannelsSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(channelsInitState());
	}
}

function* addTopChannelsWorker(action: PayloadAction<ITopCannelRequest>) {
	const { payload } = action;

	try {
		yield call(api.channels.addTopChannel, payload);
		yield put(addTopChannelsSuccess(payload.channel_id));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(channelsInitState());
	}
}

function* removeTopChannelsWorker(action: PayloadAction<ITopCannelRequest>) {
	const { payload } = action;

	try {
		yield call(api.channels.removeTopChannel, payload);
		yield put(removeTopChannelsSuccess(payload.channel_id));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(channelsInitState());
	}
}

function* moveTopChannelsWorker(action: PayloadAction<IMoveTopRequest>) {
	const { payload } = action;

	try {
		const response: { data: IChannelsResponse } = yield call(api.channels.moveTopChannel, payload);
		console.log(response);
		yield put(moveTopChannelsSuccess(1));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(channelsInitState());
	}
}

export function* channelsSaga() {
	yield takeEvery(channelsListRequest.type, channelsListWorker);
	yield takeEvery(getStatisticRequest.type, getStatisticWorker);
	yield takeEvery(getDetailsChannelRequest.type, getDetailsChannelWorker);
	yield takeEvery(changeVerifiedRequest.type, postVerifiedAccountWorker);
	yield takeEvery(changeRecommendedRequest.type, changeRecommendedWorker);
	yield takeEvery(blockChannelsRequest.type, blockChannelWorker);
	yield takeEvery(deleteChannelsRequest.type, deleteChannelWorker);
	yield takeEvery(getAdminsChannelsRequest.type, getAdminsWorker);
	yield takeEvery(getMembersRequest.type, getMembersWorker);
	yield takeEvery(getMediaSalesRequest.type, getMediaSalesWorker);
	yield takeEvery(getPostsRequest.type, getPostsWorker);
	yield takeEvery(getPostRequest.type, getPostWorker);
	yield takeEvery(getPostCommentsRequest.type, getPostCommentsWorker);
	yield takeEvery(deletePostRequest.type, deletePostWorker);
	yield takeEvery(postMediaFilesRequest.type, postMediaFilesWorker);
	yield takeEvery(getMediaFilesRequest.type, getMediaFilesWorker);
	yield takeEvery(getTopChannelsRequest.type, getTopChannelsWorker);
	yield takeEvery(addTopChannelsRequest.type, addTopChannelsWorker);
	yield takeEvery(removeTopChannelsRequest.type, removeTopChannelsWorker);
	yield takeEvery(moveTopChannelsRequest.type, moveTopChannelsWorker);
}
