export const createAvataar = (title: string): string => {
	const array = title?.trim()?.split(' ');
	if (array) {
		return array.length >= 2
			? array[0][0].toUpperCase() + array[1][0].toUpperCase()
			: title.slice(0, 2).toUpperCase();
	} else {
		return '';
	}
};
