import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ETabsGroups } from 'services/constants/lists-table';
import { SvgArrowUpHeader, SvgArrowDownHeader } from 'ui/svg-image/SvgAllChannels';

export const GroupsHeader: FC = () => {
	const { tab } = useParams();

	const onSwitch = () => {
		switch (tab) {
			case ETabsGroups.BLOCKED_GROUPS:
				return 'Block date';
			default:
				return 'Created date';
		}
	};

	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Avatar</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> Groups name</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> Groups URL</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Owner</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> # Members</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Type</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>{onSwitch()}</p>
					</div>
				</div>
			</div>
		</div>
	);
};
