import { ChangeEvent, FC, useState } from 'react';
import { LockSvg } from 'assets/svg-icon';

interface IProps {
	onLogin: (code: string) => void;
}

export const TwoFa: FC<IProps> = ({ onLogin }) => {
	const [code, setCode] = useState<string>('');

	const onHandleInput = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;

		value.length <= 6 && setCode(value);
	};

	return (
		<div className="authorization-form">
			<p className="form-title">2FA-Authorization</p>
			<p className="form-subtitle">Enter 2FA-Authorization code from app on your phone.</p>
			<div className="authorization-form__body">
				<div className="input">
					<p className="input__name">2FA-Authorization</p>
					<div className="input-wrapper">
						<input
							onChange={onHandleInput}
							value={code}
							className="input-item input-item--left-icon"
							type="number"
							name="email"
							placeholder="6-digit code"
						/>
						<span className="input-icon">
							<LockSvg />
						</span>
					</div>
					<p className="qr-code__info gray">Enter the verification code</p>
				</div>
				<div className="form-submit">
					<button
						disabled={code.length !== 6}
						onClick={() => onLogin(code)}
						type="button"
						className="button button--full-width"
					>
						Verify
					</button>
				</div>
			</div>
		</div>
	);
};
