import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StatisticsSubTabs } from './SubTabs/SubTabs';
import { Fillter } from 'ui/Fillter/Fillter';
import { statisticsCurrency, statisticsStatus, transactionTypes } from 'CONST/userFilter';
import { StatisticsHeader } from './StatisticsHeader/StatisticsHeader';
import { useSelector } from 'react-redux';
import { getChannelsLoaderSelector } from 'redux/reducers/channels/selectors';
import { Loader } from 'components/Loader';
import { StatisticsBody } from './StatisticsBody/StatisticsBody';
import { CustomersHeader } from './CustomersHeader/CustomersHeader';
import { CustomersBody } from './CustomersBody/CustomersBody';
import { IStatisticsResponse } from 'redux/reducers/finance/finance-types';
import { getLoaderSelector } from 'redux/reducers/finance/finance-selectors';
import { ETabsFinanceStatistics } from 'services/constants/lists-table';

interface IProps {
	transaction: IStatisticsResponse | null;
	setStatisticsRedquest: any;
	statisticsRedquest: any;
}

export const Statistics: FC<IProps> = ({ transaction, setStatisticsRedquest }) => {
	const [toggle, setToggle] = useState(false);
	const params = useParams();
	const loader = useSelector(getLoaderSelector);

	const handleTofuChange = () => {
		setToggle(!toggle);
	};

	useEffect(() => {
		return () => {
			setStatisticsRedquest({
				currencies: '',
				current_page: 1,
				date_from: '',
				date_to: '',
				per_page: 15,
				search: '',
				show_internal: false,
				show_transfer: false,
				status: '',
				type: '',
			});
		};
	}, []);

	return (
		<>
			<StatisticsSubTabs />
			<Fillter
				keyStatus="status"
				keyType="type"
				type={transactionTypes}
				status={statisticsStatus}
				other={statisticsCurrency}
				setDataFiltter={setStatisticsRedquest}
				typesName="Types"
				statusName="Status"
				otherName="Currency"
				grid="filter-grid"
			/>
			<div className="table-wrapper">
				<div
					className={`table table--${
						params.subTab === ETabsFinanceStatistics.CUSTOMERS
							? 'statistics-customers'
							: 'statistics-systemic'
					} table--channels-loader container-finance`}
				>
					{params.subTab !== ETabsFinanceStatistics.CUSTOMERS ? (
						<>
							<StatisticsHeader />
							{loader ? <Loader /> : <StatisticsBody transaction={transaction} />}
						</>
					) : (
						<>
							<CustomersHeader />
							{loader ? <Loader /> : <CustomersBody transaction={transaction} />}
						</>
					)}
				</div>
			</div>
		</>
	);
};
