import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { store } from 'redux/store';

import {
	IAdminAuthUserResponseData,
	IAdminLogoutData,
	IAuthStore,
	IResetReducer,
	IForgotReducer,
	ILoginRequestReducer,
	IResponseQrCode,
	I2faEnableRequest,
} from './types';
import { IRestFormSubmitValue } from 'pages/Auth/ForgotPass/types';
import { saveToken } from 'untils/saveToken';

// ==========================================:
export const initialState: IAuthStore = {
	accessToken: null,
	isAuthenticated: false,
	loginLoader: false,
	adminLogoutLoader: false,
	remember: false,
	userData: null,
	qrCode: '',
};

export const refreshToken = async (token: string): Promise<string> => {
	const res = await fetch('https://api.ellomessenger.com/api/v1/auth/refresh_token', {
		method: 'GET',
		credentials: 'omit',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'X-Refresh': localStorage.getItem('refresh_token') || '',
		},
	});

	const result: { access_token: string; refresh_token: string; status: string } = await res.json();
	saveToken(result.access_token, result.refresh_token);

	return result.access_token || '';
};

// ==========================================:
const auth = createSlice({
	name: '@@auth',
	initialState,
	reducers: {
		loginRequest: (state, action: PayloadAction<ILoginRequestReducer>) => {
			const loginRequestState = state;

			loginRequestState.loginLoader = true;
		},

		loginSuccess: (state, action: PayloadAction<IAdminAuthUserResponseData>) => {
			const { payload } = action;
			const loginState = state;
			localStorage.setItem('refresh_token', payload.refresh_token);
			localStorage.setItem('token', payload.access_token);
			loginState.isAuthenticated = true;
			loginState.loginLoader = false;
			loginState.accessToken = payload.access_token;
			loginState.userData = payload;
		},

		loginError: (state) => {
			const loginState = state;
			loginState.loginLoader = false;
		},

		generateQrRequest: (state, { payload }: PayloadAction<IRestFormSubmitValue>) => {},
		generateQrSuccess: (state, { payload }: PayloadAction<IResponseQrCode>) => {
			const initialState = state;
			initialState.qrCode = payload.otp_auth_url;
		},

		enable2Fa: (state, { payload }: PayloadAction<I2faEnableRequest>) => {},

		forgotRequest: (state, action: PayloadAction<IForgotReducer>) => {
			const loginRequestState = state;
			loginRequestState.loginLoader = true;
		},

		forgotSuccess: (state) => {
			const loginState = state;
			loginState.loginLoader = false;
		},

		resetRequest: (state, action: PayloadAction<IResetReducer>) => {
			const loginRequestState = state;
			loginRequestState.loginLoader = true;
		},

		resetSuccess: (state) => {
			const loginState = state;
			loginState.loginLoader = false;
		},

		createPassRequest: (state, action: PayloadAction<IResetReducer>) => {
			const loginRequestState = state;
			loginRequestState.loginLoader = true;
		},

		createPassSuccess: (state) => {
			const loginState = state;
			loginState.loginLoader = false;
		},

		logoutRequest: (state, action: PayloadAction<IAdminLogoutData>) => {
			const logoutState = state;

			logoutState.adminLogoutLoader = true;
		},

		refreshRequest: (state, action: PayloadAction<any>) => {
			const logoutState = state;
		},

		refreshResponse: (
			state,
			action: PayloadAction<{ access_token: string; refresh_token: string }>,
		) => {
			const refreshState = state;
			refreshState.accessToken = action.payload.access_token;
			if (action.payload.refresh_token && refreshState.userData?.refresh_token)
				refreshState.userData.refresh_token = action.payload.refresh_token;
		},

		authInitState: (state) => {
			const authInitStateState = state;

			if (authInitStateState.remember) {
				authInitStateState.isAuthenticated = false;
				authInitStateState.accessToken = null;
				authInitStateState.adminLogoutLoader = false;
				authInitStateState.loginLoader = false;
			} else {
				return initialState;
			}
		},
	},
});

export default auth.reducer;
export const {
	loginRequest,
	loginSuccess,
	loginError,
	generateQrRequest,
	generateQrSuccess,
	enable2Fa,
	forgotRequest,
	forgotSuccess,
	resetRequest,
	resetSuccess,
	createPassRequest,
	createPassSuccess,
	logoutRequest,
	refreshRequest,
	refreshResponse,
	authInitState,
} = auth.actions;
