import { IDataFiltter } from 'pages/Dashboard/Channels';
import { FC, useState, MouseEvent } from 'react';
import { SvgArrowDownHeader, SvgArrowUpHeader } from 'ui/svg-image/SvgAllChannels';

interface IPropsUsersTableHeader {
	setDataFiltter: (value: IDataFiltter) => void;
}

export const UserPartnerHeader: FC<IPropsUsersTableHeader> = ({ setDataFiltter }) => {
	const [sort, setSort] = useState<number>(0);
	const [prev, setPrev] = useState<string>('');

	const sortTable = (event: MouseEvent) => {
		const target = event.currentTarget as HTMLButtonElement;
		if (prev !== target.id && sort !== 0) {
			setSort(1);
			setPrev(target.id);
			setDataFiltter({ order_by: target.id, asc: true });
			return;
		}

		switch (sort + 1) {
			case 1:
				setDataFiltter({ order_by: target.id, asc: true });

				break;
			case 2:
				setDataFiltter({ order_by: target.id, asc: false });

				break;
			default:
				setDataFiltter({ order_by: target.id });

				break;
		}

		if (sort === 2) {
			setSort(0);
			setPrev(target.id);
			return;
		}
		setSort((prevNum) => prevNum + 1);
		setPrev(target.id);
	};

	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Photo</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> Name</p>
						<div className="sort-td">
							<button onClick={sortTable} id="name" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="name" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> User name</p>
						<div className="sort-td">
							<button onClick={sortTable} id="username" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="username" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Email</p>
					</div>
				</div>
				<div className="td td--right-custom">
					<div className="td-name">
						<p>Total</p>
						<div className="sort-td">
							<button onClick={sortTable} id="total" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="total" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right-custom">
					<div className="td-name">
						<p>Commission</p>
						<div className="sort-td">
							<button onClick={sortTable} id="commission" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="commission" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				{/* <div className="td td--center td--right-custom">
					<div className="td-name">
						<p> Verified account</p>
					</div>
				</div> */}
			</div>
		</div>
	);
};
