import { FC, useState } from 'react';
import filesize from 'file-size';

import { DeleteSvg, DocxSvg, DownloadSvg, ErrorSvg, SoundSvg } from 'assets/svg-icon';
import { useParams } from 'react-router-dom';
import { EMediaSubtabs } from 'services/constants/lists-table';
import { IMediaFilesResponse } from 'redux/reducers/channels/types';
import moment from 'moment';
import { Avatar } from 'ui/Avatar/Avatar';
import { onJsonParseBigin } from 'untils/jsonParse';
import { useDispatch } from 'react-redux';
import { getFileRequest } from 'redux/reducers/download/reducer';
import { endpoint } from 'services/endpoint';

interface IProps {
	fileDetails: IMediaFilesResponse;
}

export const Media: FC<IProps> = ({ fileDetails }) => {
	const params = useParams();
	const dispatch = useDispatch();
	const [media, setMedia] = useState(onJsonParseBigin(fileDetails.media));

	console.log(media);

	const onShowImg = () => {
		console.log(String(media?.document?.access_hash), String(media?.photo_FLAGPHOTO?.access_hash));
		switch (params.tab) {
			// case EMediaSubtabs.AUDIO:
			// 	return <SoundSvg />;

			// case EMediaSubtabs.FILES:
			// 	return <DocxSvg />;

			// case EMediaSubtabs.LINKS:
			// 	return;

			default:
				return (
					<Avatar
						hash={String(
							media?.document?.access_hash || media?.photo_FLAGPHOTO?.access_hash,
						).trim()}
						type={media?.document?.mime_type || media?.photo_FLAGPHOTO.mime_type}
						endpoint={
							media?.predicate_name.toLowerCase().includes('document')
								? '/storage/document'
								: '/storage/photo'
						}
					/>
				);
		}
	};

	const onDownload = (file: any, photo_FLAGPHOTO: any, type_file: string) => {
		console.log(media);
		if (type_file === 'video') {
			dispatch(
				getFileRequest({
					endpoint: endpoint.utils.video(
						String(file?.access_hash || photo_FLAGPHOTO?.access_hash)?.trim(),
					),
				}),
			);
		}
		if (type_file === 'messageMediaDocument') {
			dispatch(
				getFileRequest({
					endpoint: endpoint.utils.document(
						String(file?.access_hash || photo_FLAGPHOTO?.access_hash)?.trim(),
					),
				}),
			);
		}
		if (type_file === 'messageMediaPhoto') {
			dispatch(
				getFileRequest({
					endpoint: endpoint.utils.photo(
						String(file?.access_hash || photo_FLAGPHOTO?.access_hash)?.trim(),
					),
				}),
			);
		}
	};

	return (
		<section className="">
			<div className="media media__wrapper">
				<div className="media__file-inner">{onShowImg()}</div>
				<ul className="details-user__box-text details-user__box-text-custom details-user__box-tex-close">
					<li className="details-user__item">
						<span>Name</span>
						<span>{fileDetails.name}</span>
					</li>
					<li className="details-user__item">
						<span>Size</span>
						<span>{`${filesize(fileDetails.size).to('MB')} Mb`}</span>
					</li>
					<li className="details-user__item">
						<span>Author</span>
						<span>{fileDetails.author}</span>
					</li>
					<li className="details-user__item">
						<span>Published</span>
						<span>{moment(fileDetails.published).format('MMM DD, YYYY h:mm A')}</span>
					</li>
				</ul>
				<div className="files-list__block-right files-list__block-right-custom">
					<div
						onClick={() => onDownload(media.document, media.photo_FLAGPHOTO, media.predicate_name)}
					>
						<DownloadSvg />
					</div>
					<ErrorSvg />
					<DeleteSvg />
				</div>
			</div>
		</section>
	);
};
