import classNames from 'classnames';
import { FC } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import {
	EBackgroundTabs,
	EMonetizationTabs,
	ESubTabsFinance,
} from 'services/constants/lists-table';

export const MonetizationTabs: FC = () => {
	const params = useParams();

	return (
		<div className="tabs-buttons-nav">
			<Link
				to={`${ELinksName.MONETIZATION}/${EMonetizationTabs.BACKGROUND}/${EBackgroundTabs.SINGE}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EMonetizationTabs.BACKGROUND || !params.tab,
				})}
			>
				Background
			</Link>
			<Link
				to={`${ELinksName.MONETIZATION}/${EMonetizationTabs.CASHBACK}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EMonetizationTabs.CASHBACK,
				})}
			>
				Cashback
			</Link>
		</div>
	);
};
