import { FC, useState } from 'react';
import { IPhotos } from 'redux/reducers/users/types';
import { SwiperSellPhoto } from 'ui/SwiperSellPhoto';
import classNames from 'classnames';
import { GET_PHOTO } from 'services/constants/env';
import { Avatar } from 'ui/Avatar/Avatar';

interface IProps {
	photos: Array<IPhotos> | undefined;
}

export const ImageList: FC<IProps> = ({ photos }) => {
	const [open, setOpen] = useState<boolean>(false);

	const onOpenSwiper = () => {
		setOpen(!open);
	};

	return (
		<>
			{open && photos?.length && <SwiperSellPhoto images={photos} setOpenAllPhoto={setOpen} />}
			<div
				onClick={onOpenSwiper}
				className={classNames('details-user__box-photo box-photo', {
					'box-photo-one': photos?.length === 1,
				})}
			>
				{photos?.slice(0, 4).map((photo, index) => {
					return (
						<div
							key={photo.id}
							className={classNames('box-photo__inner', {
								'box-photo-one__inner': photos?.length === 1,
							})}
						>
							<div className="box-photo__box">
								<Avatar hash={String(photo.photo_access_hash)} />
							</div>
							{photos.length > 4 && index + 1 === 4 && (
								<div className="box-photo__inner-photo">
									<div className="box-photo__title">{`+${photos.length}`}</div>
									<div className="box-photo__subtitle">Photos</div>
								</div>
							)}
						</div>
					);
				})}
			</div>
		</>
	);
};
