import { ArrowBackSvg } from 'assets/svg-icon';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Details } from './Details/Details';

export const RequestDetails: FC = () => {
	const navigate = useNavigate();

	return (
		<div className="main-content">
			<div className="url color-grey big-first-letter">
				{location.pathname.slice(1).replaceAll('/', ' / ')}
			</div>
			<div className="title-block-wrap flex-content">
				<div className="title-block">
					<div className="back-button pointer" onClick={() => navigate(-1)}>
						<ArrowBackSvg />
					</div>
					<p className="title big-first-letter">Partnership Request</p>
				</div>
				<div className="request-btn">
					<button className="request-btn__btn-reject" type="button">
						Reject
					</button>
					<button className="request-btn__btn-confirm" type="button">
						Confirm
					</button>
				</div>
			</div>
			<Details />
		</div>
	);
};
