import { store } from 'redux/store';
import { IAxiosError, IAxiosRequestConfig } from './types';
import { getTokenRefreshFatch } from 'untils/getTokenFatch';
import { authInitState } from 'redux/reducers/auth/reducer';
import axios from 'axios';
let isRefreshing = false;
// ==========================================:
const onError = async (error: IAxiosError) => {
	const status = error.response ? error.response.status : null;
	const errorResponse = error.config as IAxiosRequestConfig;

	if ((status === 401 && !errorResponse?._retry) || status === null) {
		errorResponse._retry = true; // Встановлення прапорця, щоб уникнути повторних спроб
		isRefreshing = true;
		try {
			await getTokenRefreshFatch(); // Оновлення токену
			// Отримання оновленого токену з Redux state
			const state = store.getState();

			const newAccessToken = state.auth.accessToken;

			// Оновлення заголовка Authorization з новим токеном
			if (newAccessToken && errorResponse.headers) {
				errorResponse.headers['Authorization'] = `Bearer ${newAccessToken}`;
			}

			// Повторний запит з оновленим токеном
			const newRequest = await axios(errorResponse);
			return Promise.resolve(newRequest);
		} catch (tokenError) {
			return Promise.reject(tokenError); // Якщо оновлення токену не вдалось
		} finally {
			isRefreshing = false;
		}
	}

	return Promise.reject(error);
};

export default onError;
