import { FC } from 'react';
import { Editor } from 'ui/Lexical/Lexical';
import { SelectList } from 'ui/SelectList/SelectList';
import { firstUppercaseLetter } from 'untils/firstUppercaseLetter';
import { ITemplatesData } from '../data';

interface IProps {
	structure: ITemplatesData;
}

export const Accordion: FC<IProps> = ({ structure }) => {
	return (
		<div className="accordion">
			<div className="accordion__box">
				<div className="accordion__left-content">{firstUppercaseLetter(structure.title)}</div>
				<div className="accordion__right-content">
					<Editor styles={{ minHeight: 'auto' }} />
				</div>
			</div>
			{structure.code ? (
				<div className="accordion__box">
					<div className="accordion__left-content">{structure.code}</div>
					<div className="accordion__right-content">
						<Editor styles={{ minHeight: 'auto' }} />
					</div>
				</div>
			) : null}
		</div>
	);
};
