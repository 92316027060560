import { FC, MouseEvent, useRef, useState } from 'react';
import { IUsersName } from 'redux/reducers/reserved/types';
import { UsernameList } from './UsernameList';

interface IProps {
	usernames: Array<IUsersName> | undefined;
	idName: number;
	onPopup: (value: number) => void;
	isOpenModal: (event?: MouseEvent) => void;
}

export const UsernameBody: FC<IProps> = ({ usernames, idName, onPopup, isOpenModal }) => {
	return (
		<div className="table-body">
			{usernames?.map((name) => (
				<UsernameList
					isOpenModal={isOpenModal}
					popup={idName}
					onUserId={onPopup}
					key={name.id}
					name={name}
				/>
			))}
		</div>
	);
};
