import { FC } from 'react';

import { Checkbox } from 'ui/Checkbox/Checkbox';
import { IBoxFirstProps } from '../types';

export const BoxSecond: FC<IBoxFirstProps> = ({ changeAdmin, onChangeAdmin }) => {
	return (
		<ul className="admin-info__list">
			<li className="admin-info__item">
				<span className="admin-info__first-column">Reset Password</span>
				<span className="admin-info__second-column">
					<div className="checkbox-two checkbox--mb-0 ">
						<label className="checkbox-two__label">
							<input onChange={onChangeAdmin} type="checkbox" className="hidden" name="require" />
							<span className="checkbox-two__item round">
								<span className="checkbox-two__item-icon small">{/* <CheckSvg /> */}</span>
							</span>
							<div className="admin-info__checkbox">
								<span className={`checkbox-two__text`}>
									Require administrator to change their password the next time they log in
								</span>
							</div>
						</label>
					</div>
				</span>
			</li>
		</ul>
	);
};
