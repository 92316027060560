import classNames from 'classnames';
import { FC } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EAiRestrictionSubTabs, EAiServicesTabs, EAiSubTabs } from 'services/constants/lists-table';

export const AiServicesTabs: FC = () => {
	const params = useParams();

	return (
		<div className="tabs-buttons-nav">
			{/* <Link
				to={`${ELinksName.AI_SERVICES}/${EAiServicesTabs.AI_MANAGEMENT}/${EAiSubTabs.TEXT_AI}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EAiServicesTabs.AI_MANAGEMENT || !params.tab,
				})}
			>
				AI management
			</Link>
			<Link
				to={`${ELinksName.AI_SERVICES}/${EAiServicesTabs.OF_AI_INCOME}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EAiServicesTabs.OF_AI_INCOME,
				})}
			>
				Of AI Income
			</Link> */}
			<Link
				to={`${ELinksName.AI_SERVICES}/${EAiServicesTabs.AI_SETTINGS_AND_RESTRICTION}/${EAiRestrictionSubTabs.WORDS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EAiServicesTabs.AI_SETTINGS_AND_RESTRICTION || !params.tab,
				})}
			>
				AI Settings and Restrictions
			</Link>
			{/* <Link
				to={`${ELinksName.AI_SERVICES}/${EAiServicesTabs.AI_MONITORING_TOPICS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EAiServicesTabs.AI_MONITORING_TOPICS,
				})}
			>
				AI monitoring topics
			</Link>
			<Link
				to={`${ELinksName.AI_SERVICES}/${EAiServicesTabs.USERS_VIOLATIONS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EAiServicesTabs.USERS_VIOLATIONS,
				})}
			>
				Users Violations
			</Link> */}
		</div>
	);
};
