import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IChannelsRequest,
	IChannelsResponse,
	IListTypes,
	IPrivious,
	IRejectRequest,
	IReportDetails,
	IReportsPriviousSaga,
	IReportsState,
} from './types';

export const initialState: IReportsState = {
	channels: null,
	posts: null,
	groups: null,
	messages: null,
	reportsDetails: null,
	previous: null,
	types: null,
	reportsLoader: false,
};

const reports = createSlice({
	name: '@@reports',
	initialState,
	reducers: {
		getReportsChannelRequest: (state, action: PayloadAction<IChannelsRequest>) => {
			const getReportsChannelState = state;
			getReportsChannelState.reportsLoader = true;
		},

		getReportsChannelSuccess: (state, action: PayloadAction<IChannelsResponse>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
			getReportsState.channels = payload;
		},

		getReportsPostRequest: (state, action: PayloadAction<IChannelsRequest>) => {
			const getReportsState = state;
			getReportsState.reportsLoader = true;
		},

		getReportsPostSuccess: (state, action: PayloadAction<IChannelsResponse>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
			getReportsState.posts = payload;
		},

		getReportsGroupsRequest: (state, action: PayloadAction<IChannelsRequest>) => {
			const getReportsState = state;
			getReportsState.reportsLoader = true;
		},

		getReportsGroupSuccess: (state, action: PayloadAction<IChannelsResponse>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
			getReportsState.groups = payload;
		},

		getReportsMessagesRequest: (state, action: PayloadAction<IChannelsRequest>) => {
			const getReportsState = state;
			getReportsState.reportsLoader = true;
		},

		getReportsMessagesSuccess: (state, action: PayloadAction<IChannelsResponse>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
			getReportsState.messages = payload;
		},

		getReportsDetailsRequest: (state, action: PayloadAction<string>) => {
			const getReportsState = state;
			getReportsState.reportsLoader = true;
		},

		getReportsDetailsSuccess: (state, action: PayloadAction<IReportDetails | null>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
			getReportsState.reportsDetails = payload;
		},

		getReportPreviousRequest: (state, action: PayloadAction<IReportsPriviousSaga>) => {
			const getReportsState = state;
			getReportsState.reportsLoader = true;
		},

		getReportPreviousSuccess: (state, action: PayloadAction<IPrivious | null>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
			getReportsState.previous = payload;
		},

		getTypesRequest: (state) => {
			const getReportsState = state;
			getReportsState.reportsLoader = true;
		},

		getTypesSuccess: (state, action: PayloadAction<Array<IListTypes>>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
			getReportsState.types = payload;
		},

		rejectMessageRequest: (state, action: PayloadAction<IRejectRequest>) => {
			const getReportsState = state;
			getReportsState.reportsLoader = true;
		},

		rejectMessageSuccess: (state, action: PayloadAction<string>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
			// getReportsState.reportsDetails?.Report.
		},

		deactivateChannelRequest: (state, action: PayloadAction<string>) => {
			const getReportsState = state;
			getReportsState.reportsLoader = true;
		},

		deactivateChannelSuccess: (state, action: PayloadAction<string>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
		},

		deactivateGroupRequest: (state, action: PayloadAction<string>) => {
			const getReportsState = state;
			getReportsState.reportsLoader = true;
		},

		deactivateGroupSuccess: (state, action: PayloadAction<string>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
		},

		deactivateMessageRequest: (state, action: PayloadAction<string>) => {
			const getReportsState = state;
			getReportsState.reportsLoader = true;
		},

		deactivateMessageSuccess: (state, action: PayloadAction<string>) => {
			const getReportsState = state;
			const { payload } = action;
			getReportsState.reportsLoader = false;
		},

		reportsInitState: (state) => {
			const reportsInitState = state;
			reportsInitState.reportsLoader = false;
		},
	},
});

export default reports.reducer;
export const {
	getReportsChannelRequest,
	getReportsChannelSuccess,
	getReportsPostRequest,
	getReportsPostSuccess,
	getReportsGroupsRequest,
	getReportsGroupSuccess,
	getReportsMessagesRequest,
	getReportsMessagesSuccess,
	getReportsDetailsRequest,
	getReportsDetailsSuccess,
	getReportPreviousRequest,
	getReportPreviousSuccess,
	getTypesRequest,
	getTypesSuccess,
	rejectMessageRequest,
	rejectMessageSuccess,
	deactivateChannelRequest,
	deactivateChannelSuccess,
	deactivateGroupRequest,
	deactivateGroupSuccess,
	deactivateMessageRequest,
	deactivateMessageSuccess,
	reportsInitState,
} = reports.actions;
