import { SearchSvg } from 'assets/svg-icon';
import { TermsBody } from 'components/TermManagementComponents/TermsBody/TermsBody';
import { TermsHeader } from 'components/TermManagementComponents/TermsHeader/TermsHeader';
import { FC, useState, ChangeEvent } from 'react';
import { DebounceInput } from 'react-debounce-input';

export const TermManagement: FC = () => {
	const [search, setSearch] = useState<string>('');

	const onSearch = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		setSearch(value);
		// @ts-ignore
		setDataFiltter?.((prev: IDataFiltter) => {
			return {
				...prev,
				search: value,
			};
		});
	};

	return (
		<div className="main-content">
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title"> Terms management </p>
				</div>
			</div>

			<div className="table-block table-block-system margin-top">
				<div className="table-wrapper">
					<section className="system">
						<div className="system__header input-right">
							<div className="input input-custom input-search select-custom-width">
								<div className="input-wrapper">
									<DebounceInput
										className="input-item input-item--left-icon"
										placeholder="Search"
										debounceTimeout={500}
										value={search || ''}
										onChange={onSearch}
									/>
									<span className="input-icon">
										<SearchSvg />
									</span>
								</div>
							</div>
						</div>
						<div className="table table--terms">
							<TermsHeader />
							<TermsBody />
						</div>
					</section>
				</div>
			</div>
		</div>
	);
};
