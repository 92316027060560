import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IReservedUsernamesStore } from './types';

const getUsernamesState = (state: IStoreState): IReservedUsernamesStore => state.reservedUsernames;

export const getUsernamesSelector = createSelector(
	[getUsernamesState],
	(reserved: IReservedUsernamesStore) => reserved.usernames,
);

export const getreservedLoaderSelector = createSelector(
	[getUsernamesState],
	(reserved: IReservedUsernamesStore) => reserved.reservedLoader,
);
