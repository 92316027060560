import { IOpenPopup } from 'components/ChannelsComponents/ChannelsBodyTable';
import { FC, MouseEvent } from 'react';

interface IPropsBlock {
	upgradeChannel: (event: MouseEvent, value: boolean) => void;
	setIsOpenPopup: (value: IOpenPopup | null) => void;
	isOpenPopup: IOpenPopup;
}

export const Block: FC<IPropsBlock> = ({ isOpenPopup, upgradeChannel, setIsOpenPopup }) => {
	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup">
					<div className="popup-body popup-body--mt-0">
						<div className="popup-text  popup-text--center popup-text--fw-500 popup-text--fs16">
							<p>Are you sure you want to block this channel?</p>
						</div>
					</div>
					<div className="popup-buttons popup-buttons--column">
						<button
							onClick={() => setIsOpenPopup(null)}
							type="button"
							className="button  button--fixed-width"
						>
							Cancel
						</button>
						<button
							id={isOpenPopup.id}
							name={isOpenPopup.name}
							onClick={(e: MouseEvent) => {
								setIsOpenPopup(null);
								upgradeChannel(e, true);
							}}
							type="button"
							className="button button--type2 button--fixed-width"
						>
							Yes, I’m sure
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
