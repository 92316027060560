import classNames from 'classnames';
import { FC } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EChannelsTabs, EMediaSubtabs, ESubtabsConversation } from 'services/constants/lists-table';

export const ChannelTabs: FC = () => {
	const params = useParams();

	return (
		<div className="tabs-buttons-nav">
			<Link
				to={`${ELinksName.CHANNEL_DETAIL_USER}/${params.id}/${params.userId}/${EChannelsTabs.CANNELS_DATA}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EChannelsTabs.CANNELS_DATA || !params.tab,
				})}
			>
				Channel data
			</Link>
			<Link
				to={`${ELinksName.CHANNEL_DETAIL_USER}/${params.id}/${params.userId}/${EChannelsTabs.ADMINS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EChannelsTabs.ADMINS,
				})}
			>
				Admins
			</Link>
			<Link
				to={`${ELinksName.CHANNEL_DETAIL_USER}/${params.id}/${params.userId}/${EChannelsTabs.MEMBERS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EChannelsTabs.MEMBERS,
				})}
			>
				Members
			</Link>
			<Link
				to={`${ELinksName.CHANNEL_DETAIL_USER}/${params.id}/${params.userId}/${EChannelsTabs.MEDIA_SALES}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EChannelsTabs.MEDIA_SALES,
				})}
			>
				Media sales
			</Link>
			<Link
				to={`${ELinksName.CHANNEL_DETAIL_USER}/${params.id}/${params.userId}/${EChannelsTabs.POST}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EChannelsTabs.POST,
				})}
			>
				Posts
			</Link>
			<Link
				to={`${ELinksName.CHANNEL_DETAIL_USER}/${params.id}/${params.userId}/${EChannelsTabs.MEDIA_AND_FILES}/${EMediaSubtabs.MEDIA}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EChannelsTabs.MEDIA_AND_FILES,
				})}
			>
				Media and files
			</Link>
			<Link
				to={`${ELinksName.CHANNEL_DETAIL_USER}/${params.id}/${params.userId}/${EChannelsTabs.REFUNDS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EChannelsTabs.REFUNDS,
				})}
			>
				Refunds
			</Link>
		</div>
	);
};
