import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	IBalancesDeposit,
	IBalancesWithdrawl,
	ICommissionsResonse,
	IFiatResponse,
	IFinanceState,
	IMethods,
	IStatisticsResponse,
	ITransactionGraphResponse,
	IWithdrawalDetails,
} from './finance-types';

const getFinanceState = (state: IStoreState): IFinanceState => state.finance;

export const getFiatListSelector = createSelector(
	[getFinanceState],
	(finance: IFinanceState): null | IFiatResponse => finance.fiatList,
);

export const getLoaderSelector = createSelector(
	[getFinanceState],
	(finance: IFinanceState): boolean => finance.financeLoader,
);

export const getFiatSelector = createSelector(
	[getFinanceState],
	(finance: IFinanceState): null | IWithdrawalDetails => finance.fiat,
);

export const getCommissionsSelector = createSelector(
	[getFinanceState],
	(finance: IFinanceState): null | ICommissionsResonse => finance.commissions,
);

export const getMethodsSelector = createSelector(
	[getFinanceState],
	(finance: IFinanceState): null | IMethods => finance.methods,
);

export const getStatisticsSelector = createSelector(
	[getFinanceState],
	(finance: IFinanceState): null | IStatisticsResponse => finance.statistics,
);

export const getDepositSelector = createSelector(
	[getFinanceState],
	(finance: IFinanceState): null | IBalancesDeposit => finance.balancesDeposit,
);

export const getWithdrawalSelector = createSelector(
	[getFinanceState],
	(finance: IFinanceState): null | IBalancesWithdrawl => finance.balancesWithdrawal,
);

export const getGraphSelector = createSelector(
	[getFinanceState],
	(finance: IFinanceState): null | Array<ITransactionGraphResponse> => finance.graph,
);
