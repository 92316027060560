import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IAddLoyatlyRequest,
	IChangeLoyatlyRequest,
	IDepositUsersList,
	IGraphic,
	ILoyaltyLisREsponse,
	IReferral,
	IReferralState,
	IRequestGraph,
	IResponseGraph,
	ISummaRequest,
	ISummaResponse,
	LoyaltyUsersRequest,
} from './types';

export const initialState: IReferralState = {
	graph: null,
	graph_partners: null,
	graph_id_data: null,
	loyalty: null,
	loyaltyUsersList: null,
	packages: [],
	packagesData: null,
	referralLoader: false,
	referral: null,
	summa: null,
	summa_bonuses: null,
	summa_partners: null,
};

const referral = createSlice({
	name: '@@referral',
	initialState,
	reducers: {
		getGraphRequest: (state, action: PayloadAction<IRequestGraph>) => {
			const getReferralState = state;
			getReferralState.referralLoader = true;
		},

		getGraphSuccess: (state, action: PayloadAction<IGraphic>) => {
			const getReferralState = state;
			const { payload } = action;
			getReferralState.referralLoader = false;
			payload.type.includes('partners')
				? (getReferralState.graph_partners = payload)
				: (getReferralState.graph = payload);
		},

		getSummaRequest: (state, action: PayloadAction<ISummaRequest>) => {
			const getReferralState = state;
			getReferralState.referralLoader = true;
		},

		getSummaSuccess: (state, action: PayloadAction<ISummaResponse>) => {
			const getReferralState = state;
			const { payload } = action;
			getReferralState.referralLoader = false;
			getReferralState.summa = payload;
		},

		getSummaBonusesRequest: (state, action: PayloadAction<ISummaRequest>) => {
			const getReferralState = state;
			getReferralState.referralLoader = true;
		},

		getSummaBonusesSuccess: (state, action: PayloadAction<ISummaResponse>) => {
			const getReferralState = state;
			const { payload } = action;
			getReferralState.referralLoader = false;
			getReferralState.summa_bonuses = payload;
		},

		getSummaPartnersRequest: (state, action: PayloadAction<ISummaRequest>) => {
			const getReferralState = state;
			getReferralState.referralLoader = true;
		},

		getSummaPartnersSuccess: (state, action: PayloadAction<ISummaResponse>) => {
			const getReferralState = state;
			const { payload } = action;
			getReferralState.referralLoader = false;
			getReferralState.summa_partners = payload;
		},

		getGraphIdRequest: (state, action: PayloadAction<string>) => {
			const getReferralState = state;
			getReferralState.referralLoader = true;
		},

		getGraphIdSuccess: (state, action: PayloadAction<IResponseGraph>) => {
			const getReferralState = state;
			const { payload } = action;
			getReferralState.referralLoader = false;
			getReferralState.graph_id_data = payload;
		},

		addLoyatlyRequest: (state, action: PayloadAction<IAddLoyatlyRequest>) => {
			const getReferralState = state;
			getReferralState.referralLoader = true;
		},

		setLoyatlySuccess: (state, action: PayloadAction<ILoyaltyLisREsponse>) => {
			const getReferralState = state;
			const { payload } = action;
			getReferralState.referralLoader = false;
			getReferralState.loyalty?.unshift(payload);
		},

		changeLoyatlyRequest: (state, action: PayloadAction<IChangeLoyatlyRequest>) => {
			const getReferralState = state;
			getReferralState.referralLoader = true;
		},

		setChangeLoyatlySuccess: (state, action: PayloadAction<IChangeLoyatlyRequest>) => {
			const getReferralState = state;
			const { payload } = action;
			getReferralState.referralLoader = false;
			// getReferralState.loyalty?.unshift(payload);
		},

		getListLoyaltyRequest: (state, action: PayloadAction<IAddLoyatlyRequest>) => {
			const getReferralState = state;
			getReferralState.referralLoader = true;
		},

		putLoyaltyListSuccess: (state, action: PayloadAction<IReferral>) => {
			const getReferralState = state;
			const { payload } = action;
			getReferralState.referralLoader = false;
			getReferralState.referral = payload;
		},

		referralReset: (state, action: PayloadAction<null>) => {
			const getReferralState = state;
			const { payload } = action;
			getReferralState.referral = payload;
		},

		deleteLoyaltyRequest: (state, action: PayloadAction<string>) => {
			const getReferralState = state;
			getReferralState.referralLoader = true;
		},

		deleteLoyaltySuccess: (state, action: PayloadAction<string>) => {
			const getReferralState = state;
			const { payload } = action;
			getReferralState.referralLoader = false;
			// getReferralState.loyalty = payload;
		},

		addUserPackageRequest: (state, action: PayloadAction<IAddLoyatlyRequest>) => {
			const getReferralState = state;
			getReferralState.referralLoader = true;
		},

		setUserPackageSuccess: (state, action: PayloadAction<Array<ILoyaltyLisREsponse>>) => {
			const getReferralState = state;
			const { payload } = action;
			getReferralState.referralLoader = false;
			getReferralState.loyalty = payload;
		},

		deleteUserPackageRequest: (state, action: PayloadAction<string>) => {
			const getReferralState = state;
			getReferralState.referralLoader = true;
		},

		deleteUserPackageSuccess: (state, action: PayloadAction<string>) => {
			const getReferralState = state;
			const { payload } = action;
			getReferralState.referralLoader = false;
			// getReferralState.loyalty = payload;
		},

		getListUserPackageRequest: (state, action: PayloadAction<LoyaltyUsersRequest>) => {
			const getReferralState = state;
			getReferralState.referralLoader = true;
		},

		getLoyaltyUsersPartnerRequest: (state, action: PayloadAction<LoyaltyUsersRequest>) => {
			const getReferralState = state;
			getReferralState.referralLoader = true;
		},

		putLoyaltyUserPackageSuccess: (state, action: PayloadAction<IDepositUsersList>) => {
			const getReferralState = state;
			const { payload } = action;
			getReferralState.referralLoader = false;
			getReferralState.loyaltyUsersList = payload;
		},

		getPackageRequest: (state) => {
			const getReferralState = state;
			getReferralState.referralLoader = true;
		},

		putPackageSuccess: (state, action: PayloadAction<Array<ILoyaltyLisREsponse>>) => {
			const getReferralState = state;
			const { payload } = action;
			getReferralState.referralLoader = false;
			getReferralState.packagesData = payload;

			if (getReferralState.packages.length === 0)
				getReferralState.packages.push(...payload.map((element) => element.name));
		},

		referralInitState: (state) => {
			const getReferralState = state;
			getReferralState.referralLoader = false;
		},
	},
});

export default referral.reducer;
export const {
	getGraphRequest,
	getGraphSuccess,
	getGraphIdRequest,
	getGraphIdSuccess,
	addLoyatlyRequest,
	setLoyatlySuccess,
	changeLoyatlyRequest,
	setChangeLoyatlySuccess,
	getListLoyaltyRequest,
	putLoyaltyListSuccess,
	deleteLoyaltyRequest,
	deleteLoyaltySuccess,
	addUserPackageRequest,
	setUserPackageSuccess,
	deleteUserPackageRequest,
	deleteUserPackageSuccess,
	getListUserPackageRequest,
	getLoyaltyUsersPartnerRequest,
	putLoyaltyUserPackageSuccess,
	getPackageRequest,
	putPackageSuccess,
	referralReset,
	getSummaRequest,
	getSummaSuccess,
	getSummaBonusesRequest,
	getSummaBonusesSuccess,
	getSummaPartnersRequest,
	getSummaPartnersSuccess,
	referralInitState,
} = referral.actions;
