import { FC } from 'react';
import { IAiBots, ITotalStatistics } from 'redux/reducers/ai-chat-bot/types';
import { AiChatSvg, ArrowRightSvg } from 'assets/svg-icon';
import './BotsStatistics.scss';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { firstUppercaseLetter } from 'untils/firstUppercaseLetter';
import { numeralSpaceFunc } from 'untils/numerals';

interface IProps {
	statistics: Array<ITotalStatistics>;
}

export const BotsStatistics: FC<IProps> = ({ statistics }) => {
	return (
		<div className="bots-statistics">
			<div className="ai-bots">
				{statistics.map((statistic) => (
					<Link
						to={`${ELinksName.AI_CHAT_BOT_STATISTICS}/${
							statistic.bot_id
						}/${statistic.name.replaceAll(' ', '_')}`}
						key={statistic.bot_id}
						className="bots-statistics__card"
					>
						<div className="ai-bots__content">
							<AiChatSvg />
							<div className="ai-bots__blok-text">
								<div className="ai-bots__title">{firstUppercaseLetter(statistic.name)}</div>
								<div className="ai-bots__subtitle">{`Type: ${firstUppercaseLetter(
									statistic.type,
								)}`}</div>
							</div>
						</div>
						<div className="bots-statistics__info">
							<div className="ai-bots__type">Image prompts used</div>
							<div className="ai-bots__type-name">{numeralSpaceFunc(statistic.image_count)}</div>
						</div>
						<div className="bots-statistics__info">
							<div className="ai-bots__type">Chat prompts used</div>
							<div className="ai-bots__type-name">{numeralSpaceFunc(statistic.text_count)}</div>
						</div>
						<div className="ai-bots__action">
							<ArrowRightSvg />
						</div>
					</Link>
				))}
			</div>
		</div>
	);
};
