import classNames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ESubtabsUsers, ETabsAmbassador } from 'services/constants/lists-table';

interface IProps {
	subTab: string;
	tab: string;
}

export const UserPartnerTabs: FC<IProps> = ({ subTab, tab }) => {
	return (
		<div className="tabs-buttons-nav">
			<Link
				to={`${ELinksName.USER_PARTNER}/${ETabsAmbassador.TOTAL}`}
				className={classNames('tabs-buttons-nav-link', {
					active: ETabsAmbassador.TOTAL === tab || !tab,
				})}
			>
				Total
			</Link>
			<Link
				to={`${ELinksName.USER_PARTNER}/${ETabsAmbassador.DISCOUNT}/${
					subTab || ESubtabsUsers.PERSONAL_PROFILE
				}`}
				className={classNames('tabs-buttons-nav-link', {
					active: ETabsAmbassador.DISCOUNT === tab,
				})}
			>
				Discount
			</Link>
			<Link
				to={`${ELinksName.USER_PARTNER}/${ETabsAmbassador.DISCOUNT1}/${
					subTab || ESubtabsUsers.PERSONAL_PROFILE
				}`}
				className={classNames('tabs-buttons-nav-link', {
					active: ETabsAmbassador.DISCOUNT1 === tab,
				})}
			>
				Discount +1%
			</Link>
			<Link
				to={`${ELinksName.USER_PARTNER}/${ETabsAmbassador.DISCOUNT5}/${
					subTab || ESubtabsUsers.PERSONAL_PROFILE
				}`}
				className={classNames('tabs-buttons-nav-link', {
					active: ETabsAmbassador.DISCOUNT5 === tab,
				})}
			>
				Discount +5%
			</Link>
			<Link
				to={`${ELinksName.USER_PARTNER}/${ETabsAmbassador.EARN7_DISCOUNT10}/${
					subTab || ESubtabsUsers.PERSONAL_PROFILE
				}`}
				className={classNames('tabs-buttons-nav-link', {
					active: ETabsAmbassador.EARN7_DISCOUNT10 === tab,
				})}
			>
				7.5% earn 10% friend discount
			</Link>
			<Link
				to={`${ELinksName.USER_PARTNER}/${ETabsAmbassador.EARN5_DISCOUNT10}/${
					subTab || ESubtabsUsers.PERSONAL_PROFILE
				}`}
				className={classNames('tabs-buttons-nav-link', {
					active: ETabsAmbassador.EARN5_DISCOUNT10 === tab,
				})}
			>
				5% earn 10% friend discount
			</Link>

			<Link
				to={`${ELinksName.USER_PARTNER}/${ETabsAmbassador.EARN7_DISCOUNT5}/${
					subTab || ESubtabsUsers.PERSONAL_PROFILE
				}`}
				className={classNames('tabs-buttons-nav-link', {
					active: ETabsAmbassador.EARN7_DISCOUNT5 === tab,
				})}
			>
				7.5% earn 5% friend discount
			</Link>

			<Link
				to={`${ELinksName.USER_PARTNER}/${ETabsAmbassador.EARN5_DISCOUNT5}/${
					subTab || ESubtabsUsers.PERSONAL_PROFILE
				}`}
				className={classNames('tabs-buttons-nav-link', {
					active: ETabsAmbassador.EARN5_DISCOUNT5 === tab,
				})}
			>
				5% earn 5% friend discount
			</Link>
		</div>
	);
};
