import { SuccessSvg } from 'assets/svg-icon';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';

interface IProps {
	image?: string;
	title?: string;
	subtitle?: string;
	btnName?: string;
}

export const SuccessfullyPopup: FC<IProps> = ({ image, title, subtitle, btnName }) => {
	return (
		<div className="authorization-form">
			<div className="authorization-form__logo">{image || <SuccessSvg />}</div>
			<p className="form-title">{title}</p>
			<p className="form-subtitle">{subtitle}</p>
			<div className="authorization-form__body">
				<div className="form-submit">
					<Link to={ELinksName.LOGIN} className="button button--full-width">
						{btnName}
					</Link>
				</div>
			</div>
		</div>
	);
};
