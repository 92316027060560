import { Header } from 'components/Header';
import { Sidebar } from 'components/Sidebar';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getIsAuth, getToken } from 'redux/reducers/auth/selectors';
import { ELinksName } from 'services/constants/list-links-sidebar';

const AuthLayout: FC = () => {
	const isAuth = useSelector(getIsAuth);
	const location = useLocation();

	if (!isAuth) return <Navigate to={ELinksName.LOGIN} state={{ from: location }} replace />;

	return (
		<div className="wrapper">
			<Header />
			<main className="main">
				<section className="main-section">
					<Sidebar />
					<Outlet />
				</section>
			</main>
		</div>
	);
};

export default AuthLayout;
