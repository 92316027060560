import { ChangeEvent, FC } from 'react';
import { useParams } from 'react-router-dom';
import ello from '../../../assets/images/ello.png';
import ello2 from '../../../assets/images/ello2.png';
import { Button } from 'ui/Button/Button';
import { ModalTwo } from 'ui/ModalTwo/ModalTwo';

interface IProps {
	onModal: () => void;
	modal: boolean;
	handleInput: (event: ChangeEvent) => void;
	sum: string;
	onNextModal?: () => void;
	subtitle: string;
}

export const CardFinance: FC<IProps> = ({
	onModal,
	modal,
	sum,
	handleInput,
	onNextModal,
	subtitle,
}) => {
	const params = useParams();

	return (
		<div className="table-padding">
			{modal && params.tab === 'tab-finance' && (
				<ModalTwo
					title={subtitle ? subtitle : 'Top up Ello earned balance'}
					label="Amount, $"
					onModal={onModal}
					handleInput={handleInput}
					subtitle={subtitle}
					sum={sum}
					onNextModal={onNextModal}
				/>
			)}
			<div className="card-finance">
				{[1, 2].map((card) => (
					<div key={card} className="card-finance__box">
						<div className="card-finance__img-wrap">
							<img src={card === 1 ? ello : ello2} alt="" />
						</div>
						<div className="card-finance__text-wrap">
							<span className="gray">Available for withdrawal:</span>
							<span className="filter-custom__subtitle">$1580.20</span>
						</div>
						<div className="card-finance__text-wrap card-finance__line">
							<span className="gray">Frozen amount:</span>
							<span className="filter-custom__subtitle">$158.20</span>
						</div>

						<Button onClick={onModal} className="button-wrapper" type="button" name="Top up" />
					</div>
				))}
			</div>
		</div>
	);
};
