import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IUtilsApi } from './types';

export const utils: IUtilsApi = {
	countries: () => http.get(endpoint.utils.countries).then((response) => response.data),
	photo: (params: string) =>
		http.get(endpoint.utils.photo(params)).then((response) => {
			return response.data;
		}),
	video: (params: string) =>
		http.get(endpoint.utils.video(params)).then((response) => response.data),
	file: (params: string) =>
		http.get(endpoint.utils.document(params)).then((response) => response.data),
};
