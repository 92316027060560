import { FC, useState } from 'react';
import { DeleteSvg, DownloadSvg, ErrorSvg } from 'assets/svg-icon';
import { IContent, onJsonParseBigin, onJsonParseContent } from 'untils/jsonParse';
import { Avatar } from 'ui/Avatar/Avatar';
import { useDispatch } from 'react-redux';
import { IContentResponse } from 'redux/reducers/channels/types';
import { endpoint } from 'services/endpoint';
import { getFileRequest } from 'redux/reducers/download/reducer';
import filesize from 'file-size';

interface IPropsListFiles {
	content: Array<IContentResponse>;
	removePost: (postId: string, type: string) => void;
}

export const ListFiles: FC<IPropsListFiles> = ({ content, removePost }) => {
	const dispatch = useDispatch();
	const [url, setUrl] = useState<Array<number>>([]);

	const onDownload = (file: any, photo_FLAGPHOTO: any, type_file: string) => {
		console.log(photo_FLAGPHOTO);

		if (type_file === 'video') {
			dispatch(
				getFileRequest({
					endpoint: endpoint.utils.video(
						String(file?.access_hash?.trim() || photo_FLAGPHOTO?.access_hash).trim(),
					),
				}),
			);
		}

		if (type_file === 'messageMediaDocument') {
			dispatch(
				getFileRequest({
					endpoint: endpoint.utils.document(
						String(file?.access_hash?.trim() || photo_FLAGPHOTO?.access_hash).trim(),
					),
				}),
			);
		}

		if (type_file === 'messageMediaPhoto') {
			dispatch(
				getFileRequest({
					endpoint: endpoint.utils.photo(
						String(file?.access_hash?.trim() || photo_FLAGPHOTO?.access_hash).trim(),
					),
				}),
			);
		}
	};

	return (
		<div className="details-user__files-list files-list">
			<div className="files-list__title">Content</div>
			<ul className="files-list__list">
				{content.map((el, i) => {
					const result: IContent = onJsonParseBigin(el.media);

					console.log(result);

					const name_file =
						result?.document?.attributes?.[1]?.file_name ||
						result?.photo_FLAGPHOTO?.attributes?.[1]?.file_name ||
						result?.document?.attributes?.[0]?.file_name ||
						result?.photo_FLAGPHOTO?.attributes?.[0]?.file_name;

					return result.webpage ? (
						<li key={el.media + i} className="files-list__item">
							<a href={result.webpage.display_url} target="_blank" rel="noreferrer">
								{result.webpage.display_url}
							</a>
						</li>
					) : (
						<li key={el.media + i} className="files-list__item">
							<div className="files-list__block-left">
								{Number(String(result.document?.access_hash).replace(/[^+-\d]/g, '')) !== 0 ||
								Number(String(result.photo_FLAGPHOTO?.access_hash).replace(/[^+-\d]/g, '')) !==
									0 ? (
									<div className="wrapper-image">
										<Avatar
											hash={
												String(result.document?.access_hash)
													.replace(/[^+-\d]/g, '')
													.trim() ||
												String(result.photo_FLAGPHOTO?.access_hash)
													.replace(/[^+-\d]/g, '')
													.trim()
											}
											endpoint={
												result.predicate_name === 'messageMediaDocument'
													? '/storage/document'
													: '/storage/photo'
											}
										/>
									</div>
								) : null}
								<div className="files-list__text">
									<span>{name_file}</span>
									<span className="gray">{`${filesize(
										result.document?.size2_INT32 || result.photo_FLAGPHOTO?.sizes[3]?.size2,
									).to('MB')} Mb`}</span>
								</div>
							</div>

							<div className="files-list__block-right">
								<div
									onClick={() =>
										onDownload(result.document, result.photo_FLAGPHOTO, result?.predicate_name)
									}
									className="pointer"
								>
									<DownloadSvg />
								</div>
								<div className="pointer">
									<ErrorSvg />
								</div>
								<div
									onClick={() =>
										removePost(String(result.document?.id || result?.photo_FLAGPHOTO.id), 'content')
									}
									className="pointer"
								>
									<DeleteSvg />
								</div>
							</div>
						</li>
					);
				})}
			</ul>
		</div>
	);
};
