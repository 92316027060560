import { CodeGenerationSvg, PlusBlueSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { listAiBot } from 'CONST/listAiBot';
import { useSelect } from 'hooks/useSelect';
import { ChangeEvent, FC, MouseEvent, useState } from 'react';
import { CheckBoxThree } from 'ui/CheckBoxThree/CheckBoxThree';
import { Input } from 'ui/Input';
import { PopUpWrapper } from 'ui/PopUpWrapper/PopUpWrapper';
import { SelectList } from 'ui/SelectList/SelectList';
import { useDispatch } from 'react-redux';
import { addBotPromoRequest } from 'redux/reducers/ai-chat-bot/reducer';
import { IAiChatBotsResponse } from 'redux/reducers/ai-chat-bot/types';
import { useNavigate } from 'react-router-dom';
import { DebounceInput } from 'react-debounce-input';

interface IProps {
	onCreateBot: (nameBot: MouseEvent) => void;
	botsList: null | IAiChatBotsResponse;
	param: string;
	search: string;
	setSearch: (value: string) => void;
}

export const CreateBotList: FC<IProps> = ({ setSearch, search, param, onCreateBot, botsList }) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();
	const [checkBox, setCheckBox] = useState<string>('');
	const [popup, setPopup] = useState<boolean>(false);
	const [popupNext, setPopupNext] = useState<boolean>(false);
	const [numberOfCodes, setNumberOfCodes] = useState<number>(0);
	const [nameCode, setNameCode] = useState<string>('');
	const [codeLife, setCodeLife] = useState<string>('');
	const dispatch = useDispatch();
	const navigation = useNavigate();

	const resetInput = () => {
		setCodeLife('');
		setNameCode('');
		setNumberOfCodes(0);
		setSearch('');
		setCheckBox('');
	};

	const handlInput = (event: ChangeEvent) => {
		const { value, name } = event.target as HTMLInputElement;
		name === 'number_of_codes' && setNumberOfCodes(Number(value));
		name === 'name_code' && setNameCode(value);
		name !== 'number_of_codes' && name !== 'name_code' && setSearch(value);
	};

	const onClose = () => {
		setPopup(!popup);
		setCheckBox('');
		setNumberOfCodes(0);
		setNameCode('');
	};

	const onCloseNextPpup = () => {
		setPopupNext(!popupNext);
		setCheckBox('');
		setNumberOfCodes(0);
		setNameCode('');
	};

	const onChange = (event: ChangeEvent) => {
		const { name } = event.currentTarget as HTMLInputElement;

		setCheckBox(name);
	};

	const onNextPopup = () => {
		setPopup(false);
		setPopupNext(true);

		popupNext &&
			dispatch(
				addBotPromoRequest({
					data: {
						bot_id: Number(checkBox),
						count_codes: numberOfCodes,
						label: nameCode,
						life_time_days: Number(codeLife),
					},
					closePopup: setPopupNext,
					navigation,
					param,
					resetInput,
				}),
			);
	};

	return (
		<>
			<div className="add-btn-wrap">
				<button
					onClick={() => setPopup(true)}
					style={{
						display: 'flex',
						gap: '5px',
						background: '#fff',
						color: '#0A49A5',
						border: '1px solid #CFCFD2',
					}}
					className="add-btn"
					type="button"
				>
					<CodeGenerationSvg />
					Code generation
				</button>
				<button
					ref={triggerRef}
					onClick={toggleOpen}
					style={{
						display: 'flex',
						gap: '5px',
						background: '#fff',
						color: '#0A49A5',
						border: '1px solid #CFCFD2',
					}}
					className="add-btn"
					type="button"
				>
					<PlusBlueSvg />
					Create new AI Bot
				</button>
				{open ? (
					<div className="">
						<div className={classNames('select', { active: open })}>
							<div
								className="select__drop select__position select__drop-custom ai-bot-wrap"
								ref={dropRef}
							>
								<div className="select__drop-scroll">
									<div className="select__drop-item">
										<ul className="ai-bot-list">
											{listAiBot.map((item, key) => (
												<li
													id={item.replaceAll(' ', '_')}
													onClick={(e) => onCreateBot(e)}
													key={key}
													className="countries-list ai-bot-item"
												>
													{item}
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null}
			</div>
			{popup && (
				<PopUpWrapper
					handleButton={onNextPopup}
					disablet={checkBox.length ? checkBox : ''}
					isOpenModal={onClose}
					title={'Code generation'}
					btnName={'Next'}
				>
					<DebounceInput
						className={`input-item input-item--left-icon input-item--left-icon-none`}
						type="text"
						name="search"
						placeholder={'Search AI bot'}
						value={search || ''}
						onChange={handlInput}
						debounceTimeout={500}
					/>
					<div className="code-generation">
						{botsList?.ai_bots?.map((bot, index) => (
							<CheckBoxThree
								key={index}
								onChange={onChange}
								type={'radio'}
								title={bot.name}
								subtitle={bot.username}
								photo={bot.photo_access_hash}
								name={String(bot.id)}
								checkBox={checkBox}
							/>
						))}
					</div>
				</PopUpWrapper>
			)}
			{popupNext && (
				<PopUpWrapper
					handleButton={onNextPopup}
					disablet={numberOfCodes && nameCode && codeLife ? 'true' : ''}
					isOpenModal={onCloseNextPpup}
					title={'Code generation'}
					btnName={'Approve'}
					btnNameTwo={'Reject'}
				>
					<Input
						handleInput={handlInput}
						value={String(numberOfCodes)}
						type="number"
						name="number_of_codes"
						placeholder={'Number'}
						label={'Number of codes'}
						className="padding-bottom"
					/>
					<SelectList
						onSelect={setCodeLife}
						maxWidth="select-code-generation "
						label={'Codes life'}
						placeholder={'Days'}
						data={['1', '2', '3', '4', '5', '6']}
					/>
					<Input
						handleInput={handlInput}
						value={nameCode}
						type="text"
						name="name_code"
						placeholder={'Name'}
						label={'Name'}
						className="padding-bottom"
					/>
				</PopUpWrapper>
			)}
		</>
	);
};
