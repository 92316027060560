import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import avatar from 'assets/images/avatar-square.png';

export const ChannelsBody: FC = () => {
	const params = useParams();

	return (
		<div className="table-body">
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name"> Avatar</p>
					<div className="avatar-wrapper">
						<div className="avatar avatar--circle">
							<img src={avatar} alt="" />
						</div>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Name</p>
					<Link
						to={`${ELinksName.USERS_USER_DETAILS}/${params.id || ''}`}
						className="link link--type2"
					>
						Cooper Bator
					</Link>
				</div>
				<div className="td">
					<p className="td-hidden-name">Email</p>
					<p>
						<Link
							to={`${ELinksName.USERS_USER_DETAILS}/${params.id || ''}`}
							className="link link--type2"
						>
							nathan.roberts@example.com
						</Link>
					</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Custom tittle</p>
					<p>Admin</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">What can this admin do</p>
					<p>5 permits</p>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Date added</p>
					<p>Sep 24, 2022</p>
					<p className="gray">3:06 PM</p>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Last active</p>
					<p>Sep 24, 2022</p>
					<p className="gray">3:06 PM</p>
				</div>
			</div>
		</div>
	);
};
