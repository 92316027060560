import { Block } from 'components/Popups/Block';
import { FC, MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EChannelUpgradeType } from 'services/constants/type';
import { CheckSmallSvg, StarOffSvg, CloseRedSvg, CopySvg, BlockSvg } from 'assets/svg-icon';
import { mockDataChanel } from 'components/ChannelsComponents/ChannelsBodyTable/mockData';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';

export interface IOpenPopup {
	id: string;
	name: string;
}

export const MonetizationBody: FC = () => {
	const [onChangeVerification, setOnChangeVerification] = useState<number | null>(null);
	const dispatch = useDispatch();
	const [isOpenPopup, setIsOpenPopup] = useState<IOpenPopup | null>(null);

	const createAvataar = (title: string): string => {
		const array = title.trim().split(' ');
		return array.length >= 2
			? array[0][0].toUpperCase() + array[1][0].toUpperCase()
			: title.slice(0, 2).toUpperCase();
	};

	const upgradeChannel = (event: MouseEvent) => {
		const target = event.currentTarget as HTMLButtonElement;
		// (isOpenPopup || target.name !== EChannelUpgradeType.BLOCK) &&
		// dispatch(
		// 	upgradeChannelRequest({
		// 		body: { type: target.name },
		// 		chat_id: String(target.id),
		// 	}),
		// );
		// setOnChangeVerification(null);
	};

	return (
		<div className="table-body">
			{isOpenPopup && (
				<Block
					isOpenPopup={isOpenPopup}
					upgradeChannel={upgradeChannel}
					setIsOpenPopup={setIsOpenPopup}
				/>
			)}
			{mockDataChanel.map((channel) => (
				<div key={channel.id} className="tr">
					<div className="td">
						<p className="td-hidden-name">Content</p>
						<p>
							<BlockSvg />
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Name</p>
						<Link className="link-color" to={ELinksName.MONETIZATION_DETAILS}>
							Gradient 1
						</Link>
					</div>
					<div className="td">
						<p className="td-hidden-name">Description</p>
						<p>From green to blue</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Owner/Creator</p>
						<p className="link-color">Jacob Jones</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Type</p>
						<p>Image</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Price</p>
						<p>$15.00</p>
					</div>

					<div className="td">
						<p className="td-hidden-name">Sales</p>
						<p className="upper-text-custom">$15,000.00</p>
					</div>

					<div className="td">
						<p className="td-hidden-name">Amount of earnings</p>
						<p className="upper-text-custom">$15,000.00</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Status</p>
						<p className="upper-text-custom">Sep 24, 2022</p>
						<p className="upper-text-custom gray">3:06 PM</p>
					</div>
				</div>
			))}
		</div>
	);
};
