import { SearchSvg } from 'assets/svg-icon';
import { ChangeEvent, FC } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Button } from 'ui/Button/Button';
import { CheckBoxTwo } from 'ui/CheckBoxTwo/CheckBoxTwo';

interface IProps {
	title: string;
	label?: string;
	subtitle?: string;
	onModal: () => void;
	onNextModal?: () => void;
	handleInput: (event: ChangeEvent) => void;
	sum: string;
}

export const ModalTwo: FC<IProps> = ({
	title,
	subtitle,
	label,
	onModal,
	onNextModal,
	handleInput,
	sum,
}) => {
	return (
		<div className="modal">
			<div className="modal__wrap">
				<div className="modal__title">{title}</div>
				{subtitle ? null : (
					<>
						<label htmlFor="">{label}</label>
						<div className="input input-custom input-search input-amount input-search-custom input-search-width380">
							<div className="input-wrapper input-wrapper-custom input-wrapper-custom100">
								<DebounceInput
									className="input-item input-item--left-icon"
									type="text"
									name="amount"
									placeholder="Amount"
									value={sum || ''}
									onChange={handleInput}
									debounceTimeout={500}
								/>
								<span className="input-icon">
									<SearchSvg />
								</span>
							</div>
						</div>
					</>
				)}

				<Button
					className="button-wrapper button-margin"
					onClick={onNextModal}
					type={'button'}
					name={subtitle ? 'Yes, I’m sure' : 'Top up'}
				/>
				<Button className="button-border" onClick={onModal} type={'button'} name={'Cancel'} />
			</div>
		</div>
	);
};
