import { MonetizationBackGround } from 'components/MonetizationComponent/MonetizationBackGround/MonetizationBackGround';
import { MonetizationTabs } from 'components/MonetizationComponent/MonetizationTabs/MonetizationTabs';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { EMonetizationTabs } from 'services/constants/lists-table';

export const Monetization: FC = () => {
	const params = useParams();

	const definePage = () => {
		switch (params.tab) {
			case EMonetizationTabs.CASHBACK:
				return <div>Hello from CASHBACK</div>;
			default:
				return <MonetizationBackGround />;
		}
	};

	return (
		<div className="main-content">
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title big-first-letter">Monetization</p>
				</div>
			</div>
			{/* <div className="title-block-wrap"> */}
			<MonetizationTabs />

			<div className="table-block">
				{/* <div className="filter-custom"> */}
				<div className="table-wrapper">{definePage()}</div>
				{/* </div> */}
			</div>
			{/* </div> */}
		</div>
	);
};
