import { FC, MouseEvent } from 'react';
import { SelectList } from '../../../ui/SelectList/SelectList';
import { platformBalance, platformBalanceTwo } from 'CONST/platformBalance';
import { Link, useParams } from 'react-router-dom';
import { BankSvg, PaySvg, StripeSvg } from 'assets/svg-icon';
import { ChartjsLine } from 'ui/ChartjsLine/ChartjsLine';
import { SubTabs } from './SubTabs/SubTabs';
import { PlatformBalancesDeposit } from './PlatformBalancesDeposit/PlatformBalancesDeposit';
import { PlatformBalancesWithdrawal } from './PlatformBalancesWithdrawal/PlatformBalancesWithdrawal';
import {
	IBalancesDeposit,
	IBalancesWithdrawl,
	ITransactionGraphResponse,
} from 'redux/reducers/finance/finance-types';

interface IProps {
	deposit: null | IBalancesDeposit;
	withdrawal: null | IBalancesWithdrawl;
	onActivBtn: (event: MouseEvent) => void;
	activeBtn: string;
	graph: null | Array<ITransactionGraphResponse>;
}

export const PlatformBalances: FC<IProps> = ({
	onActivBtn,
	graph,
	activeBtn,
	deposit,
	withdrawal,
}) => {
	const params = useParams();

	const onDefinePage = () => {
		switch (params.subTab) {
			case 'withdrawal':
				return withdrawal ? (
					<PlatformBalancesWithdrawal
						onActivBtn={onActivBtn}
						activeBtn={activeBtn}
						withdrawal={withdrawal}
						graph={graph}
					/>
				) : null;

			default:
				return deposit ? (
					<PlatformBalancesDeposit
						onActivBtn={onActivBtn}
						activeBtn={activeBtn}
						deposit={deposit}
						graph={graph}
					/>
				) : null;
		}
	};

	return (
		<section className="platform-balances">
			<div className="platform-balances__wrapper">
				<SubTabs />
				{onDefinePage()}
			</div>
		</section>
	);
};
