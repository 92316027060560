import { CheckChatSvg, ColumnSvg, PlusSvg, PointsSvg, RowSvg, SearchSvg } from 'assets/svg-icon';
import { ChangeEvent, FC, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import formula from '../../../assets/images/formula.png';
import live from '../../../assets/images/live.png';
import './SystemChannels.scss';
import { PopupCreate } from './PopupCreate/PopupCreate';

const data = [
	{
		img: formula,
		title: 'Formula 1',
		svg: <CheckChatSvg />,
	},
	{
		img: live,
		title: 'Live Nation',
	},
];

export const SystemChannels: FC = () => {
	const [search, setSearch] = useState<string>('');
	const [change, setChange] = useState<boolean>(false);
	const [popup, setPopup] = useState<boolean>(false);

	const onSearch = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		setSearch(value);
		// @ts-ignore
		setDataFiltter?.((prev: IDataFiltter) => {
			return {
				...prev,
				search: value,
			};
		});
	};

	const onClosePopUp = () => {
		setPopup(false);
	};

	return (
		<div className="main-content">
			{popup ? <PopupCreate onClosePopUp={onClosePopUp} /> : null}
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title big-first-letter">System Channels</p>
				</div>
			</div>

			<div className="table-block table-block-system margin-top">
				<div className="table-wrapper">
					<section className="system">
						<div className="system__header">
							<div className="input input-custom input-search select-custom-width">
								<div className="input-wrapper">
									<DebounceInput
										className="input-item input-item--left-icon"
										placeholder="Search"
										debounceTimeout={500}
										value={search || ''}
										onChange={onSearch}
									/>
									<span className="input-icon">
										<SearchSvg />
									</span>
								</div>
							</div>
							<div className="system__wrap-btns">
								<div className="system__btns">
									<button onClick={() => setChange(false)} className="system__column">
										<ColumnSvg />
									</button>
									<button onClick={() => setChange(true)} className="system__row">
										<RowSvg />
									</button>
								</div>

								<button onClick={() => setPopup(!popup)} className="system__create-new">
									<PlusSvg />
									<span>Create new System Channel</span>
								</button>
							</div>
						</div>
						<div className={change ? 'system__body-row' : 'system__body-column'}>
							{data.map((channel, index) => (
								<div
									key={index}
									className={`system__channels-wrap ${change ? 'system__channels-wrap-start' : ''}`}
								>
									<div className="system__points">
										<PointsSvg />
									</div>
									<img src={channel.img} alt="avatar" />
									<div className="system__desc">
										<div className="system__title-wrap">
											<div className="system__title">{channel.title}</div>
											{channel?.svg}
										</div>
										<div className="system__subtitle">64 281 subscribers</div>
									</div>
								</div>
							))}
						</div>
					</section>
				</div>
			</div>
		</div>
	);
};
