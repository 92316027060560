import { FC } from 'react';
import { SvgArrowDownHeader, SvgArrowUpHeader } from 'ui/svg-image/SvgAllChannels';

export const AdminsHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr table-header-close-line">
				<div className="td">
					<div className="td-name">
						<p>Name</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Email</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Reason for blocking</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Role</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Status</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Block date</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Last active</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Action</p>
					</div>
				</div>
			</div>
		</div>
	);
};
