import { NotVerifiedSvg, StarOffSvg, StarOnSvg, VerifiedSvg } from 'assets/svg-icon';

export const cardName = ['Wallet Ballance', 'Earnings wallet balance'];
export const cardNameChannels = [
	'Members',
	'The amount of earnings per channel',
	'Total number of complaints within the channel',
];

export const cardNameGroups = ['Members', 'Total number of complaints within the channel'];
export const withdrawalCard = ['Total sum of all withdrawal requests', 'Pending requests'];
export const verified = {
	verified: 'Verified',
	not_verified: 'Not verified',
	svgFirst: VerifiedSvg,
	svgSecond: NotVerifiedSvg,
};

export const recommended = {
	verified: 'Recommended',
	not_verified: 'Not recommended',
	svgFirst: StarOnSvg,
	svgSecond: StarOffSvg,
};

export const userStatus = ['All', 'Paypal'];
