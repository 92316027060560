import { combineReducers } from '@reduxjs/toolkit';
import auth from 'redux/reducers/auth/reducer';
import app from 'redux/reducers/app/reducer';
import channels from 'redux/reducers/channels/reducer';
import users from 'redux/reducers/users/reducer';
import transaction from 'redux/reducers/transaction/reducer';
import reports from 'redux/reducers/reports/reducer';
import admins from 'redux/reducers/admins/reducer';
import support from 'redux/reducers/support/reducer';
import aiServicese from 'redux/reducers/ai-services/reducer';
import reservedUsernames from 'redux/reducers/reserved/reducer';
import utils from 'redux/reducers/utils/reducer';
import download from 'redux/reducers/download/reducer';
import finance from 'redux/reducers/finance/finance-reducer';
import referral from 'redux/reducers/referral/reducer';
import apkFiles from 'redux/reducers/apk-files/reducer';
import aiChatBots from 'redux/reducers/ai-chat-bot/reducer';
import reactions from 'redux/reducers/reactions/reducer';

export const rootReducer = combineReducers({
	auth,
	app,
	channels,
	users,
	transaction,
	reports,
	admins,
	support,
	aiServicese,
	reservedUsernames,
	utils,
	download,
	finance,
	referral,
	apkFiles,
	aiChatBots,
	reactions,
});
