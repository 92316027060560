import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import moment from 'moment';
import { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';
import { ESubTabsFinance } from 'services/constants/lists-table';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface IProps {
	activeBtn: string;
	labels: Array<string>;
	numbers: Array<number>;
}

export const ChartBar: FC<IProps> = ({ activeBtn, labels, numbers }) => {
	const params = useParams();
	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: 'bottom' as const,
			},
			title: {
				display: false,
				text: 'Replenishment schedules',
			},
		},
		scale: {
			ticks: {
				min: 0,
				max: 150,
				stepSize: 100,
			},
		},
		tick: {
			tick: 10,
		},
	};

	// const labels = [
	// 	'01:00',
	// 	'02:00',
	// 	'03:00',
	// 	'04:00',
	// 	'05:00',
	// 	'06:00',
	// 	'07:00',
	// 	'08:00',
	// 	'09:00',
	// 	'10:00',
	// 	'11:00',
	// 	'12:00',
	// 	'13:00',
	// 	'14:00',
	// 	'15:00',
	// 	'16:00',
	// 	'17:00',
	// 	'18:00',
	// 	'19:00',
	// 	'20:00',
	// 	'21:00',
	// 	'22:00',
	// 	'23:00',
	// 	'00:00',
	// ];
	// const labels = moment.weekdays();
	// const labels = moment.months();
	// const labels = [...Array(moment().daysInMonth())].map((e, i) => i + 1);

	const data = {
		labels,
		datasets: [
			{
				label: activeBtn[0].toUpperCase() + activeBtn.slice(1),
				data: numbers,
				backgroundColor: ESubTabsFinance.WITHDRAWALS.includes(String(params.subTab) || 'undefined')
					? '#FF758F'
					: '#44BE2E',
				borderWidth: 0,
				borderRadius: 5,
			},
		],
	};

	return (
		<div className="wrapp-bar">
			<Bar options={options} data={data} height={100} />
		</div>
	);
};
