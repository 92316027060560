import { IDataFiltter } from 'pages/Dashboard/Channels';
import { FC, useState, MouseEvent } from 'react';
import { useParams } from 'react-router-dom';
import { ESubtabsUsersDetails } from 'services/constants/lists-table';
import { SvgArrowUpHeader, SvgArrowDownHeader } from 'ui/svg-image/SvgAllChannels';

interface IPropsUserDetailsTableHeader {
	setDataFiltter: (value: IDataFiltter) => void;
}

export const UserDetailsTableHeader: FC<IPropsUserDetailsTableHeader> = ({ setDataFiltter }) => {
	const { subTab } = useParams();
	const [sort, setSort] = useState<number>(0);
	const [prev, setPrev] = useState<string>('');

	const onSwitch = () => {
		switch (subTab) {
			case ESubtabsUsersDetails.SUBSCRIPTION:
				return 'Subscription date';
			case ESubtabsUsersDetails.CREATED:
				return 'Created date';

			default:
				return 'Became an admin date';
		}
	};

	const sortTable = (event: MouseEvent) => {
		const target = event.currentTarget as HTMLButtonElement;
		if (prev !== target.id && sort !== 0) {
			setSort(1);
			setPrev(target.id);
			setDataFiltter({ order_by: target.id, asc: true });
			return;
		}

		switch (sort + 1) {
			case 1:
				setDataFiltter({ order_by: target.id, asc: true });

				break;
			case 2:
				setDataFiltter({ order_by: target.id, asc: false });

				break;
			default:
				setDataFiltter({ order_by: target.id });

				break;
		}

		if (sort === 2) {
			setSort(0);
			setPrev(target.id);
			return;
		}
		setSort((prevNum) => prevNum + 1);
		setPrev(target.id);
	};

	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Avatar</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> Channel name</p>
						<div className="sort-td">
							<button onClick={sortTable} id="channel_name" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="channel_name" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> Channel URL</p>
						<div className="sort-td">
							<button onClick={sortTable} id="channel_url" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="channel_url" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> # Members</p>
						<div className="sort-td">
							<button onClick={sortTable} id="members" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="members" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> Subscrption cost</p>
						<div className="sort-td">
							<button onClick={sortTable} id="cost" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="cost" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Status</p>
						<div className="sort-td">
							<button onClick={sortTable} id="status" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="status" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>{onSwitch()}</p>
						<div className="sort-td">
							<button onClick={sortTable} id="date" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="date" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Verified status</p>
					</div>
				</div>
			</div>
		</div>
	);
};
