import { CheckGreenSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { textForValidation } from 'CONST/textForValidation';
import { FC, useRef, useState } from 'react';

const uppercaseRegexp = /[A-Z]/;
const lowercaseRegexp = /[a-z]/;
const onlyLatinRegexp = /^[^а-яА-Я]+$/;
const numberRegexp = /[0-9]/;
const specialSymRegexp = /[_#?!@$%^&*-]/;
const minLengthRegexp = /.{8,}/;

export const PasswordStrength: FC<{ password: string }> = ({ password }) => {
	const elementsRed: any = window.document.querySelectorAll('.red');
	const elementsYellow: any = window.document.querySelectorAll('.yellow');
	const elementsStrong: any = window.document.querySelectorAll('.password-strength__item--strong');
	let matchCount = 0;
	const refColor = useRef<HTMLDivElement>(null);

	const passwordScores = (() => {
		matchCount =
			Number(uppercaseRegexp.test(password)) +
			Number(lowercaseRegexp.test(password)) +
			Number(numberRegexp.test(password)) +
			Number(specialSymRegexp.test(password)) +
			Number(minLengthRegexp.test(password)) +
			Number(onlyLatinRegexp.test(password));

		return matchCount ? matchCount - 1 : 0;
	})();

	const strengthItems = (() => {
		const items = [];
		for (let i = 1; i <= 5; i += 1) {
			items.push({ id: i, isStrong: i <= passwordScores });
		}
		return items;
	})();

	const onValidation = (text: string) => {
		switch (text) {
			case textForValidation[0]:
				return Number(minLengthRegexp.test(password));
			case textForValidation[1]:
				return Number(lowercaseRegexp.test(password)) && Number(uppercaseRegexp.test(password));
			case textForValidation[2]:
				return Number(onlyLatinRegexp.test(password));
			case textForValidation[3]:
				return Number(numberRegexp.test(password));
			case textForValidation[4]:
				return Number(specialSymRegexp.test(password));
			default:
				break;
		}
	};

	const onColor = (num: number) => {
		switch (num) {
			case 1:
				refColor.current && Array.from(refColor.current.children)[0].classList.add('red');
				return 'red';
			case 2:
				refColor.current && Array.from(refColor.current.children)[1].classList.add('red');
				return 'red';
			case 3:
				refColor.current && Array.from(refColor.current.children)[2].classList.add('yellow');
				return 'yellow';
			case 4:
				refColor.current && Array.from(refColor.current.children)[3].classList.add('yellow');
				return 'yellow';
			default:
				refColor.current &&
					Array.from(refColor.current.children)?.forEach((element: any) => {
						element.classList.remove('red');
						element.classList.add('password-strength__item--strong');
					});
				refColor.current &&
					Array.from(refColor.current.children)?.forEach((element: any) => {
						element.classList.remove('yellow');
						element.classList.add('password-strength__item--strong');
					});
		}
	};

	return (
		<div className="password-options">
			<div ref={refColor} className="password-strength">
				{strengthItems.map(({ id, isStrong }) => (
					<div
						key={id}
						className={classNames(
							'password-strength__item',
							'active',
							isStrong
								? `password-strength__item--strong ${onColor(id)}`
								: `password-strength__item--weak`,
						)}
					/>
				))}
			</div>
			<div className="check-pass">
				{textForValidation.map((text) => (
					<div key={text} className="check-pass__inner">
						<div className={classNames('check-pass__box', { active: onValidation(text) })}>
							<CheckGreenSvg />
						</div>
						<div className={classNames('check-pass__text', { green: onValidation(text) })}>
							{text}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
