import PublicLayout from 'layouts/PublicLayout';
import { ELinksName } from 'services/constants/list-links-sidebar';
import Login from 'pages/Auth/Login';
import ErrorRoutes from './ErrorRoutes';
import { ForgotPass } from 'pages/Auth/ForgotPass/ForgotPass';
import { ResetPassword } from 'pages/Auth/ResetPassword/ResetPassword';
import { Navigate } from 'react-router-dom';

const PublicRoutes = {
	path: '/',
	element: <PublicLayout />,
	children: [
		{
			element: <Login />,
			path: ELinksName.LOGIN,
		},
		{
			element: <ForgotPass />,
			path: ELinksName.RESET_PASSWORD,
		},
		{
			element: <ResetPassword />,
			path: ELinksName.INVITE_LINK,
		},
		{
			element: <ResetPassword />,
			path: ELinksName.PASSWORD_CONFIRMATION,
		},
		{
			element: <ResetPassword />,
			path: ELinksName.ERROR,
		},
		{
			element: <Navigate to={ELinksName.LOGIN} replace />,
			path: '/',
		},
		ErrorRoutes,
	],
};

export default PublicRoutes;
