import { ChangeEvent, FC, useState } from 'react';
import { AiSubSettings } from './AiSubSettings/AiSubSettings';
import { useParams } from 'react-router-dom';
import { EAiRestrictionSubTabs } from 'services/constants/lists-table';
import { Words } from './Words/Words';
import { Poses } from './Poses/Poses';
import { Links } from './Links/Links';
import { PopUpWrapper } from 'ui/PopUpWrapper/PopUpWrapper';
import { Drop } from 'ui/Drop/Drop';
import { Input } from 'ui/Input';
import { IAiServicesStore, IWordsResponse } from 'redux/reducers/ai-services/types';

interface IProps {
	forbiddenWords: IWordsResponse | null;
	createWord: () => void;
	handlInput: (event: ChangeEvent) => void;
	isOpenModal: () => void;
	word: string;
	popup: boolean;
	getId: (id: number) => void;
}

export const AiSettings: FC<IProps> = ({
	word,
	popup,
	handlInput,
	forbiddenWords,
	getId,
	isOpenModal,
	createWord,
}) => {
	const params = useParams();
	const [file, setFile] = useState<FileList | null>(null);
	const [name, setName] = useState<string>('');

	const handleChange = (file: FileList) => {
		setFile(file);
	};

	const definePage = () => {
		switch (params.subTab) {
			// case EAiRestrictionSubTabs.POSES:
			// 	return <Poses isOpenModal={isOpenModal} />;
			// case EAiRestrictionSubTabs.LINKS:
			// 	return <Links isOpenModal={isOpenModal} />;
			default:
				return <Words forbiddenWords={forbiddenWords} isOpenModal={isOpenModal} getId={getId} />;
		}
	};

	const onTitle = () => {
		switch (params.subTab) {
			// case EAiRestrictionSubTabs.POSES:
			// 	return 'Add new poses';

			// case EAiRestrictionSubTabs.LINKS:
			// 	return 'Add new link';

			default:
				return 'Add new word';
		}
	};

	const onLabel = () => {
		switch (params.subTab) {
			case EAiRestrictionSubTabs.LINKS:
				return 'Link';

			default:
				return 'Word';
		}
	};

	const titleBtn: string = onTitle();
	const label: string = onLabel();

	return (
		<div className="ai-settings">
			{popup && (
				<PopUpWrapper
					value={word}
					handleButton={createWord}
					isOpenModal={isOpenModal}
					title={titleBtn}
					btnName="Add"
					disablet={word}
				>
					<>
						{params.subTab !== EAiRestrictionSubTabs.POSES && (
							<>
								<Input
									handleInput={handlInput}
									value={word}
									type="text"
									name="word"
									placeholder={label}
									label={label}
									className="padding-bottom"
								/>
								{/* <div className="or-another">OR</div> */}
							</>
						)}
						{/* <Drop handleChange={handleChange} /> */}
					</>
				</PopUpWrapper>
			)}
			<AiSubSettings />
			{definePage()}
		</div>
	);
};
