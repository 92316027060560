import { ApkFilkeSvg, DeleteSvg, DownloadSvg } from 'assets/svg-icon';
import { SmallLoader } from 'components/SmallLoader';
import moment from 'moment';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { IFile } from 'redux/reducers/apk-files/types';
import { getLoaderSelector } from 'redux/reducers/download/selectors';

interface IProps {
	apkList: Array<IFile>;
	isOpenModal: (id: number, name_apk: string) => void;
	onDownload: (name_apk: string) => void;
}

export const ApkFileList: FC<IProps> = ({ onDownload, apkList, isOpenModal }) => {
	const loaderApk = useSelector(getLoaderSelector);

	return (
		<div className="apk-file__body">
			<ul className="apk-file__list">
				<li className="apk-file__item apk-file__item-header">
					<span className="apk-file__name">File</span>
					<span className="apk-file__name td--right">Date of download</span>
					<span className="apk-file__name td--right">Action</span>
				</li>
				{apkList.map((apk) => (
					<li key={apk.id} className="apk-file__item">
						<span className="apk-file__name">{apk.file}</span>
						<div className="apk-file__name">
							<p className="td--right">{moment(apk.created_at).format('MMM DD, YYYY')}</p>
							<p className="gray td--right">{moment(apk.created_at).format('h:mm A')}</p>
						</div>
						<div className="apk-file__btn-wrap td--right-custom">
							<button onClick={() => onDownload(apk.file)} className="apk-file__btn">
								{loaderApk ? <SmallLoader /> : <DownloadSvg />}
							</button>
							<button onClick={() => isOpenModal(apk.id, apk.file)} className="apk-file__btn">
								<DeleteSvg />
							</button>
						</div>
					</li>
				))}
			</ul>
		</div>
	);
};
