import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ETabsFinanceStatistics } from 'services/constants/lists-table';

export const StatisticsSubTabs: FC = () => {
	const params = useParams();

	return (
		<div className="table-tabs table-tabs--type2 table-tabs-custom tab-up">
			<Link
				to={`${ELinksName.FINANCE}/${params.tab}/${ETabsFinanceStatistics.SISTEMATIC}`}
				className={classNames('table-tabs__item', {
					active: params.subTab === ETabsFinanceStatistics.SISTEMATIC || !params.subTab,
				})}
			>
				Systemic
			</Link>

			<Link
				to={`${ELinksName.FINANCE}/${params.tab}/${ETabsFinanceStatistics.CUSTOMERS}`}
				className={classNames('table-tabs__item', {
					active: params.subTab === ETabsFinanceStatistics.CUSTOMERS,
				})}
			>
				User transfers internal
			</Link>
		</div>
	);
};
