import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IDownloadStore } from './types';

const getState = (state: IStoreState): IDownloadStore => state.download;

export const getLoaderSelector = createSelector(
	[getState],
	(download: IDownloadStore) => download.reservedLoader,
);

// export const getreservedLoaderSelector = createSelector(
// 	[getUsernamesState],
// 	(reserved: IReservedUsernamesStore) => reserved.reservedLoader,
// );
