import { IAdminAuthUserResponseData, ILoginRequestPayload } from 'redux/reducers/auth/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IAuthApi } from './types';

// ==========================================:
export const auth: IAuthApi = {
	login: (payload) =>
		http
			.post<ILoginRequestPayload, { data: IAdminAuthUserResponseData }>(
				endpoint.auth.LOGIN,
				payload,
			)
			.then((response) => response.data),

	forgot: (payload) => http.post(endpoint.auth.FORGOT, payload).then((response) => response.data),
	getQrCode: (payload) =>
		http.post(endpoint.auth.GET_QR, payload).then((response) => response.data),
	twoFaEnable: (payload) =>
		http.post(endpoint.auth.ENABLE_TWO_FA, payload).then((response) => response.data),
	resetPass: (payload) =>
		http.post(endpoint.auth.RESET_PASS, payload).then((response) => response.data),
	createPass: (payload) =>
		http.post(endpoint.auth.CREATE_PASS, payload).then((response) => response.data),
	refresh: (payload) =>
		http.post(endpoint.auth.CREATE_PASS, payload).then((response) => response.data),
	logout: () => http.post(endpoint.auth.LOGOUT),
};
