import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IAdminsResponse, IAdminsState } from './types';

const getAdminsState = (state: IStoreState): IAdminsState => state.admins;

export const getAdminsListSelector = createSelector(
	[getAdminsState],
	(state: IAdminsState): null | Array<IAdminsResponse> => state?.adminsList,
);

export const getAdminSelector = createSelector(
	[getAdminsState],
	(state: IAdminsState): null | IAdminsResponse => state?.admin,
);

export const getLoaderSelector = createSelector(
	[getAdminsState],
	(state: IAdminsState): boolean => state.adminsLoader,
);
