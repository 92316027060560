import moment from 'moment';
import { FC, useState } from 'react';
import DatePicker from 'react-datepicker';

interface IProps {
	addDate?: (date: Date, index: number) => void;
	index?: number;
	className?: string;
}

export const Calendar: FC<IProps> = ({ addDate, index, className }) => {
	const [startDate, setStartDate] = useState<Date>();

	return (
		<DatePicker
			className={`input-item ${className ? className : ''}`}
			// selected={startDate}
			onChange={(date: Date) => {
				setStartDate(date);
				addDate?.(date, index || 0);
			}}
			value={startDate && moment(startDate).format('DD.MM.YYYY')}
			showMonthDropdown
			showYearDropdown
			dropdownMode="select"
		/>
	);
};
