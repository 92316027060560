import { tabPagination } from 'CONST/finance';
import { Commissions } from 'components/FinanceComponent/Commissions/Commissions';
import { CryptoBalances } from 'components/FinanceComponent/CryptoBalances/CryptoBalances';
import { FinanceTabs } from 'components/FinanceComponent/FinanceTabs/FinanceTabs';
import { PaymentMethods } from 'components/FinanceComponent/PaymentMethods/PaymentMethods';
import { PlatformBalances } from 'components/FinanceComponent/PlatformBalances/PlatformBalances';
import { Statistics } from 'components/FinanceComponent/Statistics/Statistics';
import { WithdrawalRequests } from 'components/FinanceComponent/WithdrawalRequests/WithdrawalRequests';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	getBalancesDepositRequest,
	getBalancesWithdrawalRequest,
	getCommissionsRequest,
	getFinanceRequest,
	getMethodsRequest,
	getTransactionGraphRequest,
	getTransactionRequest,
} from 'redux/reducers/finance/finance-reducer';
import {
	getCommissionsSelector,
	getDepositSelector,
	getFiatListSelector,
	getGraphSelector,
	getMethodsSelector,
	getStatisticsSelector,
	getWithdrawalSelector,
} from 'redux/reducers/finance/finance-selectors';
import {
	ESubTabsFinance,
	ETabsBalances,
	ETabsFinanceStatistics,
} from 'services/constants/lists-table';
import { Pagination } from 'ui/Pagination';

export const Finance: FC = () => {
	const fiat = useSelector(getFiatListSelector);
	const commissions = useSelector(getCommissionsSelector);
	const transaction = useSelector(getStatisticsSelector);
	const methods = useSelector(getMethodsSelector);
	const deposit = useSelector(getDepositSelector);
	const withdrawal = useSelector(getWithdrawalSelector);
	const graph = useSelector(getGraphSelector);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [activeBtn, setActiveBtn] = useState<string>('day');
	const params = useParams();
	const dispatch = useDispatch();
	const pagin = params.tab === 'statistics' ? transaction : fiat;
	const [withdrawalFiat, setWithdrawalFiat] = useState({
		country_code: '',
		currencies: '',
		current_page: 1,
		date_from: '',
		date_to: '',
		per_page: 15,
		search: '',
		wallet_type: '',
		withdraw_method: '',
	});

	const [statisticsRedquest, setStatisticsRedquest] = useState({
		currencies: '',
		current_page: 1,
		date_from: '',
		date_to: '',
		per_page: 15,
		search: '',
		show_internal: false,
		show_transfer: false,
		status: '',
		type: '',
	});

	const [graphRequest, getGraphRequest] = useState({
		// limit: 0,
		// offset: 0,
		payment_system: 'paypal',
		period: 'day',
		type: 'deposit',
	});

	const onActivBtn = (event: MouseEvent) => {
		const { name } = event.target as HTMLButtonElement;
		setActiveBtn(name);
		getGraphRequest((prev) => ({ ...prev, period: name }));
	};

	const definePage = () => {
		switch (params.tab) {
			case ESubTabsFinance.COMMISSIONS:
				return commissions ? <Commissions commissions={commissions} /> : null;
			case ESubTabsFinance.PAYMENT:
				return methods ? <PaymentMethods methods={methods} /> : null;
			case ESubTabsFinance.STATISTICS:
				return (
					<Statistics
						statisticsRedquest={statisticsRedquest}
						transaction={transaction}
						setStatisticsRedquest={setStatisticsRedquest}
					/>
				);
			case ESubTabsFinance.WITHDRAWALS:
				return <WithdrawalRequests fiat={fiat} setWithdrawalFiat={setWithdrawalFiat} />;
			// case ESubTabsFinance.CRYPTO:
			// 	return <CryptoBalances />;
			default:
				return (
					<PlatformBalances
						activeBtn={activeBtn}
						onActivBtn={onActivBtn}
						withdrawal={withdrawal}
						deposit={deposit}
						graph={graph}
					/>
				);
		}
	};

	useEffect(() => {
		params.tab === ESubTabsFinance.WITHDRAWALS && dispatch(getFinanceRequest(withdrawalFiat));
	}, [withdrawalFiat, params.subTab]);

	useEffect(() => {
		params.tab === ESubTabsFinance.STATISTICS &&
			dispatch(
				getTransactionRequest({
					...statisticsRedquest,
					show_internal: params.subTab === ETabsFinanceStatistics.CUSTOMERS ? true : false,
					show_transfer: params.subTab === ETabsFinanceStatistics.CUSTOMERS ? true : false,
				}),
			);
	}, [statisticsRedquest, params.subTab]);

	useEffect(() => {
		dispatch(getCommissionsRequest());
		dispatch(getMethodsRequest());
	}, []);

	useEffect(() => {
		if (params.subTab === ETabsBalances.WITHDRAWAL) {
			(params.tab === ESubTabsFinance.PLATFORM || !params.tab) &&
				dispatch(getBalancesWithdrawalRequest());
			(params.tab === ESubTabsFinance.PLATFORM || !params.tab) &&
				dispatch(getTransactionGraphRequest({ ...graphRequest, type: 'withdrawal' }));
		} else {
			(params.tab === ESubTabsFinance.PLATFORM || !params.tab) &&
				dispatch(getBalancesDepositRequest());
			(params.tab === ESubTabsFinance.PLATFORM || !params.tab) &&
				dispatch(getTransactionGraphRequest(graphRequest));
		}
	}, [params.subTab, graphRequest]);

	console.log(params.tab);

	const submitFilter = (value: number) => {
		setCurrentPage(value);
		setWithdrawalFiat((prev) => ({ ...prev, current_page: value }));
	};

	const submitFilterStatistics = (value: number) => {
		setCurrentPage(value);
		setStatisticsRedquest((prev) => ({ ...prev, current_page: value }));
	};

	return (
		<div className="main-content">
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title">Finance</p>
				</div>
			</div>
			{/* <div className="title-block-wrap"> */}
			<FinanceTabs />

			<div className="table-block">
				{/* <div className="filter-custom"> */}
				<div className="table-wrapper">{definePage()}</div>
				{/* </div> */}
			</div>
			{/* </div> */}
			{tabPagination.includes(params.tab || '') && pagin && pagin.total > 15 ? (
				<Pagination
					request={params.tab === 'statistics' ? submitFilterStatistics : submitFilter}
					currentPage={currentPage}
					numberPage={Math.ceil(pagin.total / 15)}
				/>
			) : null}
		</div>
	);
};
