import { mediaSales } from 'CONST/userFilter';
import { Loader } from 'components/Loader';
import { Pagination } from 'components/Pagination';
import { UsersSubtabs } from 'components/UsersComponents/UsersSubtabs';
import { UsersTableBody } from 'components/UsersComponents/UsersTableBody';
import { mockDataUsers } from 'components/UsersComponents/UsersTableBody/mockDataUsers';
import { UsersTableHeader } from 'components/UsersComponents/UsersTableHeader';
import { UsersTabs } from 'components/UsersComponents/UsersTabs';
import { IDataFiltter } from 'pages/Dashboard/Channels';
import { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getLoader } from 'redux/reducers/auth/selectors';
import { getMembersRequest } from 'redux/reducers/channels/reducer';
import { IMembersResponse } from 'redux/reducers/channels/types';
import { getUsersList } from 'redux/reducers/users/selectors';
import { Fillter } from 'ui/Fillter/Fillter';
import { RefundsTabs } from './RefundsTabs/RefundsTabs';
import { RefundsTableHeader } from './RefundsTableHeader/RefundsTableHeader';
import { RefundsTableBody } from './RefundsTableBody/RefundsTableBody';
import { NotFound } from 'ui/NotFound/NotFound';

interface IPropsMembers {
	members: IMembersResponse;
	setDataFiltter: (value: IDataFiltter) => void;
}

export const Refunds: FC<IPropsMembers> = ({ members, setDataFiltter }) => {
	const loader = useSelector(getLoader);
	const params = useParams();

	return (
		<>
			<div className="table-block">
				<Fillter
					keyStatus="status"
					keyType="types"
					type={[]}
					status={[]}
					typesName=""
					statusName=""
					setDataFiltter={setDataFiltter}
				/>
				<RefundsTabs />
				<div className="table-wrapper">
					<div className="table table--refund">
						<RefundsTableHeader setDataFiltter={setDataFiltter} />
						{loader ? (
							<Loader />
						) : members?.channel_members?.length ? (
							<RefundsTableBody members={members?.channel_members} param={params.tab} />
						) : (
							<NotFound name={'Channel Admins'} />
						)}
					</div>
				</div>
			</div>
			{/* {usersList?.page_count && usersList?.page_count > 1 ? (
				<Pagination
					request={submitFilter}
					currentPage={usersList.page}
					numberPage={usersList.page_count}
				/>
			) : null} */}
		</>
	);
};
