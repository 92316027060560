import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDownloadStore, IFileConfig } from './types';

export const initialState: IDownloadStore = {
	reservedLoader: false,
};

const download = createSlice({
	name: '@@download',
	initialState,
	reducers: {
		getFileRequest: (state, action: PayloadAction<IFileConfig>) => {
			const reservedUsernamesState = state;
			reservedUsernamesState.reservedLoader = true;
		},
		getFileSuccess: (state) => {
			const reservedUsernamesState = state;
			reservedUsernamesState.reservedLoader = false;
		},
		downloadInitState: (state) => {
			const reservedUsernamesInitStatee = state;
			reservedUsernamesInitStatee.reservedLoader = false;
		},
	},
});

export default download.reducer;
export const { getFileRequest, getFileSuccess, downloadInitState } = download.actions;
