import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import { ALLOW_REDUX_DEVTOOLS_EXTENSION } from 'services/constants/env';

import rootSagas from './rootSagas';
import { persistConfig } from './persistConfig';
import { rootReducer } from './reducers/rootReducer';

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(sagaMiddleware),
	devTools: ALLOW_REDUX_DEVTOOLS_EXTENSION,
	preloadedState: {},
});

const persistor = persistStore(store);
sagaMiddleware.run(rootSagas);

export { store, persistor };
