import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ETabsReserved } from 'services/constants/lists-table';

export const ReservedTabs: FC = () => {
	const params = useParams();

	return (
		<div className="tabs-buttons-nav">
			{/* <Link
				to={`${ELinksName.RESERVED_USERNAMES}/${ETabsReserved.REGISTRATION_REQUESTS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsReserved.REGISTRATION_REQUESTS || !params.tab,
				})}
			>
				Registration requests
			</Link> */}
			<Link
				to={`${ELinksName.RESERVED_USERNAMES}/${ETabsReserved.USERNAMES}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsReserved.USERNAMES || !params.ta,
				})}
			>
				Usernames
			</Link>
		</div>
	);
};
