import { ChangeEvent, FC } from 'react';
import { UserPartnerHeader } from './UserPartnerHeader/UserPartnerHeader';
import { Loader } from 'components/Loader';
import { UserPartnerBody } from './UserPartnerBody/UserPartnerBody';
import { NotFound } from 'ui/NotFound/NotFound';
import { IDataFiltter } from 'pages/Dashboard/Channels';
import { UserPartnerSubtab } from './UserPartnerSubtab/UserPartnerSubtab';
import { DebounceInput } from 'react-debounce-input';
import { SearchSvg } from 'assets/svg-icon';
import { IDepositUsersList } from 'redux/reducers/referral/types';
import { useSelector } from 'react-redux';
import { getLoader } from 'redux/reducers/referral/selectors';
import { onPackage } from 'untils/onPackage';

interface IProps {
	setDataFiltter: (value: IDataFiltter) => void;
	dataFiltter: IDataFiltter;
	onSearch: (event: ChangeEvent) => void;
	search: string;
	params: {
		subTab?: string;
		tab?: string;
	};
	id_package: number | undefined;
	listUsers: null | IDepositUsersList;
	discount: string;
}

export const UsersWrapper: FC<IProps> = ({
	onSearch,
	params,
	search,
	dataFiltter,
	setDataFiltter,
	listUsers,
	discount,
}) => {
	const loader = useSelector(getLoader);
	console.log(discount);

	return (
		<div className="table-block">
			<div className="user-partner-header">
				<div className="user-partner-text">{discount}</div>
				<div className="input input-custom input-search select-custom-width">
					<div className="input-wrapper">
						<DebounceInput
							className="input-item input-item--left-icon"
							placeholder="Search"
							debounceTimeout={500}
							value={search || ''}
							onChange={onSearch}
						/>
						<span className="input-icon">
							<SearchSvg />
						</span>
					</div>
				</div>
			</div>
			<UserPartnerSubtab tab={params.tab || ''} subTab={params.subTab || ''} />
			<div className="table-wrapper">
				<div className="table table--users-partners">
					<UserPartnerHeader setDataFiltter={setDataFiltter} />
					{loader ? (
						<Loader />
					) : listUsers?.loyally_full_users.length ? (
						<UserPartnerBody listUsers={listUsers?.loyally_full_users} search={''} />
					) : (
						<NotFound name={dataFiltter?.search || ''} />
					)}
				</div>
			</div>
		</div>
	);
};
