import { FC, MouseEvent } from 'react';
import { numeralFunc } from 'untils/numerals';
import { ChartHeader } from '../ChartHeader/ChartHeader';
import { ChartBar } from '../Graphs/ChartBar';
import { IGraphic } from 'redux/reducers/referral/types';

interface IProps {
	activeBtnUsers: string;
	onActivBtnUsers: (event: MouseEvent) => void;
	activeBtnPartners: string;
	onActivBtnPartners: (event: MouseEvent) => void;
	graph: null | IGraphic;
	graphPartners: null | IGraphic;
	partners: number;
	bonuses: number;
}

export const Total: FC<IProps> = ({
	graphPartners,
	partners,
	bonuses,
	activeBtnUsers,
	onActivBtnUsers,
	activeBtnPartners,
	onActivBtnPartners,
	graph,
}) => {
	return (
		<div className="table-block padding-wrapper">
			<div className="filter-custom__info-card padding-null">
				<div className="filter-custom__card">
					<p className="filter-custom__title color-grey">Total paid as bonuses</p>
					<p className="filter-custom__subtitle">{`$${numeralFunc(bonuses)}`}</p>
				</div>

				<div className="filter-custom__card">
					<p className="filter-custom__title color-grey">Total paid to partners</p>
					<p className="filter-custom__subtitle">{`$${numeralFunc(partners)}`}</p>
				</div>
			</div>
			<div className="platform-balances__graph-wrap">
				<ChartHeader
					name="Paid to all users"
					onActivBtn={onActivBtnUsers}
					activeBtn={activeBtnUsers}
				/>
				<ChartBar
					activeBtn={'Paid'}
					backgroundColor={'#27AE60'}
					labels={graph?.graph?.map((item) => item.Date) || []}
					numbers={graph?.graph?.map((item) => item.Total) || []}
				/>
			</div>

			<div className="platform-balances__graph-wrap">
				<ChartHeader
					name="Paid to partners"
					onActivBtn={onActivBtnPartners}
					activeBtn={activeBtnPartners}
				/>
				<ChartBar
					activeBtn={'Paid'}
					backgroundColor={'#6CA5F9'}
					labels={graphPartners?.graph?.map((item) => item.Date) || []}
					numbers={graphPartners?.graph?.map((item) => item.Total) || []}
				/>
			</div>
		</div>
	);
};
