/* eslint-disable no-nested-ternary */
import { Block } from 'components/Popups/Block';
import { FC, MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EChannelUpgradeType } from 'services/constants/type';
import { CheckSmallSvg, StarOffSvg, CloseRedSvg, CopySvg } from 'assets/svg-icon';
import { mockDataChanel } from 'components/ChannelsComponents/ChannelsBodyTable/mockData';
import { IStatisticsResponse } from 'redux/reducers/finance/finance-types';
import moment from 'moment';
import { numeralFunc } from 'untils/numerals';

export interface IOpenPopup {
	id: string;
	name: string;
}

interface IProps {
	transaction: IStatisticsResponse | null;
}

export const CustomersBody: FC<IProps> = ({ transaction }) => {
	const [onChangeVerification, setOnChangeVerification] = useState<number | null>(null);
	const dispatch = useDispatch();
	const [isOpenPopup, setIsOpenPopup] = useState<IOpenPopup | null>(null);

	const createAvataar = (title: string): string => {
		const array = title.trim().split(' ');
		return array.length >= 2
			? array[0][0].toUpperCase() + array[1][0].toUpperCase()
			: title.slice(0, 2).toUpperCase();
	};

	const upgradeChannel = (event: MouseEvent) => {
		const target = event.currentTarget as HTMLButtonElement;
		// (isOpenPopup || target.name !== EChannelUpgradeType.BLOCK) &&
		// 	dispatch(
		// 		upgradeChannelRequest({
		// 			body: { type: target.name },
		// 			chat_id: String(target.id),
		// 		}),
		// 	);
		// setOnChangeVerification(null);
	};

	return (
		<div className="table-body">
			{isOpenPopup && (
				<Block
					isOpenPopup={isOpenPopup}
					upgradeChannel={upgradeChannel}
					setIsOpenPopup={setIsOpenPopup}
				/>
			)}
			{transaction?.transactions?.map((trans) => (
				<div key={trans.transaction.id} className="tr">
					<div className="td">
						<p className="td-hidden-name">Tx ID</p>
						<p className="link-color">{trans.transaction.id}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">{`Sender's full name`}</p>
						<p className="link-color">{`${trans.first_name} ${trans.last_name}`}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">{`Recipient's full name`}</p>
						<p className="link-color">{`${trans.first_name} ${trans.last_name}`}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Type</p>
						<p className="link-color">{trans.transaction.type || '-'}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Currency</p>
						<p>{trans.transaction.currency.toUpperCase()}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Date</p>
						<p>{moment(trans.transaction.created_at).format('MMM DD, YYYY')}</p>
						<p className="gray">{moment(trans.transaction.created_at).format('h:mm A')}</p>
					</div>

					<div className="td">
						<p className="td-hidden-name">Amount</p>
						<p className="upper-text-custom">{`$${numeralFunc(trans.transaction.amount)}`}</p>
					</div>

					<div className="td">
						<p className="td-hidden-name">Fee</p>
						<p className="upper-text-custom">{`$${numeralFunc(trans.transaction.fee)}`}</p>
					</div>
					<div className="td action-flex td--left">
						<p className="td-hidden-name">Status</p>
						<p
							className={`upper-custom ${trans.transaction.status
								.toLowerCase()
								.replaceAll(' ', '_')}`}
						>
							{trans.transaction.status.replaceAll('_', ' ')}
						</p>
					</div>
				</div>
			))}
		</div>
	);
};
