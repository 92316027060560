import { FC } from 'react';
import ava from '../../../../assets/images/ava.png';
import { CheckGreenSvg, CornerSvg, DeleteSvg, ErrorSvg, LockRedSvg } from 'assets/svg-icon';
import './Details.scss';
import { ReportsBody } from 'components/ReportsComponents/ReportsBody';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { EReportsTabs, ETabsUsersDetails } from 'services/constants/lists-table';
import { ReportsGroupsHeader } from 'components/ReportsComponents/ReportsGroupsHeader/ReportsGroupsHeader';
import { ReportsChannelsHeader } from 'components/ReportsComponents/ReportsHeader';
import {
	getChannelsSelector,
	getGroupsSelector,
	getMessagesSelector,
	getPostsSelector,
} from 'redux/reducers/reports/selectors';
import { IChannelsResponse, IPrivious, IReportDetails } from 'redux/reducers/reports/types';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
	deactivateChannelRequest,
	deactivateGroupRequest,
	deactivateMessageRequest,
	rejectMessageRequest,
} from 'redux/reducers/reports/reducer';
import { Avatar } from 'ui/Avatar/Avatar';
import { ELinksName } from 'services/constants/list-links-sidebar';

interface IProps {
	reportDetails: IReportDetails;
	previous: IPrivious;
}

export const Details: FC<IProps> = ({ reportDetails, previous }) => {
	const params = useParams();
	const { channel, user, report, reports } = reportDetails;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onHeaderTable = () => {
		switch (params.tab) {
			case EReportsTabs.GROUPS:
				return <ReportsGroupsHeader />;

			case EReportsTabs.MESSAGES_IN_GROUPS:
				return <ReportsGroupsHeader />;

			default:
				return <ReportsChannelsHeader />;
		}
	};

	const defineSelector = () => {
		if (params.tab === EReportsTabs.GROUPS) {
			return getGroupsSelector;
		}
		if (params.tab === EReportsTabs.MESSAGES_IN_GROUPS) {
			return getMessagesSelector;
		}
		if (params.tab === EReportsTabs.CHANNEL_POSTS) {
			return getPostsSelector;
		}

		return getChannelsSelector;
	};

	const onNavigate = () => {
		navigate(`${ELinksName.REPORTS}/${params.tab}`);
	};

	const onReject = () => {
		dispatch(
			rejectMessageRequest({
				navigate: onNavigate,
				id: params.id as string,
			}),
		);
	};

	const onBlockChannel = () => {
		dispatch(deactivateChannelRequest(String(channel.id)));
	};

	const onBlockPosts = () => {
		params.tab === EReportsTabs.CHANNEL_POSTS &&
			// dispatch(deactivateGroupRequest(String(channel.id)));
			dispatch(deactivateMessageRequest(String(params.id)));

		params.tab === EReportsTabs.MESSAGES_IN_GROUPS &&
			dispatch(deactivateMessageRequest(String(channel.id)));
	};

	const tableDate: IChannelsResponse | null = useSelector(defineSelector());

	return (
		<div className="admin-info report-details">
			<div className="report-details__header">
				<div className="report-details__header-left-box">
					<Avatar hash={String(channel.photo || '')} />
					<div className="report-details__header-text upper-custom">{channel.name}</div>
				</div>
				<div className="report-details__header-right-box">
					<div onClick={onReject} className="report-details__reject pointer">
						<CheckGreenSvg />
						<div className="report-details__header-text-green">Reject</div>
					</div>
					<div onClick={onBlockChannel} className="report-details__reject pointer">
						<LockRedSvg />
						<div className="report-details__header-text-red">Block the channel</div>
					</div>
					{(params.tab === EReportsTabs.CHANNEL_POSTS ||
						params.tab === EReportsTabs.MESSAGES_IN_GROUPS) && (
						<div onClick={onBlockPosts} className="report-details__reject pointer">
							<LockRedSvg />
							<div className="report-details__header-text-red">Block the posts</div>
						</div>
					)}
				</div>
			</div>
			<div className="report-details__title">Report</div>
			<div className="report-details__line-bottom">
				<div className="report-details__report-info">
					<p className="report-details__text">Report text</p>
					<p className="report-details__text">{report.text}</p>
					<div className="report-details__report-inner">
						<div className="report-details__column">
							<span className="report-details__text">Reason for complaint</span>
							<span className="report-details__text">Date</span>
						</div>
						<div className="report-details__column">
							<span className="report-details__text">{report.type}</span>
							<span className="report-details__text">
								{moment(report.created_at).format('MMM DD, YYYY, h:mm A')}
							</span>
						</div>
					</div>
				</div>
				{(params.tab === EReportsTabs.MESSAGES_IN_GROUPS ||
					params.tab === EReportsTabs.CHANNEL_POSTS) &&
					reports && (
						<div className="report-details__message-inner">
							{reports?.map((item) => (
								<div key={item.id} className="report-details__message-wrap">
									<div className="report-details__avatar">
										<img src={ava} alt="" />
									</div>
									<div className="report-details__sms-text-wrap">
										<div className="report-details__corner">
											<CornerSvg />
										</div>
										<div className="report-details__sms-left-content">
											<div className="report-details__autor-name">{item.username}</div>
											<div className="report-details__autor-text">{item.text}</div>
											<div className="report-details__text-time">
												{moment(item.created_at).format('h:mm A')}
											</div>
										</div>
										<div className="report-details__sms-right-content">
											<div className="files-list__block-right files-list__block-right-custom">
												<ErrorSvg />
												<DeleteSvg />
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					)}
			</div>

			<div className="report-details__title">Author</div>
			<div className="report-details__line-bottom">
				<div className="report-details__report-info">
					<div className="report-details__report-inner">
						<div className="report-details__column">
							<span className="report-details__text">Name</span>
							<span className="report-details__text">Email</span>
							<span className="report-details__text">Username</span>
							<span className="report-details__text">Account type</span>
							<span className="report-details__text">Date of registration</span>
							<span className="report-details__text">Last Activity</span>
							<span className="report-details__text">
								Frequency of complaints from the account in the last month
							</span>
						</div>
						<div className="report-details__column">
							<Link
								to={`${ELinksName.USER_DETAILS}/${report.user_id}/${'all-channels'}/${String(
									ETabsUsersDetails.USER_DATA,
								)}`}
								className="report-details__text link link--type2"
							>
								{user.name || '-'}
							</Link>
							<Link
								to={`${ELinksName.USER_DETAILS}/${report.user_id}/${'all-channels'}/${String(
									ETabsUsersDetails.USER_DATA,
								)}`}
								className="report-details__text link link--type2"
							>
								{user.email || '-'}
							</Link>
							<Link
								to={`${ELinksName.USER_DETAILS}/${report.user_id}/${'all-channels'}/${String(
									ETabsUsersDetails.USER_DATA,
								)}`}
								className="report-details__text link link--type2"
							>
								{user.username ? `@${user.username}` : '-'}
							</Link>
							<span className="report-details__text">{user.account_type || '-'}</span>
							<span className="report-details__text">
								{moment(user.date_registration).format('MMM DD, YYYY, h:mm A') || '-'}
							</span>
							<span className="report-details__text">
								{moment(user.last_activity).format('MMM DD, YYYY, h:mm A') || '-'}
							</span>
							<span className="report-details__text">{user.frequency || '-'}</span>
						</div>
					</div>
				</div>
			</div>

			<div className="report-details__title">Channel</div>
			<div className="report-details__line-bottom">
				<div className="report-details__report-info">
					<div className="report-details__report-inner">
						<div className="report-details__column">
							<span className="report-details__text">Name</span>
							<span className="report-details__text">Link</span>
							<span className="report-details__text">Owner</span>
						</div>
						<div className="report-details__column">
							<Link
								to={`${ELinksName.CHANNEL_DETAIL_USER}/${report.channel_id}/all-channels`}
								className="report-details__text link link--type2"
							>
								{channel.name || '-'}
							</Link>
							<Link
								to={`${ELinksName.CHANNEL_DETAIL_USER}/${report.channel_id}/all-channels`}
								className="report-details__text link link--type2"
							>
								{channel.link || '-'}
							</Link>
							<Link
								to={`${ELinksName.CHANNEL_DETAIL_USER}/${report.channel_id}/all-channels`}
								className="report-details__text link link--type2"
							>
								{channel.owner || '-'}
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="report-details__title">Previous report</div>
			<div className="table-wrapper">
				<div
					style={{ padding: '0' }}
					className="table table--users-private-reports container-finance"
				>
					{onHeaderTable()}
					{previous?.reports ? (
						<ReportsBody param={params.tab as string} reports={previous.reports} />
					) : null}
				</div>
			</div>
		</div>
	);
};
