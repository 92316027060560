import { IDataFiltter } from 'pages/Dashboard/Channels';
import { FC, MouseEvent } from 'react';
import { SvgArrowDownHeader, SvgArrowUpHeader } from 'ui/svg-image/SvgAllChannels';

interface IPropsReportsHeader {
	setDataFiltter: (value: IDataFiltter) => void;
}

export const ReportsChannelsHeader: FC = () => {
	const sortTable = (event: MouseEvent) => {
		const target = event.currentTarget as HTMLButtonElement;
	};

	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>User ID</p>
						<div className="sort-td">
							<button onClick={sortTable} id="user_id" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="user_id" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Username</p>
						<div className="sort-td">
							<button onClick={sortTable} id="user_id" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="user_id" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Channel</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Channel URL</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Type</p>
						<div className="sort-td">
							<button onClick={sortTable} id="type" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="type" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Total channel report</p>
						<div className="sort-td">
							<button onClick={sortTable} id="reason" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="reason" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Date</p>
					</div>
				</div>
			</div>
		</div>
	);
};
