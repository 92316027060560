import React, { FC } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const ChartjsLine: FC<{ options: any; data: any; height: number }> = ({
	options,
	data,
	height,
}) => {
	return (
		<Line options={options} data={data} height={height} style={{ height: '300px important' }} />
	);
};
