import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	IDepositUsersList,
	IGraphic,
	ILoyaltyLisREsponse,
	IReferral,
	IReferralState,
	ISummaResponse,
} from './types';

const getReferralState = (state: IStoreState): IReferralState => state.referral;

export const getGraphSelector = createSelector(
	[getReferralState],
	(referral: IReferralState): null | IGraphic => referral.graph,
);

export const getGraphPartnersSelector = createSelector(
	[getReferralState],
	(referral: IReferralState): null | IGraphic => referral.graph_partners,
);

export const getGraphIdSelector = createSelector(
	[getReferralState],
	(referral: IReferralState): null | IGraphic => referral.graph,
);

export const getLoader = createSelector(
	[getReferralState],
	(referral: IReferralState): boolean => referral.referralLoader,
);

export const getPackages = createSelector(
	[getReferralState],
	(referral: IReferralState): Array<string> => referral.packages,
);

export const getPackagesDetails = createSelector(
	[getReferralState],
	(referral: IReferralState): null | Array<ILoyaltyLisREsponse> => referral.packagesData,
);

export const getReferral = createSelector(
	[getReferralState],
	(referral: IReferralState): null | IReferral => referral.referral,
);

export const getListUsersDiscount = createSelector(
	[getReferralState],
	(referral: IReferralState): null | IDepositUsersList => referral.loyaltyUsersList,
);

export const getSummaSelector = createSelector(
	[getReferralState],
	(referral: IReferralState): null | ISummaResponse => referral.summa,
);

export const getSummaBonusesSelector = createSelector(
	[getReferralState],
	(referral: IReferralState): null | ISummaResponse => referral.summa_bonuses,
);

export const getSummaPartnersSelector = createSelector(
	[getReferralState],
	(referral: IReferralState): null | ISummaResponse => referral.summa_partners,
);
