import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Image } from './Image/Image';
import { IDetailsChannel } from 'redux/reducers/channels/types';
import moment from 'moment';
import { findCountry } from 'untils/findCountry';
import { ICountry } from 'redux/reducers/users/types';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ETabsUsersDetails } from 'services/constants/lists-table';

interface IDetailsChannels {
	channel: IDetailsChannel;
	countriesList: null | Array<ICountry>;
}

export const DetailsChannels: FC<IDetailsChannels> = ({ channel, countriesList }) => {
	const {
		title,
		link,
		cost,
		created_at,
		owner_first_name,
		owner_last_name,
		category,
		country,
		adult,
		about,
		type,
		photo_access_hash,
		end_date,
		start_date,
		genre_name,
		sub_genre_name,
		user_id,
		deactivated,
		deleted,
	} = channel;

	const onStatusUser = () => {
		if (deactivated) {
			return 'blocked-users';
		} else if (deleted) {
			return 'delete-users';
		} else {
			return 'registered';
		}
	};

	return (
		<section className="user-data__details-user details-user">
			<ul className="details-user__box-text">
				<li className="details-user__item">
					<span>Type</span>
					<span className="details-user__item-upper">{type || '-'}</span>
				</li>
				<li className="details-user__item">
					<span>Channel Name</span>
					<span>{title || '-'}</span>
				</li>
				<li className="details-user__item">
					<span>Channel URL</span>
					<a target="_blank" className="details-user__link" href={link} rel="noreferrer">
						{link || '-'}
					</a>
				</li>
				<li className="details-user__item">
					<span>Subscription cost</span>
					<span>{`$${cost || 0}`}</span>
				</li>
				<li className="details-user__item">
					<span>Created date</span>
					<span>{moment(created_at).format('MMM DD, YYYY h:mm A') || '-'}</span>
				</li>
				{type === 'course' ? (
					<>
						<li className="details-user__item">
							<span>Start date</span>
							<span>{moment(start_date).format('MMM DD, YYYY h:mm A') || '-'}</span>
						</li>
						<li className="details-user__item">
							<span>End date</span>
							<span>{moment(end_date).format('MMM DD, YYYY h:mm A') || '-'}</span>
						</li>
					</>
				) : null}
				<li className="details-user__item">
					<span>Owner</span>
					<Link
						to={`${ELinksName.USER_DETAILS}/${user_id}/${onStatusUser() || 'all-channels'}/${String(
							ETabsUsersDetails.USER_DATA,
						)}`}
						className="details-user__link"
					>
						{owner_first_name + ' ' + owner_last_name || '-'}
					</Link>
				</li>
				<li className="details-user__item">
					<span>Category</span>
					<span>{category || '-'}</span>
				</li>
				{category === 'Music' ? (
					<>
						<li className="details-user__item">
							<span>Genre</span>
							<span>{genre_name || '-'}</span>
						</li>
						<li className="details-user__item">
							<span>Subgenre</span>
							<span>{sub_genre_name || '-'}</span>
						</li>
					</>
				) : null}

				<li className="details-user__item">
					<span>Country</span>
					<span>{findCountry(countriesList, country)}</span>
				</li>
				<li className="details-user__item">
					<span>Age restrictions</span>
					<span>{adult ? '18+' : '-'}</span>
				</li>
				<li className="details-user__item2">
					<span>Channel Description</span>
				</li>
				<li className="details-user__item2 word-break">
					<span>{about || '-'}</span>
				</li>
			</ul>
			<div className="details-user__img-wrapper">
				<Image photo_access_hash={photo_access_hash} />
			</div>
		</section>
	);
};
