import {
	AdminsdSvg,
	AiChatBotSvg,
	AiSvg,
	AmbassadorSvg,
	ApkFilkeSvg,
	ContentSvg,
	DashboardSvg,
	DollarSvg,
	EmailSvg,
	FinancedSvg,
	GroupsdSvg,
	ReactionSvg,
	ReservedSvg,
	SuportSvg,
	SystemSvg,
	TermsSvg,
} from 'assets/svg-icon';
import { ELinksName } from 'services/constants/list-links-sidebar';
import {
	EBackgroundTabs,
	EMonetizationTabs,
	ESubtabsTransaction,
	ESubtabsUsers,
	ETabsContent,
	ETabsTransaction,
	ETabsUsers,
} from 'services/constants/lists-table';
import {
	SvgChannels,
	SvgReportsSidebar,
	SvgTransactionsSidebar,
	SvgUsersSidebar,
} from 'ui/svg-image/SvgSidebar';

export const listLinksSidebar = [
	{
		link: ELinksName.DASHBOARD,
		links_name: ELinksName.DASHBOARD,
		linkName: 'Dashboard',
		svg: DashboardSvg,
		id: 0,
		role: [
			'owner',
			'admin',
			'user_manager',
			'help_desk',
			'billing_manager',
			'manager',
			'user',
			'help',
		],
	},
	{
		link: ELinksName.CHANNELS,
		links_name: ELinksName.CHANNELS,
		linkName: 'Channels',
		svg: SvgChannels,
		id: 1,
		role: [
			'owner',
			'admin',
			'user_manager',
			'help_desk',
			'billing_manager',
			'manager',
			'user',
			'help',
		],
	},
	{
		link: ELinksName.GROUPS,
		links_name: ELinksName.GROUPS,
		linkName: 'Groups',
		svg: GroupsdSvg,
		id: 2,
		role: [
			'owner',
			'admin',
			'user_manager',
			'help_desk',
			'billing_manager',
			'manager',
			'user',
			'help',
		],
	},
	{
		link: `${ELinksName.USERS}/${ETabsUsers.REGISTRED}/${ESubtabsUsers.PERSONAL_PROFILE}`,
		linkName: 'Users',
		links_name: ELinksName.USERS,
		svg: SvgUsersSidebar,
		id: 3,
		role: [
			'owner',
			'admin',
			'user_manager',
			'help_desk',
			'billing_manager',
			'manager',
			'user',
			'help',
		],
	},
	{
		link: ELinksName.ADMINS,
		links_name: ELinksName.ADMINS,
		linkName: 'Admins',
		svg: AdminsdSvg,
		id: 4,
		role: ['owner'],
	},
	{
		link: ELinksName.FINANCE,
		links_name: ELinksName.FINANCE,
		linkName: 'Finance',
		svg: FinancedSvg,
		id: 5,
		role: [
			'owner',
			'admin',
			'user_manager',
			'help_desk',
			'billing_manager',
			'manager',
			'user',
			'help',
		],
	},
	{
		link: `${ELinksName.MONETIZATION}/${EMonetizationTabs.BACKGROUND}/${EBackgroundTabs.SINGE}`,
		linkName: 'Monetization',
		links_name: ELinksName.MONETIZATION,
		svg: DollarSvg,
		id: 6,
		role: [
			'owner',
			'admin',
			'user_manager',
			'help_desk',
			'billing_manager',
			'manager',
			'user',
			'help',
		],
	},
	{
		link: ELinksName.AI_CHAT_BOT,
		links_name: ELinksName.AI_CHAT_BOT,
		linkName: 'AI Chat Bot',
		svg: AiChatBotSvg,
		id: 8,
		role: [
			'owner',
			'admin',
			'user_manager',
			'help_desk',
			'billing_manager',
			'manager',
			'user',
			'help',
		],
	},
	{
		link: ELinksName.AI_SERVICES,
		links_name: ELinksName.AI_SERVICES,
		linkName: 'AI Services',
		svg: AiSvg,
		id: 9,
		role: [
			'owner',
			'admin',
			'user_manager',
			'help_desk',
			'billing_manager',
			'manager',
			'user',
			'help',
		],
	},
	// {
	// 	link: `${ELinksName.TRANSACTIONS}/${ETabsTransaction.WITHDRAWALS}/${ESubtabsTransaction.TRANSACTION}`,
	// 	linkName: 'Transactions',
	// 	links_name: ELinksName.TRANSACTIONS,
	// 	svg: SvgTransactionsSidebar,
	// 	id: 8,
	// 	role: [
	// 		'owner',
	// 		'admin',
	// 		'user_manager',
	// 		'help_desk',
	// 		'billing_manager',
	// 		'manager',
	// 		'user',
	// 		'help',
	// 	],
	// },
	{
		link: ELinksName.REPORTS,
		links_name: ELinksName.REPORTS,
		linkName: 'Reports',
		svg: SvgReportsSidebar,
		id: 10,
		role: ['owner', 'admin', 'user_manager', 'help_desk', 'billing_manager', 'user', 'help'],
	},
	{
		link: ELinksName.RESERVED_USERNAMES,
		links_name: ELinksName.RESERVED_USERNAMES,
		linkName: 'Reserved usernames',
		svg: ReservedSvg,
		id: 11,
		role: ['owner', 'admin', 'user_manager', 'help_desk', 'billing_manager', 'user', 'help'],
	},
	{
		link: `${ELinksName.CONTENT}/${ETabsContent.TRACK}`,
		links_name: ELinksName.CONTENT,
		linkName: 'Content',
		svg: ContentSvg,
		id: 12,
		role: ['owner', 'admin', 'user_manager', 'help_desk', 'billing_manager', 'user', 'help'],
	},
	{
		link: `${ELinksName.SYSTEM_CHANNELS}`,
		links_name: ELinksName.SYSTEM_CHANNELS,
		linkName: 'System Channels',
		svg: SystemSvg,
		id: 13,
		role: ['owner', 'admin', 'user_manager', 'help_desk', 'billing_manager', 'user', 'help'],
	},
	{
		link: `${ELinksName.TERMS_MANAGEMENT}`,
		links_name: ELinksName.TERMS_MANAGEMENT,
		linkName: 'Terms management',
		svg: TermsSvg,
		id: 14,
		role: ['owner', 'admin', 'user_manager', 'help_desk', 'billing_manager', 'user', 'help'],
	},
	{
		link: `${ELinksName.ELLO_PARTNERS}`,
		links_name: ELinksName.ELLO_PARTNERS,
		linkName: 'Ello Partners',
		svg: EmailSvg,
		id: 15,
		role: ['owner', 'admin', 'user_manager', 'help_desk', 'billing_manager', 'user', 'help'],
	},
	{
		link: `${ELinksName.APK}`,
		links_name: ELinksName.APK,
		linkName: 'APK File',
		svg: ApkFilkeSvg,
		id: 16,
		role: ['owner', 'admin', 'user_manager', 'help_desk', 'billing_manager', 'user', 'help'],
	},
	{
		link: `${ELinksName.USER_PARTNER}`,
		links_name: ELinksName.USER_PARTNER,
		linkName: 'Ambassador Program',
		svg: AmbassadorSvg,
		id: 17,
		role: ['owner', 'admin', 'user_manager', 'help_desk', 'billing_manager', 'user', 'help'],
	},
	{
		link: ELinksName.SUPORT,
		links_name: ELinksName.SUPORT,
		linkName: 'Support',
		svg: SuportSvg,
		id: 18,
		role: ['owner', 'admin', 'user_manager', 'help_desk', 'billing_manager', 'user', 'help'],
	},
	{
		link: ELinksName.REACTIONS,
		links_name: ELinksName.REACTIONS,
		linkName: 'Reactions',
		svg: ReactionSvg,
		id: 19,
		role: ['owner', 'admin', 'user_manager', 'help_desk', 'billing_manager', 'user', 'help'],
	},
];
