import { userStatus } from 'CONST/cardName';
import classNames from 'classnames';
import { FC, MouseEvent } from 'react';
import { useParams } from 'react-router-dom';
import { ESubTabsFinance } from 'services/constants/lists-table';
import { SelectList } from 'ui/SelectList/SelectList';

interface IProps {
	onActivBtn: (event: MouseEvent) => void;
	activeBtn: string;
}

export const ChartHeader: FC<IProps> = ({ onActivBtn, activeBtn }) => {
	const params = useParams();

	return (
		<div className="platform-balances__graph-header">
			<div className="platform-balances__graph-title">
				{ESubTabsFinance.WITHDRAWALS.includes(String(params.subTab))
					? 'Withdrawal schedules'
					: 'Replenishment schedules'}
			</div>
			<div className="platform-balances__graph-filters">
				<SelectList placeholder={'All'} data={userStatus} />
				<div className="platform-balances__graph-btn-wrap">
					<button
						onClick={onActivBtn}
						className={classNames('platform-balances__graph-btn', {
							'platform-balances__graph-btn-active': activeBtn === 'day',
						})}
						name="day"
						type="button"
					>
						Day
					</button>
					<button
						onClick={onActivBtn}
						className={classNames('platform-balances__graph-btn', {
							'platform-balances__graph-btn-active': activeBtn === 'week',
						})}
						name="week"
						type="button"
					>
						Week
					</button>
					<button
						onClick={onActivBtn}
						className={classNames('platform-balances__graph-btn', {
							'platform-balances__graph-btn-active': activeBtn === 'month',
						})}
						name="month"
						type="button"
					>
						Month
					</button>
					<button
						onClick={onActivBtn}
						className={classNames('platform-balances__graph-btn', {
							'platform-balances__graph-btn-active': activeBtn === 'year',
						})}
						name="year"
						type="button"
					>
						Year
					</button>
				</div>
			</div>
		</div>
	);
};
