export const SvgVerifiedAccount = () => (
	<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="9.80005" cy="9" r="5" fill="white" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.7935 2.24049C12.2449 2.0248 12.8186 2.27409 12.9944 2.74288C13.2187 3.34105 13.5861 4.09534 14.1465 4.65641C14.707 5.21748 15.4604 5.58533 16.0579 5.80986C16.5262 5.98583 16.7752 6.56022 16.5598 7.01212C16.2694 7.62121 15.9547 8.41609 15.9547 9.00098C15.9547 9.614 16.3003 10.4577 16.6014 11.0764C16.808 11.5009 16.6031 12.0381 16.1711 12.2276C15.558 12.4965 14.743 12.9295 14.1465 13.5266C13.6046 14.0691 13.2432 14.7471 13.0171 15.2899C12.8271 15.746 12.2593 15.9851 11.8142 15.7714C11.2032 15.478 10.3977 15.1558 9.80682 15.1558C9.19628 15.1558 8.35694 15.4996 7.73952 15.8003C7.31356 16.0078 6.75718 15.8052 6.54985 15.3788C6.27942 14.8227 5.85823 14.0992 5.28629 13.5266C4.70349 12.9431 3.96415 12.5164 3.40462 12.2463C2.98781 12.0452 2.79589 11.5183 2.99954 11.1023C3.30351 10.4814 3.6589 9.62262 3.6589 9.00098C3.6589 8.40942 3.33707 7.60308 3.04398 6.99138C2.83046 6.54576 3.0693 5.97732 3.52496 5.78711C4.06714 5.56078 4.74439 5.19892 5.28629 4.65641C5.88274 4.0593 6.31523 3.24334 6.58378 2.62962C6.77307 2.19704 7.30968 1.99192 7.73376 2.19876C8.35176 2.50019 9.19449 2.84617 9.80682 2.84617C10.3911 2.84617 11.185 2.5312 11.7935 2.24049ZM12.8949 6.61734C12.6965 6.41055 12.3749 6.41055 12.1765 6.61734L8.72214 10.2256L7.40135 8.84886C7.20297 8.64207 6.88132 8.64207 6.68294 8.84886C6.48455 9.05565 6.48455 9.39093 6.68294 9.59772L8.4029 11.3906C8.50932 11.4965 8.7831 11.6447 9.02694 11.3906L12.8949 7.35867C13.0933 7.15188 13.0933 6.82413 12.8949 6.61734Z"
			fill="#0A49A5"
		/>
	</svg>
);

export const SvgUnverifiedAccount = () => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.259 1.65722C14.0247 1.03216 13.2597 0.699779 12.6578 0.987363C11.8466 1.37498 10.7879 1.79494 10.009 1.79494C9.19251 1.79494 8.06887 1.33362 7.24487 0.931725C6.67942 0.655933 5.96394 0.929433 5.71156 1.50621C5.3535 2.32449 4.77684 3.41244 3.98157 4.20859C3.25903 4.93194 2.35605 5.41442 1.63313 5.71619C1.02559 5.9698 0.707139 6.72772 0.99183 7.32188C1.38262 8.13748 1.81172 9.21261 1.81172 10.0013C1.81172 10.8302 1.33787 11.9753 0.932576 12.8031C0.661047 13.3578 0.916933 14.0603 1.47269 14.3285C2.21872 14.6885 3.2045 15.2575 3.98157 16.0355C4.74417 16.7989 5.30575 17.7636 5.66632 18.5052C5.94276 19.0737 6.68461 19.3438 7.25255 19.0672C8.07577 18.6662 9.19489 18.2078 10.009 18.2078C10.7968 18.2078 11.8707 18.6373 12.6854 19.0286C13.2789 19.3136 14.036 18.9948 14.2893 18.3865C14.5908 17.6628 15.0727 16.7588 15.7952 16.0355C16.5905 15.2393 17.6772 14.662 18.4946 14.3036C19.0707 14.0509 19.3439 13.3346 19.0684 12.7685C18.667 11.9436 18.2062 10.8187 18.2062 10.0013C18.2062 9.22149 18.6257 8.16166 19.0129 7.34954C19.3001 6.74701 18.9681 5.98115 18.3437 5.74652C17.5471 5.44714 16.5425 4.95668 15.7952 4.20859C15.048 3.4605 14.5581 2.45478 14.259 1.65722Z"
			fill="#0A49A5"
		/>
	</svg>
);

export const SvgRecommended = () => (
	<svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M0.6875 4.6875L4.32941 8.125L11.625 1.25"
			stroke="#0A49A5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const SvgNotRecommended = () => (
	<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.77543 10.0996C9.97069 10.2949 10.2873 10.2949 10.4825 10.0996C10.6778 9.90437 10.6778 9.58779 10.4825 9.39253L9.77543 10.0996ZM1.45846 0.368448C1.26319 0.173185 0.946612 0.173185 0.75135 0.368448C0.556088 0.56371 0.556088 0.880292 0.75135 1.07555L1.45846 0.368448ZM0.75135 9.39253C0.556088 9.58779 0.556088 9.90437 0.75135 10.0996C0.946613 10.2949 1.26319 10.2949 1.45846 10.0996L0.75135 9.39253ZM10.4825 1.07555C10.6778 0.880292 10.6778 0.56371 10.4825 0.368448C10.2873 0.173185 9.97069 0.173185 9.77543 0.368448L10.4825 1.07555ZM10.4825 9.39253L1.45846 0.368448L0.75135 1.07555L9.77543 10.0996L10.4825 9.39253ZM1.45846 10.0996L10.4825 1.07555L9.77543 0.368448L0.75135 9.39253L1.45846 10.0996Z"
			fill="#74747B"
		/>
	</svg>
);

export const SvgAction = () => (
	<svg
		className="fill"
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M3.5 6.5V5C3.5 2.5175 4.25 0.5 8 0.5C11.75 0.5 12.5 2.5175 12.5 5V6.5"
			stroke="#EF4062"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8 12.875C9.03553 12.875 9.875 12.0355 9.875 11C9.875 9.96447 9.03553 9.125 8 9.125C6.96447 9.125 6.125 9.96447 6.125 11C6.125 12.0355 6.96447 12.875 8 12.875Z"
			stroke="#EF4062"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11.75 15.5H4.25C1.25 15.5 0.5 14.75 0.5 11.75V10.25C0.5 7.25 1.25 6.5 4.25 6.5H11.75C14.75 6.5 15.5 7.25 15.5 10.25V11.75C15.5 14.75 14.75 15.5 11.75 15.5Z"
			stroke="#EF4062"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const SvgActionBlocked = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.51501 12.14C6.86001 12.5825 7.40001 12.875 8.00001 12.875C9.03501 12.875 9.87501 12.035 9.87501 11C9.87501 10.5725 9.73251 10.175 9.49251 9.85999"
			stroke="#27AE60"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M1.115 14.6C0.6575 14.03 0.5 13.1225 0.5 11.75V10.25C0.5 7.25 1.25 6.5 4.25 6.5H11.75C12.02 6.5 12.2675 6.5075 12.5 6.5225C14.8775 6.6575 15.5 7.52 15.5 10.25V11.75C15.5 14.75 14.75 15.5 11.75 15.5H4.25C3.98 15.5 3.7325 15.4925 3.5 15.4775"
			stroke="#27AE60"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3.5 6.5V5C3.5 2.5175 4.25 0.5 8 0.5C11.1125 0.5 12.155 1.535 12.425 3.17"
			stroke="#27AE60"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M15.5 0.5L0.5 15.5" stroke="#27AE60" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
);

export const SvgArrowUpHeader = () => (
	<svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M0.981791 4.16663L3.94832 4.16663L6.61962 4.16663C7.07675 4.16663 7.30531 3.55949 6.98151 3.20359L4.51496 0.492435C4.11974 0.0580225 3.47691 0.0580224 3.08169 0.492435L2.14364 1.52351L0.615141 3.20359C0.296109 3.55949 0.52467 4.16663 0.981791 4.16663Z"
			fill="#070708"
		/>
	</svg>
);

export const SvgArrowDownHeader = () => (
	<svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.61831 0.833374H3.65178H0.980474C0.523352 0.833374 0.294792 1.44051 0.618586 1.79641L3.08514 4.50756C3.48036 4.94198 4.12318 4.94198 4.5184 4.50756L5.45646 3.47649L6.98496 1.79641C7.30399 1.44051 7.07543 0.833374 6.61831 0.833374Z"
			fill="#070708"
		/>
	</svg>
);

export const SvgArrowDownDownload = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.5 11.2664L12 15.7664L16.5 11.2664"
			stroke="#0A49A5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M12 15.7664V3.76636" stroke="#0A49A5" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M3 18.7664C8.835 20.7164 15.165 20.7164 21 18.7664"
			stroke="#0A49A5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
