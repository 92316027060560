import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IReferralApi } from './types';
import {
	IAddLoyatlyRequest,
	IChangeLoyatlyRequest,
	IRequestGraph,
	ISummaRequest,
	IUserPackageAddREquest,
	LoyaltyUsersRequest,
} from 'redux/reducers/referral/types';

export const referral: IReferralApi = {
	getGraph: (payload: IRequestGraph) =>
		http.post(endpoint.referrals.GET_GRAPHS, payload).then((response) => response.data),
	getSumma: (payload: ISummaRequest) =>
		http.post(endpoint.referrals.GET_SUMMA, payload).then((response) => response.data),
	getGraphId: (payload: string) =>
		http.get(endpoint.referrals.GET_GRAPH(payload)).then((response) => response.data),
	addLoyatly: (payload: IAddLoyatlyRequest) =>
		http.post(endpoint.referrals.ADD_LOYALTY, payload).then((response) => response.data),
	changeLoyalty: (payload: IChangeLoyatlyRequest) =>
		http.post(endpoint.referrals.CHANGE_LOYALTY, payload).then((response) => response.data),
	getPackageList: () => http.get(endpoint.referrals.LOYALTY_LIST).then((response) => response.data),
	deletePackage: (payload: string) =>
		http.delete(endpoint.referrals.DELETE_LOYALTY(payload)).then((response) => response.data),
	userPackageAdd: (payload: IUserPackageAddREquest) =>
		http.post(endpoint.referrals.ADD_LOYALTY_USER, payload).then((response) => response.data),
	deleteUserPackage: (payload: string) =>
		http.delete(endpoint.referrals.DELETE_LOYALTY_USER(payload)).then((response) => response.data),
	getLoyaltyUsers: (payload: LoyaltyUsersRequest) =>
		http.post(endpoint.referrals.GET_LOYALTY_USERS, payload).then((response) => response.data),
	getLoyaltyUsersPartner: (payload: LoyaltyUsersRequest) =>
		http
			.post(endpoint.referrals.GET_LOYALTY_USERS_PARTNER, payload)
			.then((response) => response.data),
};
