import { MobileSvg } from 'assets/svg-icon';
import { ApkFileList } from 'components/ApkFileList/ApkFileList';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch, useSelector } from 'react-redux';
import {
	addApkRequest,
	deleteApkRequest,
	getListApkRequest,
} from 'redux/reducers/apk-files/reducer';
import { DropFile } from 'ui/DropFile/DropFile';
import './ApkFileList.scss';
import { getApkLoaderSelector, getListApkSelector } from 'redux/reducers/apk-files/selectors';
import { PopUpWrapper } from 'ui/PopUpWrapper/PopUpWrapper';
// import { Loader } from 'components/Loader';
// import { SmallLoader } from 'components/SmallLoader';
import { getFileRequest } from 'redux/reducers/download/reducer';
import { endpoint } from 'services/endpoint';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { getLoaderSelector } from 'redux/reducers/download/selectors';

const fileTypes = ['APK'];

export const ApkFile: FC = () => {
	const [name, setName] = useState<string>('');
	const [idApk, setIdApk] = useState<number>(0);
	const [file, setFile] = useState<File | null>(null);
	const dispatch = useDispatch();
	const apkList = useSelector(getListApkSelector);
	const loader = useSelector(getApkLoaderSelector);
	const [percentage, setPercentage] = useState(0);
	const [size, setSize] = useState(0);
	const loaderApk = useSelector(getLoaderSelector);

	const handleChange = (data: File) => {
		setFile(data);

		const formData = new FormData();
		formData.append('file', data);
		setSize(data.size);
		dispatch(addApkRequest({ file: formData, size: data.size, onPercent: setPercentage }));
	};

	const removeApk = (_event: MouseEvent) => {
		dispatch(deleteApkRequest(String(idApk)));
		setIdApk(0);
	};

	const onDownload = (name_apk: string) => {
		console.log(name_apk);
		dispatch(
			getFileRequest({
				endpoint: endpoint.utils.uploads(name_apk),
			}),
		);
	};

	const isOpenModal = (id: number, name_apk: string) => {
		setIdApk(id);
		console.log(name_apk);
		setName(name_apk);
	};

	const isCloseModal = () => {
		setIdApk(0);
	};

	useEffect(() => {
		dispatch(getListApkRequest({}));
	}, []);

	console.log(percentage, size);

	return (
		<div className="main-content">
			{idApk ? (
				<PopUpWrapper
					loader={loaderApk}
					handleButton={removeApk}
					isOpenModal={isCloseModal}
					title="Are you sure you want to delete this APK File?"
					btnName="Yes, I’m sure"
					disablet="active"
				/>
			) : null}
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title big-first-letter">APK File</p>
				</div>
			</div>
			{apkList?.total ? (
				<div className="apk-file">
					<ApkFileList onDownload={onDownload} isOpenModal={isOpenModal} apkList={apkList.files} />
				</div>
			) : (
				<div className="background__wrap">
					<div className="background__inner-drop">
						<div className="background__img-wrap">
							<div className="background__img-svg">
								<MobileSvg />
							</div>
							<FileUploader
								classes="opacity"
								handleChange={handleChange}
								name="file"
								types={fileTypes}
							/>
						</div>
						<p className="background__title">Drop file here</p>
						<p className="background__text-gray">
							{file
								? //@ts-ignore
								  `File name: ${file?.name || ''}`
								: `(APK)`}
						</p>
						{loader ? (
							// <SmallLoader />
							<div style={{ width: 60, height: 60 }}>
								<CircularProgressbar
									styles={buildStyles({
										backgroundColor: '#3e98c7',
									})}
									value={Math.floor((percentage / size) * 100)}
									text={`${Math.floor((percentage / size) * 100) || 0}%`}
								/>
							</div>
						) : (
							<button className="background__drop-dtn">
								<FileUploader
									classes="opacity"
									handleChange={handleChange}
									name="file"
									types={fileTypes}
								/>
								<span className="background__btn-name">Browse file</span>
							</button>
						)}
					</div>
				</div>
			)}
		</div>
	);
};
