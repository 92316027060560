import { FC } from 'react';
import { ChannelsHeader } from './ChannelsHeader/ChannelsHeader';
import { ChannelsBody } from './ChannelsBody/ChannelsBody';

export const GroupsAdmins: FC = () => {
	return (
		<div className="table table--channels-private-admins margin-top">
			<ChannelsHeader />
			<ChannelsBody />
		</div>
	);
};
