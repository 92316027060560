import { CloseSvg } from 'assets/svg-icon';
import { FC, ReactNode, MouseEvent, useEffect, useState } from 'react';
import './ModalWrapper.scss';
import { SmallLoader } from 'ui/SmallLoader';

interface IProps {
	nameBtn: string;
	title: string;
	subTitle?: string;
	children?: ReactNode;
	onClosePopUp: (event: MouseEvent) => void;
	onSubmit?: () => void;
	loader?: boolean;
}

export const ModalWrapper: FC<IProps> = ({
	onSubmit,
	onClosePopUp,
	subTitle,
	nameBtn,
	title,
	children,
	loader,
}) => {
	const [elemH, setElemH] = useState<number>(0);
	const [pageYOffset, setPageYOffset] = useState<number>(0);
	const [scroll, setScroll] = useState(0);

	const handleScroll = () => {
		setScroll(window.scrollY);
	};

	setTimeout(() => {
		setElemH(Number(document.querySelector('.modal-wrap')?.clientHeight) || 0);
		setPageYOffset(window.pageYOffset);
	}, 500);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<div className="blur">
			<div
				style={{
					top: `${(window.innerHeight - (elemH || 0)) / 2 + (scroll || pageYOffset)}px`,
					opacity: elemH ? 1 : 0,
				}}
				className="modal-wrap"
			>
				<button name="" type="button" onClick={onClosePopUp} className="system__popup-close">
					<CloseSvg />
				</button>
				<h3 className="modal-wrap__title">{title}</h3>
				{subTitle ? <h4 className="modal-wrap__sub-title">{subTitle}</h4> : null}
				{children}
				<button onClick={onSubmit} className="modal-wrap__btn">
					{loader ? <SmallLoader /> : nameBtn}
				</button>
			</div>

			{/* {elemH ? null : <Loader />} */}
		</div>
	);
};
