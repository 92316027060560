import { IDataFiltter } from 'pages/Dashboard/Channels';
import { FC, MouseEvent } from 'react';
import { SvgArrowDownHeader, SvgArrowUpHeader } from 'ui/svg-image/SvgAllChannels';

interface IPropsUsersTableHeader {
	setDataFiltter: (value: IDataFiltter) => void;
}

export const RefundsTableHeader: FC<IPropsUsersTableHeader> = ({ setDataFiltter }) => {
	const sortTable = (event: MouseEvent) => {
		const target = event.currentTarget as HTMLButtonElement;
		setDataFiltter({ order_by: target.id, sort: target.name });
	};

	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Photo</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> Name</p>
						<div className="sort-td">
							<button onClick={sortTable} id="username" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="username" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> User name</p>
						<div className="sort-td">
							<button onClick={sortTable} id="username" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="username" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Subscription date</p>
						<div className="sort-td">
							<button onClick={sortTable} id="registration_date" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="registration_date" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Amount per subscription</p>
						<div className="sort-td">
							<button onClick={sortTable} id="registration_date" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="registration_date" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Action</p>
						<div className="sort-td">
							<button onClick={sortTable} id="last_login" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="last_login" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
