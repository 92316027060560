import { userStatus } from 'CONST/cardName';
import { BankBlackSvg, BankSvg, PaySvg, StripeSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { FC, MouseEvent } from 'react';
import {
	IBalancesWithdrawl,
	ITransactionGraphResponse,
} from 'redux/reducers/finance/finance-types';
import { ChartBar } from 'ui/Graphs/ChartBar';
import { Select } from 'ui/Select/Select';
import { SelectList } from 'ui/SelectList/SelectList';
import { numeralFunc } from 'untils/numerals';
import { ChartHeader } from '../ChartHeader/ChartHeader';

interface IProps {
	withdrawal: IBalancesWithdrawl;
	onActivBtn: (event: MouseEvent) => void;
	activeBtn: string;
	graph: null | Array<ITransactionGraphResponse>;
}

export const PlatformBalancesWithdrawal: FC<IProps> = ({
	graph,
	onActivBtn,
	activeBtn,
	withdrawal,
}) => {
	const onCreateLabel = () => {
		const result: Array<string> = [];
		graph?.map((number) => number.data.map((item) => result.push(item.period)));
		return result;
	};

	const onCreateNumbers = () => {
		const result: Array<number> = [];
		graph?.map((number) => number.data.map((item) => result.push(item.amount)));
		return result;
	};

	return (
		<>
			<div className="platform-balances__filter-inner">
				<div className="platform-balances__text">Balance on the platform</div>
			</div>

			<div className="filter-custom__info-card margin-top-card">
				<div className="filter-custom__card">
					<p className="filter-custom__title color-grey">Total withdrawal</p>
					<p className="filter-custom__subtitle">{`$${numeralFunc(
						withdrawal.total_platform_withdrawal,
					)}`}</p>
				</div>

				<div className="filter-custom__card">
					<p className="filter-custom__title color-grey">Total pending withdrawal</p>
					<p className="filter-custom__subtitle">
						{`$${numeralFunc(withdrawal.total_platform_pending_withdrawal)}`}
					</p>
				</div>

				<div className="filter-custom__card">
					<p className="filter-custom__title color-grey">
						Predicted withdrawal earning for the next month
					</p>
					<p className="filter-custom__subtitle">{`$${numeralFunc(
						withdrawal.total_platform_pending_withdrawal,
					)}`}</p>
				</div>
			</div>

			<div className="gray-background">
				<div className="platform-balances__filter-inner">
					<div className="platform-balances__text">Balance by witdrawal methods</div>
				</div>

				<div className="platform-balances__box-left-body">
					<div className="platform-balances__left-card">
						<PaySvg />
						<div className="platform-balances__text-wrapper">
							<p className="filter-custom__title">PayPal</p>
							<p className="filter-custom__subtitle">{`$${numeralFunc(
								withdrawal.paypal_withdraw_balance,
							)}`}</p>
						</div>
					</div>
					<div className="platform-balances__left-card">
						<BankBlackSvg />
						<div className="platform-balances__text-wrapper">
							<p className="filter-custom__title">Bank transfer</p>
							<p className="filter-custom__subtitle">
								{`$${numeralFunc(withdrawal.bank_withdraw_balance)}`}
							</p>
						</div>
					</div>
				</div>
			</div>

			{/* <div className="platform-balances__body-wrapper">
					<div className="platform-balances__box-right">
						<div className="platform-balances__box-left-header">
							<p className="platform-balances__left-title">Replenishment schedules</p>
							<Link className="platform-balances__left-link" to="/">
								Top Up
							</Link>
						</div>
						<ChartjsLine options={options} data={data} height={70} />
					</div>
				</div>
				<div className="filter-custom__info-card margin-top-card">
					{platformBalanceTwo.map((el) => (
						<div key={el} className="filter-custom__card">
							<p className="filter-custom__title color-grey">{el}</p>
							<p className="filter-custom__subtitle">$80,856.00</p>
						</div>
					))}
				</div> */}
			<div className="platform-balances__graph-wrap">
				<ChartHeader onActivBtn={onActivBtn} activeBtn={activeBtn} />
				<ChartBar
					activeBtn={activeBtn}
					labels={graph?.map((label) => label.period) || []}
					numbers={graph?.map((number) => number.total) || []}
				/>
			</div>
		</>
	);
};
