import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EBackgroundTabs, ETabsFinanceWithdrawal } from 'services/constants/lists-table';

export const SubTabs: FC = () => {
	const params = useParams();

	return (
		<div className="table-tabs table-tabs--type2 table-tabs-custom tab-up">
			<Link
				to={`${ELinksName.MONETIZATION}/${params.tab}/${EBackgroundTabs.SINGE}`}
				className={classNames('table-tabs__item', {
					active: params.subTab === EBackgroundTabs.SINGE || !params.subTab,
				})}
			>
				Singe
			</Link>

			<Link
				to={`${ELinksName.MONETIZATION}/${params.tab}/${EBackgroundTabs.PACK}`}
				className={classNames('table-tabs__item', {
					active: params.subTab === EBackgroundTabs.PACK,
				})}
			>
				Pack
			</Link>
		</div>
	);
};
