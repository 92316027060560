import { ArrowDownSvg, CheckSvg, FilterSvg, PlusSvg, SearchSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { reportList } from 'CONST/selectList';
import moment from 'moment';
import { IDataFiltter } from 'pages/Dashboard/Channels';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'ui/Button/Button';
import { SelectList } from 'ui/SelectList/SelectList';
import { DebounceInput } from 'react-debounce-input';
import { useDispatch, useSelector } from 'react-redux';
import { getTypesRequest } from 'redux/reducers/reports/reducer';

import { getTypesSelector } from 'redux/reducers/reports/selectors';

export const FilterAiBot: FC<{
	type?: Array<string>;
	status?: Array<string>;
	category?: Array<string>;
	other?: Array<string>;
	typesName?: string;
	statusName?: string;
	otherName?: string;
	defaultSelectType: string;
	defaultSelectStatus: string;
	defaultSelectCategory: string;
	grid?: string;
	setDataFiltter?: (value: IDataFiltter) => void;
}> = ({
	type,
	status,
	category,
	typesName,
	setDataFiltter,
	defaultSelectType,
	defaultSelectStatus,
	defaultSelectCategory,
}) => {
	const [search, setSearch] = useState<string>('');
	const [open, setOpen] = useState<boolean>(false);

	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const typesList = useSelector(getTypesSelector);
	const navigate = useNavigate();

	const getTypes = (value: string) => {
		// @ts-ignore
		setDataFiltter?.((prev: IDataFiltter) => {
			return {
				...prev,
				type_id: typesList?.find((item) => value.replaceAll('_', ' ') === item.Title.toLowerCase())
					?.Id,
			};
		});
	};

	const onSearch = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		setSearch(value);
		navigate({
			search: '1',
		});
		// @ts-ignore
		setDataFiltter?.((prev: IDataFiltter) => {
			return {
				...prev,
				search: value,
			};
		});
	};

	const onSave = () => {};

	return (
		<div className="filter-wrapper filter-custom">
			<div className="filter-custom__inputs-block filter-custom__inputs-block--grid">
				<div className="wraper-report">
					<div onClick={() => setOpen(!open)} className="filter-custom__btn pointer">
						<div className="filter-custom__btn-name">
							<FilterSvg />
							<span>Filters</span>
						</div>
						<ArrowDownSvg />
					</div>
					{pathname.includes('reports') && (
						<SelectList data={reportList} maxWidth={'max-width'} defaultSelect="Export" />
					)}
				</div>
				<div className="active-btn">
					<div className="input input-custom input-search select-custom-width">
						<div className="input-wrapper">
							<DebounceInput
								className="input-item input-item--left-icon"
								placeholder="Search by name, username"
								debounceTimeout={500}
								value={search || ''}
								onChange={onSearch}
							/>
							<span className="input-icon">
								<SearchSvg />
							</span>
						</div>
					</div>
					{pathname.includes('monetization') && (
						<Button className="button-wrapper" type={'button'} name={'Add background'}>
							<PlusSvg />
						</Button>
					)}
				</div>
			</div>

			<div className={classNames('filter-custom__inputs-inner-custom', { 'open-custom': open })}>
				<div className={classNames('ai-bots__filter-wrap', { none: !open })}>
					<div className="ai-bots__select-list">
						<div>
							<div className="filter-custom__header">
								<div className="filter-custom__title-table">{typesName}</div>
							</div>
							<div>
								<div className="">
									<div className="">
										<div className="filter-custom__country-wrapper">
											<SelectList
												onSelect={getTypes}
												data={category?.map((item) => item) || []}
												defaultSelect={defaultSelectCategory}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div>
							<div className="filter-custom__header">
								<div className="filter-custom__title-table">{typesName}</div>
							</div>
							<div>
								<div className="">
									<div className="">
										<div className="filter-custom__country-wrapper">
											<SelectList
												onSelect={getTypes}
												data={type?.map((item) => item) || []}
												defaultSelect={defaultSelectType}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div>
							<div className="filter-custom__header">
								<div className="filter-custom__title-table">{typesName}</div>
							</div>
							<div>
								<div className="">
									<div className="">
										<div className="filter-custom__country-wrapper">
											<SelectList
												onSelect={getTypes}
												data={status?.map((item) => item) || []}
												defaultSelect={defaultSelectStatus}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<button onClick={onSave} type="button" className="ai-bots__btn-save">
						Save
					</button>
				</div>
			</div>
		</div>
	);
};
