import { IDataFiltter } from 'pages/Dashboard/Channels';
import { FC, MouseEvent, useState } from 'react';
import { SvgArrowDownHeader, SvgArrowUpHeader } from 'ui/svg-image/SvgAllChannels';

interface IPropsUsersTableHeader {
	setDataFiltter: (value: IDataFiltter) => void;
}

export const UsersTableHeader: FC<IPropsUsersTableHeader> = ({ setDataFiltter }) => {
	const [sort, setSort] = useState<number>(0);
	const [prev, setPrev] = useState<string>('');

	const sortTable = (event: MouseEvent) => {
		const target = event.currentTarget as HTMLButtonElement;
		if (prev !== target.id && sort !== 0) {
			setSort(1);
			setPrev(target.id);
			setDataFiltter({ order_by: target.id, asc: true });
			return;
		}

		switch (sort + 1) {
			case 1:
				setDataFiltter({ order_by: target.id, asc: true });

				break;
			case 2:
				setDataFiltter({ order_by: target.id, asc: false });

				break;
			default:
				setDataFiltter({ order_by: target.id });

				break;
		}

		if (sort === 2) {
			setSort(0);
			setPrev(target.id);
			return;
		}
		setSort((prevNum) => prevNum + 1);
		setPrev(target.id);
	};

	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Photo</p>
					</div>
				</div>
				<div className="td">
					<div onClick={sortTable} className="td-name pointer">
						<p> Name</p>
						<div className="sort-td">
							<button id="username" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button id="username" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div onClick={sortTable} className="td-name pointer">
						<p> User name</p>
						<div className="sort-td">
							<button id="username" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button id="username" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Email</p>
					</div>
				</div>
				<div className="td">
					<div onClick={sortTable} className="td-name pointer">
						<p> Registration date</p>
						<div className="sort-td">
							<button id="registration_date" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button id="registration_date" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div onClick={sortTable} className="td-name pointer">
						<p> Last login</p>
						<div className="sort-td">
							<button id="last_login" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button id="last_login" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right-custom">
					<div className="td-name">
						<p> Wallet balance</p>
						<div onClick={sortTable} className="sort-td pointer">
							<button id="balance" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button id="balance" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right-custom">
					<div onClick={sortTable} className="td-name pointer">
						<p style={{ whiteSpace: 'nowrap' }}> Register Country</p>
						<div className="sort-td">
							<button onClick={sortTable} id="country_code" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="country_code" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right-custom">
					<div onClick={sortTable} className="td-name pointer">
						<p> Profile</p>
						<div className="sort-td">
							<button id="profile" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button id="profile" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right td--right-custom">
					<div onClick={sortTable} className="td-name pointer">
						<p> Gender</p>
						<div className="sort-td">
							<button id="gender" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button id="gender" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--center td--right-custom">
					<div className="td-name">
						<p> Verified account</p>
					</div>
				</div>
			</div>
		</div>
	);
};
