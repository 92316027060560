import { FC, MouseEvent } from 'react';
import { SvgArrowDownHeader, SvgArrowUpHeader } from 'ui/svg-image/SvgAllChannels';

export const PartnersRequestHeader: FC = () => {
	const sortTable = (event: MouseEvent) => {};
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>RequestID</p>
						<div className="sort-td">
							<button onClick={sortTable} id="username" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="username" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Name</p>
						<div className="sort-td">
							<button onClick={sortTable} id="username" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="username" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Username</p>
						<div className="sort-td">
							<button onClick={sortTable} id="registration_date" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="registration_date" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Request date</p>
						<div className="sort-td">
							<button onClick={sortTable} id="last_login" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="last_login" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right-custom">
					<div className="td-name">
						<p>Type</p>
					</div>
				</div>
				<div className="td td--right-custom">
					<div className="td-name">
						<p>Status</p>
					</div>
				</div>
			</div>
		</div>
	);
};
