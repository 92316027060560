import { FC, MouseEvent } from 'react';
import { SvgArrowDownHeader, SvgArrowUpHeader } from 'ui/svg-image/SvgAllChannels';

export const MonetizationHeader: FC = () => {
	const sortTable = (event: MouseEvent) => {
		const target = event.currentTarget as HTMLButtonElement;
	};

	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Content</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Name</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Description</p>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>Owner/Creator</p>
						<div className="sort-td">
							<button onClick={sortTable} id="type" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="type" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Type</p>
						<div className="sort-td">
							<button onClick={sortTable} id="type" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="type" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Price</p>
						<div className="sort-td">
							<button onClick={sortTable} id="type" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="type" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Sales</p>
						<div className="sort-td">
							<button onClick={sortTable} id="type" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="type" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Amount of earnings</p>
						<div className="sort-td">
							<button onClick={sortTable} id="type" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="type" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td action-flex td--left">
					<div className="td-name">
						<p>Created date</p>
						<div className="sort-td">
							<button onClick={sortTable} id="type" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="type" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
