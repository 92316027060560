import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IAppStore } from 'redux/reducers/app/types';

const getAppState = (state: IStoreState): IAppStore => state.app;
export const getApp = createSelector([getAppState], (app: IAppStore) => app);

// ====================================================:
export const getMobileMenuStatus = createSelector(
	[getApp],
	(app: IAppStore): boolean => app.mobileMenu,
);

// ====================================================:
export const appLanguage = createSelector([getApp], (app): string => app.lang);

export const getSidebar = createSelector([getApp], (app): boolean => app?.openSidebar || false);

export const getChat = createSelector([getApp], (app): number | null => app?.chatActive);
export const getStatus = createSelector([getApp], (app): number | null => app?.status);
export const getTabs = createSelector([getApp], (app): number => app.tabsSupport);
export const getTabNameSupport = createSelector([getApp], (app): string => app.tabNameSupport);
