import { IDataFiltter } from 'pages/Dashboard/Channels';
import { FC, MouseEvent, useState } from 'react';
import { SvgArrowDownHeader, SvgArrowUpHeader } from 'ui/svg-image/SvgAllChannels';
interface IProps {
	dataFiltter: IDataFiltter;
	setDataFiltter: (value: IDataFiltter) => void;
}

export const ListCodeHeader: FC<IProps> = ({ setDataFiltter }) => {
	const [sort, setSort] = useState(0);
	const [prev, setPrev] = useState<string>('');

	const sortTable = (event: MouseEvent) => {
		const target = event.currentTarget as HTMLButtonElement;

		if (prev !== target.id && sort !== 0) {
			setSort(1);
			setPrev(target.id);
			setDataFiltter({ order_by: target.id, asc: true });
			return;
		}

		switch (sort + 1) {
			case 1:
				setDataFiltter({ order_by: target.id, asc: true });
				break;
			case 2:
				setDataFiltter({ order_by: target.id, asc: false });
				break;
			default:
				setDataFiltter({ order_by: target.id });
				break;
		}

		if (sort === 2) {
			setSort(0);
			setPrev(target.id);
			return;
		}
		setSort((prevNum) => prevNum + 1);
		setPrev(target.id);
	};
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Name</p>
						<div className="sort-td">
							<button onClick={sortTable} id="name" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="name" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p> Avatar </p>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p> Bot name</p>
						<div className="sort-td">
							<button onClick={sortTable} id="bot_name" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="bot_name" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>Date</p>
						<div className="sort-td">
							<button onClick={sortTable} id="date" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="date" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>Codes number</p>
					</div>
				</div>

				<div className="td td--right">
					<div className="td-name">
						<p>Codes life</p>
					</div>
				</div>
			</div>
		</div>
	);
};
