import { isAxiosError } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
// import L from 'i18n-react';
import { api, responseErrors } from 'services';
// import { notificationContainer } from '../../../untils/notificationContainer';
import { IApkListRequest, IApkListResponse, IFile, IUploadFile } from './types';
import {
	addApkRequest,
	addApkSuccess,
	apkFilesState,
	deleteApkRequest,
	deleteApkSuccess,
	getListApkRequest,
	getListApkSuccess,
} from './reducer';

function* getListApkWorker(action: PayloadAction<IApkListRequest>) {
	const { payload } = action;

	try {
		const response: { data: IApkListResponse } = yield call(api.apkFiles.getListApk, payload);
		yield put(getListApkSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(apkFilesState());
	}
}

function* addApkWorker(action: PayloadAction<IUploadFile>) {
	const { payload } = action;

	try {
		const response: { data: IFile } = yield call(api.apkFiles.addApk, payload);
		// console.log(response.data);

		yield put(addApkSuccess(response.data));
		// yield onClose?.();
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(apkFilesState());
	}
}

function* deleteApkWorker(action: PayloadAction<string>) {
	const { payload } = action;

	try {
		yield call(api.apkFiles.deleteApk, payload);
		yield put(deleteApkSuccess(payload));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(apkFilesState());
	}
}

export function* apkFilesSaga() {
	yield takeEvery(getListApkRequest.type, getListApkWorker);
	yield takeEvery(addApkRequest.type, addApkWorker);
	yield takeEvery(deleteApkRequest.type, deleteApkWorker);
}
