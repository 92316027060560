export const templatesData: Array<ITemplatesData> = [
	{ id: 1, name: 'what’s_chatGPT?', title: 'Tittle', code: 'Code' },
	{ id: 2, name: 'tell_me_a_joke', title: 'Tittle', code: 'Code' },
	{ id: 3, name: 'seal_a_business_deal', title: 'Tittle', code: 'Code' },
	{
		id: 4,
		name: 'delicious_fruit_dessert_recipes',
		title: 'Tittle',
		code: 'Code',
	},
];

export interface ITemplatesData {
	id: number;
	name: string;
	title: string;
	code: string;
}
