import { ArrowBackSvg, PointsSvg } from 'assets/svg-icon';
import useOutsideClick from 'hooks/useOutsideClick';
import { ChangeEvent, FC, MouseEvent, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import zip from '../../../assets/images/zip.png';
import track from '../../../assets/images/track.png';
import avatar from '../../../assets/images/avat.png';
import { Input } from 'ui/Input';
import { PopUpWrapper } from 'ui/PopUpWrapper/PopUpWrapper';
import { Popup } from 'ui/Popup/Popup';
import { SvgArrowDownDownload } from 'ui/svg-image/SvgAllChannels';
import { onButtonNameContent, onTitlePopUpAlbum } from 'untils/popUpWrapperFunc';

export const AlbumDetails: FC = () => {
	const navigate = useNavigate();
	const [popup, setPopup] = useState<number>(0);
	const ref = useRef<HTMLButtonElement | null>(null);
	const [namePopup, setNamePopup] = useState<string>('');
	const [name, setName] = useState<string>('');

	const onPopup = (value: number) => {
		setPopup(value);
	};

	useOutsideClick(ref, () => onPopup(0));

	const onTitlePopUp = () => {};

	const isOpenModal = (event?: MouseEvent) => {
		const { id } = event?.target as HTMLButtonElement;

		if (id.toLowerCase() === 'edit') {
			navigate(`${ELinksName.EDIT_TRACK}/${43534534534543}`);
		}

		setNamePopup(id);
	};

	const handleButton = () => {};

	const handleInput = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;

		setName(value);
	};

	return (
		<div className="main-content">
			{namePopup && (
				<PopUpWrapper
					isOpenModal={isOpenModal}
					handleButton={handleButton}
					title={onTitlePopUpAlbum(namePopup)}
					btnName={onButtonNameContent(namePopup)}
				>
					{namePopup !== 'Delete' ? (
						<div style={{ width: '340px' }}>
							<p
								style={{
									marginTop: '-15px',
									marginBottom: '15px',
								}}
								className="gray-light center"
							>
								By transferring the right you... blah blah blah...text description of the feature.
							</p>
							<Input
								handleInput={handleInput}
								value={name}
								type="text"
								name="word"
								placeholder="Name or Email"
								label="Partner"
								className="padding-bottom"
							/>
						</div>
					) : null}
				</PopUpWrapper>
			)}
			<div className="url color-grey big-first-letter">
				{location.pathname.slice(1).replaceAll('/', ' / ')}
			</div>
			<div className="title-block-wrap flex-content">
				<div className="title-block">
					<div className="back-button pointer" onClick={() => navigate(-1)}>
						<ArrowBackSvg />
					</div>
					<p className="title big-first-letter">Album page</p>
				</div>
				<div className="button-style-wrap tr">
					<a className="button-style" download href="" target="_blank" rel="noopener noreferrer">
						PDF
					</a>
					<div className={popup ? 'active' : ''} style={{ position: 'relative' }}>
						<button
							ref={ref}
							onClick={() => onPopup(popup ? 0 : 1)}
							className="button-style"
							type="button"
						>
							<PointsSvg />
						</button>
						<Popup
							data={['Edit', 'Delete', 'Transfer rights']}
							onPopup={onPopup}
							isOpenModal={isOpenModal}
							className="popup-position"
						/>
					</div>
				</div>
			</div>
			<div className="track-details">
				<div className="track-details__header">
					<div className="track-details__block-img">
						<img src={track} alt="" />
					</div>
				</div>
				<div className="track-details__body">
					<div className="track-details__title">Lost in Time</div>
					<div className="track-details__subtitle">Gorillaz • 3:25 • 14.06.23</div>
					<div className="track-details__desc">
						&quot;Lost in Time&ldquo; is a captivating and introspective ballad that takes you on a
						nostalgic journey through bittersweet memories and the longing for what once was.
					</div>
					<p className="track-details__title-h3">Personal data</p>
					<ul className="track-details__list track-details__list--padding">
						<li className="track-details__item ">
							<span>First name</span>
							<span className="blue">Albert</span>
						</li>
						<li className="track-details__item ">
							<span>Last name</span>
							<span className="blue">Flores</span>
						</li>
						<li className="track-details__item ">
							<span>Partner Username</span>
							<span className="blue">@Starbucks</span>
						</li>
						<li className="track-details__item ">
							<span>Email</span>
							<span className="blue">Albert</span>
						</li>
					</ul>
					<p className="track-details__title-h3">Track data</p>
					<ul className="track-details__list track-details__list--padding">
						<li className="track-details__item ">
							<span>Title</span>
							<span className="blue">Lost in Time</span>
						</li>
						<li className="track-details__item ">
							<span>Nickname or group name</span>
							<span className="blue">@Starbucks</span>
						</li>
						<li className="track-details__item ">
							<span>Genre</span>
							<span className="blue">Rock, Pop</span>
						</li>
						<li className="track-details__item ">
							<span>Record label</span>
							<span className="blue">Nintendo</span>
						</li>
						<li className="track-details__item ">
							<span>Release date</span>
							<span className="blue">Sep 24, 2022</span>
						</li>
						<li className="track-details__item ">
							<span>Key Tags</span>
							<span className="blue">Rock, Pop</span>
						</li>
					</ul>

					<p className="track-details__title-h3">4 Tracks</p>
					<ul className="track-details__list track-details__list--padding">
						<li className="track-details__item">
							<span>Track 1</span>
							<span className="blue flex-content-gap">
								<img src={avatar} alt="" />
								<span>Abstergo Ltd.</span>
							</span>
						</li>
						<li className="track-details__item">
							<span>Track 2</span>
							<span className="blue flex-content-gap">
								<img src={avatar} alt="" />
								<span>Abstergo Ltd.</span>
							</span>
						</li>
						<li className="track-details__item">
							<span>Track 3</span>
							<span className="blue flex-content-gap">
								<img src={avatar} alt="" />
								<span>Abstergo Ltd.</span>
							</span>
						</li>
						<li className="track-details__item">
							<span>Track 4</span>
							<span className="blue flex-content-gap">
								<img src={avatar} alt="" />
								<span>Abstergo Ltd.</span>
							</span>
						</li>
					</ul>

					<p className="track-details__title-h3">Adress data</p>
					<ul className="track-details__list track-details__list--padding">
						<li className="track-details__item ">
							<span>Your address</span>
							<span className="blue">3517 W. Gray St. Utica, Pennsylvania 57867</span>
						</li>
						<li className="track-details__item ">
							<span>Street</span>
							<span className="blue">Gray</span>
						</li>
						<li className="track-details__item ">
							<span>City</span>
							<span className="blue">Utica</span>
						</li>
						<li className="track-details__item ">
							<span>Region</span>
							<span className="blue">Pennsylvania</span>
						</li>
						<li className="track-details__item ">
							<span>Postal code</span>
							<span className="blue">57867</span>
						</li>
					</ul>
				</div>
				<div className="track-details__footer">
					<p className="track-details__title-h3">Copyright documents</p>
					<div className="track-details__file-wrap">
						<div className="track-details__file-info">
							<img src={zip} alt="" className="track-details__img" />
							<div className="track-details__name">Name example.zip</div>
						</div>
						<SvgArrowDownDownload />
					</div>
				</div>
			</div>
		</div>
	);
};
