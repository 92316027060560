/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ETransaction } from 'services/constants/status';
import { EChannelUpgradeType } from 'services/constants/type';
import {
	ITransactionState,
	ITreasuryRequest,
	ITreasuryResponse,
	IUpgradeWithdrawalRequest,
	IWithdrawalRequest,
	IWithdrawalResponse,
} from './types';

export const initialState: ITransactionState = {
	withdrawal: null,
	treasury: null,
	transactionsLoader: false,
};

const transaction = createSlice({
	name: '@@transaction',
	initialState,
	reducers: {
		getWithdrawalRequest: (state, action: PayloadAction<IWithdrawalRequest>) => {
			const withdrawalState = state;
			withdrawalState.transactionsLoader = true;
		},

		getWithdrawalSuccess: (state, action: PayloadAction<IWithdrawalResponse>) => {
			const withdrawalState = state;
			const { payload } = action;
			withdrawalState.transactionsLoader = false;
			withdrawalState.withdrawal = payload;
		},

		getAdminTreasuryRequest: (state, action: PayloadAction<ITreasuryRequest>) => {
			const treasuryState = state;
			treasuryState.transactionsLoader = true;
		},

		getAdminTreasurySuccess: (state, action: PayloadAction<ITreasuryResponse>) => {
			const treasuryState = state;
			const { payload } = action;
			treasuryState.transactionsLoader = false;
			treasuryState.treasury = payload;
		},

		putTransactionsApproveRequest: (state, action: PayloadAction<IUpgradeWithdrawalRequest>) => {},

		putTransactionsApproveSuccess: (state, action: PayloadAction<IUpgradeWithdrawalRequest>) => {
			const withdrawalState = state;
			const { payload } = action;
			if (withdrawalState.withdrawal) {
				withdrawalState.withdrawal.data.forEach((el) => {
					if (el.id === payload.transaction_id) el.status = ETransaction.COMPLETED;
				});
			}
		},

		putTransactionsRejectRequest: (state, action: PayloadAction<IUpgradeWithdrawalRequest>) => {},

		putTransactionsRejectSuccess: (state, action: PayloadAction<IUpgradeWithdrawalRequest>) => {
			const withdrawalState = state;
			const { payload } = action;
			if (withdrawalState.withdrawal) {
				withdrawalState.withdrawal.data.forEach((el) => {
					if (el.id === payload.transaction_id) el.status = ETransaction.REJECTED;
				});
			}
		},

		transactionInitState: (state) => {
			const transactionInitState = state;
			transactionInitState.transactionsLoader = false;
		},
	},
});

export default transaction.reducer;
export const {
	getWithdrawalRequest,
	getWithdrawalSuccess,
	getAdminTreasuryRequest,
	getAdminTreasurySuccess,
	putTransactionsApproveRequest,
	putTransactionsApproveSuccess,
	putTransactionsRejectRequest,
	putTransactionsRejectSuccess,
	transactionInitState,
} = transaction.actions;
