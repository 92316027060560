import { FC } from 'react';
import { useSwiper } from 'swiper/react';
import { ISlideNextButton } from '../types';

const SlideNextButton: FC<ISlideNextButton> = ({ nextImg, startImg, numberImg }) => {
	const swiper = useSwiper();

	return (
		<button
			// disabled={startImg === numberImg}
			onClick={() => {
				swiper.slideNext();
				nextImg();
			}}
			type="button"
			className="slider-arrow slider-arrow--next"
		>
			<svg
				className="fill"
				width="16"
				height="28"
				viewBox="0 0 16 28"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0.93934 0.93934C1.52513 0.353553 2.47487 0.353553 3.06066 0.93934L15.0607 12.9393C15.6464 13.5251 15.6464 14.4749 15.0607 15.0607L3.06066 27.0607C2.47487 27.6464 1.52513 27.6464 0.93934 27.0607C0.353553 26.4749 0.353553 25.5251 0.93934 24.9393L11.8787 14L0.93934 3.06066C0.353553 2.47487 0.353553 1.52513 0.93934 0.93934Z"
					fill="white"
				/>
			</svg>
		</button>
	);
};

export default SlideNextButton;
