import { FC } from 'react';
import avatar from 'assets/images/avatar-square.png';
import { SvgArrowUpHeader, SvgArrowDownHeader } from 'ui/svg-image/SvgAllChannels';
import { ESubtabsConversation } from 'services/constants/lists-table';
import { useParams } from 'react-router-dom';
import { IGroupsResponse } from 'redux/reducers/users/types';
import moment from 'moment';
import { Loader } from 'components/Loader';

interface IProps {
	tableDate: IGroupsResponse;
	loader: boolean;
}

export const Groups: FC<IProps> = ({ tableDate, loader }) => {
	const { subTab } = useParams();
	const chats = tableDate?.data?.chats;

	const onSwitch = () => {
		switch (subTab) {
			case ESubtabsConversation.CONVERSATIONS_CHANNELS:
				return 'Became a member date';
			case ESubtabsConversation.CONVERSATIONS_GROUP:
				return 'Created date';
			default:
				return 'Became a admin date';
		}
	};

	return (
		<div className="table table--conversations-channels">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>Avatar</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p> Groups name</p>
							<div className="sort-td">
								<button type="button">
									<SvgArrowUpHeader />
								</button>
								<button type="button">
									<SvgArrowDownHeader />
								</button>
							</div>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p> Groups URL</p>
							<div className="sort-td">
								<button type="button">
									<SvgArrowUpHeader />
								</button>
								<button type="button">
									<SvgArrowDownHeader />
								</button>
							</div>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p># Members</p>
							<div className="sort-td">
								<button type="button">
									<SvgArrowUpHeader />
								</button>
								<button type="button">
									<SvgArrowDownHeader />
								</button>
							</div>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Status</p>
							<div className="sort-td">
								<button type="button">
									<SvgArrowUpHeader />
								</button>
								<button type="button">
									<SvgArrowDownHeader />
								</button>
							</div>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>{onSwitch()}</p>
							<div className="sort-td">
								<button type="button">
									<SvgArrowUpHeader />
								</button>
								<button type="button">
									<SvgArrowDownHeader />
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="table-body">
				{loader ? (
					<Loader />
				) : (
					chats?.map((chat) => (
						<div key={chat.id} className="tr">
							<div className="td">
								<p className="td-hidden-name"> Avatar</p>
								<div className="avatar-wrapper">
									<div className="avatar avatar--circle">
										<img src={chat.photo} alt="" />
									</div>
								</div>
							</div>
							<div className="td">
								<p className="td-hidden-name"> Groups name </p>
								<p>
									<a href="/" className="link link--type2">
										{chat.title}
									</a>
								</p>
							</div>
							<div className="td">
								<p className="td-hidden-name"> Groups URL</p>
								<p>{chat.link}</p>
							</div>
							<div className="td">
								<p className="td-hidden-name"> # Members </p>
								<p>
									<p>{chat.participant_count}</p>
								</p>
							</div>
							<div className="td">
								<p className="td-hidden-name">Status</p>
								<p>{chat.pay_type}</p>
							</div>
							<div className="td">
								<p className="td-hidden-name">{onSwitch()}</p>
								<p>{moment(chat.invited_at).format('DD.MM.YYYY, h:mm:ss')}</p>
							</div>
						</div>
					))
				)}
			</div>
		</div>
	);
};
