/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { isAxiosError } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { api, responseErrors } from 'services';
import { notificationContainer } from '../../../untils/notificationContainer';
import {
	loginRequest,
	loginSuccess,
	logoutRequest,
	authInitState,
	forgotRequest,
	resetRequest,
	generateQrSuccess,
	generateQrRequest,
	enable2Fa,
	loginError,
	createPassRequest,
	forgotSuccess,
	resetSuccess,
	refreshRequest,
} from './reducer';
import {
	IAdminAuthUserResponseData,
	IAdminLogoutData,
	IResetReducer,
	IForgotReducer,
	ILoginRequestReducer,
	IResponseQrCode,
	I2faEnableRequest,
	IAuthResponse,
} from './types';
import { IRestFormSubmitValue } from 'pages/Auth/ForgotPass/types';
import { http } from 'services/http';
import { WEB_API_URL } from 'services/constants/env';
import { endpoint } from 'services/endpoint';

// =============================================================:
function* loginRequestWorker({ payload }: PayloadAction<ILoginRequestReducer>) {
	const { paramsApi, setOpenQr } = payload;

	try {
		const response: IAdminAuthUserResponseData = yield call(api.auth.login, paramsApi);
		if (response.message === 'need to change password') {
			notificationContainer(response?.message, 'info');
			yield put(authInitState());
		} else {
			yield put(loginSuccess(response));
		}
	} catch (error) {
		if (isAxiosError(error)) {
			yield put(loginError());

			if (
				error?.response?.data.message === 'no otp data' ||
				error?.response?.data.message === 'token is invalid or user does not exist'
			) {
				yield setOpenQr(error?.response?.data.message);
				// notificationContainer(error?.response?.data.message, 'error');
				yield put(authInitState());
			} else if (error?.response?.data?.errors?.totp) {
				const code: string = error?.response?.data?.errors?.totp[0];
				notificationContainer(code, 'info');
				yield put(authInitState());
			} else {
				responseErrors(error);
				yield put(authInitState());
			}
		}
	} finally {
		yield put(loginError());
	}
}

function* getQrCodeRequestWorker({ payload }: PayloadAction<IRestFormSubmitValue>) {
	try {
		yield put(showLoading());
		const response: IResponseQrCode = yield call(api.auth.getQrCode, payload);
		yield put(generateQrSuccess(response));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* enable2faRequestWorker({ payload }: PayloadAction<I2faEnableRequest>) {
	const { paramsApi, setOpenQr } = payload;

	try {
		yield put(showLoading());
		yield call(api.auth.twoFaEnable, paramsApi);
		yield setOpenQr('true');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* forgotRequestWorker({ payload }: PayloadAction<IForgotReducer>) {
	const { paramsApi, setSuccessfully } = payload;

	try {
		yield put(showLoading());
		yield call(api.auth.forgot, paramsApi);
		yield setSuccessfully(true);
		yield put(forgotSuccess());
	} catch (error) {
		yield put(forgotSuccess());

		if (isAxiosError(error)) {
			if (error?.response?.data?.errors?.totp) {
				const code: string = error?.response?.data?.errors?.totp[0];
				notificationContainer(code, 'info');
				yield put(authInitState());
			} else {
				responseErrors(error);
				yield put(authInitState());
			}
		}
	} finally {
		yield put(hideLoading());
	}
}

function* resetRequestWorker({ payload }: PayloadAction<IResetReducer>) {
	const { paramsApi, setSuccessfully } = payload;

	try {
		yield call(api.auth.resetPass, paramsApi);
		yield setSuccessfully(true);
		yield put(authInitState());
		yield put(resetSuccess());
	} catch (error) {
		if (isAxiosError(error)) {
			if (error?.response?.data?.errors?.totp) {
				const code: string = error?.response?.data?.errors?.totp[0];
				notificationContainer(code, 'info');
				yield put(authInitState());
			} else {
				responseErrors(error);
				yield put(authInitState());
			}
		}
	} finally {
		yield put(hideLoading());
	}
}

function* createPassWorker({ payload }: PayloadAction<IResetReducer>) {
	const { paramsApi, setSuccessfully } = payload;

	try {
		yield put(showLoading());
		yield call(api.auth.createPass, paramsApi);
		yield setSuccessfully(true);
	} catch (error) {
		if (isAxiosError(error)) {
			if (error?.response?.data?.errors?.totp) {
				const code: string = error?.response?.data?.errors?.totp[0];
				notificationContainer(code, 'info');
				yield put(authInitState());
			} else {
				responseErrors(error);
				yield put(authInitState());
			}
		}
	} finally {
		yield put(hideLoading());
		yield put(authInitState());
	}
}

// =============================================================:
function* logoutRequestWorker(action: PayloadAction<IAdminLogoutData>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		// yield call(api.auth.logout);
		yield put(authInitState());
		payload.navigate('');
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* refreshRequestWorker(action: PayloadAction<any>) {
	const { payload } = action;

	try {
		const response: IAuthResponse = {
			access_token: '',
			refresh_token: '',
		};

		// yield put(refreshResponse(response.access_token));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
		yield put(authInitState());
	}
}

// =============================================================:
export function* authSaga() {
	yield takeEvery(loginRequest.type, loginRequestWorker);
	yield takeEvery(forgotRequest.type, forgotRequestWorker);
	yield takeEvery(resetRequest.type, resetRequestWorker);
	yield takeEvery(logoutRequest.type, logoutRequestWorker);
	yield takeEvery(generateQrRequest.type, getQrCodeRequestWorker);
	yield takeEvery(enable2Fa.type, enable2faRequestWorker);
	yield takeEvery(createPassRequest.type, createPassWorker);
	yield takeEvery(refreshRequest.type, refreshRequestWorker);
}
