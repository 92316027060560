import { FC } from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import { ITextareaFieldProps } from './types';

export const TextareaField: FC<ITextareaFieldProps> = ({
	name,
	label,
	className,
	placeholder,
	textareaProps = {},
}) => {
	const [field, meta] = useField(name);
	const isShowError = meta.touched && meta.error;

	return (
		<div className="form-group">
			<div>
				{label && <p className="input-name">{label}</p>}
				<div className={`textarea ${className ? className : ''}`}>
					<textarea {...field} placeholder={placeholder} {...textareaProps} />
				</div>
			</div>
			{isShowError && <p className="input-notification input-notification--error">{meta.error}</p>}
		</div>
	);
};
