import { FC } from 'react';
import { Oval } from 'react-loader-spinner';

export const SmallLoader: FC = () => {
	return (
		<span
			style={{ display: 'flex', justifyContent: 'center' }}
			className="louder-small-wrapper-custom"
		>
			<Oval secondaryColor="grey" color="#fff" height={20} width={20} />
		</span>
	);
};
