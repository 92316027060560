import { FilterSvg, LenSvg, SearchSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { ChangeEvent, FC, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { IUsersRequest } from 'redux/reducers/support/types';

interface IProps {
	forFilter: (value: string) => void;
	onFilter: (values: IUsersRequest) => void;
}

export const SidebarFilter: FC<IProps> = ({ forFilter, onFilter }) => {
	const [search, setSearch] = useState<string>('');

	const onHandleSearch = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		setSearch(value);
		onFilter({ search: value });
	};

	return (
		<div className="sidebar-left__filter">
			<div className={`input input-custom input-search sidebar-left__input`}>
				<div className="input-wrapper">
					<DebounceInput
						className="input-item input-item--left-icon"
						type="text"
						name="search"
						placeholder="Search"
						value={search || ''}
						onChange={onHandleSearch}
						debounceTimeout={500}
					/>
					<span className="input-icon">
						<SearchSvg />
					</span>
				</div>
			</div>
			<div className="sidebar-left__btn-wrap">
				<span onClick={() => forFilter('country')} className="pointer">
					<LenSvg />
				</span>
				<span onClick={() => forFilter('filter')} className="pointer">
					<FilterSvg />
				</span>
			</div>
		</div>
	);
};
