import moment from 'moment';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';

export const TermsBody: FC = () => {
	return (
		<div className="table-body">
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name"> Document title </p>
					<Link to={ELinksName.TERMS_MANAGEMENT_CHANGE}>Privacy Policy</Link>
				</div>
				<div className="td">
					<p className="td-hidden-name"> Link to web page </p>
					<Link to={ELinksName.TERMS_MANAGEMENT_CHANGE}>https://policies.ello.com/egreg455</Link>
				</div>
				<div className="td">
					<p className="td-hidden-name"> Last updated date </p>
					<p>{moment(new Date()).format('MMM DD, YYYY')}</p>
					<p className="gray">{moment(new Date()).format('h:mm A')}</p>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name"> Available languages </p>
					<p>English, Ukrainian</p>
				</div>
			</div>
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name"> Document title </p>
					<Link to={ELinksName.TERMS_MANAGEMENT_CHANGE}>Terms of Service</Link>
				</div>
				<div className="td">
					<p className="td-hidden-name"> Link to web page </p>
					<Link to={ELinksName.TERMS_MANAGEMENT_CHANGE}>https://policies.ello.com/egreg455</Link>
				</div>
				<div className="td">
					<p className="td-hidden-name"> Last updated date </p>
					<p>{moment(new Date()).format('MMM DD, YYYY')}</p>
					<p className="gray">{moment(new Date()).format('h:mm A')}</p>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name"> Available languages </p>
					<p>English, Ukrainian</p>
				</div>
			</div>
		</div>
	);
};
