import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IAiServicesStore } from './types';

const getChannelsState = (state: IStoreState): IAiServicesStore => state.aiServicese;

export const getForbiddenWordsSelector = createSelector(
	[getChannelsState],
	(aiServicese: IAiServicesStore) => aiServicese.listWords,
);
