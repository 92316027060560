import classNames from 'classnames';
import { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { SvgBigSidebar, SvgSmallSidebar } from 'ui/svg-image/SvgSidebar';
import { listLinksSidebar } from './sidebarData';
import { SearchSvg } from 'assets/svg-icon';
import { getUserData } from 'redux/reducers/auth/selectors';
import { setOpenSidebar } from 'redux/reducers/app/reducer';
import { getSidebar } from 'redux/reducers/app/selectors';

export const Sidebar: FC = () => {
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const user = useSelector(getUserData);
	const [open, setOpen] = useState(false);
	const sidebar = useSelector(getSidebar);

	const onCloseSidebar = () => {
		dispatch(setOpenSidebar(true));
	};

	return (
		<div
			className={classNames('sidebar', {
				'sidebar-phone': sidebar,
				'minimized-custom': open,
			})}
		>
			<div className="sidebar-top">
				<button onClick={() => setOpen(!open)} type="button" className="sidebar__action">
					{!open ? <SvgSmallSidebar /> : <SvgBigSidebar />}
				</button>
				<nav className="sidebar-nav">
					<ul className="sidebar-nav__list">
						<div className="input input-custom input-search input-opacity-mob">
							<div className="input-wrapper">
								<input
									className="input-item input-item--left-icon"
									type="text"
									name="search"
									placeholder="Search"
								/>
								<span className="input-icon">
									<SearchSvg />
								</span>
							</div>
						</div>
						{listLinksSidebar.map((link) =>
							link.role.includes(user?.role?.toLowerCase() as string) ? (
								<li key={link.id}>
									<Link
										onClick={onCloseSidebar}
										to={link.link}
										className={classNames({
											active: pathname === link.link || pathname.includes(link?.links_name || ' '),
											// ||
											// (link.link.includes(pathname.slice(1, pathname.slice(1).indexOf('/'))) &&
											// 	pathname[pathname.lastIndexOf('/') + 1])
										})}
									>
										<span className="sidebar-nav-icon">
											<link.svg />
										</span>
										{!open && <span className="sidebar-nav-text">{link.linkName}</span>}
									</Link>
								</li>
							) : null,
						)}
					</ul>
				</nav>
			</div>
		</div>
	);
};
