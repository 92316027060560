import classNames from 'classnames';
import { FC } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EGroupsTabs, EMediaSubtabs } from 'services/constants/lists-table';

export const GroupsTabs: FC = () => {
	const params = useParams();

	return (
		<div className="tabs-buttons-nav">
			<Link
				to={`${ELinksName.GROUPS_DETAILS}/${params.id}/${EGroupsTabs.GROUPS_DATA}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EGroupsTabs.GROUPS_DATA || !params.tab,
				})}
			>
				Groups data
			</Link>
			<Link
				to={`${ELinksName.GROUPS_DETAILS}/${params.id}/${EGroupsTabs.ADMINS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EGroupsTabs.ADMINS,
				})}
			>
				Admins
			</Link>
			<Link
				to={`${ELinksName.GROUPS_DETAILS}/${params.id}/${EGroupsTabs.MEMBERS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EGroupsTabs.MEMBERS,
				})}
			>
				Members
			</Link>
			<Link
				to={`${ELinksName.GROUPS_DETAILS}/${params.id}/${EGroupsTabs.MESSAGES}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EGroupsTabs.MESSAGES,
				})}
			>
				Messages
			</Link>
			<Link
				to={`${ELinksName.GROUPS_DETAILS}/${params.id}/${EGroupsTabs.MEDIA_AND_FILES}/${EMediaSubtabs.MEDIA}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EGroupsTabs.MEDIA_AND_FILES,
				})}
			>
				Media and files
			</Link>
		</div>
	);
};
