import { ArrowDownGraySvg, ArrowUpGraySvg, EditSmallSvg } from 'assets/svg-icon';
import { Accordion } from 'components/AIChatBotComponents/Structure/Accordion/Accordion';
import { IStructureData } from 'components/AIChatBotComponents/Structure/data';
import { FC, MouseEvent, useState } from 'react';
import { IButtonBot, IButtons } from 'redux/reducers/ai-chat-bot/types';
import { firstUppercaseLetter } from 'untils/firstUppercaseLetter';

interface IProps {
	buttons: Array<IStructureData>;
	onEditButton: (name: { [key: string]: string }, option: number, key: string) => void;
	onEditCommand?: (name: { [key: string]: string }, option: number, key: string) => void;
	keyBtn: string | undefined;
}

export const Structure: FC<IProps> = ({ onEditButton, buttons, keyBtn }) => {
	const [open, setOpen] = useState<string>('');
	const buttonsNew: any = buttons;

	const handleButton = (event: MouseEvent) => {
		const { id } = event.currentTarget as HTMLDivElement;
		setOpen(id === open ? '' : id);
	};

	return (
		<div className="structure">
			{buttons.map((button) => (
				<div key={button.id} className="structure__box">
					<div id={String(button.name)} onClick={handleButton} className="structure__header">
						<div className="structure__name">
							<div className="structure__line">
								<span></span>
								<span></span>
							</div>
							<span>{firstUppercaseLetter(button.name.replaceAll('_', ' '))}</span>
							<EditSmallSvg />
						</div>
						<div className="structure__arrow">
							{open === String(button.name) ? <ArrowUpGraySvg /> : <ArrowDownGraySvg />}
						</div>
					</div>
					{open === String(button.name) ? (
						<Accordion
							onEditButton={onEditButton}
							structure={buttonsNew as Array<IButtons>}
							keyBtn={keyBtn}
						/>
					) : null}
				</div>
			))}
		</div>
	);
};
