import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const handleDownload = (json: any) => {
	// Створюємо нову книгу
	const workbook = XLSX.utils.book_new();

	// Конвертуємо JSON у лист (sheet)
	const worksheet = XLSX.utils.json_to_sheet(json);

	// Додаємо лист до книги
	XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

	// Генеруємо файл Excel
	const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

	// Створюємо Blob для збереження файлу
	const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

	// Зберігаємо файл
	saveAs(blob, `list.xlsx`);
};
