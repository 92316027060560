import { FC, useEffect } from 'react';

interface IProps {
	title?: string;
	subtitle?: string;
	btnNameOne?: string;
	btnNameTwo?: string;
	setText?: (value: string) => void;
	onClick: () => void;
}

export const PopUpAsk: FC<IProps> = ({
	setText,
	title,
	subtitle,
	btnNameOne,
	btnNameTwo,
	onClick,
}) => {
	const height =
		window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

	const onClose = () => {
		setText?.('');
	};

	// const heightPopup = (): number => {
	// 	return document.querySelector('.authorization-form')!?.clientHeight;
	// };

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = '';
		};
	}, []);

	return (
		<div className="blur-wrapper-popup">
			<div style={{ top: height / 2 - 240 / 2 }} className="authorization-form fit-content">
				<p className="form-title">{title}</p>
				<p className="form-subtitle">{subtitle}</p>
				<div className="authorization-form__body">
					<div className="form-submit">
						<button onClick={onClick} type="button" className="button button--248-width ">
							{btnNameOne}
						</button>
					</div>
					<div className="form-submit">
						<button
							onClick={onClose}
							type="button"
							className="button button--gray button--248-width "
						>
							{btnNameTwo}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
