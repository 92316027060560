export enum ETransaction {
	PENDING = 'pending',
	COMPLETED = 'completed',
	REJECTED = 'rejected',
}

export enum EREPORTS {
	BLOCKED = 'blocked',
	DECLINED = 'declined',
	REPORTED = 'reported',
	POSTED = 'posted',
}
