import { store } from 'redux/store';
import { getAuth } from 'redux/reducers/auth/selectors';
import { IAxiosRequestConfig } from './types';

// ==========================================:
const onSuccessMultipart = (config: IAxiosRequestConfig) => {
	const requestConfig = config;
	const state: any = store.getState();
	const auth = getAuth(state);

	if (auth && auth.accessToken) {
		requestConfig.headers = {
			Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
			'Content-Type': 'multipart/form-data',
		};
	}

	return requestConfig;
};

export default onSuccessMultipart;
