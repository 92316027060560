import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAdminsState, IAdminsResponse, IAdminsRequest } from './types';
import {
	I2FaRequest,
	IAddNewUserSlice,
	IAdmin,
	IChangeAdmin,
	IDeleteUser,
	IDeleteUserSlice,
} from 'ui/AddNewAdmins/type';
import { string } from 'yargs';

export const initialState: IAdminsState = {
	adminsList: null,
	admin: null,
	adminsLoader: false,
};

const admins = createSlice({
	name: '@@admins',
	initialState,
	reducers: {
		getAdminsListRequest: (state, action: PayloadAction<IAdminsRequest>) => {
			const usersListState = state;
			usersListState.adminsLoader = true;
		},

		getAdminsListSuccess: (state, action: PayloadAction<Array<IAdminsResponse> | null>) => {
			const usersListState = state;
			const { payload } = action;
			usersListState.adminsLoader = false;
			usersListState.adminsList = payload;
		},

		getAdminRequest: (state, action: PayloadAction<IAdmin>) => {
			const usersListState = state;
			usersListState.adminsLoader = true;
		},

		getAdminSuccess: (state, action: PayloadAction<IAdminsResponse | null>) => {
			const usersListState = state;
			const { payload } = action;
			usersListState.adminsLoader = false;
			usersListState.admin = payload;
		},

		createAdminRequest: (state, action: PayloadAction<IAddNewUserSlice>) => {
			const usersListState = state;
			usersListState.adminsLoader = true;
		},

		createAdminSuccess: (state) => {
			const usersListState = state;
			usersListState.adminsLoader = false;
		},

		changeAdminRequest: (state, action: PayloadAction<IChangeAdmin>) => {
			const usersListState = state;
			usersListState.adminsLoader = true;
		},

		changeAdminSuccess: (state) => {
			const usersListState = state;
			usersListState.adminsLoader = false;
		},

		deleteAdminRequest: (state, action: PayloadAction<IDeleteUserSlice>) => {
			const usersListState = state;
			usersListState.adminsLoader = true;
		},

		deleteAdminSuccess: (state, { payload }: PayloadAction<number>) => {
			const usersListState = state;
			usersListState.adminsLoader = false;
			if (usersListState.adminsList) {
				usersListState.adminsList = usersListState.adminsList.filter(
					(admin) => admin.id !== payload,
				);
			}
		},

		enableOrDisable2FaAdmin: (state, action: PayloadAction<I2FaRequest>) => {
			const usersListState = state;
			usersListState.adminsLoader = true;
		},

		getCSVRequest: (state) => {
			const usersListState = state;
			usersListState.adminsLoader = true;
		},

		adminsInitState: (state) => {
			const usersInitState = state;
			usersInitState.adminsLoader = false;
		},
	},
});

export default admins.reducer;
export const {
	getAdminsListRequest,
	getAdminsListSuccess,
	createAdminRequest,
	createAdminSuccess,
	getAdminRequest,
	getAdminSuccess,
	changeAdminRequest,
	changeAdminSuccess,
	deleteAdminRequest,
	deleteAdminSuccess,
	enableOrDisable2FaAdmin,
	getCSVRequest,
	adminsInitState,
} = admins.actions;
