import { NotificationSvg, SearchSvg } from 'assets/svg-icon';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { MenuPopup } from 'ui/MenuPopup/MenuPopup';
import logo from '../../assets/images/logo.svg';
import { getUserData } from 'redux/reducers/auth/selectors';
import { setOpenSidebar } from 'redux/reducers/app/reducer';
import { getSidebar } from 'redux/reducers/app/selectors';

export const Header: FC = () => {
	const dispatch = useDispatch();
	const sidebar = useSelector(getSidebar);
	const user = useSelector(getUserData);

	const onOpenSidebar = () => {
		dispatch(setOpenSidebar(sidebar));
	};

	return (
		<header className="header">
			<button onClick={onOpenSidebar} type="button" className="mob-nav-btn">
				<svg className="fill" viewBox="0 0 448 512">
					<path
						fill="currentColor"
						d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
					/>
				</svg>
			</button>
			<Link to={ELinksName.DASHBOARD} className="header__logo header__logo--custom">
				<img src={logo} alt="" />
			</Link>
			<div className="input input-custom input-search input-opacity">
				<div className="input-wrapper">
					<input
						className="input-item input-item--left-icon"
						type="text"
						name="search"
						placeholder="Search"
					/>
					<span className="input-icon">
						<SearchSvg />
					</span>
				</div>
			</div>

			<div className="admin">
				<div className="admin-notification">
					<div className="notification-red"></div>
					<NotificationSvg />
				</div>
				<p className="admin__name">{user?.first_name + ' ' + user?.last_name}</p>
				<MenuPopup />
			</div>
		</header>
	);
};
