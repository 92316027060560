import { FC, useState } from 'react';
import { ArrowBackSvg, PhotoSvg } from 'assets/svg-icon';
import './ElloPartners.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { ElloPartnersTabs } from 'components/ElloPartnersComponents/ElloPartnersTabs';
import { Fillter } from 'ui/Fillter/Fillter';
import { IDataFiltter } from '../Channels';
import { ElloPartnersSubTabs } from 'components/ElloPartnersComponents/ElloPartnersSubTabs/ElloPartnersSubTabs';
import { CurrentUserHeader } from 'components/ElloPartnersComponents/CurrentUserHeader/CurrentUserHeader';
import { CurrentUserBody } from 'components/ElloPartnersComponents/CurrentUserBody/CurrentUserBody';
import { PartnersRequestBody } from 'components/ElloPartnersComponents/PartnersRequest/PartnersRequestBody/PartnersRequestHeader';
import { PartnersRequestHeader } from 'components/ElloPartnersComponents/PartnersRequest/PartnersRequestHeader/PartnersRequestHeader';

export const ElloPartners: FC = () => {
	const navigate = useNavigate();
	const params = useParams();

	const [dataFiltter, setDataFiltter] = useState<IDataFiltter>({
		country_code: '',
		current_page: 1,
		date_from: '',
		date_to: '',
		search: '',
		type: params.subTab?.replace('-profile', '') || 'personal',
	});

	const definePage = () => {
		switch (params.tab) {
			case 'request':
				return (
					<>
						<PartnersRequestHeader />
						<PartnersRequestBody />
					</>
				);

			default:
				return (
					<>
						<CurrentUserHeader />
						<CurrentUserBody />
					</>
				);
		}
	};

	return (
		<div className="main-content">
			<div className="title-block-wrap flex-content">
				<div className="title-block">
					<div className="back-button pointer" onClick={() => navigate(-1)}>
						<ArrowBackSvg />
					</div>
					<p className="title big-first-letter">Ello Partners</p>
				</div>
			</div>
			<ElloPartnersTabs />
			<div className="table-block ello-part">
				{params?.tab?.includes('request') ? null : <ElloPartnersSubTabs />}
				<Fillter keyStatus="status" keyType="types" setDataFiltter={setDataFiltter} />

				<div className="table-wrapper">
					<div
						className={`table table--${
							params.tab === 'request' ? 'partners-request' : 'partners-management'
						}`}
					>
						{definePage()}
					</div>
				</div>
			</div>
		</div>
	);
};
