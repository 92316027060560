import { FC } from 'react';
import { SubscriptionsHeader } from './SubscriptionsHeader/SubscriptionsHeader';
import { SubscriptionsBody } from './SubscriptionsBody/SubscriptionsBody';
import { useParams } from 'react-router-dom';
import { ESubscriptionTabs } from 'services/constants/lists-table';

export const Subscriptions: FC = () => {
	const { subTab } = useParams();

	return (
		<div
			className={`"table table--subscribers-customers${
				subTab === ESubscriptionTabs.AI ? '-ui' : ''
			} table-padding"`}
		>
			<SubscriptionsHeader />
			<SubscriptionsBody />
		</div>
	);
};
