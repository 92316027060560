import { PayloadAction } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import {
	getAdminTreasuryRequest,
	getAdminTreasurySuccess,
	getWithdrawalRequest,
	getWithdrawalSuccess,
	putTransactionsApproveRequest,
	putTransactionsApproveSuccess,
	putTransactionsRejectRequest,
	putTransactionsRejectSuccess,
	transactionInitState,
} from './reducer';
import {
	ITreasuryRequest,
	ITreasuryResponse,
	IUpgradeWithdrawalRequest,
	IWithdrawalRequest,
	IWithdrawalResponse,
} from './types';
// import { notificationContainer } from 'services/utils/notificationContainer';

function* getWithdrawalWorker(action: PayloadAction<IWithdrawalRequest>) {
	const { payload } = action;

	try {
		const response: IWithdrawalResponse = yield call(api.transaction.getWithdrawal, payload);
		yield put(getWithdrawalSuccess(response));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(transactionInitState());
	}
}

function* getAdminTreasuryWorker(action: PayloadAction<ITreasuryRequest>) {
	const { payload } = action;

	try {
		const response: ITreasuryResponse = yield call(api.transaction.getAdminTreasury, payload);
		yield put(getAdminTreasurySuccess(response));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(transactionInitState());
	}
}

function* transactionsApproveWorker(action: PayloadAction<IUpgradeWithdrawalRequest>) {
	const { payload } = action;

	try {
		yield call(api.transaction.transactionsApprove, payload);
		yield put(putTransactionsApproveSuccess(payload));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(transactionInitState());
	}
}

function* transactionsRejectWorker(action: PayloadAction<IUpgradeWithdrawalRequest>) {
	const { payload } = action;

	try {
		yield call(api.transaction.transactionsReject, payload);
		yield put(putTransactionsRejectSuccess(payload));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(transactionInitState());
	}
}

export function* transactionSaga() {
	yield takeEvery(getWithdrawalRequest.type, getWithdrawalWorker);
	yield takeEvery(getAdminTreasuryRequest.type, getAdminTreasuryWorker);
	yield takeEvery(putTransactionsApproveRequest.type, transactionsApproveWorker);
	yield takeEvery(putTransactionsRejectRequest.type, transactionsRejectWorker);
}
