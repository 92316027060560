import {
	IAdminsChannelsRequest,
	IChannelsRequest,
	IDeactivateRequest,
	IDeleteRequest,
	IMediaFilesListRequest,
	IMembersRequest,
	IMoveTopRequest,
	IPostRequest,
	IPostResponse,
	IRecommendedRequest,
	IStatisticRequest,
	ITopCannelRequest,
	IVerifiedREquest,
} from 'redux/reducers/channels/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IAdmins, IChannelsApi, IMediaSails, IMembers, IPosts } from './types';

export const channels: IChannelsApi = {
	channelsList: (payload: IChannelsRequest) =>
		http.post(endpoint.channels.CHANNELS_LIST, payload).then((response) => response.data),
	getStatistic: (payload: IStatisticRequest) =>
		http.post(endpoint.channels.STATISTIC, payload).then((response) => response.data),
	getDetailsChanel: (id: string) =>
		http.get(endpoint.channels.DETAILS_CHANNEL(id)).then((response) => response.data),
	postVerifiedAccountChanel: (payload: IVerifiedREquest) =>
		http.post(endpoint.channels.VERIFY_CHANNELS, payload).then((response) => response.data),
	postChangeRecommended: (payload: IRecommendedRequest) =>
		http.post(endpoint.channels.RECOMMEND_CHANNELS, payload).then((response) => response.data),
	blockChannel: (payload: IDeactivateRequest) =>
		http.post(endpoint.channels.DEACTIVATE, payload).then((response) => response.data),
	deleteChannel: (payload: IDeleteRequest) =>
		http.delete(endpoint.channels.DELETE_CHANNEL(payload)).then((response) => response.data),
	getMembers: ({ paramsApi, id }: IMembers) =>
		http.post(endpoint.channels.MEMBERS(id), paramsApi).then((response) => response.data),
	getAdmins: ({ paramsApi, id }: IAdmins) =>
		http.post(endpoint.channels.ADMINS(id), paramsApi).then((response) => response.data),
	getMediaSails: ({ paramsApi, id }: IMediaSails) =>
		http.post(endpoint.channels.MEDIA_SALES(id), paramsApi).then((response) => response.data),
	getPosts: ({ paramsApi, id }: IPosts) =>
		http.post(endpoint.channels.POSTS(id), paramsApi).then((response) => response.data),
	getPost: (payload: IPostRequest) =>
		http
			.get(endpoint.channels.POST_DETAILS(payload.channel_id, payload.post_id))
			.then((response) => response.data),
	getPostComments: ({ paramsApi, body }) =>
		http
			.post(endpoint.channels.POST_COMENTS(paramsApi.channel_id, paramsApi.post_id), body)
			.then((response) => response.data),
	deletePost: (payload: IPostRequest) =>
		http
			.delete(endpoint.channels.POST_DETAILS(payload.channel_id, payload.post_id))
			.then((response) => response.data),
	postMediaFiles: ({ channel_id, body }: IMediaFilesListRequest) =>
		http
			.post(endpoint.channels.POST_MEDIA_FILE(channel_id), body)
			.then((response) => response.data),
	getMediaFile: (payload) =>
		http
			.get(endpoint.channels.GET_MEDIA_FILE(payload.channel_id, payload.file_id))
			.then((response) => response.data),
	getTopChannels: () =>
		http.get(endpoint.channels.CHANNELS_RECOMMEND_TOP_LIST).then((response) => response.data),
	addTopChannel: (payload: ITopCannelRequest) =>
		http
			.post(endpoint.channels.CHANNELS_RECOMMEND_ADD_TOP, payload)
			.then((response) => response.data),
	removeTopChannel: (payload: ITopCannelRequest) =>
		http
			.post(endpoint.channels.CHANNELS_RECOMMEND_REMOVE_TOP, payload)
			.then((response) => response.data),
	moveTopChannel: (payload: IMoveTopRequest) =>
		http.post(endpoint.channels.RECOMMEND_MOVE_TOP, payload).then((response) => response.data),
};
