import { FC, useState } from 'react';
import { Button } from 'ui/Button/Button';
import './AiBot.scss';
import { SvgArrowDownHeader, SvgArrowUpHeader } from 'ui/svg-image/SvgAllChannels';

export const AiBot: FC = () => {
	const [popup, setPopup] = useState<boolean>(false);

	const isOpenModal = () => {
		setPopup(!popup);
	};

	return (
		<div className="ai-management">
			<div className="ai-management__header">
				<span>Previous report</span>
				<div>
					<Button
						className="button-big-wrapper"
						onClick={isOpenModal}
						type={'button'}
						name={'Block'}
					/>
				</div>
			</div>
			<div className="ai-bot-body">
				<div className="ai-bot-status">
					<span className="ai-bot-status">Current status:</span>
					<span className="ai-bot-status-active">Active</span>
				</div>
				<div className="ai-bot-status">
					<span className="ai-bot-status">User payed Ai sevices:</span>
					<span className="ai-bot-status-active">TXT sevice monthly</span>
				</div>
				<div className="ai-bot-title">Block history</div>
				<div className="table table--ai-bot">
					<div className="ai-settings__content">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Username</p>
										<div className="sort-td">
											<button type="button">
												<SvgArrowUpHeader />
											</button>
											<button type="button">
												<SvgArrowDownHeader />
											</button>
										</div>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>AI Type</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Date</p>
										<div className="sort-td">
											<button type="button">
												<SvgArrowUpHeader />
											</button>
											<button type="button">
												<SvgArrowDownHeader />
											</button>
										</div>
									</div>
								</div>
								<div className="td td--right-custom">
									<div className="td-name">
										<p>Request</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">
										<p>Approved violation Date</p>
										<div className="sort-td">
											<button type="button">
												<SvgArrowUpHeader />
											</button>
											<button type="button">
												<SvgArrowDownHeader />
											</button>
										</div>
									</div>
								</div>
								<div className="td td--right-custom">
									<div className="td-name">
										<p>Duration</p>
										<div className="sort-td">
											<button type="button">
												<SvgArrowUpHeader />
											</button>
											<button type="button">
												<SvgArrowDownHeader />
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{[1, 2, 3].map((el) => (
								<div key={el} className="tr">
									<div className="td">
										<p className="td-hidden-name">Username</p>
										<p>@Cooper</p>
									</div>
									<div className="td">
										<p className="td-hidden-name">AI Type</p>
										<p>Text</p>
									</div>
									<div className="td">
										<p className="td-hidden-name">Date</p>
										<p className="td-hidden-name">Approved violation Date</p>
										<div>Sep 24, 2022</div> <div className="gray">3:06 PM</div>
									</div>
									<div className="td td--right">
										<p className="td-hidden-name">Request</p>
										<p>
											Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.{' '}
										</p>
									</div>
									<div className="td td--right">
										<p className="td-hidden-name">Approved violation Date</p>
										<div>Sep 24, 2022</div> <div className="gray">3:06 PM</div>
									</div>
									<div className="td td--right-custom">
										<p className="td-hidden-name">Duration</p>
										<p>14 Day</p>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
