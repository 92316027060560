/* eslint-disable @typescript-eslint/no-misused-promises */
import { FC, useEffect, useState } from 'react';
import type HCaptcha from '@hcaptcha/react-hcaptcha';
import { useTranslation } from 'react-i18next';
import useCaptcha from 'hooks/useCaptcha';
import { Captcha } from '../Captcha/Captcha';

interface IProps {
	onActiveCaptcha: (value: boolean) => void;
	errorCaptcha: string | undefined;
	captchaRef: any;
}

export const CaptchaWrapper: FC<IProps> = ({ onActiveCaptcha, errorCaptcha, captchaRef }) => {
	const { t } = useTranslation();
	const [token, setToken] = useState<HCaptcha | string | null>(null);
	const [error, setError] = useState('');
	const onLoad = useCaptcha();

	useEffect(() => {
		token && onActiveCaptcha(true);
	}, [token]);

	return (
		<div className="captcha-wrap">
			<div className="">
				<Captcha
					onLoad={() => onLoad(setError, token)}
					setToken={setToken}
					captchaRef={captchaRef}
					token={token}
				/>
			</div>
		</div>
	);
};
