import { ChangeEvent, FC } from 'react';
import { SearchSvg } from 'assets/svg-icon';
import { SelectList } from 'ui/SelectList/SelectList';
import { AdminsHeader } from './AdminsHeader/AdminsHeader';
import { AdminsBody } from './AdminsBody/AdminsBody';
import { IAdminsResponse } from 'redux/reducers/admins/types';
import { DebounceInput } from 'react-debounce-input';
import { NotFound } from 'ui/NotFound/NotFound';

interface IProps {
	admins: Array<IAdminsResponse> | null;
	onSearch: (event: ChangeEvent) => void;
	search: string;
}

export const AdminsBlocked: FC<IProps> = ({ admins, onSearch, search }) => {
	return (
		<>
			<div className="filter-wrapper filter-flex-between">
				<div className="user-data__block">
					<SelectList placeholder={'Export'} data={['CSV', 'JSON', 'PDF']} />
				</div>
				<div className="input input-custom input-search select-custom-width">
					<div className="input-wrapper">
						<DebounceInput
							className="input-item input-item--left-icon"
							type="text"
							name="search"
							placeholder="Search"
							value={search || ''}
							onChange={onSearch}
							debounceTimeout={500}
						/>
						<span className="input-icon">
							<SearchSvg />
						</span>
					</div>
				</div>
			</div>

			<div className="table-wrapper">
				<div className="table table--users-private-chats margin-top">
					<AdminsHeader />
					{admins?.length ? <AdminsBody admins={admins} /> : <NotFound name={'admins blocked'} />}
				</div>
			</div>
		</>
	);
};
