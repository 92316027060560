export const structureData: Array<IStructureData> = [
	{ id: 1, name: 'edit_bot', leftContent: 'text', number_of_templates: '' },
	{ id: 2, name: 'error_bot', leftContent: 'text', number_of_templates: '' },
	{ id: 3, name: 'photo_bot', leftContent: 'text', number_of_templates: '' },
	{
		id: 4,
		name: 'start_bot',
		leftContent: 'text',
		number_of_templates: 'Number of templates',
	},
	{
		id: 5,
		name: 'text_bot',
		leftContent: 'text',
		number_of_templates: 'Number of templates',
	},
];

export interface IStructureData {
	id: number;
	name: string;
	leftContent: string;
	number_of_templates: string;
}
