import { FC, MouseEvent, useEffect, useState } from 'react';
import { Accordion } from './Accordion/Accordion';
import { appearanceData } from './data';
import './Appearance.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	listLogoRequest,
	uploadBackgroundRequest,
	uploadLogoRequest,
} from 'redux/reducers/ai-chat-bot/reducer';
import { bytesToMB } from 'untils/bytesToMB';

import {
	getBackgroundLoaderSelector,
	getListAvatarsSelector,
	getLogoLoaderSelector,
} from 'redux/reducers/ai-chat-bot/selectors';
import { LogoHistory } from '../LogoHistory/LogoHistory';
import { SmallLoader } from 'ui/SmallLoader';
interface IProps {
	botId: string;
}

export const Appearance: FC<IProps> = ({ botId }) => {
	const dispatch = useDispatch();
	const backgroundLoader = useSelector(getBackgroundLoaderSelector);
	const logoLoader = useSelector(getLogoLoaderSelector);
	const listAvatar = useSelector(getListAvatarsSelector);
	const [size, setSize] = useState<Array<number>>([]);
	const [nameFile, setNameFile] = useState<Array<string>>([]);
	const [nameInput, setNameInput] = useState<Array<string>>([]);

	const onUpload = (data: File, name: string) => {
		!nameInput.includes(name)
			? setNameFile((prev) => [...prev, data.name])
			: setNameFile(nameFile.map((item, index) => (nameInput[index] === name ? data.name : item)));

		!nameInput.includes(name)
			? setSize((prev) => [...prev, bytesToMB(+data.size)])
			: setSize(
					size.map((item, index) => (nameInput[index] === name ? bytesToMB(+data.size) : item)),
			  );

		!nameInput.includes(name) && setNameInput((prev) => [...prev, name]);

		const formData = new FormData();
		formData.append('file', data);
		const request = {
			data: { file: formData },
			botId: botId,
		};
		name === 'logo'
			? dispatch(uploadLogoRequest({ ...request }))
			: dispatch(uploadBackgroundRequest({ ...request }));
	};

	useEffect(() => {
		dispatch(listLogoRequest(String(botId)));
	}, []);

	return (
		<div className="structure">
			{appearanceData.map((structure, index) => (
				<div key={structure.id} className="structure__box">
					{index === 1 ? (
						listAvatar?.length ? (
							<LogoHistory structure={structure} avaList={listAvatar} botId={botId} />
						) : (
							<SmallLoader />
						)
					) : (
						<Accordion
							onUpload={onUpload}
							structure={structure}
							size={nameInput.length ? size[nameInput.indexOf(structure.name)] : 0}
							nameFile={nameInput.length ? nameFile[nameInput.indexOf(structure.name)] : ''}
							loader={structure.name === 'logo' ? logoLoader : backgroundLoader}
						/>
					)}
				</div>
			))}
		</div>
	);
};
