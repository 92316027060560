import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EChannelUpgradeType } from 'services/constants/type';
import {
	IUsersState,
	IUsersRequest,
	IUsersResponse,
	IUsersVerifyRequest,
	IUserRequest,
	IUserResponse,
	IDeleteSagaRequest,
	IBlockUserSagaRequest,
	IChannelsResponse,
	IGroupsResponse,
	ITransactionResponse,
	IPhotosResponse,
	ICountry,
	ILimitRequest,
} from './types';

export const initialState: IUsersState = {
	usersList: null,
	userDetails: null,
	photos: null,
	channels: null,
	groups: null,
	transaction: null,
	usersLoader: false,
	countries: null,
};

const users = createSlice({
	name: '@@users',
	initialState,
	reducers: {
		getUsersListRequest: (state, action: PayloadAction<IUsersRequest>) => {
			const usersListState = state;
			usersListState.usersLoader = true;
		},

		getUsersListSuccess: (state, action: PayloadAction<IUsersResponse>) => {
			const usersListState = state;
			const { payload } = action;
			usersListState.usersLoader = false;
			usersListState.usersList = payload;
		},

		putUpgradeRequest: (state, action: PayloadAction<IUsersVerifyRequest>) => {
			const upgradeState = state;
			upgradeState.usersLoader = true;
		},

		putUpgradeSuccess: (state, action: PayloadAction<IUsersVerifyRequest>) => {
			const upgradeState = state;
			upgradeState.usersLoader = false;
			const { payload } = action;
			upgradeState.usersList?.data.users.forEach((user) => {
				if (user.id === payload.user_id) user.verified = payload.verify;
			});
		},

		getUserRequest: (state, action: PayloadAction<IUserRequest>) => {
			const usersListState = state;
			usersListState.usersLoader = true;
		},

		getUserSuccess: (state, action: PayloadAction<IUserResponse | null>) => {
			const usersListState = state;
			const { payload } = action;

			usersListState.usersLoader = false;
			usersListState.userDetails = payload;
		},

		blockUserRequest: (state, action: PayloadAction<IBlockUserSagaRequest>) => {
			const usersListState = state;
			usersListState.usersLoader = true;
		},

		blockUserSuccess: (state, action: PayloadAction<IUserResponse>) => {
			const usersListState = state;
			const { payload } = action;
			usersListState.usersLoader = false;
			usersListState.userDetails = payload;
		},

		deleteUserRequest: (state, action: PayloadAction<IDeleteSagaRequest>) => {
			const usersListState = state;
			usersListState.usersLoader = true;
		},

		deleteUserSuccess: (state) => {
			const usersListState = state;
			usersListState.usersLoader = false;
		},

		getPhotosRequest: (state, action: PayloadAction<IUserRequest>) => {
			const usersListState = state;
			usersListState.usersLoader = true;
		},

		getPhotosSuccess: (state, action: PayloadAction<IPhotosResponse>) => {
			const usersListState = state;
			const { payload } = action;
			usersListState.usersLoader = false;
			usersListState.photos = payload;
		},

		getChannelsRequest: (state, action: PayloadAction<IUserRequest>) => {
			const usersListState = state;
			usersListState.usersLoader = true;
		},

		getChannelsSuccess: (state, action: PayloadAction<IChannelsResponse>) => {
			const usersListState = state;
			const { payload } = action;
			usersListState.usersLoader = false;
			usersListState.channels = payload;
		},

		getGroupsRequest: (state, action: PayloadAction<IUserRequest>) => {
			const usersListState = state;
			usersListState.usersLoader = true;
		},

		getGroupsSuccess: (state, action: PayloadAction<IGroupsResponse>) => {
			const usersListState = state;
			const { payload } = action;
			usersListState.usersLoader = false;
			usersListState.groups = payload;
		},

		getTransactionRequest: (state, action: PayloadAction<IUserRequest>) => {
			const usersListState = state;
			usersListState.usersLoader = true;
		},

		getTransactionSuccess: (state, action: PayloadAction<ITransactionResponse>) => {
			const usersListState = state;
			const { payload } = action;
			usersListState.usersLoader = false;
			usersListState.transaction = payload;
		},

		putCounties: (state, action: PayloadAction<Array<ICountry>>) => {
			const usersListState = state;
			const { payload } = action;
			usersListState.countries = payload;
		},

		setLimitRequest: (state, action: PayloadAction<ILimitRequest>) => {
			const usersListState = state;
			const { payload } = action;
			usersListState.usersLoader = false;
		},

		setLimitSuccess: (state, action: PayloadAction<ILimitRequest>) => {
			const usersListState = state;
			const { payload } = action;

			usersListState.usersLoader = true;
			if (usersListState.userDetails?.data) {
				usersListState.userDetails.data = {
					...usersListState.userDetails.data,
					limit_channel_public_creator: payload.limit_channel_public_creator,
					limit_channel_public_subscriber: payload.limit_channel_public_subscriber,
				};
			}
		},

		usersInitState: (state) => {
			const usersInitState = state;
			usersInitState.usersLoader = false;
		},
	},
});

export default users.reducer;
export const {
	getUsersListRequest,
	getUsersListSuccess,
	putUpgradeRequest,
	putUpgradeSuccess,
	getUserRequest,
	getUserSuccess,
	blockUserRequest,
	blockUserSuccess,
	deleteUserRequest,
	deleteUserSuccess,
	getPhotosRequest,
	getPhotosSuccess,
	getChannelsRequest,
	getChannelsSuccess,
	getGroupsRequest,
	getGroupsSuccess,
	getTransactionRequest,
	getTransactionSuccess,
	usersInitState,
	setLimitSuccess,
	setLimitRequest,
	putCounties,
} = users.actions;
