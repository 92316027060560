import {
	WEB_API_URL,
	WEB_API_URL_STAGE,
	WEB_API_URL_QA,
	WEB_SOKET_API_URL,
	WEB_SOKET_API_URL_STAGE,
	WEB_SOKET_API_URL_QA,
} from 'services/constants/env';

export const onHttp = () => {
	if (
		// window.location.href.includes('localhost:') ||
		window.location.href.includes('stage-admin.ellomessenger') ||
		window.location.href.includes('admin-vpn.nimdalages')
	) {
		return WEB_API_URL_STAGE;
	} else if (
		window.location.href.includes('localhost:') ||
		window.location.href.includes('qa-admin.nimdalages')
	) {
		return WEB_API_URL_QA;
	} else {
		return WEB_API_URL;
	}
};

export const onHttpCentrifuge = () => {
	if (
		// window.location.href.includes('localhost:') ||
		window.location.href.includes('stage-admin.ellomessenger') ||
		window.location.href.includes('admin-vpn.nimdalages')
	) {
		return WEB_SOKET_API_URL_STAGE;
	} else if (
		window.location.href.includes('qa-admin.nimdalages') ||
		window.location.href.includes('localhost:')
	) {
		return WEB_SOKET_API_URL_QA;
	} else {
		return WEB_SOKET_API_URL;
	}
};

export const onGetUrl = () => {
	if (
		window.location.href.includes('stage-admin.ellomessenger') ||
		window.location.href.includes('qa-admin.nimdalages') ||
		window.location.href.includes('localhost:')
	) {
		return 'https://stage.ello.team/';
	} else {
		return 'https://ello.team/';
	}
};
