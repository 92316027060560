import { FC } from 'react';
import img from '../../../../../assets/images/car.jpg';

export const Image: FC = () => {
	return (
		<div>
			<img src={img} alt="Avatar channel" />
		</div>
	);
};
