import { ArrowBackSvg } from 'assets/svg-icon';
import { FC, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EAiChatBotTabs } from 'services/constants/lists-table';
import './Statistics.scss';
import { SelectList } from 'ui/SelectList/SelectList';
import { useDispatch, useSelector } from 'react-redux';
import { getBotsStatisticsRequest } from 'redux/reducers/ai-chat-bot/reducer';
import { getStatisticsSelector } from 'redux/reducers/ai-chat-bot/selectors';
import moment from 'moment';
import { IStatisticsList } from 'redux/reducers/ai-chat-bot/types';
import { firstUppercaseLetter } from 'untils/firstUppercaseLetter';

export const StatisticsBot: FC = () => {
	const getStatistics = useSelector(getStatisticsSelector);
	const dispatch = useDispatch();
	const params = useParams();

	useEffect(() => {
		dispatch(
			getBotsStatisticsRequest({
				current_page: 1,
				per_page: 15,
				bot_id: Number(params.id),
			}),
		);
	}, []);

	return (
		<div className="statistic-block">
			<div className="statistic-block__header">
				<div className="title-block">
					<div className="title-block-wrap">
						<div className="title-block">
							<Link
								className="back-button"
								to={`${ELinksName.AI_CHAT_BOT}/${EAiChatBotTabs.BOTS_STATISTICS}`}
							>
								<ArrowBackSvg />
							</Link>
							<p className="title">
								{firstUppercaseLetter(params?.name?.replaceAll('_', ' ') || '')}
							</p>
						</div>
					</div>
				</div>
				<div className="statistic-block__select">
					<SelectList placeholder="Select" data={['Export', 'Import']} />
				</div>
			</div>

			<div className="statistic-block__table">
				<div className="statistic-block__table-header">
					<div className="statistic-block__tr">
						<div className="statistic-block__th">Month</div>
						<div className="statistic-block__th">Image prompts used</div>
						<div className="statistic-block__th">Chat prompts used</div>
					</div>
				</div>
				<div className="statistic-block__table-body">
					{getStatistics?.list?.map((statistic, index) => (
						<div key={index} className="statistic-block__tr">
							<div className="statistic-block__td">
								{moment(statistic.month, 'M').format('MMMM')}
							</div>
							<div className="statistic-block__td">{statistic.image_count}</div>
							<div className="statistic-block__td">{statistic.text_count}</div>
						</div>
					))}
					<div className="statistic-block__tr statistic-block-remove-line">
						<div className="statistic-block__td statistic-block-weight">Total</div>
						<div className="statistic-block__td statistic-block-weight">
							{getStatistics?.list?.reduce(
								(total: number, current: IStatisticsList) => total + current.image_count,
								0,
							)}
						</div>
						<div className="statistic-block__td statistic-block-weight">
							{getStatistics?.list?.reduce(
								(total: number, current: IStatisticsList) => total + current.text_count,
								0,
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
