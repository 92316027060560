/* eslint-disable react/jsx-no-undef */
import { AppleSvg, PaySvg, StripeSvg } from 'assets/svg-icon';
import { FC, MouseEvent } from 'react';
import { IBalancesDeposit, ITransactionGraphResponse } from 'redux/reducers/finance/finance-types';
import { ChartBar } from 'ui/Graphs/ChartBar';
import { numeralFunc } from 'untils/numerals';
import { ChartHeader } from '../ChartHeader/ChartHeader';

interface IProps {
	deposit: IBalancesDeposit;
	onActivBtn: (event: MouseEvent) => void;
	activeBtn: string;
	graph: null | Array<ITransactionGraphResponse>;
}

export const PlatformBalancesDeposit: FC<IProps> = ({ deposit, graph, onActivBtn, activeBtn }) => {
	return (
		<>
			<div className="platform-balances__filter-inner">
				<div className="platform-balances__text">Balance on the platform</div>
			</div>

			<div className="filter-custom__info-card margin-top-card">
				<div className="filter-custom__card">
					<p className="filter-custom__title">Total platform balance</p>
					<p className="filter-custom__subtitle">{`$${numeralFunc(
						deposit.total_platform_balance,
					)}`}</p>
				</div>

				<div className="filter-custom__card">
					<p className="filter-custom__title">Earnings balance</p>
					<p className="filter-custom__subtitle">{`$${numeralFunc(deposit.earning_balance)}`}</p>
				</div>
			</div>

			<div className="gray-background">
				<div className="platform-balances__filter-inner">
					<div className="platform-balances__text">Balance by replenishment methods</div>
				</div>

				<div className="platform-balances__box-left-body">
					<div className="platform-balances__left-card">
						<AppleSvg />
						<div className="platform-balances__text-wrapper">
							<p className="filter-custom__title color-grey">Apple</p>
							<p className="filter-custom__subtitle">{`$${numeralFunc(
								deposit.apple_deposited_balance,
							)}`}</p>
						</div>
					</div>
					<div className="platform-balances__left-card">
						<StripeSvg style={{ width: '63px' }} />
						<div className="platform-balances__text-wrapper">
							<p className="filter-custom__title color-grey">Bank transfer</p>
							<p className="filter-custom__subtitle">
								{`$${numeralFunc(deposit.stripe_deposited_balance)}`}
							</p>
						</div>
					</div>
				</div>
			</div>

			{/* <div className="platform-balances__body-wrapper">
					<div className="platform-balances__box-right">
						<div className="platform-balances__box-left-header">
							<p className="platform-balances__left-title">Replenishment schedules</p>
							<Link className="platform-balances__left-link" to="/">
								Top Up
							</Link>
						</div>
						<ChartjsLine options={options} data={data} height={70} />
					</div>
				</div>
				<div className="filter-custom__info-card margin-top-card">
					{platformBalanceTwo.map((el) => (
						<div key={el} className="filter-custom__card">
							<p className="filter-custom__title color-grey">{el}</p>
							<p className="filter-custom__subtitle">$80,856.00</p>
						</div>
					))}
				</div> */}
			<div className="platform-balances__graph-wrap">
				<ChartHeader onActivBtn={onActivBtn} activeBtn={activeBtn} />
				<ChartBar
					activeBtn={activeBtn}
					labels={graph?.map((label) => label.period) || []}
					numbers={graph?.map((number) => number.total) || []}
				/>
			</div>
		</>
	);
};
