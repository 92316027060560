import { FC } from 'react';
import { Fillter } from 'ui/Fillter/Fillter';
import { channelsCategory, channelsTypes } from 'CONST/userFilter';
import { getChannelsLoaderSelector } from 'redux/reducers/channels/selectors';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SubTabs } from './SubTabs/SubTabs';
import { EBackgroundTabs } from 'services/constants/lists-table';
import { MonetizationHeader } from './MonetizationHeader/MonetizationHeader';
import { MonetizationBody } from './MonetizationBody/MonetizationBody';

export const MonetizationBackGround: FC = () => {
	const loader = useSelector(getChannelsLoaderSelector);
	const params = useParams();

	return (
		<>
			<SubTabs />
			<Fillter
				type={channelsTypes}
				status={channelsCategory}
				typesName="Types"
				statusName="Status"
				keyStatus="status"
				keyType="types"
			/>

			<div className="table-wrapper">
				<div
					className={`table table--statistics-customers table--channels-loader container-finance`}
				>
					<MonetizationHeader />
					<MonetizationBody />
				</div>
			</div>
		</>
	);
};
