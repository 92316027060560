import { FC, useEffect, useState } from 'react';
import { RecommendedHeader } from './RecommendedHeader/RecommendedHeader';
import { useParams } from 'react-router-dom';
import { RecommendedBody } from './RecommendedBody/RecommendedBody';
import { useDispatch, useSelector } from 'react-redux';
import { getRecommendedRequest } from 'redux/reducers/ai-chat-bot/reducer';
import { getRecommendedSelector } from 'redux/reducers/ai-chat-bot/selectors';
import { Pagination } from 'ui/Pagination';
import { IDataFiltter } from 'pages/Dashboard/Channels';

interface IProps {
	dataFiltter: IDataFiltter;
	setDataFiltter: (value: IDataFiltter) => void;
}

export const RecommendedTable: FC<IProps> = ({ setDataFiltter, dataFiltter }) => {
	const params = useParams();
	const dispatch = useDispatch();
	const recommends = useSelector(getRecommendedSelector);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const submitFilter = (value: number) => {
		setCurrentPage(value);
	};

	useEffect(() => {
		dispatch(
			getRecommendedRequest({
				current_page: 1,
				per_page: 15,
				search: dataFiltter.search ? dataFiltter.search : undefined,
			}),
		);
	}, [dataFiltter, currentPage]);

	return (
		<div className="table-wrapper">
			<div className="table table--ai-recommended">
				<RecommendedHeader param={params?.tab as string} setDataFiltter={setDataFiltter} />
				<div className="table-body">
					<RecommendedBody recommends={recommends?.ai_bots} />
				</div>
			</div>
			{recommends && recommends.total >= 15 ? (
				<Pagination
					request={submitFilter}
					currentPage={currentPage}
					numberPage={Math.ceil(recommends.total / 15)}
				/>
			) : null}
		</div>
	);
};
