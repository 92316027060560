import { endpoint } from 'services/endpoint';
import { http, multipart } from 'services/http';
import { ISupportApi } from './types';
import {
	IChangeStatus,
	ICreateLetterRequest,
	IMassages,
	INoteRequest,
	ISubscribe,
	ITicketsRequest,
	IUploadFileRequred,
	IUsersRequest,
} from 'redux/reducers/support/types';
import { IAxiosRequestConfig } from 'services/http/types';

export const support: ISupportApi = {
	createLetter: (payload: ICreateLetterRequest) =>
		http.post(endpoint.support.SUPPORT_SEND, payload).then((response) => response.data),
	subscribeAll: () => http.get(endpoint.support.SUBSCRIBE_ALL).then((response) => response.data),
	subscribe: (payload: ISubscribe) =>
		http.post(endpoint.support.SUBSCRIBE, payload).then((response) => response.data),
	getAllUsers: (payload: IUsersRequest) =>
		http.post(endpoint.support.USERS_WHO_NEED_HELP, payload).then((response) => response.data),
	getTypes: () => http.get(endpoint.support.GET_TYPES).then((response) => response.data),
	getMassages: (payload: IMassages) =>
		http.post(endpoint.support.GET_HISTORY, payload).then((response) => response.data),
	changeStatus: (payload: IChangeStatus) =>
		http.post(endpoint.support.EDIT_STATUS, payload).then((response) => response.data),
	changeType: (payload: IChangeStatus) =>
		http.post(endpoint.support.EDIT_TYPE, payload).then((response) => response.data),
	getNotes: (params: string) =>
		http.get(endpoint.support.NOTES_LIST(params)).then((response) => response.data),
	postNote: (payload: INoteRequest) =>
		http.post(endpoint.support.NOTES_ADD, payload).then((response) => response.data),
	editNote: (payload: INoteRequest) =>
		http.post(endpoint.support.NOTES_EDIT, payload).then((response) => response.data),
	deleteNote: (payload: string) =>
		http.delete(endpoint.support.NOTES_DELETE(payload)).then((response) => response.data),
	getTickets: (payload: ITicketsRequest) =>
		http.post(endpoint.support.TICKETS, payload).then((response) => response.data),
	uploadFile: (payload: IUploadFileRequred) =>
		multipart
			.post(
				endpoint.support.UPLOAD(String(payload.channel_id), String(payload.user_id)),
				payload.body?.file,
			)
			.then((response) => response.data),
};
