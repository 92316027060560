import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IReserved } from './types';
import { IUsersNameRequest } from 'redux/reducers/reserved/types';

export const reserved: IReserved = {
	getUsernames: (payload: IUsersNameRequest) =>
		http.post(endpoint.reserved.GET_USERSNSME, payload).then((response) => response.data),
	editUsernames: (payload: any) =>
		http.post(endpoint.reserved.EDIT_USERSNSME, payload).then((response) => response.data),
	addUsernames: (payload: any) =>
		http.post(endpoint.reserved.ADD_USERSNSME, payload).then((response) => response.data),
	deleteUsernames: (payload: string) =>
		http.delete(endpoint.reserved.DELETE_USERSNSME(payload)).then((response) => response.data),
	checkUsernames: (payload: any) =>
		http.post(endpoint.reserved.CHECK_USERSNSME, payload).then((response) => response.data),
};
