import { Comments } from 'components/Comments/Comments';
import moment from 'moment';
import { ListFiles } from 'pages/Dashboard/NameExemple/ListFiles/ListFiles';
import { ChangeEvent, FC } from 'react';
import { ICommentsResponse, IPostResponse } from 'redux/reducers/channels/types';

interface IPropsPostInfo {
	post: IPostResponse;
	comments: ICommentsResponse | null;
	onHandleSearch: (event: ChangeEvent) => void;
	search: string;
	setSelect: (value: string) => void;
	removePost: (postId: string, type: string) => void;
}

export const PostInfo: FC<IPropsPostInfo> = ({
	onHandleSearch,
	post,
	comments,
	search,
	setSelect,
	removePost,
}) => {
	const { author, comments_count, published, post_text, content } = post;

	return (
		<>
			<section className="user-data__details-user details-exemple details-exemple-custom">
				<ul className="details-user__box-text details-user__box-text-custom">
					<li className="details-user__item">
						<span>Author</span>
						<span>{author}</span>
					</li>
					<li className="details-user__item">
						<span>Comments</span>
						<span>{comments_count}</span>
					</li>
					<li className="details-user__item">
						<span>Published</span>
						<span>{moment(published).format('MMM DD, YYYY, h:mm A')}</span>
					</li>
					<li className="details-user__item2">
						<span>Post text</span>
					</li>
					<li className="details-user__item2">
						<span>{post_text}</span>
					</li>
				</ul>
				<div className="details-user__img-wrapper details-user__img-wrapper-custom">
					{content?.length ? <ListFiles content={content} removePost={removePost} /> : null}
				</div>
			</section>
			{comments_count ? (
				<section>
					<Comments
						setSelect={setSelect}
						onHandleSearch={onHandleSearch}
						comments={comments}
						search={search}
						removePost={removePost}
					/>
				</section>
			) : null}
		</>
	);
};
