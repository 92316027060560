import { FC } from 'react';
import { AdminHeader } from './AdminHeader/AdminHeader';
import { AdminBody } from './AdminBody/AdminBody';
import { IAdminsResponse } from 'redux/reducers/admins/types';

interface IProps {
	rule: {
		ruleName: string;
		ruleDesc: string;
	};
	role: Array<IAdminsResponse> | undefined;
}

export const AdminRules: FC<IProps> = ({ rule, role }) => {
	return (
		<section className="admin-rule">
			<div className="admin-rule__wrapper">
				<div className="admin-rule__rule">
					<div className="admin-rule__rule-header">{rule.ruleName}</div>
					<div className="admin-rule__rule-body">{rule.ruleDesc}</div>
				</div>
				<div className="table table--admin-rules margin-top">
					<AdminHeader />
					<AdminBody roles={role} />
				</div>
			</div>
		</section>
	);
};
