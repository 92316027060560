import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import {
	ESubtabsConversation,
	ESubtabsUsersDetails,
	ETabsUsersDetails,
} from 'services/constants/lists-table';

export const TabsDetailsUser: FC = () => {
	const params = useParams();

	return (
		<div className="tabs-buttons-nav flex-wrap">
			<Link
				to={`${ELinksName.USER_DETAILS}/${params.id}/${params.userId}/${ETabsUsersDetails.USER_DATA}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsUsersDetails.USER_DATA,
				})}
			>
				User data
			</Link>
			<Link
				to={`${ELinksName.USER_DETAILS}/${params.id}/${params.userId}/${
					ETabsUsersDetails.CHANNELS
				}/${String(ESubtabsUsersDetails.SUBSCRIPTION)}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsUsersDetails.CHANNELS || !params.tab,
				})}
			>
				Channels
			</Link>
			<Link
				to={`${ELinksName.USER_DETAILS}/${params.id}/${params.userId}/${ETabsUsersDetails.CONVERSATION}/${ESubtabsConversation.CONVERSATIONS_CHANNELS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsUsersDetails.CONVERSATION,
				})}
			>
				Groups
			</Link>
			<Link
				to={`${ELinksName.USER_DETAILS}/${params.id}/${params.userId}/${ETabsUsersDetails.TRANSACTION_HISTORY}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsUsersDetails.TRANSACTION_HISTORY,
				})}
			>
				Transaction history
			</Link>
			<Link
				to={`${ELinksName.USER_DETAILS}/${params.id}/${params.userId}/${ETabsUsersDetails.SUBSCRIPTIONS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsUsersDetails.SUBSCRIPTIONS,
				})}
			>
				Subscriptions
			</Link>
			<Link
				to={`${ELinksName.USER_DETAILS}/${params.id}/${params.userId}/${ETabsUsersDetails.FINANCE}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsUsersDetails.FINANCE,
				})}
			>
				Finance
			</Link>
			<Link
				to={`${ELinksName.USER_DETAILS}/${params.id}/${params.userId}/${ETabsUsersDetails.LIMITS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsUsersDetails.LIMITS,
				})}
			>
				Limits
			</Link>
			<Link
				to={`${ELinksName.USER_DETAILS}/${params.id}/${params.userId}/${ETabsUsersDetails.AI_BOT}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsUsersDetails.AI_BOT,
				})}
			>
				Ai Bot
			</Link>
			<Link
				to={`${ELinksName.USER_DETAILS}/${params.id}/${params.userId}/${ETabsUsersDetails.DISCOUNT}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsUsersDetails.DISCOUNT,
				})}
			>
				Discount
			</Link>
		</div>
	);
};
