import { FC } from 'react';
import { IAiBots } from 'redux/reducers/ai-chat-bot/types';
import { AiBot } from './AiBot/AiBot';
import './AiBots.scss';
interface IProps {
	botsList?: Array<IAiBots>;
}

export const AiBots: FC<IProps> = ({ botsList }) => {
	return (
		<div className="ai-bots">
			{botsList?.map((bot) => (
				<AiBot key={bot?.id} bot={bot} />
			))}
		</div>
	);
};
