import { FC } from 'react';
import { Oval } from 'react-loader-spinner';

export const Loader: FC = () => {
	return (
		<div className="louder-wrapper-custom">
			<Oval secondaryColor="grey" color="#0a49a5" height={60} width={60} />
		</div>
	);
};
