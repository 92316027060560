import { FC } from 'react';
import { SvgArrowDownHeader, SvgArrowUpHeader } from 'ui/svg-image/SvgAllChannels';

export const ChannelsHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Photo</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Name</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Email</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Custom tittle</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>What can this admin do</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Date added</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Last active</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
