import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ESubscriptionTabs, ETabsUsersDetails } from 'services/constants/lists-table';

export const SubscriptionTabs: FC = () => {
	const params = useParams();

	return (
		<div className="table-tabs table-tabs--type2 table-tabs-custom tabs-top-margin">
			{params.tab !== ETabsUsersDetails.USER_DATA && (
				<Link
					to={`${ELinksName.USER_DETAILS}/${params.id}/${params.userId}/${params.tab}/${ESubscriptionTabs.SUBSCRIPTION_CHANNELS}`}
					className={classNames('table-tabs__item', {
						active: params.subTab === ESubscriptionTabs.SUBSCRIPTION_CHANNELS || !params.subTab,
					})}
				>
					Subscription channels
				</Link>
			)}
			<Link
				to={`${ELinksName.USER_DETAILS}/${params.id}/${params.userId}/${params.tab}/${ESubscriptionTabs.COURSE_CHANNEL}`}
				className={classNames('table-tabs__item', {
					active: params.subTab === ESubscriptionTabs.COURSE_CHANNEL,
				})}
			>
				Course channel
			</Link>
			<Link
				to={`${ELinksName.USER_DETAILS}/${params.id}/${params.userId}/${params.tab}/${ESubscriptionTabs.BACKGROUND}`}
				className={classNames('table-tabs__item', {
					active: params.subTab === ESubscriptionTabs.BACKGROUND,
				})}
			>
				Backgrounds
			</Link>
			<Link
				to={`${ELinksName.USER_DETAILS}/${params.id}/${params.userId}/${params.tab}/${ESubscriptionTabs.AI}`}
				className={classNames('table-tabs__item', {
					active: params.subTab === ESubscriptionTabs.AI,
				})}
			>
				Ai
			</Link>
		</div>
	);
};
