import classNames from 'classnames';
import { FC } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import {
	ESubTabsFinance,
	ETabsFinanceBalances,
	ETabsFinanceStatistics,
	ETabsFinanceWithdrawal,
} from 'services/constants/lists-table';

export const FinanceTabs: FC = () => {
	const params = useParams();

	return (
		<div className="tabs-buttons-nav">
			<Link
				to={`${ELinksName.FINANCE}/${ESubTabsFinance.PLATFORM}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ESubTabsFinance.PLATFORM || !params.tab,
				})}
			>
				Platform balances
			</Link>
			<Link
				to={`${ELinksName.FINANCE}/${ESubTabsFinance.WITHDRAWALS}/${ETabsFinanceWithdrawal.FIAT}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ESubTabsFinance.WITHDRAWALS,
				})}
			>
				Withdrawal requests
			</Link>
			<Link
				to={`${ELinksName.FINANCE}/${ESubTabsFinance.COMMISSIONS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ESubTabsFinance.COMMISSIONS,
				})}
			>
				Commissions
			</Link>
			<Link
				to={`${ELinksName.FINANCE}/${ESubTabsFinance.PAYMENT}/${ETabsFinanceWithdrawal.FIAT}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ESubTabsFinance.PAYMENT,
				})}
			>
				Payment methods
			</Link>
			<Link
				to={`${ELinksName.FINANCE}/${ESubTabsFinance.STATISTICS}/${ETabsFinanceStatistics.SISTEMATIC}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ESubTabsFinance.STATISTICS,
				})}
			>
				Statistics
			</Link>
			{/* <Link
				to={`${ELinksName.FINANCE}/${ESubTabsFinance.CRYPTO}/${ETabsFinanceBalances.BALANCES}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ESubTabsFinance.CRYPTO,
				})}
			>
				Crypto balances
			</Link> */}
		</div>
	);
};
