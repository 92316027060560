import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IApkFilesStore } from './types';

const getApkState = (state: IStoreState): IApkFilesStore => state.apkFiles;

export const getListApkSelector = createSelector(
	[getApkState],
	(aiServicese: IApkFilesStore) => aiServicese.listApk,
);

export const getApkLoaderSelector = createSelector(
	[getApkState],
	(aiServicese: IApkFilesStore) => aiServicese.apkLoader,
);
