import { PointsSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import useOutsideClick from 'hooks/useOutsideClick';
import { FC, MouseEvent, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Toggle from 'react-toggle';
import { checkUsernamesRequest } from 'redux/reducers/reserved/reducer';
import { IUsersName } from 'redux/reducers/reserved/types';
import { Popup } from 'ui/Popup/Popup';

interface IProps {
	name: IUsersName;
	onUserId: (value: number) => void;
	popup: number;
	isOpenModal: (event?: MouseEvent) => void;
}

export const UsernameList: FC<IProps> = ({ name, onUserId, isOpenModal }) => {
	const { username, id, full_check } = name;
	const len = name?.config?.excepted_for_emails.length;
	const [toggle, setToggle] = useState<boolean>(full_check);
	const [popup, setPopup] = useState<number>(0);
	const ref = useRef<HTMLDivElement | null>(null);
	const dispatch = useDispatch();

	const onPopup = (value: number) => {
		setPopup(value);
	};

	useOutsideClick(ref, () => onPopup(0));

	const handleTofuChange = (id: number) => {
		setToggle(!toggle);
		dispatch(
			checkUsernamesRequest({
				check: !toggle,
				id,
			}),
		);
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name"> Username </p>
				<p>{username}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name"> TM owner </p>
				<p>{name.owner}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name"> Exception for use </p>
				<p className="exception-justify">
					{name?.config?.excepted_for_emails?.map((email, index) => (
						<span key={index}>{`${email}${len - 1 !== index ? ',' : ''}`}</span>
					))}
				</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name"> Restrict in all cases </p>
				<div>
					<Toggle defaultChecked={toggle} icons={false} onChange={() => handleTofuChange(id)} />
				</div>
			</div>
			<div ref={ref} className={classNames('td td--right', { active: id === popup })}>
				<p className="td-hidden-name"> Action </p>
				<Popup
					data={['Edit', 'Exception for use', 'Delete']}
					onPopup={onPopup}
					isOpenModal={isOpenModal}
				/>
				<span
					style={{ padding: '0 10px' }}
					className="point-padding pointer"
					onClick={() => {
						onPopup(id);
						onUserId(id);
					}}
				>
					<PointsSvg />
				</span>
			</div>
		</div>
	);
};
