import { backgroundTypeList, fileTypeList, partnerTypeList } from 'CONST/selectList';
import { MobileSvg, TabletSvg } from 'assets/svg-icon';
import { FC, useState } from 'react';
import { DropFile } from 'ui/DropFile/DropFile';
import { Input } from 'ui/Input';
import { SelectList } from 'ui/SelectList/SelectList';
import { Textarea } from 'ui/Textarea/Textarea';

const titleOne = 'Upload background for Mobile devices';
const titleTwo = 'Upload background for Tablet devices';
const subtitleOne =
	'Example Description. To upload an image as a chat background in the admin panel, you need to click on the corresponding button and select the desired file on your computer.';
const subtitleTwo =
	'Example Description. To upload an image as a chat background in the admin panel, you need to click on the corresponding button and select the desired file on your computer. ';

export const MonetizationDetails: FC = () => {
	const [file, setFile] = useState<any>(null);

	const handleChange = (data: any) => {
		const f = data;
		setFile(data);
	};

	return (
		<section className="background">
			<div className="background__wrapper">
				<div className="background__grid-container">
					<div className="details-user__header-comments background__select-wrapper">
						<p className="background__title">Type</p>
						<div className="comments__filter-wrapper">
							<SelectList data={backgroundTypeList} maxWidth="max-width253" />
						</div>
					</div>
				</div>
			</div>
			<div className="background__wrapper">
				<div className="background__grid-container">
					<div className="details-user__header-comments background__box">
						<div className="background__select-wrapper">
							<p className="background__title">File type</p>
							<div className="comments__filter-wrapper">
								<SelectList data={fileTypeList} maxWidth="max-width253" />
							</div>
						</div>
						<div className="background__select-wrapper">
							<p className="background__title">Partner</p>
							<div className="comments__filter-wrapper">
								<SelectList data={partnerTypeList} maxWidth="max-width253" />
							</div>
						</div>
						<div className="background__select-wrapper">
							<p className="background__title">Background Name</p>
							<Input
								type="text"
								name="backgroundName"
								placeholder="Name"
								className="max-width253"
							/>
						</div>
						<div className="background__select-wrapper">
							<p className="background__title">Price</p>
							<Input type="text" name="price" placeholder="Price" className="max-width253" />
						</div>
						<div className="background__select-wrapper">
							<p className="background__title">Owner/Creator</p>
							<Input type="text" name="owner" placeholder="Name" className="max-width253" />
						</div>
					</div>
					<Textarea
						handleTextarea={() => {}}
						name="description"
						placeholder="Text"
						title="Description"
						value={''}
					/>
				</div>
			</div>
			<DropFile
				handleChange={handleChange}
				file={file}
				title={titleOne}
				subtitle={subtitleOne}
				svg={<MobileSvg />}
			/>
			<DropFile
				handleChange={handleChange}
				file={file}
				title={titleTwo}
				subtitle={subtitleTwo}
				svg={<TabletSvg />}
			/>
		</section>
	);
};
