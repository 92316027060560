import { ArrowBackSvg, PhotoSvg } from 'assets/svg-icon';
import { FC } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import track from '../../../assets/images/track.png';
import note from '../../../assets/images/Icons.png';
import './EditTrack.scss';
import { EditTrackForms } from 'components/Forms/EditTrackForms/EditTrackForms';

export const EditTrack: FC = () => {
	const navigate = useNavigate();

	return (
		<div className="main-content">
			<div className="url color-grey big-first-letter">
				{location.pathname.slice(1).replaceAll('/', ' / ')}
			</div>
			<div className="title-block-wrap flex-content">
				<div className="title-block">
					<div className="back-button pointer" onClick={() => navigate(-1)}>
						<ArrowBackSvg />
					</div>
					<p className="title big-first-letter">Edit Track</p>
				</div>
			</div>
			<div className="track-details">
				<div className="edit-track">
					<div className="edit-track-wrap">
						<img src={note} alt="" />
						<div className="edit-track-inner">
							<p className="edit-track-text-black">Media File 3_1024x720.mp4</p>
							<p className="edit-track-text-gray">50 MB.</p>
						</div>
					</div>
					<div className="track-details__header">
						<div className="track-details__block-img">
							<img src={track} alt="" />
						</div>
					</div>
					<Link to="/" className="edit-track__link">
						<PhotoSvg />
						<p className="">Set Track Cover</p>
					</Link>
					<EditTrackForms />
				</div>
			</div>
		</div>
	);
};
