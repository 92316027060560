import { cardNameGroups, verified } from 'CONST/cardName';
import { DeleteSvg, LockRedSvg } from 'assets/svg-icon';
import { DetailsUser } from 'components/UserDetailsComponents/UserData/DetailsUser/DetailsUser';
import { FC, useState } from 'react';
import { Select } from 'ui/Select/Select';
import { GroupsChannels } from './DetailsChannels/GroupsChannels';

export const GroupsData: FC = () => {
	const [toggle, setToggle] = useState(false);

	const handleTofuChange = () => {
		setToggle(!toggle);
	};

	return (
		<div className="user-data">
			<div className="user-data__header">
				<div className="user-data__block left channels-data">
					<Select data={verified} />

					{/* <Select data={recommended} /> */}
				</div>
				<div className="user-data__block right width">
					<div className="user-data__toggle-wrapper right pointer">
						<LockRedSvg />
						<span className="user-data__toggle-text red">Block</span>
					</div>
					<div className="user-data__toggle-wrapper right pointer">
						<DeleteSvg />
						<span className="user-data__toggle-text red">Delete group</span>
					</div>
				</div>
			</div>
			<div className="user-data__body">
				<div className="user-data__card-wrapper">
					{cardNameGroups.map((key) => (
						<div key={key} className="user-data__card">
							<div className="user-data__title">{key}</div>
							<div className="user-data__subtitle">$1,550.00</div>
						</div>
					))}
				</div>
				<GroupsChannels />
			</div>
		</div>
	);
};
