import { channelsTabs, mediaTabs } from 'CONST/channels-tabs';
import { ArrowBackSvg, SearchSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { MediaAndFiles } from 'components/ChannelsDetailsComponents/MediaAndFiles/MediaAndFiles';
import { MediaSubtabs } from 'components/ChannelsDetailsComponents/MediaAndFiles/MediaSubtabs/MediaSubtabs';
import { Comments } from 'components/Comments/Comments';
import { GroupsAdmins } from 'components/GroupsDetailsComponent/ChannelsAdmins/GroupsAdmins';
import { GroupsData } from 'components/GroupsDetailsComponent/ChannelsData/GroupsData';
import { GroupsMembers } from 'components/GroupsDetailsComponent/GroupsMembers/GroupsMembers';
import { GroupsTabs } from 'components/GroupsDetailsComponent/GroupsTabs/GroupsTabs';
import { SubTabsDetailsUser } from 'components/UserDetailsComponents/SubTabsDetailsUser';
import { SubTabsConversations } from 'components/UserDetailsComponents/SubTabsGroups';
import { FC } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { IMediaFilesListResponse } from 'redux/reducers/channels/types';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EGroupsTabs, ETabsUsersDetails } from 'services/constants/lists-table';

export const GroupsDetails: FC = () => {
	const params = useParams();
	const location = useLocation();

	const definePage = () => {
		switch (params.tab) {
			case EGroupsTabs.MESSAGES:
				return (
					<div className="user-data user-data-custom">{/* <Comments comments={null} /> */}</div>
				);
			case EGroupsTabs.ADMINS:
				return <GroupsAdmins />;
			case EGroupsTabs.MEMBERS:
				return <GroupsMembers />;
			case EGroupsTabs.MEDIA_AND_FILES:
				return <MediaAndFiles media={{} as IMediaFilesListResponse} />;
			default:
				return <GroupsData />;
		}
	};

	return (
		<div className="main-content">
			<div className="url color-grey big-first-letter">
				{location.pathname.slice(1).replaceAll('/', ' / ')}
			</div>
			<div className="title-block-wrap">
				<div className="title-block">
					<Link className="back-button" to={ELinksName.GROUPS}>
						<ArrowBackSvg />
					</Link>
					<p className="title big-first-letter">elloapp.org/Motorist</p>
				</div>
			</div>
			<GroupsTabs />
			<div className="table-block">
				<div className="filter-custom">
					{!channelsTabs.includes(params.tab || '') && (
						<div
							className={classNames(
								'input input-item-margin input-custom input-search input-search-custom',
								{
									'input-search-wrapper': mediaTabs.includes(params.tab || ''),
								},
							)}
						>
							{mediaTabs.includes(params.tab || '') && <MediaSubtabs onSetType={() => {}} />}
							<div className="input-wrapper input-wrapper-custom">
								<input
									className="input-item input-item--left-icon"
									type="text"
									name="search"
									placeholder="Search by user, username, email"
								/>
								<span className="input-icon">
									<SearchSvg />
								</span>
							</div>
						</div>
					)}
					{params.tab === ETabsUsersDetails.CHANNELS && <SubTabsDetailsUser />}
					{params.tab === ETabsUsersDetails.CONVERSATION && <SubTabsConversations />}
					<div className="table-wrapper">{definePage()}</div>
				</div>
			</div>
			{/* pagination */}
		</div>
	);
};
