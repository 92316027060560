import React from 'react';

export const ButtonStub: React.FC = () => {
	return (
		<li>
			<button disabled type="button">
				...
			</button>
		</li>
	);
};
