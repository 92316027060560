import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICountries, IUtilsState } from './types';

export const initialState: IUtilsState = {
	countries: null,
	utilsLoader: false,
	photo: '',
};

const utils = createSlice({
	name: '@@utils',
	initialState,
	reducers: {
		getCountriesRequest: (state) => {
			const usersListState = state;
			usersListState.utilsLoader = true;
		},

		getCountriesSuccess: (state, action: PayloadAction<ICountries>) => {
			const utilsState = state;
			const { payload } = action;
			utilsState.utilsLoader = false;
			utilsState.countries = payload;
		},

		getPhotoRequest: (state, action: PayloadAction<string>) => {
			const usersListState = state;
			usersListState.utilsLoader = true;
		},

		getPhotoSuccess: (state, { payload }: PayloadAction<string>) => {
			const utilsState = state;

			utilsState.utilsLoader = false;
			utilsState.photo = payload;
		},

		utilsInitState: (state) => {
			const utilsInitState = state;
			utilsInitState.utilsLoader = false;
		},
	},
});

export default utils.reducer;
export const {
	getCountriesRequest,
	getCountriesSuccess,
	getPhotoRequest,
	getPhotoSuccess,
	utilsInitState,
} = utils.actions;
