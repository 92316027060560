import { ChangeEvent, FC, useEffect, useState } from 'react';
import { ArrowBackSvg, DeleteSvg, ErrorSvg } from 'assets/svg-icon';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EChannelsTabs } from 'services/constants/lists-table';
import { PostInfo } from './PostInfo/PostInfo';
import { useDispatch, useSelector } from 'react-redux';
import {
	deletePostRequest,
	getPostCommentsRequest,
	getPostRequest,
} from 'redux/reducers/channels/reducer';
import { getPostCommentsSelector, getPostSelector } from 'redux/reducers/channels/selectors';
import { Pagination } from 'ui/Pagination';

export const PostDetails: FC = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [search, setSearch] = useState<string>('');
	const [select, setSelect] = useState<string>('Show 5 on page');
	const navigate = useNavigate();

	const post = useSelector(getPostSelector);
	const comments = useSelector(getPostCommentsSelector);

	const onHandleSearch = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		setSearch(value);
	};

	const submitFilter = (value: number) => {
		setCurrentPage(value);
	};

	const removePost = (postId: string, type: string) => {
		console.log(postId);
		dispatch(
			deletePostRequest({
				payload: {
					channel_id: params.tab as string,
					post_id: postId,
				},
				type,
				navigate,
			}),
		);
	};

	useEffect(() => {
		dispatch(
			getPostRequest({
				channel_id: params.tab as string,
				post_id: params.id as string,
			}),
		);
	}, []);

	useEffect(() => {
		dispatch(
			getPostCommentsRequest({
				paramsApi: {
					channel_id: params.tab as string,
					post_id: params.id as string,
				},
				body: {
					search,
					per_page: +select.replace(/\D+/g, ''),
					current_page: currentPage,
				},
			}),
		);
	}, [search, select, currentPage]);

	return (
		<div className="main-content">
			<div className="title-block-wrap">
				<div className="title-block">
					<Link
						className="back-button"
						to={`${ELinksName.CHANNEL_DETAIL_USER}/${params.tab}/all-channels/${EChannelsTabs.POST}`}
					>
						<ArrowBackSvg />
					</Link>
					<p className="title">Post</p>
				</div>
			</div>
			<div className="table-block table-block-wrapper">
				<div className="user-data">
					<div className="">
						<div className="user-data__block right width left-custom">
							<div className="user-data__toggle-wrapper right pointer">
								<ErrorSvg />
								<span className="user-data__toggle-text user-data__toggle-text-custom orange">
									Warn about violation
								</span>
							</div>
							<div
								onClick={() => removePost(String(params.id), 'removeAll')}
								className="user-data__toggle-wrapper right pointer"
							>
								<DeleteSvg />
								<span className="user-data__toggle-text user-data__toggle-text-custom red show">
									Delete all media
								</span>
							</div>
						</div>
					</div>
					<div className="user-data__body">
						{post ? (
							<PostInfo
								onHandleSearch={onHandleSearch}
								post={post}
								comments={comments}
								search={search}
								setSelect={setSelect}
								removePost={removePost}
							/>
						) : null}
					</div>
				</div>
			</div>
			{comments?.comments?.length && comments?.total > 15 ? (
				<Pagination
					request={submitFilter}
					currentPage={currentPage}
					numberPage={Math.ceil(comments.total / 15)}
				/>
			) : null}
		</div>
	);
};
