import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EReportsTabs } from 'services/constants/lists-table';

export const ReportsTabs: FC = () => {
	const params = useParams();

	return (
		<div className="tabs-buttons-nav">
			<Link
				to={`${ELinksName.REPORTS}/${EReportsTabs.CHANNELS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EReportsTabs.CHANNELS || !params.tab,
				})}
			>
				Channels
			</Link>
			<Link
				to={`${ELinksName.REPORTS}/${EReportsTabs.GROUPS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EReportsTabs.GROUPS,
				})}
			>
				Groups
			</Link>
			<Link
				to={`${ELinksName.REPORTS}/${EReportsTabs.CHANNEL_POSTS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EReportsTabs.CHANNEL_POSTS,
				})}
			>
				Channel posts
			</Link>
			<Link
				to={`${ELinksName.REPORTS}/${EReportsTabs.MESSAGES_IN_GROUPS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EReportsTabs.MESSAGES_IN_GROUPS,
				})}
			>
				Messages in groups
			</Link>
		</div>
	);
};
