import axios, { isAxiosError } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
// import L from 'i18n-react';
import { responseErrors } from 'services';
import { downloadInitState, getFileRequest, getFileSuccess } from './reducer';
import { downloadFile } from 'untils/downloadFile';
import { IFileConfig } from './types';
import { onHttp } from 'untils/onHttp';

function* getFileWorker(action: PayloadAction<IFileConfig>) {
	const { endpoint } = action.payload;

	try {
		yield axios({
			method: 'get',
			url: onHttp() + endpoint,
			responseType: 'blob',
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
			},
		}).then(function (response) {
			downloadFile(
				response.data,
				response.headers['content-type'],
				response.headers['content-disposition'],
			);
		});
		yield put(getFileSuccess());
	} catch (error) {
		console.log(error);
		yield put(downloadInitState());
		if (isAxiosError(error)) responseErrors(error.response);
	}
}

export function* downloadSaga() {
	yield takeEvery(getFileRequest.type, getFileWorker);
}
