import classNames from 'classnames';
import { FC } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EAiChatBotTabs } from 'services/constants/lists-table';

export const AIChatBotTabs: FC<{ idBot: number | undefined }> = ({ idBot }) => {
	const params = useParams();

	return (
		<div className="tabs-buttons-nav">
			<Link
				to={`${ELinksName.AI_CHAT_BOT}/${EAiChatBotTabs.AI_BOTS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EAiChatBotTabs.AI_BOTS || !params.tab,
				})}
			>
				AI bots
			</Link>
			<Link
				to={`${ELinksName.AI_CHAT_BOT}/${EAiChatBotTabs.PRICING}/${idBot}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EAiChatBotTabs.PRICING,
				})}
			>
				Pricing
			</Link>
			<Link
				to={`${ELinksName.AI_CHAT_BOT}/${EAiChatBotTabs.BOTS_STATISTICS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EAiChatBotTabs.BOTS_STATISTICS,
				})}
			>
				Bots statistics
			</Link>{' '}
			<Link
				to={`${ELinksName.AI_CHAT_BOT}/${EAiChatBotTabs.RECOMMENDED_AI_BOTS}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EAiChatBotTabs.RECOMMENDED_AI_BOTS,
				})}
			>
				Recommended AI bots
			</Link>
			<Link
				to={`${ELinksName.AI_CHAT_BOT}/${EAiChatBotTabs.LIST_CODES}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === EAiChatBotTabs.LIST_CODES,
				})}
			>
				List of generated codes
			</Link>
		</div>
	);
};
