import { FC } from 'react';
import { PostsHeader } from './PostsHeader/PostsHeader';
import { PostsBody } from './PostsBody/PostsBody';
import { IPostsResponse } from 'redux/reducers/channels/types';
import { NotFound } from 'ui/NotFound/NotFound';

interface IPropsPosts {
	posts: IPostsResponse;
	loader: boolean;
}

export const Posts: FC<IPropsPosts> = ({ posts, loader }) => {
	return (
		<div className="table table--channels-private-post margin-top">
			<PostsHeader />
			{posts?.posts?.length ? (
				<PostsBody posts={posts} loader={loader} />
			) : (
				<NotFound name={'Channel Admins'} />
			)}
		</div>
	);
};
