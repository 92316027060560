export const dataAmount = ['Show 25 on page', 'Show 15 on page', 'Show 5 on page'];

export const backgroundTypeList = ['Single'];

export const fileTypeList = ['Image', 'Video', 'File'];

export const partnerTypeList = ['Marvel', 'NetFlix', 'Disnep'];

export const reportList = ['Export', 'Import'];
export const roleList = ['Admin', 'User Manager', 'Help Desk', 'Billing manager'];
