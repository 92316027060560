import { isAxiosError } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
// import L from 'i18n-react';
import { api, responseErrors } from 'services';
import { notificationContainer } from '../../../untils/notificationContainer';
import {
	getUsernamesRequest,
	getUsernamesSuccess,
	editUsernamesRequest,
	editUsernamesSuccess,
	addUsernamesRequest,
	addUsernamesSuccess,
	deleteUsernamesRequest,
	deleteUsernamesSuccess,
	reservedUsernamesInitState,
	checkUsernamesRequest,
} from './reducer';
import {
	ICreateUsernames,
	IDeleteName,
	IUsersName,
	IUsersNameRequest,
	IUsersNameResponse,
} from './types';

function* getUsernamesWorker(action: PayloadAction<IUsersNameRequest>) {
	const { payload } = action;

	try {
		const response: { data: IUsersNameResponse } = yield call(api.reserved.getUsernames, payload);
		yield put(getUsernamesSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(reservedUsernamesInitState());
	}
}

function* editUsernamesWorker(action: PayloadAction<ICreateUsernames>) {
	const { onClose, body } = action.payload;

	try {
		const response: { data: IUsersName } = yield call(api.reserved.editUsernames, body);
		yield put(editUsernamesSuccess(response.data));
		yield body.username
			? notificationContainer('The name of the edited', 'info')
			: notificationContainer('Added exception to use', 'info');
		onClose?.();
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(reservedUsernamesInitState());
	}
}

function* addUsernamesWorker(action: PayloadAction<ICreateUsernames>) {
	const { onClose, body } = action.payload;

	try {
		const response: { data: IUsersName } = yield call(api.reserved.addUsernames, body);
		yield put(addUsernamesSuccess(response.data));
		yield onClose?.();
		yield notificationContainer('The name was add to the exception', 'info');
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(reservedUsernamesInitState());
	}
}

function* deleteUsernamesWorker({ payload }: PayloadAction<IDeleteName>) {
	const { id, onClose, body } = payload;

	try {
		yield call(api.reserved.deleteUsernames, id);
		const response: { data: IUsersNameResponse } = yield call(api.reserved.getUsernames, body);
		yield put(getUsernamesSuccess(response.data));
		yield onClose?.();
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(reservedUsernamesInitState());
	}
}

function* checkUsernamesWorker(action: PayloadAction<string>) {
	const { payload } = action;

	try {
		yield call(api.reserved.checkUsernames, payload);
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(reservedUsernamesInitState());
	}
}

export function* reservedUsernamesSaga() {
	yield takeEvery(getUsernamesRequest.type, getUsernamesWorker);
	yield takeEvery(editUsernamesRequest.type, editUsernamesWorker);
	yield takeEvery(addUsernamesRequest.type, addUsernamesWorker);
	yield takeEvery(deleteUsernamesRequest.type, deleteUsernamesWorker);
	yield takeEvery(checkUsernamesRequest.type, checkUsernamesWorker);
}
