import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	IContent,
	IMassagesResponse,
	INote,
	ISupportState,
	ISupportsInfo,
	ITicketsResponse,
	ITypes,
	IUsers,
} from './types';

const getSupportState = (state: IStoreState): ISupportState => state.support;

export const getUsersSelector = createSelector(
	[getSupportState],
	(support: ISupportState): null | IUsers => support.users,
);

export const getSubscribeAllSelector = createSelector(
	[getSupportState],
	(support: ISupportState): null | ISupportsInfo => support.subscribeAll,
);

export const getTypesSelector = createSelector(
	[getSupportState],
	(support: ISupportState): null | Array<ITypes> => support.types,
);

export const getMasageSelector = createSelector(
	[getSupportState],
	(support: ISupportState): null | IMassagesResponse => support.massages,
);

export const getNotesSelector = createSelector(
	[getSupportState],
	(support: ISupportState): null | Array<INote> => support.notes,
);

export const getClient = createSelector(
	[getSupportState],
	(support: ISupportState): string => support.client,
);

export const getTickets = createSelector(
	[getSupportState],
	(support: ISupportState): ITicketsResponse | null => support.tickets,
);

export const getChatList = createSelector(
	[getSupportState],
	(
		support: ISupportState,
	): null | Array<{
		admin: boolean;
		mime_type?: string;
		message: string;
		hash?: string;
		type?: string;
	}> => support.chatList,
);
