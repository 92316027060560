import { FC } from 'react';
import avatar from '../../../../assets/images/Photos.png';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';

export const MediaSalesBody: FC = () => {
	return (
		<div className="table-body">
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">Content</p>
					<div className="avatar-wrapper">
						<div className="avatar avatar--square">
							<img src={avatar} alt="" />
						</div>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Name</p>
					<Link to={`${ELinksName.NAME_EXEMPLE}/${1}`}>Name example</Link>
				</div>
				<div className="td">
					<p className="td-hidden-name">Type</p>
					<p>
						{/* <Link
							to={`${ELinksName.USERS_USER_DETAILS}/${params.id || ''}`}
							className="link link--type2"
						> */}
						Image
						{/* </Link> */}
					</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Price</p>
					<p>$15.00</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Available quantity</p>
					<p>1 345</p>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Sales</p>
					<p>456</p>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Amount of earnings</p>
					<p>$6,840.00</p>
					<p className="gray">3:06 PM</p>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Created date</p>
					<p>Sep 24, 2022</p>
					<p className="gray">3:06 PM</p>
				</div>
			</div>
		</div>
	);
};
