import { ArrowBackSvg } from 'assets/svg-icon';
import { MonetizationDetails } from 'components/MonetizationDetailsComponent/MonetizationDetails/MonetizationDetails';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { Button } from 'ui/Button/Button';

export const MonetizationDetailsPage: FC = () => {
	const location = useLocation();

	return (
		<div className="main-content">
			<div className="url color-grey big-first-letter">
				{location.pathname.slice(1).replaceAll('/', ' / ')}
			</div>
			<div className="title-block-wrap">
				<div className="header-wrapper">
					<div className="title-block big-first-letter">
						<Link className="back-button" to={ELinksName.MONETIZATION}>
							<ArrowBackSvg />
						</Link>
						<p className="title">{`Background: ${'Gradient 1'}`}</p>
					</div>
					<Button className="button-wrapper" disabled={true} type={'button'} name={'Save'} />
				</div>
				<MonetizationDetails />
			</div>
		</div>
	);
};
