import { Block } from 'components/Popups/Block';
import { FC, MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EChannelUpgradeType } from 'services/constants/type';
import { CheckSmallSvg, StarOffSvg, CloseRedSvg, CopySvg, BlockSvg } from 'assets/svg-icon';
import { mockDataChanel } from 'components/ChannelsComponents/ChannelsBodyTable/mockData';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';

export interface IOpenPopup {
	id: string;
	name: string;
}

export const Body: FC = () => {
	const [onChangeVerification, setOnChangeVerification] = useState<number | null>(null);
	const dispatch = useDispatch();
	const [isOpenPopup, setIsOpenPopup] = useState<IOpenPopup | null>(null);

	const upgradeChannel = (event: MouseEvent) => {
		const target = event.currentTarget as HTMLButtonElement;
		// (isOpenPopup || target.name !== EChannelUpgradeType.BLOCK) &&
		// dispatch(
		// 	upgradeChannelRequest({
		// 		body: { type: target.name },
		// 		chat_id: String(target.id),
		// 	}),
		// );
		// setOnChangeVerification(null);
	};

	return (
		<div className="table-body">
			{isOpenPopup && (
				<Block
					isOpenPopup={isOpenPopup}
					upgradeChannel={upgradeChannel}
					setIsOpenPopup={setIsOpenPopup}
				/>
			)}
			{[1, 2, 3, 4, 5].map((_, index) => (
				<div key={index} className="tr">
					<div className="td">
						<p className="td-hidden-name">Content number</p>
						<p>123456</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Album name</p>
						<Link to={ELinksName.ALBUM_DETAILS}>
							<p className="link-color">One Percent</p>
						</Link>
					</div>
					<div className="td">
						<p className="td-hidden-name">Artist name</p>
						<Link to={ELinksName.ALBUM_DETAILS}>
							<p>Guy Hawkins</p>
						</Link>
					</div>
					<div className="td">
						<p className="td-hidden-name">Partner username</p>
						<Link to={ELinksName.ALBUM_DETAILS}>
							<p>@L&apos;Oréal</p>
						</Link>
					</div>

					<div className="td">
						<p className="td-hidden-name">Record label</p>
						<p className="upper-text-custom">L&apos;Oréal</p>
					</div>

					<div className="td">
						<p className="td-hidden-name">Genre</p>
						<p className="upper-text-custom">Rock</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Number of tracks</p>
						<p className="upper-text-custom">{index}</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Date of registration</p>
						<p className="upper-text-custom">Sep 24, 2022</p>
						<p className="upper-text-custom gray">3:06 PM</p>
					</div>
				</div>
			))}
		</div>
	);
};
