import classNames from 'classnames';
import { FC } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import {
	EBackgroundTabs,
	EMonetizationTabs,
	ESubTabsFinance,
	ETabsContent,
} from 'services/constants/lists-table';

export const Tabs: FC = () => {
	const params = useParams();

	return (
		<div className="tabs-buttons-nav">
			<Link
				to={`${ELinksName.CONTENT}/${ETabsContent.TRACK}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsContent.TRACK || !params.tab,
				})}
			>
				Track
			</Link>
			<Link
				to={`${ELinksName.CONTENT}/${ETabsContent.ALBUM}`}
				className={classNames('tabs-buttons-nav-link', {
					active: params.tab === ETabsContent.ALBUM,
				})}
			>
				Album
			</Link>
		</div>
	);
};
