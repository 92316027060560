import { FC } from 'react';
import { useParams, Link } from 'react-router-dom';
import avatar from 'assets/images/avatar-square.png';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EMediaSubtabs } from 'services/constants/lists-table';
import { IFiles } from 'redux/reducers/channels/types';
import moment from 'moment';
import { IContent, onJsonParseBigin, onJsonParseContent } from 'untils/jsonParse';
import { Avatar } from 'ui/Avatar/Avatar';

interface IProps {
	media: Array<IFiles>;
}

export const MediaBody: FC<IProps> = ({ media }) => {
	const params = useParams();

	return (
		<div className="table-body">
			{media?.map((element) => {
				const result: IContent = onJsonParseBigin(element.media);

				const name_file =
					result?.document?.attributes?.[1]?.file_name ||
					result?.photo_FLAGPHOTO?.attributes?.[1]?.file_name ||
					result?.document?.attributes?.[0]?.file_name ||
					result?.photo_FLAGPHOTO?.attributes?.[0]?.file_name;

				return (
					<div key={element.id} className="tr">
						<div className="td">
							<p className="td-hidden-name">Media</p>
							<Link
								to={`${ELinksName.MEDIA_DETAILS}/${params.id}/${element.id}/${params.subTab}`}
								className="avatar-wrapper"
							>
								<div className="avatar avatar--square">
									<Avatar
										hash={String(
											result?.document?.access_hash || result?.photo_FLAGPHOTO?.access_hash,
										).trim()}
										type={result?.document?.mime_type || ''}
										endpoint={
											result?.predicate_name.toLowerCase().includes('document')
												? '/storage/document'
												: '/storage/photo'
										}
									/>
								</div>
								<p>{name_file}</p>
							</Link>
						</div>
						<div className="td">
							<p className="td-hidden-name">Author</p>
							<p>{element.author}</p>
						</div>
						<div className="td td--right">
							<p className="td-hidden-name">Published</p>
							<p>{moment(element.published).format('MMM DD, YYYY')}</p>
							<p className="gray">{moment(element.published).format('h:mm A')}</p>
						</div>
						{EMediaSubtabs.LINKS === params.subTab && (
							<div className="td td--right">
								<p className="td-hidden-name">Action</p>
								<p>{moment(element.published).format('MMM DD, YYYY')}</p>
								<p className="gray">{moment(element.published).format('h:mm A')}</p>
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
};
