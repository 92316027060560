import { HeaderSuport } from 'components/HeaderSuport/HeaderSuport';
import { SidebarSupportLeft } from 'components/SidebarSupportLeft/SidebarSupportLeft';
import { SidebarSupportRight } from 'components/SidebarSupportRight/SidebarSupportRight';
import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	Navigate,
	Outlet,
	useLocation,
	useNavigate,
	useOutletContext,
	useParams,
} from 'react-router-dom';
import { getIsAuth, getToken } from 'redux/reducers/auth/selectors';
import { ELinksName } from 'services/constants/list-links-sidebar';
import {
	// getMassageRequest,
	getNotesRequest,
	getTypesRequest,
	getUsersRequest,
	removeListChats,
	subscribeAllRequest,
	ticketsRequest,
	changeStatus,
	onConect,
	setMassage,
} from 'redux/reducers/support/reducer';
import { getClient, getUsersSelector } from 'redux/reducers/support/selectors';
import { IUsersRequest } from 'redux/reducers/support/types';
import { getUserRequest } from 'redux/reducers/users/reducer';
import { setStatus, setTabSupport } from 'redux/reducers/app/reducer';
import { getTabNameSupport } from 'redux/reducers/app/selectors';
import { Centrifuge } from 'centrifuge';
import { onHttpCentrifuge } from 'untils/onHttp';
import { getTokenRefreshFatch } from 'untils/getTokenFatch';
import { onJsonParseBigin } from 'untils/jsonParse';
import { IsJsonString } from 'untils/isStringJson';
import { getUserDetails } from 'redux/reducers/users/selectors';
import { index } from 'untils/formValidation';

type ContextType = {
	centrifuge: any;
};

interface IMassage {
	channel: string;
	data: {
		admin: boolean;
		input: string;
		admin_id: number;
	};
}

const SuportLayout: FC = () => {
	const dispatch = useDispatch();
	const isAuth = useSelector(getIsAuth);
	const location = useLocation();
	const [openUser, setOpenUser] = useState<number>(0);
	const [hideLeft, setHideLeft] = useState(false);
	const [hideRight, setHideRight] = useState(false);
	const users = useSelector(getUsersSelector);
	const [filter, setFilter] = useState<IUsersRequest>({});
	const token = useSelector(getToken);
	const client = useSelector(getClient);
	const nameTabSupport = useSelector(getTabNameSupport);
	const navigate = useNavigate();
	const [fetching, setFetching] = useState<boolean>(false);
	const [offset, setOffset] = useState<number>(0);
	const [id_user, setId_user] = useState<number>(0);
	const userData = useSelector(getUserDetails);
	const { search } = useLocation();

	const centrifuge = new Centrifuge(onHttpCentrifuge() + 'wsapp', {
		token: token || localStorage.getItem('token') || '',
		getToken: getTokenRefreshFatch || '',
	});

	useEffect(() => {
		if (isAuth && token) {
			centrifuge.connect();
			console.log('Start');
			centrifuge.on('connected', function (ctx: { client: string }) {
				dispatch(onConect(ctx.client));
			});
		}
	}, []);

	useEffect(() => {
		if (isAuth && token) {
			centrifuge.on('publication', (message: IMassage) => {
				const result: any = IsJsonString(message?.data?.input)
					? onJsonParseBigin(message?.data?.input)
					: message?.data?.input;

				if (result.document) {
					dispatch(getUsersRequest({}));
				}
				console.log(message);

				if (message.channel === 'service') {
					if (result?.in?.userId !== userData?.data.id) {
						return;
					}

					dispatch(
						ticketsRequest({
							previous: nameTabSupport === 'Previos' ? true : false,
							current_page: 1,
							per_page: 15,
							user_id: result?.in?.userId,
						}),
					);

					console.log(result?.in);

					if (result?.type || result?.type === 0) {
						dispatch(changeStatus({ id: result.id as number, type: result.type }));
					}
					if (result?.in?.outMessage) {
						dispatch(getUsersRequest({}));

						// dispatch(
						// 	ticketsRequest({
						// 		current_page: 1,
						// 		per_page: 15,
						// 		user_id: result?.in?.UserId,
						// 	}),
						// );
						// dispatch(setMassage({ admin: false, message: result?.in?.outMessage?.message }));
					}
					if (result?.outMessage?.message) {
						dispatch(getUsersRequest({}));
						// console.log(result?.outMessage);

						// dispatch(
						// 	ticketsRequest({
						// 		current_page: 1,
						// 		per_page: 15,
						// 		user_id: result?.in?.UserId,
						// 	}),
						// );
						dispatch(setMassage({ admin: false, message: result?.outMessage?.message }));
						return;
					}
					dispatch(subscribeAllRequest());
				} else {
					if (!message?.data.admin_id) {
						const result: any = onJsonParseBigin(message?.data?.input);

						const idUserActiveChat = window.location.href.slice(
							window.location.href.indexOf('?') + 1,
						);
						console.log(result, idUserActiveChat);

						// if (result?.userId === Number(idUserActiveChat)) {
						// 	console.log('document');

						// 	if (message?.data.admin_id) {
						// 		return;
						// 	}

						// 	dispatch(
						// 		setMassage({
						// 			admin: true,
						// 			message: result?.outMessage?.message,
						// 			hash: result?.document?.access_hash,
						// 			type: result?.document?.mime_type,
						// 		}),
						// 	);
						// 	return;
						// }

						if (result?.outMessage?.message) {
							result?.userId === Number(idUserActiveChat) &&
								dispatch(setMassage({ admin: false, message: result?.outMessage?.message }));
						}

						if (result?.document) {
							console.log('document');

							dispatch(
								setMassage({
									admin: true,
									message: result?.outMessage?.message,
									hash: result?.document?.access_hash,
									type: result?.document?.mime_type,
								}),
							);
						}

						if (result?.outMessage?.message) {
							// result?.userId === id_user && dispatch(getUsersRequest({}));
							console.log(result?.userId, id_user);

							// dispatch(
							// 	ticketsRequest({
							// 		current_page: 1,
							// 		per_page: 15,
							// 		user_id: result?.in?.UserId,
							// 	}),
							// );
							result?.userId === search.slice(1) &&
								dispatch(setMassage({ admin: false, message: result?.outMessage?.message }));
						}

						if (result?.media) {
							console.log('media', result, userData?.data.id);
							result?.user_id === userData?.data.id &&
								dispatch(
									setMassage({
										admin: false,
										type:
											result?.media?.photo_FLAGPHOTO?.predicate_name ||
											result?.media?.document?.predicate_name,
										message: result?.outMessage?.message || result?.media?.document?.message,
										mime_type:
											result?.media?.photo_FLAGPHOTO?.mime_type ||
											result?.media?.document?.mime_type,
										hash:
											result?.media?.photo_FLAGPHOTO?.access_hash ||
											result?.media?.document?.access_hash,
									}),
								);
						}
					}

					if (message?.data.admin_id) {
						console.log(message?.data);
						dispatch(setMassage({ admin: true, message: result }));
					}
				}

				// console.log('support_id', support_id);

				// if (message.channel.includes(String(support_id))) {
				// 	dispatch(
				// 		getMassageRequest({
				// 			current_page: 1,
				// 			per_page: 10000,
				// 			id: support_id as number,
				// 		}),
				// 	);
				// }
			});
		}

		// return () => {
		// 	centrifuge.disconnect();
		// };
	}, [isAuth, userData]);

	const onOpenUser = (value: number, status: number | null = null, user_id: number) => {
		dispatch(removeListChats());
		setOpenUser(value === openUser ? 0 : value);
		dispatch(setTabSupport(value === openUser ? 0 : value));
		navigate({
			search: `?${user_id}`,
		});

		setId_user(user_id);

		dispatch(setStatus(status));
		value !== openUser &&
			dispatch(
				ticketsRequest({
					previous: nameTabSupport === 'Previos' ? true : false,
					current_page: 1,
					per_page: 15,
					user_id,
				}),
			);
		dispatch(
			getUserRequest({
				user_id: String(users?.data.find((user) => user.support_id === value)?.user_id) || '',
			}),
		);
		dispatch(getNotesRequest(String(value)));
	};

	const onFilter = (values: IUsersRequest) => {
		setFilter((prev) => ({ ...prev, ...values }));
	};

	const onSaveChange = () => {
		if (!filter?.country_code) {
			delete filter.country_code;
			delete filter.country_name;
		}

		if (!filter?.type_id) {
			delete filter.type_id;
		}

		dispatch(getUsersRequest(filter));
	};

	useEffect(() => {
		client && dispatch(subscribeAllRequest());
		dispatch(getTypesRequest());
	}, [location.pathname, client]);

	useEffect(() => {
		if (!filter?.country_code) {
			delete filter.country_code;
			delete filter.country_name;
		}

		dispatch(getUsersRequest(filter));
	}, [filter?.search]);

	useEffect(() => {
		dispatch(getUsersRequest({ ...filter, per_page: offset }));

		setOffset((prev) => prev + 15);
		setFetching(false);
	}, [fetching]);

	useEffect(() => {
		if (users && offset < users.total) {
			document?.querySelector('.users__list')?.addEventListener('scroll', handleScroll);
		}
		return () =>
			document.querySelector('.users__list')?.removeEventListener('scroll', handleScroll);
	}, [users]);

	const handleScroll = (e: any) => {
		const scrollHeight = e.target.scrollHeight;
		const currentHeight = e.target.scrollTop + window.innerHeight;

		if (users?.total) {
			if (currentHeight + 1 - scrollHeight < 100 && offset <= users.total) {
				setFetching(true);
			}
		}
	};

	if (!isAuth) return <Navigate to={ELinksName.LOGIN} state={{ from: location }} replace />;

	return (
		<div className="wrapper wrapper-support">
			<HeaderSuport
				setHideLeft={setHideLeft}
				setHideRight={setHideRight}
				hideLeft={hideLeft}
				hideRight={hideRight}
				openUser={openUser}
				id_user={id_user}
			/>
			<main className="main">
				<section className="main-section">
					<SidebarSupportLeft
						setHideLeft={setHideLeft}
						hideLeft={hideLeft}
						activUser={openUser}
						onOpenUser={onOpenUser}
						users={users}
						onFilter={onFilter}
						onSaveChange={onSaveChange}
						offset={offset}
						setOffset={setOffset}
					/>
					{openUser ? (
						<Outlet context={{ centrifuge }} />
					) : (
						<div className="main-content chats-background"></div>
					)}
					{openUser ? <SidebarSupportRight hideRight={hideRight} openUser={openUser} /> : null}
				</section>
			</main>
		</div>
	);
};

export default SuportLayout;

export function useSuport() {
	return useOutletContext<ContextType>();
}
