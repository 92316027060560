import Channels from 'pages/Dashboard/Channels';
import ChannelsDetailsUser from 'pages/Dashboard/ChannelsDetailsUser';
import Reports from 'pages/Dashboard/Reports';
// import Transaction from 'pages/Dashboard/Transaction';
import UserDetails from 'pages/Dashboard/UserDetails';
import Users from 'pages/Dashboard/Users';
import { Navigate } from 'react-router-dom';
import { ELinksName, EParams } from 'services/constants/list-links-sidebar';

import AuthLayout from '../layouts/AuthLayout';
import ErrorRoutes from './ErrorRoutes';
import { Dashboard } from 'pages/Dashboard/Dashboard';
import { Groups } from 'pages/Dashboard/Groups';
import { Admins } from 'pages/Dashboard/Admins/Admins';
import { Finance } from 'pages/Dashboard/Finance/Finance';
import { Monetization } from 'pages/Dashboard/Monetization/Monetization';
import { NameExemple } from 'pages/Dashboard/NameExemple/NameExemple';
import { PostDetails } from 'pages/Dashboard/PostDetails/PostDetails';
import { MediaAndFilesDetails } from 'pages/Dashboard/MediaAndFilesDetails/MediaAndFilesDetails';
import { GroupsDetails } from 'pages/Dashboard/GroupsDetails/GroupsDetails';
import { AiServices } from 'pages/Dashboard/AiServices/AiServices';
import { MonetizationDetailsPage } from 'pages/Dashboard/MonetizationDetails/MonetizationDetails';
import { ResetPassword } from 'pages/Auth/ResetPassword/ResetPassword';
import { AdminDetails } from 'pages/Dashboard/AdminDetails/AdminDetails';
import { ProtectedRoute } from './ProtectedRoute';
import Suport from 'pages/Dashboard/Suport/Suport';
import Reserved from 'pages/Dashboard/Reserved/Reserved';
import { RegistrationRequests } from 'pages/Dashboard/RegistrationRequests/RegistrationRequests';
import { ReportsDetails } from 'pages/Dashboard/ReportsDetails/ReportsDetails';
import { Content } from 'pages/Dashboard/Content/Content';
import { TrackDetails } from 'pages/Dashboard/TrackDetails/TrackDetails';
import { AlbumDetails } from 'pages/Dashboard/AlbumDetails/AlbumDetails';
import { EditTrack } from 'pages/Dashboard/EditTrack/EditTrack';
import { SystemChannels } from 'pages/Dashboard/SystemChannels/SystemChannels';
import { TermManagement } from 'pages/Dashboard/TermManagement/TermManagement';
import { TermManagementChange } from 'pages/Dashboard/TermManagementChange/TermManagementChange';
import { ElloPartners } from 'pages/Dashboard/ElloPartners/ElloPartners';
import { RequestDetails } from 'components/ElloPartnersComponents/RequestDetails/RequestDetails';
import { ManagementDetails } from 'components/ElloPartnersComponents/ManagementDetails/ManagementDetails';
import { ApkFile } from 'pages/ApkFile/ApkFile';
import { UserPartner } from 'pages/Dashboard/UserPartner/UserPartner';
import { AiChatBot } from 'pages/Dashboard/AiChatBot/AiChatBot';
import { Reactions } from 'pages/Dashboard/Reactions/Reactions';
import { AddReaction } from 'components/ReactionsComponent/AddReaction/AddReaction';
import { StatisticsBot } from 'components/AIChatBotComponents/BotsStatistics/Statistics/Statistics';
import { EditAiChatBot } from 'pages/Dashboard/EditAiChatBot/EditAiChatBot';
import { CreateBot } from 'pages/Dashboard/CreateBot/CreateBot';
import { CodesInfo } from 'components/AIChatBotComponents/ListCodes/CodesInfo/CodesInfo';

const PrivateRoutes = {
	path: '/',
	element: <AuthLayout />,
	children: [
		{
			element: (
				<ProtectedRoute
					roles={[
						'owner',
						'admin',
						'user_manager',
						'help_desk',
						'billing_manager',
						'manager',
						'user',
						'help',
					]}
				>
					<Dashboard />
				</ProtectedRoute>
			),
			path: ELinksName.DASHBOARD + EParams.TAB,
		},
		{
			element: <Channels />,
			path: ELinksName.CHANNELS + EParams.TAB,
		},
		{
			element: <PostDetails />,
			path: ELinksName.POST_DETAILS + EParams.TAB + EParams.ID,
		},
		{
			element: <MediaAndFilesDetails />,
			path: ELinksName.MEDIA_DETAILS + EParams.CHANNEL_ID + EParams.ID + EParams.TAB,
		},
		{
			element: <NameExemple />,
			path: ELinksName.NAME_EXEMPLE + EParams.ID,
		},
		{
			element: <Groups />,
			path: ELinksName.GROUPS + EParams.TAB,
		},
		{
			element: <GroupsDetails />,
			path: ELinksName.GROUPS_DETAILS + EParams.ID + EParams.TAB + EParams.SUBTAB,
		},
		{
			element: (
				<ProtectedRoute roles={['owner']}>
					<Admins />
				</ProtectedRoute>
			),
			path: ELinksName.ADMINS + EParams.TAB,
		},
		{
			element: (
				<ProtectedRoute roles={['owner']}>
					<AdminDetails />
				</ProtectedRoute>
			),
			path: ELinksName.ADMINS_DETAILS + EParams.ID,
		},
		{
			element: <Finance />,
			path: ELinksName.FINANCE + EParams.TAB + EParams.SUBTAB,
		},
		{
			element: <Monetization />,
			path: ELinksName.MONETIZATION + EParams.TAB + EParams.SUBTAB,
		},
		{
			element: <MonetizationDetailsPage />,
			path: ELinksName.MONETIZATION_DETAILS,
		},
		// {
		// 	element: <Transaction />,
		// 	path: ELinksName.TRANSACTIONS + EParams.TAB + EParams.SUBTAB,
		// },
		{
			element: <UserDetails />,
			path: ELinksName.USER_DETAILS + EParams.ID + EParams.USER_ID + EParams.TAB + EParams.SUBTAB,
		},
		{
			element: <ChannelsDetailsUser />,
			path:
				ELinksName.USERS_USER_DETAILS + EParams.ID + EParams.USER_ID + EParams.TAB + EParams.SUBTAB,
		},
		{
			element: <Users />,
			path: ELinksName.USERS + EParams.TAB + EParams.SUBTAB,
		},
		{
			element: <ChannelsDetailsUser />,
			path:
				ELinksName.CHANNEL_DETAIL_USER +
				EParams.ID +
				EParams.USER_ID +
				EParams.TAB +
				EParams.SUBTAB,
		},
		{
			element: <AiServices />,
			path: ELinksName.AI_SERVICES + EParams.TAB + EParams.SUBTAB,
		},
		{
			element: <Reports />,
			path: ELinksName.REPORTS + EParams.TAB,
		},

		{
			element: <ReportsDetails />,
			path: ELinksName.REPORT_DETAILS + EParams.ID + EParams.TAB,
		},

		{
			element: <Reserved />,
			path: ELinksName.RESERVED_USERNAMES + EParams.TAB,
		},

		{
			element: <Content />,
			path: ELinksName.CONTENT + EParams.TAB,
		},

		{
			element: <TrackDetails />,
			path: ELinksName.TRACK_DETAILS,
		},

		{
			element: <EditTrack />,
			path: ELinksName.EDIT_TRACK + EParams.ID,
		},

		{
			element: <SystemChannels />,
			path: ELinksName.SYSTEM_CHANNELS,
		},

		{
			element: <TermManagement />,
			path: ELinksName.TERMS_MANAGEMENT,
		},

		{
			element: <TermManagementChange />,
			path: ELinksName.TERMS_MANAGEMENT_CHANGE + EParams.TAB,
		},

		{
			element: <AlbumDetails />,
			path: ELinksName.ALBUM_DETAILS,
		},

		{
			element: <ElloPartners />,
			path: ELinksName.ELLO_PARTNERS + EParams.TAB + EParams.SUBTAB,
		},

		{
			element: <RequestDetails />,
			path: ELinksName.REQUEST_DETAILS,
		},

		{
			element: <ManagementDetails />,
			path: ELinksName.MANAGEMENT_DETAILS_PERSONAL_DATA + EParams.TAB + EParams.SUBTAB,
		},

		{
			element: <RegistrationRequests />,
			path: ELinksName.REGISTRATION_REQUESTS + EParams.TAB,
		},
		{
			element: <ApkFile />,
			path: ELinksName.APK,
		},
		{
			element: <UserPartner />,
			path: ELinksName.USER_PARTNER + EParams.TAB + EParams.SUBTAB,
		},
		{
			element: <AiChatBot />,
			path: ELinksName.AI_CHAT_BOT + EParams.TAB + EParams.ID,
		},
		{
			element: <Reactions />,
			path: ELinksName.REACTIONS + EParams.TAB,
		},
		{
			element: <AddReaction />,
			path: ELinksName.REACTIONS_ADD + EParams.TAB,
		},
		{
			element: <AddReaction />,
			path: ELinksName.REACTIONS_EDIT + EParams.TAB,
		},
		{
			element: <StatisticsBot />,
			path: ELinksName.AI_CHAT_BOT_STATISTICS + EParams.ID + EParams.NAME,
		},
		{
			element: <EditAiChatBot />,
			path: ELinksName.EDIT_BOT + EParams.ID + EParams.NAME + EParams.TAB,
		},
		{
			element: <CreateBot />,
			path: ELinksName.CREATE_BOT + EParams.NAME + EParams.TAB,
		},
		{
			element: <CodesInfo />,
			path: ELinksName.CODES_INFO + EParams.ID + EParams.NAME,
		},
		// {
		// 	element: <ResetPassword />,
		// 	path: ELinksName.INVITE_LINK,
		// },
		{
			element: <Navigate to={ELinksName.DASHBOARD + EParams.TAB} replace />,
			path: '/',
		},
		ErrorRoutes,
	],
};

export default PrivateRoutes;
