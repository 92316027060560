import { DocxSvg, FigSvg, PdfSvg, XlsSvg, ZipSvg } from 'assets/svg-icon';

export const getTypeFile = (key: string) => {
	console.log(key);
	switch (key) {
		case 'application/zip':
			return <ZipSvg />;
		case 'application/docx':
			return <DocxSvg />;
		case 'application/xls':
			return <XlsSvg />;
		case 'application/pdf':
			return <PdfSvg />;
		case 'application/fig':
			return <FigSvg />;

		default:
			return <>Unknown file</>;
	}
};
