import { CloseRedSvg } from 'assets/svg-icon';
import moment from 'moment';
import { FC, MouseEvent } from 'react';
import { IBotList } from 'redux/reducers/ai-chat-bot/types';

interface IProps {
	onPopup: (event: MouseEvent) => void;
	botInfo: IBotList;
}

export const CodesInfoBody: FC<IProps> = ({ onPopup, botInfo }) => {
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Code</p>
				<p>{botInfo.code}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				<p>{botInfo.status}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Activation date</p>
				<p>{moment(botInfo.activation_time).format('MMM DD, YYYY')}</p>
				<p className="gray">{moment(botInfo.activation_time).format('h:mm A')}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">User ID</p>
				<p className="upper-text-custom">{botInfo.user_id}</p>
			</div>
			<div className="td td--right td--right-custom td--left">
				<p className="td-hidden-name">Action</p>
				{!botInfo.deactivated ? (
					<button id={String(botInfo.user_id)} name={botInfo.code} onClick={onPopup} type="button">
						<CloseRedSvg />
					</button>
				) : null}
			</div>
		</div>
	);
};
