/* eslint-disable no-nested-ternary */
import { Block } from 'components/Popups/Block';
import { FC, MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EChannelUpgradeType } from 'services/constants/type';
import { CheckSmallSvg, StarOffSvg, CloseRedSvg, CopySvg } from 'assets/svg-icon';
import { mockDataChanel } from 'components/ChannelsComponents/ChannelsBodyTable/mockData';

export interface IOpenPopup {
	id: string;
	name: string;
}

export const CryptoBody: FC = () => {
	const [onChangeVerification, setOnChangeVerification] = useState<number | null>(null);
	const dispatch = useDispatch();
	const [isOpenPopup, setIsOpenPopup] = useState<IOpenPopup | null>(null);

	const createAvataar = (title: string): string => {
		const array = title.trim().split(' ');
		return array.length >= 2
			? array[0][0].toUpperCase() + array[1][0].toUpperCase()
			: title.slice(0, 2).toUpperCase();
	};

	const upgradeChannel = (event: MouseEvent) => {
		const target = event.currentTarget as HTMLButtonElement;
		// (isOpenPopup || target.name !== EChannelUpgradeType.BLOCK) &&
		// 	dispatch(
		// 		upgradeChannelRequest({
		// 			body: { type: target.name },
		// 			chat_id: String(target.id),
		// 		}),
		// 	);
		// setOnChangeVerification(null);
	};

	return (
		<div className="table-body">
			{isOpenPopup && (
				<Block
					isOpenPopup={isOpenPopup}
					upgradeChannel={upgradeChannel}
					setIsOpenPopup={setIsOpenPopup}
				/>
			)}
			{[].map((channel) => (
				<div key={channel} className="tr">
					<div className="td">
						<p className="td-hidden-name">Tx ID</p>
						<p className="link-color">123456</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Full name</p>
						<p className="link-color">Cody Fisher</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Username</p>
						<p className="link-color">@Cooper</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Network</p>
						<p>ETH</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Currency</p>
						<p>USDT</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Date</p>
						<p className="upper-text-custom">Sep 24, 2022</p>
						<p className="upper-text-custom gray">3:06 PM</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Amount</p>
						<p className="upper-text-custom link-color">$550.00</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Fee</p>
						<p className="upper-text-custom">$550.00</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Wallet balance</p>
						<p className="upper-text-custom link-color">$550.00</p>
					</div>
					<div className="td action-flex-between td--left">
						<p className="td-hidden-name">Wallet address</p>
						<span>1BQ9qza7…iBtkt4ee</span>
						<span className="link-color">
							<CopySvg />
						</span>
					</div>
					<div className="td action-flex td--left">
						<p className="td-hidden-name">Action</p>
						<p className="upper-text-custom">
							<CheckSmallSvg />
						</p>
						<p className="upper-text-custom">
							<CloseRedSvg />
						</p>
					</div>
				</div>
			))}
		</div>
	);
};
