import classNames from 'classnames';
import { FC, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EChannelsTabs, EMediaSubtabs, EGroupsTabs } from 'services/constants/lists-table';

interface IProps {
	onSetType: (value: string) => void;
	onRestTab?: () => void;
}

export const MediaSubtabs: FC<IProps> = ({ onRestTab, onSetType }) => {
	const params = useParams();

	const getLink = () => {
		switch (params.tab) {
			case 'groups-media-and-files':
				return EGroupsTabs.MEDIA_AND_FILES;

			default:
				return EChannelsTabs.MEDIA_AND_FILES;
		}
	};

	useEffect(() => {
		return () => onRestTab?.();
	}, []);

	return (
		<div className="table-tabs table-tabs--type2 table-tabs-custom">
			<Link
				onClick={() => onSetType(EMediaSubtabs.MEDIA)}
				to={`${ELinksName.CHANNEL_DETAIL_USER}/${params.id}/${params.userId}/${getLink()}/${
					EMediaSubtabs.MEDIA
				}`}
				className={classNames('table-tabs__item table-tabs__item-custom table-tabs__item-padding', {
					active: params.subTab === EMediaSubtabs.MEDIA || !params.subTab,
				})}
			>
				Media
			</Link>
			<Link
				onClick={() => onSetType(EMediaSubtabs.FILES)}
				to={`${ELinksName.CHANNEL_DETAIL_USER}/${params.id}/${params.userId}/${getLink()}/${
					EMediaSubtabs.FILES
				}`}
				className={classNames('table-tabs__item table-tabs__item-custom table-tabs__item-padding', {
					active: params.subTab === EMediaSubtabs.FILES || !params.subTab,
				})}
			>
				Files
			</Link>
			<Link
				onClick={() => onSetType(EMediaSubtabs.AUDIO)}
				to={`${ELinksName.CHANNEL_DETAIL_USER}/${params.id}/${params.userId}/${getLink()}/${
					EMediaSubtabs.AUDIO
				}`}
				className={classNames('table-tabs__item table-tabs__item-custom table-tabs__item-padding', {
					active: params.subTab === EMediaSubtabs.AUDIO || !params.subTab,
				})}
			>
				Audio
			</Link>
			<Link
				onClick={() => onSetType(EMediaSubtabs.LINKS)}
				to={`${ELinksName.CHANNEL_DETAIL_USER}/${params.id}/${params.userId}/${getLink()}/${
					EMediaSubtabs.LINKS
				}`}
				className={classNames('table-tabs__item table-tabs__item-custom table-tabs__item-padding', {
					active: params.subTab === EMediaSubtabs.LINKS || !params.subTab,
				})}
			>
				Links
			</Link>
		</div>
	);
};
