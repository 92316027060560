import { FC } from 'react';

export const TermsHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr tr-not-border">
				<div className="td">
					<div className="td-name">
						<p>Document title</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Link to web page</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Last updated date</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Available languages</p>
					</div>
				</div>
			</div>
		</div>
	);
};
