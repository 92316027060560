import { store } from 'redux/store';
import { saveToken } from './saveToken';
import { authInitState, refreshResponse } from 'redux/reducers/auth/reducer';
import { WEB_API_URL, WEB_API_URL_STAGE } from 'services/constants/env';
import { onHttp } from './onHttp';

export async function getTokenRefreshFatch() {
	try {
		const res = await fetch(`${onHttp()}/auth/refresh_token`, {
			method: 'GET',
			credentials: 'omit',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-Refresh': localStorage.getItem('refresh_token') || '',
			},
		});
		const result: { access_token: string; refresh_token: string } = await res.json();

		if (!result?.refresh_token) {
			store.dispatch(authInitState());
		}

		result?.refresh_token && saveToken(result.access_token, result.refresh_token);
		result?.refresh_token && store.dispatch(refreshResponse(result));
		// window.location.reload();
		return result.access_token;
	} catch (error) {
		store.dispatch(authInitState());
		return '';
	}
}
