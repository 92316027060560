import { Accordion } from 'components/AIChatBotComponents/Appearance/Accordion/Accordion';
import { appearanceData } from 'components/AIChatBotComponents/Appearance/data';
import { FC } from 'react';

export const Appearance: FC = () => {
	return (
		<div className="structure">
			{appearanceData.map((structure, index) => (
				<div key={structure.id} className="structure__box">
					<Accordion structure={structure} />
				</div>
			))}
		</div>
	);
};
