import { FC } from 'react';
import avatar from '../../../../assets/images/ava.png';
import { HorizenPointsSvg, NotVerifiedGraySvg, StarOffSvg, StarOnSvg } from 'assets/svg-icon';
import { SvgVerifiedAccount } from 'ui/svg-image/SvgAllChannels';
import classNames from 'classnames';
import { IRecommended } from 'redux/reducers/ai-chat-bot/types';
import { Avatar } from 'ui/Avatar/Avatar';
import { createAvataar } from 'untils/createAvatar';

interface IProps {
	recommends: undefined | Array<IRecommended>;
}

export const RecommendedBody: FC<IProps> = ({ recommends }) => {
	return (
		<>
			{recommends?.map((recommend, index) => (
				<div key={recommend.bot_id} className="tr">
					<div className="td">
						<p className="td-hidden-name">№</p>
						<p>{index + 1}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name"> Avatar</p>
						<div className="avatar-wrapper">
							<div className="avatar avatar--circle">
								{recommend?.photo_access_hash ? (
									<Avatar hash={String(recommend?.photo_access_hash)} />
								) : (
									<div className="avatar-custom">
										<span>{createAvataar(recommend?.name)}</span>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name"> Bot name</p>
						<p>{recommend.name}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name"> Bot URL </p>
						<p>{recommend.username}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Category</p>
						<p>{recommend?.category || '-'}</p>
					</div>
					<div className="td td--right td--right-custom td--left">
						<p className="td-hidden-name">Recommended</p>
						<p className="upper-text-custom">
							{recommend?.recommended ? <StarOnSvg /> : <StarOffSvg />}
						</p>
					</div>
					<div className="td td--right td--right-custom td--left">
						<p className="td-hidden-name">Verified status</p>
						{recommend?.verified ? (
							<button type="button" className="vertified-btn">
								<SvgVerifiedAccount />
							</button>
						) : (
							<button type="button" className="vertified-btn">
								<NotVerifiedGraySvg />
							</button>
						)}
					</div>
					<div className={classNames('td td--right-custom pointer')}>
						<p className="td-hidden-name">Action</p>

						<div>
							<HorizenPointsSvg />
						</div>
					</div>
				</div>
			))}
		</>
	);
};
