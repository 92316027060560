import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IAiServices } from './types';
import { IGetWordsRequest } from 'redux/reducers/ai-services/types';

export const aiServices: IAiServices = {
	getBadWords: (payload: IGetWordsRequest) =>
		http.post(endpoint.aiServices.GET_BAD_WORDS, payload).then((response) => response.data),
	editBadWord: (payload: any) =>
		http.post(endpoint.aiServices.EDIT_BAD_WORDS, payload).then((response) => response.data),
	addBadWord: (payload: any) =>
		http.post(endpoint.aiServices.ADD_BAD_WORD, payload).then((response) => response.data),
	deleteBadWord: (payload: any) =>
		http.delete(endpoint.aiServices.DELETE_BAD_WORD(payload)).then((response) => response.data),
};
