import { IDeleteRequest, IUserRequest, IUsersRequest } from 'redux/reducers/users/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IAdminsApi } from './types';
import {
	I2FaRequest,
	IAddNewUserSubmit,
	IAdmin,
	IChangeAdmin,
	IDeleteUser,
} from 'ui/AddNewAdmins/type';
import { IAdminsRequest } from 'redux/reducers/admins/types';

export const admins: IAdminsApi = {
	adminsList: (params: IAdminsRequest) =>
		http.get(endpoint.admins.ADMINS_LIST, { params }).then((response) => response.data),
	createAdmins: (params: IAddNewUserSubmit) =>
		http.post(endpoint.admins.CREATE_ADMINS, params).then((response) => response.data),
	getAdmin: (params: IAdmin) =>
		http.get(endpoint.admins.ADMIN_INFO, { params }).then((response) => response.data),
	deleteAdmin: (params: IDeleteUser) =>
		http.post(endpoint.admins.DELETE_ADMIN, params).then((response) => response.data),
	changeAdmin: (params: IChangeAdmin) =>
		http.post(endpoint.admins.CHANGE_ADMIN, params).then((response) => response.data),
	enableOrDisableAdmin: (params: I2FaRequest) =>
		http.post(endpoint.admins.ENABLE_OR_DESABLE_2FA, params).then((response) => response.data),
	getCSV: () => http.get(endpoint.admins.GET_CSV).then((response) => response.data),
};
