import { PayloadAction } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'untils/notificationContainer';
import {
	sendLetterRequest,
	reportsInitState,
	getLetterSuccess,
	subscribeAllRequest,
	getUsersRequest,
	getUsersSuccess,
	subscribeAllSuccess,
	getTypesRequest,
	getMassageResponse,
	getMassageRequest,
	changeStatusRequest,
	changeStatusResponse,
	creareNoteRequest,
	getNotesRequest,
	getNotesResponse,
	getTypesResponse,
	editNoteRequest,
	deleteNoteRequest,
	ticketsResponse,
	ticketsRequest,
	createNoteResponse,
	editNoteResponse,
	deleteNoteResponse,
	subscribeSuccess,
	subscribeRequest,
	uploadFileRequest,
	changeTypeRequest,
	changeTypeResponse,
} from './reducer';
import {
	IChangeStatus,
	IChangeStatusResponse,
	IChannelsResponse,
	ICreateLetterRequest,
	IMassages,
	IMassagesResponse,
	INote,
	INoteRequest,
	INotesResponse,
	ISubscribe,
	ISupportsInfo,
	ITicketsRequest,
	ITicketsResponse,
	ITypes,
	IUploadFileRequred,
	IUsers,
	IUsersRequest,
} from './types';

function* sendLetterWorker(action: PayloadAction<ICreateLetterRequest>) {
	const { payload } = action;

	try {
		const response: { data: IChannelsResponse } = yield call(api.support.createLetter, payload);
		yield put(getLetterSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* subscribeAllWorker() {
	try {
		const response: { data: ISupportsInfo } = yield call(api.support.subscribeAll);
		yield put(subscribeAllSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* subscribeWorker({ payload }: PayloadAction<ISubscribe>) {
	try {
		const response: { data: ISupportsInfo } = yield call(api.support.subscribe, payload);
		yield put(subscribeSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* getUsersWorker(action: PayloadAction<IUsersRequest>) {
	const { payload } = action;

	try {
		const response: { data: IUsers } = yield call(api.support.getAllUsers, payload);
		yield put(getUsersSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* getTypesWorker() {
	try {
		const response: { data: Array<ITypes> } = yield call(api.support.getTypes);
		yield put(getTypesResponse(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* getMassagesWorker(action: PayloadAction<IMassages>) {
	const { payload } = action;
	try {
		const response: { data: IMassagesResponse } = yield call(api.support.getMassages, payload);
		yield put(getMassageResponse(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* changeStatusWorker(action: PayloadAction<IChangeStatus>) {
	const { payload } = action;

	try {
		const response: { data: Array<IChangeStatusResponse> } = yield call(
			api.support.changeStatus,
			payload,
		);
		yield put(changeStatusResponse(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* changeTypeWorker(action: PayloadAction<IChangeStatus>) {
	const { payload } = action;

	try {
		const response: { data: Array<IChangeStatusResponse> } = yield call(
			api.support.changeType,
			payload,
		);
		yield put(changeTypeResponse(payload));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* getNotesWorker(action: PayloadAction<string>) {
	const { payload } = action;

	try {
		const response: { data: Array<INote> } = yield call(api.support.getNotes, payload);
		yield put(getNotesResponse(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* createNoteWorker(action: PayloadAction<INoteRequest>) {
	const { payload } = action;

	try {
		const response: { data: INote } = yield call(api.support.postNote, payload);
		yield put(createNoteResponse(response.data));
		yield notificationContainer('The note created successfully', 'success');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* editNoteWorker(action: PayloadAction<INoteRequest>) {
	const { payload } = action;

	try {
		const response: { data: INote } = yield call(api.support.editNote, payload);
		yield put(editNoteResponse(response.data));
		yield notificationContainer('Note edited successfully', 'success');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* deleteNoteWorker(action: PayloadAction<string>) {
	const { payload } = action;

	try {
		yield call(api.support.deleteNote, payload);
		yield put(deleteNoteResponse(Number(payload)));
		yield notificationContainer('Note delete successfully', 'success');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* ticketsWorker(action: PayloadAction<ITicketsRequest>) {
	const { payload } = action;

	try {
		const response: { data: ITicketsResponse } = yield call(api.support.getTickets, payload);

		yield put(ticketsResponse(response.data));
		// yield notificationContainer('Note delete successfully', 'success');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

function* uploadFileWorker(action: PayloadAction<IUploadFileRequred>) {
	const { payload } = action;

	try {
		const response: { data: ITicketsResponse } = yield call(api.support.uploadFile, payload);
		// yield put(ticketsResponse(response.data));
		// yield notificationContainer('Note delete successfully', 'success');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(reportsInitState());
	}
}

export function* supportSaga() {
	yield takeEvery(sendLetterRequest.type, sendLetterWorker);
	yield takeEvery(subscribeAllRequest.type, subscribeAllWorker);
	yield takeEvery(subscribeRequest.type, subscribeWorker);
	yield takeEvery(getUsersRequest.type, getUsersWorker);
	yield takeEvery(getTypesRequest.type, getTypesWorker);
	yield takeEvery(getMassageRequest.type, getMassagesWorker);
	yield takeEvery(changeStatusRequest.type, changeStatusWorker);
	yield takeEvery(getNotesRequest.type, getNotesWorker);
	yield takeEvery(creareNoteRequest.type, createNoteWorker);
	yield takeEvery(editNoteRequest.type, editNoteWorker);
	yield takeEvery(deleteNoteRequest.type, deleteNoteWorker);
	yield takeEvery(ticketsRequest.type, ticketsWorker);
	yield takeEvery(uploadFileRequest.type, uploadFileWorker);
	yield takeEvery(changeTypeRequest.type, changeTypeWorker);
}
