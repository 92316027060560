import classNames from 'classnames';
import { FC, ReactNode } from 'react';

interface IProps {
	disabled?: boolean;
	type: 'submit' | 'button';
	name: string;
	children?: ReactNode | string;
	onClick?: () => void;
	className: string;
}

export const Button: FC<IProps> = ({ onClick, disabled, type, name, children = '', className }) => {
	return (
		<button onClick={onClick} className={classNames(className, { disabled: disabled })} type={type}>
			{children ? <span>{children}</span> : null}
			<span>{name}</span>
		</button>
	);
};
