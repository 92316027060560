import { SearchSvg } from 'assets/svg-icon';
import { ChangeEvent, FC } from 'react';
import { DebounceInput } from 'react-debounce-input';

interface IProps {
	value: string;
	handleChange: (event: ChangeEvent) => void;
}

export const InputDebounce: FC<IProps> = ({ value, handleChange }) => {
	return (
		<div className="input input-custom input-search input-search-custom">
			<div className="input-wrapper input-search-custom">
				<DebounceInput
					className="input-item input-item--left-icon"
					type="text"
					name="search"
					placeholder="Search"
					value={value || ''}
					onChange={handleChange}
					debounceTimeout={500}
				/>
				<span className="input-icon">
					<SearchSvg />
				</span>
			</div>
		</div>
	);
};
