import { FC } from 'react';
import { Header } from './Header/Header';
import { Body } from './Body/Body';

export const TrackTable: FC = () => {
	return (
		<div className="table-wrapper">
			<div className={`table table--track-customers table--channels-loader`}>
				<Header />
				<Body />
			</div>
		</div>
	);
};
