import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IChannelsStore } from './types';

const getChannelsState = (state: IStoreState): IChannelsStore => state.channels;

export const getChannelsListSelector = createSelector(
	[getChannelsState],
	(channels: IChannelsStore) => channels.channelsList,
);

export const getTopChannelListSelector = createSelector(
	[getChannelsState],
	(channels: IChannelsStore) => channels.topChannelList,
);

export const getStatisticSelector = createSelector(
	[getChannelsState],
	(channels: IChannelsStore) => channels.statistics,
);

export const getDetailsSelector = createSelector(
	[getChannelsState],
	(channels: IChannelsStore) => channels.detailsChannel,
);

export const getChannelsLoaderSelector = createSelector(
	[getChannelsState],
	(channels: IChannelsStore) => channels.channelsLoader,
);

export const getAdminsSelector = createSelector(
	[getChannelsState],
	(channels: IChannelsStore) => channels.admins,
);

export const getMembersSelector = createSelector(
	[getChannelsState],
	(channels: IChannelsStore) => channels.members,
);

export const getMediaSalesSelector = createSelector(
	[getChannelsState],
	(channels: IChannelsStore) => channels.mediaSails,
);

export const getPostsSelector = createSelector(
	[getChannelsState],
	(channels: IChannelsStore) => channels.posts,
);

export const getPostSelector = createSelector(
	[getChannelsState],
	(channels: IChannelsStore) => channels.post,
);

export const getPostCommentsSelector = createSelector(
	[getChannelsState],
	(channels: IChannelsStore) => channels.comments,
);

export const getListMediaFilesSelector = createSelector(
	[getChannelsState],
	(channels: IChannelsStore) => channels.mediaFiles,
);

export const getMediaFilesSelector = createSelector(
	[getChannelsState],
	(channels: IChannelsStore) => channels.mediaFile,
);

// export const getMessagesSelector = createSelector(
// 	[getChannelsState],
// 	(channels: IChannelsStore): IMessagesResponse | null => channels.channelMessages,
// );
