import { IAdminsResponse } from 'redux/reducers/admins/types';
import { IChangeAdmin } from 'ui/AddNewAdmins/type';

export const checkDifferent = (admin: IAdminsResponse, changeAdmin: IChangeAdmin): boolean => {
	const result = [
		admin.blocked === changeAdmin.blocked,
		admin.email === changeAdmin.email,
		admin.first_name === changeAdmin.first_name,
		admin.last_name === changeAdmin.last_name,
		admin.need_change_password === changeAdmin.need_change_password,
		admin.role === changeAdmin.role,
	];

	return result.includes(false);
};
