import { GoogleAuthSvg, LockSvg } from 'assets/svg-icon';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { enable2Fa, generateQrRequest, loginRequest } from 'redux/reducers/auth/reducer';
import { getQr } from 'redux/reducers/auth/selectors';
import QRCode from 'react-qr-code';
import { TwoFa } from 'components/2Fa/2Fa';
import { ILoginRequestPayload } from 'redux/reducers/auth/types';

interface IProps {
	onCloseQr: () => void;
	email: string;
	loginData: ILoginRequestPayload;
	setOpenQr: (value: string) => void;
	openQr: string;
}

export const QRCodePopup: FC<IProps> = ({ onCloseQr, email, loginData, openQr, setOpenQr }) => {
	const [code, setCode] = useState<string>('');

	const dispatch = useDispatch();
	const qrUrl = useSelector(getQr);

	const onHandleInput = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;

		value.length <= 6 && setCode(value);
	};

	useEffect(() => {
		openQr === 'no otp data' && dispatch(generateQrRequest({ email }));
	}, []);

	const sendCode = () => {
		dispatch(
			enable2Fa({
				paramsApi: { email, token: code },
				setOpenQr,
			}),
		);
	};

	const onLogin = (value: string) => {
		const data: ILoginRequestPayload = { ...loginData, otp_token: value };
		dispatch(loginRequest({ paramsApi: data, setOpenQr }));
	};

	return (
		<div className="authorization-form__body">
			{openQr !== 'no otp data' ? (
				<TwoFa onLogin={onLogin} />
			) : (
				<div className="authorization-form qr-code">
					<div className="authorization-form__logo">
						<GoogleAuthSvg />
					</div>
					<p className="form-title">Google Authenticator</p>
					<p className="qr-code__subtitle gray">Protecting your account with a digital key</p>
					<p className="qr-code__subtitle">Scan this QR code with the Google Authenticator.</p>
					<div className="qr-code__wrapper">
						<QRCode value={qrUrl} />
					</div>
					<div className="input">
						<p className="input__name">2FA-Authorization</p>
						<div className="input-wrapper">
							<input
								value={code}
								onChange={onHandleInput}
								className="input-item input-item--left-icon"
								type="number"
								name="number"
								placeholder="6-digit code"
							/>
							<span className="input-icon">
								<LockSvg />
							</span>
						</div>
						<p className="qr-code__info gray">Enter the verification code</p>
					</div>
					<div className="form-submit">
						<button
							onClick={sendCode}
							disabled={code.length !== 6}
							type="button"
							className="button button--full-width"
						>
							Enable 2FA
						</button>
					</div>
					<div className="form-submit">
						<button
							onClick={onCloseQr}
							type="button"
							className="button button--full-width button--gray"
						>
							Cancel
						</button>
					</div>
				</div>
			)}
		</div>
	);
};
