import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ESubtabsConversation } from 'services/constants/lists-table';

export const SubTabsConversations: FC = () => {
	const params = useParams();

	return (
		<div className="table-tabs table-tabs--type2 table-tabs-custom">
			<Link
				to={`${ELinksName.USER_DETAILS}/${params.id}/${params.userId}/${params.tab}/${ESubtabsConversation.CONVERSATIONS_CHANNELS}`}
				className={classNames('table-tabs__item', {
					active: params.subTab === ESubtabsConversation.CONVERSATIONS_CHANNELS || !params.subTab,
				})}
			>
				Member
			</Link>
			<Link
				to={`${ELinksName.USER_DETAILS}/${params.id}/${params.userId}/${params.tab}/${ESubtabsConversation.CONVERSATIONS_GROUP}`}
				className={classNames('table-tabs__item', {
					active: params.subTab === ESubtabsConversation.CONVERSATIONS_GROUP || !params.subTab,
				})}
			>
				Created
			</Link>
			<Link
				to={`${ELinksName.USER_DETAILS}/${params.id}/${params.userId}/${params.tab}/${ESubtabsConversation.CONVERSATIONS_CHATS}`}
				className={classNames('table-tabs__item', {
					active: params.subTab === ESubtabsConversation.CONVERSATIONS_CHATS || !params.subTab,
				})}
			>
				Admin
			</Link>
		</div>
	);
};
