import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import avatar from 'assets/images/avatar-square.png';
import { PlusRadSvg } from 'assets/svg-icon';
import { numeralFunc } from 'untils/numerals';
import moment from 'moment';
import { ESubscriptionTabs } from 'services/constants/lists-table';

export const SubscriptionsBody: FC = () => {
	const params = useParams();

	return (
		<div className="table-body">
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name"> Avatar</p>
					<div className="avatar-wrapper">
						<div className="avatar avatar--circle">
							<img src={avatar} alt="" />
						</div>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name"> Channel name</p>
					<p>3D digital sculpting</p>
				</div>
				{params.subTab !== ESubscriptionTabs.AI && (
					<div className="td">
						<p className="td-hidden-name"> Channel URL </p>
						<p>
							<Link
								to={`${ELinksName.USERS_USER_DETAILS}/${params.id || ''}`}
								className="link link--type2"
							>
								elloapp.org/3DSculpting
							</Link>
						</p>
					</div>
				)}
				<div className="td">
					<p className="td-hidden-name">Type</p>
					<p>Paid</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Start date</p>
					<p className="td--righ-custom">{moment(new Date()).format('MMM DD, YYYY')}</p>
					<p className="td--righ-custom gray">{moment(new Date()).format('h:mm A')}</p>
				</div>

				<div className="td">
					<p className="td-hidden-name">Due date</p>
					<p className="td--righ-custom">{moment(new Date()).format('MMM DD, YYYY')}</p>
					<p className="td--righ-custom gray">{moment(new Date()).format('h:mm A')}</p>
				</div>
				<div className="td td--righ-custom">
					<p className="td-hidden-name">Cost</p>
					<p>{`$${numeralFunc(15)}`}</p>
				</div>
				<div className="td td--righ-custom">
					<p className="td-hidden-name green">Status</p>
					<p>Active</p>
				</div>
				<div className="td td--righ-custom">
					<p className="td-hidden-name">Action</p>
					<p>
						<PlusRadSvg />
					</p>
				</div>
			</div>
		</div>
	);
};
