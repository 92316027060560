import classNames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ESubtabsUsers, ETabsAmbassador } from 'services/constants/lists-table';

interface IProps {
	subTab: string;
	tab: string;
}

export const UserPartnerSubtab: FC<IProps> = ({ subTab, tab }) => {
	return (
		<div className="table-tabs table-tabs--type2 table-tabs-custom">
			<Link
				to={`${ELinksName.USER_PARTNER}/${tab || ETabsAmbassador.DISCOUNT}/${
					ESubtabsUsers.PERSONAL_PROFILE
				}`}
				className={classNames('table-tabs__item', {
					active: subTab === ESubtabsUsers.PERSONAL_PROFILE,
				})}
			>
				Personal profile
			</Link>
			<Link
				to={`${ELinksName.USER_PARTNER}/${tab || ETabsAmbassador.DISCOUNT}/${
					ESubtabsUsers.BLOCKED_PROFILE
				}`}
				className={classNames('table-tabs__item', {
					active: subTab === ESubtabsUsers.BLOCKED_PROFILE,
				})}
			>
				Business profile
			</Link>
		</div>
	);
};
