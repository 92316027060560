import { CreateSystemForm } from 'components/Forms/CreateSystemForm/CreateSystemForm';
import { FC } from 'react';
import photo from '../../../../assets/images/photo.png';
import { CloseSvg } from 'assets/svg-icon';

interface IProps {
	onClosePopUp: () => void;
}

export const PopupCreate: FC<IProps> = ({ onClosePopUp }) => {
	return (
		<div className="system__popup-back">
			<div className="system__popup">
				<div onClick={onClosePopUp} className="system__popup-close">
					<CloseSvg />
				</div>
				<div className="system__popup-title">Create System Channels</div>
				<img src={photo} alt="" />
				<CreateSystemForm />
			</div>
		</div>
	);
};
