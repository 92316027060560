import { IChannelsRequest } from 'redux/reducers/reports/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IReportsApi } from './types';

export const reports: IReportsApi = {
	getChannels: (payload: IChannelsRequest) =>
		http.post(endpoint.reports.REPORTS_CHANNEL, payload).then((response) => response.data),
	getGroups: (payload: IChannelsRequest) =>
		http.post(endpoint.reports.REPORTS_GROUPS, payload).then((response) => response.data),
	getChannelPosts: (payload: IChannelsRequest) =>
		http.post(endpoint.reports.REPORTS_POSTS, payload).then((response) => response.data),
	getMessages: (payload: IChannelsRequest) =>
		http.post(endpoint.reports.REPORTS_MESSAGES, payload).then((response) => response.data),
	getReportsDetails: (payload: string) =>
		http.get(endpoint.reports.GET_DETAILS(payload)).then((response) => response.data),
	getReportPreviousMessages: ({ id, payload }: { id: string; payload: IChannelsRequest }) =>
		http
			.post(endpoint.reports.POST_LIST_PREVIOUS_CHANNEL(id), payload)
			.then((response) => response.data),
	getReportPreviousGroups: ({ id, payload }: { id: string; payload: IChannelsRequest }) =>
		http
			.post(endpoint.reports.POST_LIST_PREVIOUS_GROUPS(id), payload)
			.then((response) => response.data),
	getReportPreviousChannelMessages: ({ id, payload }: { id: string; payload: IChannelsRequest }) =>
		http
			.post(endpoint.reports.POST_LIST_PREVIOUS_CHANNEL_MESSAGES(id), payload)
			.then((response) => response.data),
	getReportPreviousChannel: ({ id, payload }: { id: string; payload: IChannelsRequest }) =>
		http
			.post(endpoint.reports.POST_LIST_PREVIOUS_MESSAGES(id), payload)
			.then((response) => response.data),
	getTypes: () => http.get(endpoint.reports.LIST_TYPES).then((response) => response.data),
	rejectMessages: (payload: string) =>
		http.delete(endpoint.reports.REJECT_MESSAGES(payload)).then((response) => response.data),
	deactivateChannel: (payload: string) =>
		http.delete(endpoint.reports.DEACTIVATE_CHANNEL(payload)).then((response) => response.data),
	deactivateGroupWorker: (payload: string) =>
		http.delete(endpoint.reports.DEACTIVATE_GROUP(payload)).then((response) => response.data),
	deactivateMessageWorker: (payload: string) =>
		http.delete(endpoint.reports.DEACTIVATE_MESSAGE(payload)).then((response) => response.data),
};
