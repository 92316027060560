import { isAxiosError } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
// import L from 'i18n-react';
import { api, responseErrors } from 'services';
import { notificationContainer } from '../../../untils/notificationContainer';
import {
	addBadWordRequest,
	addBadWordSuccess,
	aiServicesInitState,
	deleteBadWordRequest,
	deleteBadWordSuccess,
	editBadWordRequest,
	editBadWordSuccess,
	getBadWordsRequest,
	getBadWordsSuccess,
} from './reducer';
import { IGetWordsRequest, IWordResponse, IWordRquest, IWordsResponse } from './types';
import { IDeleteName } from '../reserved/types';

function* getBadWordsWorker(action: PayloadAction<IGetWordsRequest>) {
	const { payload } = action;

	try {
		const response: { data: IWordsResponse } = yield call(api.aiServices.getBadWords, payload);
		yield put(getBadWordsSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(aiServicesInitState());
	}
}

function* editBadWordWorker({ payload }: PayloadAction<string>) {
	try {
		const response: { data: any } = yield call(api.aiServices.editBadWord, payload);

		yield put(editBadWordSuccess(response));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(aiServicesInitState());
	}
}

function* addBadWordWorker(action: PayloadAction<IWordRquest>) {
	const { body, onClose } = action.payload;

	try {
		const response: { data: IWordResponse } = yield call(api.aiServices.addBadWord, body);
		yield put(addBadWordSuccess(response.data));
		yield onClose?.();
		yield notificationContainer('Added a new word', 'info');
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(aiServicesInitState());
	}
}

function* deleteBadWordWorker(action: PayloadAction<IDeleteName>) {
	const { id, onClose, body } = action.payload;

	try {
		yield call(api.aiServices.deleteBadWord, id);
		const response: { data: IWordsResponse } = yield call(api.aiServices.getBadWords, body);

		yield put(deleteBadWordSuccess(response.data));
		yield onClose?.();
		yield notificationContainer('The word has been deleted', 'info');
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(aiServicesInitState());
	}
}

export function* aiServicesSaga() {
	yield takeEvery(getBadWordsRequest.type, getBadWordsWorker);
	yield takeEvery(editBadWordRequest.type, editBadWordWorker);
	yield takeEvery(addBadWordRequest.type, addBadWordWorker);
	yield takeEvery(deleteBadWordRequest.type, deleteBadWordWorker);
}
