import moment from 'moment';
import { ChangeEvent, MouseEvent, FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { IAdminsResponse } from 'redux/reducers/admins/types';
import { IChangeAdmin } from 'ui/AddNewAdmins/type';

import { Checkbox } from 'ui/Checkbox/Checkbox';

export interface Props {
	onChangeAdmin?: (event: MouseEvent | ChangeEvent) => void;
	onEnableOrDisable2Fa?: (email: string, disable: boolean) => void;
	changeAdmin: IChangeAdmin;
	admin?: IAdminsResponse;
}

export const BoxFirst: FC<Props> = ({ admin, changeAdmin, onChangeAdmin }) => {
	const [text, setText] = useState<string>(admin?.first_name + ' ' + admin?.last_name);

	return (
		<ul className="admin-info__list">
			<li className="admin-info__item">
				<span className="admin-info__first-column">Full name</span>
				<input
					name="fullName"
					onChange={(event: ChangeEvent) => {
						const { value } = event.target as HTMLInputElement;

						onChangeAdmin?.(event);
						setText(value);
					}}
					value={text}
					placeholder="Full name"
					type="text"
					className="admin-info__input"
				/>
			</li>
			<li className="admin-info__item">
				<span className="admin-info__first-column">Email</span>
				<Link to="/" className="admin-info__second-column">
					{admin?.email}
				</Link>
			</li>
			<li className="admin-info__item">
				<span className="admin-info__first-column">Last active</span>
				<span className="admin-info__second-column">
					{admin?.last_login ? moment(admin.last_login).format('MMM DD, YYYY, h:mm A') : '-'}
				</span>
			</li>
			<li className="admin-info__item">
				<span className="admin-info__first-column">Created date</span>
				<span className="admin-info__second-column">
					{moment(admin?.created_at).format('MMM DD, YYYY, h:mm A')}
				</span>
			</li>
			<li className="admin-info__item">
				<span className="admin-info__first-column">Status</span>
				<span className="admin-info__second-column">
					<Checkbox
						onChange={onChangeAdmin}
						subtitle="Normal access to the Admin Panel (default)"
						title="Active"
						name="blocked"
						type="radio"
						checked={!changeAdmin.blocked}
						id="active"
					/>
					<Checkbox
						onChange={onChangeAdmin}
						subtitle="Block administrator from accessing the Admin Panel"
						title="Blocked"
						name="blocked"
						type="radio"
						checked={changeAdmin.blocked}
					/>
				</span>
			</li>
		</ul>
	);
};
