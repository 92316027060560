export const SvgShowPass = () => (
	<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10.8974 7.6025L7.10244 11.3975C6.61494 10.91 6.31494 10.2425 6.31494 9.5C6.31494 8.015 7.51494 6.815 8.99994 6.815C9.74244 6.815 10.4099 7.115 10.8974 7.6025Z"
			stroke="#B1B1B5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M13.3649 4.82749C12.0524 3.83749 10.5524 3.29749 8.99988 3.29749C6.35238 3.29749 3.88488 4.85749 2.16738 7.55749C1.49238 8.61499 1.49238 10.3925 2.16738 11.45C2.75988 12.38 3.44988 13.1825 4.19988 13.8275"
			stroke="#B1B1B5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.31494 15.1475C7.16994 15.5075 8.07744 15.7025 8.99994 15.7025C11.6474 15.7025 14.1149 14.1425 15.8324 11.4425C16.5074 10.385 16.5074 8.60751 15.8324 7.55001C15.5849 7.16001 15.3149 6.79251 15.0374 6.44751"
			stroke="#B1B1B5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11.6326 10.025C11.4376 11.0825 10.5751 11.945 9.51758 12.14"
			stroke="#B1B1B5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.1025 11.3975L1.5 17"
			stroke="#B1B1B5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16.5 2L10.8975 7.6025"
			stroke="#B1B1B5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const SvgPass = () => (
	<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14.8425 11.6975C13.2975 13.235 11.085 13.7075 9.14249 13.1L5.60999 16.625C5.35499 16.8875 4.85249 17.045 4.49249 16.9925L2.85749 16.7675C2.31749 16.6925 1.81499 16.1825 1.73249 15.6425L1.50749 14.0075C1.45499 13.6475 1.62749 13.145 1.87499 12.89L5.39999 9.36499C4.79999 7.41499 5.26499 5.20249 6.80999 3.66499C9.02249 1.45249 12.615 1.45249 14.835 3.66499C17.055 5.87749 17.055 9.48499 14.8425 11.6975Z"
			stroke="#B1B1B5"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M5.16748 13.6175L6.89248 15.3425"
			stroke="#B1B1B5"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10.875 8.75C11.4963 8.75 12 8.24632 12 7.625C12 7.00368 11.4963 6.5 10.875 6.5C10.2537 6.5 9.75 7.00368 9.75 7.625C9.75 8.24632 10.2537 8.75 10.875 8.75Z"
			stroke="#B1B1B5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const SvgEmail = () => (
	<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.75 13.875H4.25C2 13.875 0.5 12.75 0.5 10.125V4.875C0.5 2.25 2 1.125 4.25 1.125H11.75C14 1.125 15.5 2.25 15.5 4.875V10.125C15.5 12.75 14 13.875 11.75 13.875Z"
			stroke="#B1B1B5"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11.75 5.25L9.4025 7.125C8.63 7.74 7.3625 7.74 6.59 7.125L4.25 5.25"
			stroke="#B1B1B5"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
