import { ChangeEvent, FC } from 'react';

export const CheckBoxTwo: FC<{
	onChange?: (event: ChangeEvent) => void;
	title: string;
	subtitle?: string;
	status?: string;
	checked?: boolean;
	type?: string;
	name?: string;
	id?: string;
	photo?: string;
}> = ({ photo, title, name, subtitle, onChange, checked, type, id }) => {
	return (
		<div className="checkbox-one checkbox--mb-0 ">
			<label className="checkbox-one__label">
				{type ? (
					<input
						id={id}
						onChange={onChange}
						type={type}
						className="hidden"
						defaultChecked={checked}
						checked={checked}
						name={name}
					/>
				) : null}
				{type ? (
					<span className="checkbox-one__item">
						<span className="checkbox-one__item-icon small-oval"></span>
					</span>
				) : null}
				<img className="checkbox-one__photo" src={photo} alt="" />

				<div className="admin-info__checkbox">
					<span className={`checkbox-one__text ${title === 'Blocked' ? 'red' : ''}`}>{title}</span>
					<span className="checkbox-one__text gray">{subtitle}</span>
				</div>
			</label>
		</div>
	);
};
