import { DeleteSvg, PlusBlueSvg } from 'assets/svg-icon';
import { FC } from 'react';
import { IAiServicesStore, IWordsResponse } from 'redux/reducers/ai-services/types';
import { Button } from 'ui/Button/Button';
import { SelectList } from 'ui/SelectList/SelectList';

const nameWords = ['eBay', `McDonald's`, 'Sony', 'Starbucks', 'Louis Vuitton'];

interface IProps {
	isOpenModal: () => void;
	forbiddenWords: IWordsResponse | null;
	getId: (id: number) => void;
}

export const Words: FC<IProps> = ({ isOpenModal, forbiddenWords, getId }) => {
	return (
		<div className="ai-settings__body">
			<div className="ai-settings__body-header">
				<span className="ai-settings__title">Forbidden words</span>
				<div className="ai-settings__inputs-wrap">
					{/* <SelectList maxWidth="maxWidth" placeholder="Export" data={['CSV', 'JSON', 'PDF']} /> */}

					<Button
						className="button-border"
						onClick={isOpenModal}
						type={'button'}
						name={'Add new word'}
					>
						<PlusBlueSvg />
					</Button>
				</div>
			</div>
			<ul className="ai-settings__list">
				{forbiddenWords?.words?.map((word) => (
					<li key={word.Id} className="ai-settings__item">
						<span className="ai-settings__name">{word.Word}</span>
						<button onClick={() => getId(word.Id)} className="ai-settings__btn">
							<DeleteSvg />
						</button>
					</li>
				))}
			</ul>
		</div>
	);
};
