import { IDataFiltter } from 'pages/Dashboard/Channels';
import { FC, MouseEvent, useState } from 'react';
import { ETabsChannel } from 'services/constants/lists-table';
import { SvgArrowDownHeader, SvgArrowUpHeader } from 'ui/svg-image/SvgAllChannels';

interface IPropsChannelsHeaderTable {
	setDataFiltter: (value: IDataFiltter) => void;
	param: string;
}

export const ChannelsHeaderTable: FC<IPropsChannelsHeaderTable> = ({ setDataFiltter, param }) => {
	const [sort, setSort] = useState(0);
	const [prev, setPrev] = useState<string>('');

	const sortTable = (event: MouseEvent) => {
		const target = event.currentTarget as HTMLButtonElement;

		if (prev !== target.id && sort !== 0) {
			setSort(1);
			setPrev(target.id);
			setDataFiltter({ order_by: target.id, asc: true });
			return;
		}

		switch (sort + 1) {
			case 1:
				setDataFiltter({ order_by: target.id, asc: true });

				break;
			case 2:
				setDataFiltter({ order_by: target.id, asc: false });

				break;
			default:
				setDataFiltter({ order_by: target.id });

				break;
		}

		if (sort === 2) {
			setSort(0);
			setPrev(target.id);
			return;
		}
		setSort((prevNum) => prevNum + 1);
		setPrev(target.id);
	};

	return (
		<div className="table-header">
			<div className="tr">
				{param === ETabsChannel.RECOMMENDED_CHANNELS && (
					<div className="td td--right-custom">
						<div className="td-name">
							<p> № </p>
						</div>
					</div>
				)}
				<div className="td">
					<div className="td-name">
						<p> Avatar </p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p> Channel name</p>
						<div className="sort-td">
							<button onClick={sortTable} id="title" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="title" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Channel URL</p>
						<div className="sort-td">
							<button onClick={sortTable} id="invite_link" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="invite_link" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p># Members</p>
						<div className="sort-td">
							<button onClick={sortTable} id="members" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="members" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Type</p>
						<div className="sort-td">
							<button onClick={sortTable} id="type" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="type" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Created date</p>
						<div className="sort-td">
							<button onClick={sortTable} id="type" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="type" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				{param !== ETabsChannel.RECOMMENDED_CHANNELS && (
					<div className="td td--right">
						<div className="td-name">
							<p>Subscrption cost</p>
							<div className="sort-td">
								<button onClick={sortTable} id="type" name="asc" type="button">
									<SvgArrowUpHeader />
								</button>
								<button onClick={sortTable} id="type" name="desc" type="button">
									<SvgArrowDownHeader />
								</button>
							</div>
						</div>
					</div>
				)}
				<div className="td td--right">
					<div className="td-name">
						<p>Country</p>
						<div className="sort-td">
							<button onClick={sortTable} id="type" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="type" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Category</p>
						<div className="sort-td">
							<button onClick={sortTable} id="type" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="type" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Age</p>
						<div className="sort-td">
							<button onClick={sortTable} id="type" name="asc" type="button">
								<SvgArrowUpHeader />
							</button>
							<button onClick={sortTable} id="type" name="desc" type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Recommended</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p> Verified account</p>
					</div>
				</div>
				{param === ETabsChannel.RECOMMENDED_CHANNELS && (
					<div className="td td--right">
						<div className="td-name">Action</div>
					</div>
				)}
			</div>
		</div>
	);
};
