import { mediaSales } from 'CONST/userFilter';
import { Loader } from 'components/Loader';
import { Pagination } from 'components/Pagination';
import { UsersSubtabs } from 'components/UsersComponents/UsersSubtabs';
import { UsersTableBody } from 'components/UsersComponents/UsersTableBody';
import { mockDataUsers } from 'components/UsersComponents/UsersTableBody/mockDataUsers';
import { UsersTableHeader } from 'components/UsersComponents/UsersTableHeader';
import { UsersTabs } from 'components/UsersComponents/UsersTabs';
import { IDataFiltter } from 'pages/Dashboard/Channels';
import { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getLoader } from 'redux/reducers/auth/selectors';
import { getUsersList } from 'redux/reducers/users/selectors';
import { Fillter } from 'ui/Fillter/Fillter';

export const GroupsMembers: FC = () => {
	const loader = useSelector(getLoader);
	const usersList = useSelector(getUsersList);
	const params = useParams();

	const [dataFiltter, setDataFiltter] = useState<IDataFiltter>({
		order_by: '',
		sort: '',
	});

	return (
		<>
			<div className="table-block">
				<Fillter
					keyStatus="status"
					keyType="types"
					type={mediaSales}
					status={[]}
					typesName="Types"
					statusName=""
				/>
				<UsersSubtabs />
				<div className="table-wrapper">
					<div className="table table--users-registered-personal">
						<UsersTableHeader setDataFiltter={setDataFiltter} />
						{loader ? (
							<Loader />
						) : (
							usersList?.data.users && (
								<UsersTableBody members={usersList?.data.users} param={params.tab} />
							)
						)}
					</div>
				</div>
			</div>
			{/* {usersList?.page_count && usersList?.page_count > 1 ? (
				<Pagination
					request={submitFilter}
					currentPage={usersList.page}
					numberPage={usersList.page_count}
				/>
			) : null} */}
		</>
	);
};
