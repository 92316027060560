import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	ICreateUsernames,
	IDeleteName,
	IReservedUsernamesStore,
	IRestrictRequest,
	IUsersName,
	IUsersNameRequest,
	IUsersNameResponse,
} from './types';

export const initialState: IReservedUsernamesStore = {
	usernames: null,
	reservedLoader: false,
};

const reservedUsernames = createSlice({
	name: '@@reservedUsernames',
	initialState,
	reducers: {
		getUsernamesRequest: (state, action: PayloadAction<IUsersNameRequest>) => {
			const reservedUsernamesState = state;
			reservedUsernamesState.reservedLoader = true;
		},
		getUsernamesSuccess: (state, action: PayloadAction<IUsersNameResponse>) => {
			const { payload } = action;
			const reservedUsernamesState = state;
			reservedUsernamesState.reservedLoader = false;
			reservedUsernamesState.usernames = payload;
		},

		editUsernamesRequest: (state, action: PayloadAction<ICreateUsernames>) => {
			const reservedUsernamesState = state;
			reservedUsernamesState.reservedLoader = true;
		},
		editUsernamesSuccess: (state, action: PayloadAction<IUsersName>) => {
			const { payload } = action;
			const reservedUsernamesState = state;

			if (reservedUsernamesState.usernames?.usernames) {
				reservedUsernamesState.usernames.usernames =
					reservedUsernamesState.usernames?.usernames.map((user) => {
						if (payload.id === user.id) {
							return payload;
						}
						return user;
					});
			}
		},

		addUsernamesRequest: (state, action: PayloadAction<ICreateUsernames>) => {
			const reservedUsernamesState = state;
			reservedUsernamesState.reservedLoader = true;
		},
		addUsernamesSuccess: (state, action: PayloadAction<IUsersName>) => {
			const { payload } = action;
			const reservedUsernamesState = state;
			reservedUsernamesState.usernames?.usernames.unshift(payload);
		},

		deleteUsernamesRequest: (state, action: PayloadAction<IDeleteName>) => {
			const reservedUsernamesState = state;
			reservedUsernamesState.reservedLoader = true;
		},
		deleteUsernamesSuccess: (state, action: PayloadAction<string>) => {
			const { payload } = action;
			const reservedUsernamesState = state;
			if (reservedUsernamesState.usernames?.usernames) {
				reservedUsernamesState.usernames.usernames =
					reservedUsernamesState.usernames.usernames.filter((user) => user.id !== Number(payload));
			}
		},

		checkUsernamesRequest: (state, action: PayloadAction<IRestrictRequest>) => {},
		checkUsernamesSuccess: (state, action: PayloadAction<IRestrictRequest>) => {
			const { payload } = action;
			const reservedUsernamesState = state;
			reservedUsernamesState.usernames?.usernames.forEach((element) => {
				if (element.id === payload.id) element.full_check = payload.check;
			});
		},

		reservedUsernamesInitState: (state) => {
			const reservedUsernamesInitStatee = state;
			reservedUsernamesInitStatee.reservedLoader = false;
		},
	},
});

export default reservedUsernames.reducer;
export const {
	getUsernamesRequest,
	getUsernamesSuccess,
	editUsernamesRequest,
	editUsernamesSuccess,
	addUsernamesRequest,
	addUsernamesSuccess,
	deleteUsernamesRequest,
	deleteUsernamesSuccess,
	checkUsernamesRequest,
	checkUsernamesSuccess,
	reservedUsernamesInitState,
} = reservedUsernames.actions;
