import { ArrowBackSvg, ArrowDownSvg, DeleteSvg, DownloadSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { FC, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { IAddRequest, IAnimation, IReactionResponse } from 'redux/reducers/reactions/types';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { DropFile } from 'ui/DropFile/DropFile';

interface IProps {
	handleImage: (img: any, key: string) => void;
	image: IAnimation;
	pathname: string;
	file: IAnimation;
	open: string;
	isOpenModal: () => void;
	onOpen: (event: MouseEvent) => void;
	onSubmitReaction: (values: IAddRequest) => void;
	reactionData: null | IReactionResponse;
}

export const AddReactionForms: FC<IProps> = ({
	isOpenModal,
	handleImage,
	onOpen,
	onSubmitReaction,
	pathname,
	image,
	file,
	open,
	reactionData,
}) => {
	const initialValues = {
		emoticon: reactionData?.reaction.emoticon || '',
		title: reactionData?.reaction.title || '',
		inactive: reactionData?.reaction.inactive || false,
		center_icon: '',
		static_icon: '',
		appear_animation: '',
		activate_animation: '',
		select_animation: '',
		around_animation: '',
		effect_animation: '',
	};

	return (
		<Formik
			initialValues={initialValues}
			// validationSchema={validationSchema}
			onSubmit={(values: IAddRequest, { setSubmitting }) => {
				onSubmitReaction(values);
				setSubmitting(false);
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, values, dirty, errors, setFieldValue }) => {
				const onAddImages = (img: any, key: string) => {
					handleImage(img, key);
					setFieldValue(key, img);
				};

				console.log(values);

				return (
					<Form>
						<div className="title-block-wrap">
							<div className="title-block">
								<div className="title-block-wrap">
									<div className="title-block">
										<Link className="back-button" to={ELinksName.REACTIONS}>
											<ArrowBackSvg />
										</Link>
									</div>
								</div>
								<div className="header-title">
									<p className="title">
										{pathname.includes('add') ? 'Add Reaction' : 'Edit Reaction'}
									</p>
									<div className="add-btn-wrap">
										<button
											disabled={!(isValid && dirty) || isSubmitting}
											// onClick={onClosePopup}
											style={{
												display: 'flex',
												gap: '5px',
												background: '#0A49A5',
												color: '#fff',
												border: '1px solid #0A49A5',
											}}
											className="add-btn"
											type="submit"
										>
											Save
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="reactions-add">
							{pathname.includes('edit') && (
								<div onClick={isOpenModal} className="reactions-add__delete">
									<DeleteSvg />
									<span className="user-data__toggle-text red">Delete reaction</span>
								</div>
							)}
							<div
								id="information"
								onClick={onOpen}
								className={classNames('reactions-add__information', {
									active: open === 'information',
								})}
							>
								<div className="reactions-add__information-header">
									<div className="reactions-add__information-title">Information</div>
									<ArrowDownSvg />
								</div>
								<div className="reactions-add__input-wrapper">
									<div className="reactions-add__input-name">Tittle</div>
									<Field name="title" type="text" className="reactions-add__input" />
								</div>
								<div className="reactions-add__input-wrapper">
									<div className="reactions-add__input-name">Reaction</div>
									<Field name="emoticon" type="text" className="reactions-add__input" />
								</div>
							</div>

							<div
								id="file"
								onClick={onOpen}
								className={classNames('reactions-add__information', {
									active: open === 'file',
								})}
							>
								<div className="reactions-add__information-header">
									<div className="reactions-add__information-title">SelectAnimation</div>
									<ArrowDownSvg />
								</div>
								<div style={{ alignItems: 'flex-start' }} className="reactions-add__input-wrapper">
									<div className="reactions-add__input-name">Add an animation file.</div>
									<DropFile
										photo={image.select_animation}
										name="select_animation"
										svg={<DownloadSvg />}
										file={file.select_animation}
										handleChange={(file: any) => onAddImages(file, 'select_animation')}
									/>
								</div>
							</div>
							<div
								id="StaticIcon"
								onClick={onOpen}
								className={classNames('reactions-add__information', {
									active: open === 'StaticIcon',
								})}
							>
								<div className="reactions-add__information-header">
									<div className="reactions-add__information-title">StaticIcon</div>
									<ArrowDownSvg />
								</div>
								<div style={{ alignItems: 'flex-start' }} className="reactions-add__input-wrapper">
									<div className="reactions-add__input-name">Add an StaticIcon file.</div>
									<DropFile
										photo={image.static_icon}
										svg={<DownloadSvg />}
										file={file.static_icon}
										name="static_icon"
										handleChange={(file: any) => onAddImages(file, 'static_icon')}
									/>
								</div>
							</div>
							<div
								id="CenterIcon"
								onClick={onOpen}
								className={classNames('reactions-add__information', {
									active: open === 'CenterIcon',
								})}
							>
								<div className="reactions-add__information-header">
									<div className="reactions-add__information-title">CenterIcon</div>
									<ArrowDownSvg />
								</div>
								<div style={{ alignItems: 'flex-start' }} className="reactions-add__input-wrapper">
									<div className="reactions-add__input-name">Add an CenterIcon file.</div>
									<DropFile
										photo={image.center_icon}
										svg={<DownloadSvg />}
										file={file.center_icon}
										name="center_icon"
										handleChange={(file: any) => onAddImages(file, 'center_icon')}
									/>
								</div>
							</div>
							<div
								id="AppearAnimation"
								onClick={onOpen}
								className={classNames('reactions-add__information', {
									active: open === 'AppearAnimation',
								})}
							>
								<div className="reactions-add__information-header">
									<div className="reactions-add__information-title">AppearAnimation</div>
									<ArrowDownSvg />
								</div>
								<div style={{ alignItems: 'flex-start' }} className="reactions-add__input-wrapper">
									<div className="reactions-add__input-name">Add an AppearAnimation file.</div>
									<DropFile
										photo={image.appear_animation}
										svg={<DownloadSvg />}
										file={file.appear_animation}
										name="appear_animation"
										handleChange={(file: any) => onAddImages(file, 'appear_animation')}
									/>
								</div>
							</div>
							<div
								id="ActivateAnimation"
								onClick={onOpen}
								className={classNames('reactions-add__information', {
									active: open === 'ActivateAnimation',
								})}
							>
								<div className="reactions-add__information-header">
									<div className="reactions-add__information-title">ActivateAnimation</div>
									<ArrowDownSvg />
								</div>
								<div style={{ alignItems: 'flex-start' }} className="reactions-add__input-wrapper">
									<div className="reactions-add__input-name">Add an ActivateAnimation file.</div>
									<DropFile
										photo={image.activate_animation}
										svg={<DownloadSvg />}
										file={file.activate_animation}
										name="activate_animation"
										handleChange={(file: any) => onAddImages(file, 'activate_animation')}
									/>
								</div>
							</div>
							<div
								id="AroundAnimation"
								onClick={onOpen}
								className={classNames('reactions-add__information', {
									active: open === 'AroundAnimation',
								})}
							>
								<div className="reactions-add__information-header">
									<div className="reactions-add__information-title">AroundAnimation</div>
									<ArrowDownSvg />
								</div>
								<div style={{ alignItems: 'flex-start' }} className="reactions-add__input-wrapper">
									<div className="reactions-add__input-name">Add an AroundAnimation file.</div>
									<DropFile
										photo={image.around_animation}
										svg={<DownloadSvg />}
										file={file.around_animation}
										name="around_animation"
										handleChange={(file: any) => onAddImages(file, 'around_animation')}
									/>
								</div>
							</div>
							<div
								id="EffectAnimation"
								onClick={onOpen}
								className={classNames('reactions-add__information', {
									active: open === 'EffectAnimation',
								})}
							>
								<div className="reactions-add__information-header">
									<div className="reactions-add__information-title">EffectAnimation</div>
									<ArrowDownSvg />
								</div>
								<div style={{ alignItems: 'flex-start' }} className="reactions-add__input-wrapper">
									<div className="reactions-add__input-name">Add an EffectAnimation file.</div>
									<DropFile
										photo={image.effect_animation}
										svg={<DownloadSvg />}
										file={file.effect_animation}
										name="effect_animation"
										handleChange={(file: any) => onAddImages(file, 'effect_animation')}
									/>
								</div>
							</div>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
