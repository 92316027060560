import { FC } from 'react';
import { SvgArrowDownHeader, SvgArrowUpHeader } from 'ui/svg-image/SvgAllChannels';

export const UsernameHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Username</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>TM owner</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Exception for use</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Restrict in all cases</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Action</p>
					</div>
				</div>
			</div>
		</div>
	);
};
