export const typeFilter = [
	'Deposit',
	'Withdrawal',
	'Transferring earnings to a payment wallet',
	'Paying for a channel subscription',
	'Purchased online course',
	'Buying media',
	'Payment for AI services',
	'Сashback withdrawal',
];

export const typeStatus = ['Approved', 'Declined', 'Pending'];

export const channelsTypes = [
	'Blocked',
	'Private',
	'Active',
	'Public',
	'Verified',
	'Course',
	'Paid',
];

export const channelsCategory = [
	'Music',
	'Film',
	'Animation',
	'Autos',
	'Vehicles',
	'Home pets',
	'Animals',
	'Sports',
	'Travel',
	'Gaming',
	'People & Blogs',
	'Masterclass',
	'Comedy',
	'Health & Beauty',
	'Entertainment',
	'News & Politics',
	'How to & Style',
	'Fashion',
	'Education',
	'Science & Technology',
	'Nonprofit & Activism',
];

export const mediaSales = ['Image', 'Video', 'Audio', 'File'];

export const countryShow = [
	'user-transaction-history',
	'report-channels',
	'report-groups',
	'report-messages-in-groups',
	'report-channel-posts',
];

export const transactionTypes = [
	'Deposit',
	'Withdrawal',
	'Transferring earnings to a payment wallet',
	'Paying for a channel subscription',
	'Purchased online course',
	'Buying media',
	'Payment for AI services',
	'Сashback withdrawal',
];

export const statisticsStatus = ['Completed', 'Canceled', 'Admin check', 'Processing', 'Error'];
export const statisticsCurrency = ['USD', 'USDT', 'ETH', 'BTC'];

export const transactionStatus = ['Approved', 'Declined', 'Pending'];

export const reportsListParams = [
	'channel-report',
	'group-report',
	'channel-post-report',
	'group-post-report',
	'group-post-report',
];
