import { ChangeEvent, FC } from 'react';

interface IProps {
	type: string;
	className?: string;
	name: string;
	placeholder?: string;
	label?: string;
	value?: string;
	handleInput?: (event: ChangeEvent) => void;
	svg?: any;
}

export const Input: FC<IProps> = ({
	value,
	handleInput,
	type,
	className,
	name,
	placeholder,
	label,
}) => {
	return (
		<div
			className={`input input-custom input-search input-search-custom input-search-width380 ${className}`}
		>
			<div className="input-wrapper input-wrapper-custom input-wrapper-custom100">
				<div className="popup-box__label">{label}</div>
				<input
					className={`input-item input-item--left-icon input-item--left-icon-none`}
					type={type}
					name={name}
					value={value}
					placeholder={placeholder}
					onChange={handleInput}
				/>
			</div>
		</div>
	);
};
