export const onTitlePopUp = (key: string) => {
	switch (key) {
		case 'Edit':
			return 'Edit usernames';
		case 'Exception for use':
			return 'Exception for use';
		case 'Delete':
			return 'Are you sure you want to delete this username?';

		default:
			return 'Add new usernames';
	}
};

export const onButtonName = (key: string) => {
	switch (key) {
		case 'Edit':
			return 'Save';
		case 'Exception for use':
			return 'Save';
		case 'Delete':
			return 'Yes, I’m sure';
		default:
			return 'Add';
	}
};

export const onPlaceholder = (key: string) => {
	switch (key) {
		case 'Edit':
			return '@username';
		case 'Exception for use':
			return 'Email';

		default:
			return '@username';
	}
};

export const onLabel = (key: string) => {
	switch (key) {
		case 'Edit':
			return 'Usernames';
		case 'Exception for use':
			return 'Add Email';

		default:
			return 'Usernames';
	}
};

export const onTitlePopUpContent = (key: string) => {
	switch (key) {
		case 'Delete':
			return 'Are you sure you want to delete this track?';

		default:
			return 'Transfer rights';
	}
};

export const onButtonNameContent = (key: string) => {
	switch (key) {
		case 'Delete':
			return 'Yes, I’m sure';
		default:
			return 'Transfer';
	}
};

export const onTitlePopUpAlbum = (key: string) => {
	switch (key) {
		case 'Delete':
			return 'Are you sure you want to delete this album?';

		default:
			return 'Transfer rights';
	}
};
