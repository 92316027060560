import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { ICountries, IUtilsState } from './types';

const getUtilsState = (state: IStoreState): IUtilsState => state.utils;

export const getCountries = createSelector(
	[getUtilsState],
	(state: IUtilsState): null | ICountries => state?.countries,
);

export const getPhoto = createSelector([getUtilsState], (state: IUtilsState): any => state?.photo);
