import { CheckChatSvg, FlagSvg } from 'assets/svg-icon';
import { FC } from 'react';
import { ICountries } from 'redux/reducers/utils/types';

interface IProps {
	countries: ICountries | null;
	country: string;
	byCountry: (value: string, code: string) => void;
	onSaveChange: () => void;
	contentReset: () => void;
}

export const Language: FC<IProps> = ({
	countries,
	country,
	byCountry,
	onSaveChange,
	contentReset,
}) => {
	return (
		<div className="sidebar-left__users-wrap users">
			<ul className="users__list county-lan-height">
				{countries?.countries?.map((item) => (
					<li key={item.name} className="county-lan">
						<div
							onClick={() => byCountry(item.name, item.code)}
							className="county-lan__name pointer"
						>
							<img src={item.flag} alt={`Flag ${item.name}`} />
							<span>{item.name}</span>
						</div>
						{country === item.name ? <CheckChatSvg /> : null}
					</li>
				))}
			</ul>
			<button
				onClick={() => {
					onSaveChange();
					contentReset();
				}}
				className="county-lan__button"
			>
				Save
			</button>
		</div>
	);
};
