import { FC } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { SvgEmail } from 'ui/svg-image/SvgLogin';
import ErrorMsg from 'components/ErrorMsg';
import { email } from '../../../untils/formValidation';
import { ResetPassSvg } from 'assets/svg-icon';
import { IResetForm, IRestFormSubmitValue } from 'pages/Auth/ForgotPass/types';
import { CheckYourEmail } from 'components/CheckYourEmail/CheckYourEmail';
import { TwoFa } from 'components/2Fa/2Fa';

export const ForgotPassForm: FC<IResetForm> = ({ forgotPassSubmit, successfully }) => {
	const initialValues = {
		email: '',
	};

	const validationSchema = yup.object().shape({
		email,
	});

	return (
		<div className="authorization-form__body">
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values: IRestFormSubmitValue, { setSubmitting }) => {
					forgotPassSubmit(values);
					setSubmitting(false);
				}}
				validateOnBlur
				enableReinitialize
			>
				{({ isSubmitting, isValid, values, errors }) => {
					return (
						<Form>
							{successfully ? (
								<CheckYourEmail email={values.email} />
							) : (
								<div className="authorization-form">
									<div className="authorization-form__logo">
										<ResetPassSvg />
									</div>
									<p className="form-title">Reset Password</p>
									<p className="form-subtitle">Enter your email address to reset your password.</p>
									<div className="authorization-form__body">
										<div className="input">
											<p className="input__name">Email</p>
											<div className="input-wrapper">
												<Field
													className="input-item input-item--left-icon"
													type="email"
													name="email"
													placeholder="Email"
												/>
												<span className="input-icon">
													<SvgEmail />
												</span>
											</div>
											{errors?.email ? <ErrorMsg msg={errors.email} /> : null}
										</div>
										<div className="form-submit">
											<button
												disabled={!(isValid && values.email) || isSubmitting}
												type="submit"
												className="button button--full-width"
											>
												Send
											</button>
										</div>
									</div>
								</div>
							)}
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};
