import { FC } from 'react';
import './TotalCard.scss';
import { FlagSvg } from 'assets/svg-icon';

export const TotalCard: FC = () => {
	return (
		<section className="total-card">
			<div className="total-card__box">
				<div className="card-crypto__title-wrapper total-card__wrap-title">
					<div className="card-balance__name">Total number of users on the platform</div>
					<div className="card-balance__subname">490 268 256</div>
				</div>
				<div className="chart-block__left-box">
					<span className="green left">Online</span>
					<span className="blue-big">39 268</span>
				</div>
				<ul className="total-card__list">
					<li className="total-card__item">
						<span className="total-card__title">
							<FlagSvg />
							United Kingdom
						</span>
						<span className="total-card__subtitle">11 505 268</span>
					</li>
					<li className="total-card__item">
						<span className="total-card__title">
							<FlagSvg />
							United Kingdom
						</span>
						<span className="total-card__subtitle">8 000 000</span>
					</li>
					<li className="total-card__item">
						<span className="total-card__title">
							<FlagSvg />
							United Kingdom
						</span>
						<span className="total-card__subtitle">4 869 269</span>
					</li>
				</ul>
				<button className="card-top__btn">Show More</button>
			</div>
			<div className="total-card__box">
				<div className="card-crypto__title-wrapper total-card__wrap-title">
					<div className="card-balance__name">
						Total number of channels on the platform by category
					</div>
					<div className="card-balance__subname">49 268</div>
				</div>
				<ul className="total-card__list">
					<li className="total-card__item">
						<span className="total-card__title">Free public</span>
						<span className="total-card__subtitle">11 505 268</span>
					</li>
					<li className="total-card__item">
						<span className="total-card__title">Free private</span>
						<span className="total-card__subtitle">8 000 000</span>
					</li>
					<li className="total-card__item">
						<span className="total-card__title">Paid</span>
						<span className="total-card__subtitle">4 869 269</span>
					</li>
					<li className="total-card__item">
						<span className="total-card__title">Course</span>
						<span className="total-card__subtitle">4 869 269</span>
					</li>
				</ul>
				<button className="card-top__btn">Show More</button>
			</div>
			<div className="total-card__box">
				<div className="card-crypto__title-wrapper total-card__wrap-title">
					<div className="card-balance__name">
						Total number of groups on the platform by category
					</div>
					<div className="card-balance__subname">49 268</div>
				</div>
				<ul className="total-card__list">
					<li className="total-card__item">
						<span className="total-card__title">Public</span>
						<span className="total-card__subtitle">11 505 268</span>
					</li>
					<li className="total-card__item">
						<span className="total-card__title">Private</span>
						<span className="total-card__subtitle">8 000 000</span>
					</li>
				</ul>
			</div>
		</section>
	);
};
