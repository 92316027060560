import { notificationContainer } from 'untils/notificationContainer';

export function fetchWithAuthentication(url: string, authToken: string) {
	const headers = new Headers();
	headers.set('Authorization', `Bearer ${authToken}`);
	return fetch(url, { headers });
}

export async function displayProtectedImage(
	ref: any,
	imageUrl: string,
	authToken: string,
	setLoader: (value: boolean) => void,
	link?: string,
) {
	setLoader(true);
	// Fetch the image.

	try {
		const response = await fetchWithAuthentication(imageUrl, authToken);
		// Create an object URL from the data.
		const blob = await response.blob();
		await setLoader(false);
		const objectUrl = window.URL.createObjectURL(blob);

		if (ref.current) {
			link
				? ref.current.setAttribute('href', objectUrl)
				: ref.current.setAttribute('src', objectUrl);
		}
	} catch (error) {
		console.log(error);
	}
}
