import { ArrowDownGraySvg, ArrowUpGraySvg, TemplateSvg } from 'assets/svg-icon';
import { Accordion } from 'components/AIChatBotComponents/TextTemplate/Accordion/Accordion';
import { templatesData } from 'components/AIChatBotComponents/TextTemplate/data';
import { FC, MouseEvent, useState } from 'react';
import { Button } from 'ui/Button/Button';
import { firstUppercaseLetter } from 'untils/firstUppercaseLetter';

export const Template: FC = () => {
	const [open, setOpen] = useState<string>('');

	const handleButton = (event: MouseEvent) => {
		const { id } = event.currentTarget as HTMLDivElement;
		setOpen(id === open ? '' : id);
	};

	const isOpenModal = () => {};

	return (
		<div className="structure">
			{templatesData.map((structure, index) => (
				<div key={structure.id} className="structure__box">
					<div id={String(structure.id)} onClick={handleButton} className="structure__header">
						<div className="structure__name">
							<span className="structure__line">{`${index + 1})`}</span>
							<span>{firstUppercaseLetter(structure.name.replaceAll('_', ' '))}</span>
						</div>
						<div className="structure__arrow">
							{open === String(structure.id) ? <ArrowUpGraySvg /> : <ArrowDownGraySvg />}
						</div>
					</div>
					{open === String(structure.id) ? <Accordion structure={structure} /> : null}
				</div>
			))}
			<div className="template-btn">
				<Button className="button-border" onClick={isOpenModal} type={'button'} name={'Template'}>
					<TemplateSvg />
				</Button>
			</div>
		</div>
	);
};
