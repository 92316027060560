import { ArrowLeftSvg } from 'assets/svg-icon';
import { ResetPasswordForm } from 'components/Forms/ResetPasswordForm/ResetPasswordForm';
import { IResetPass } from 'components/Forms/ResetPasswordForm/types';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, createSearchParams, useLocation, useNavigate } from 'react-router-dom';

import { authInitState, createPassRequest, resetRequest } from 'redux/reducers/auth/reducer';
import { IResetRequestPayload } from 'redux/reducers/auth/types';
import { ELinksName } from 'services/constants/list-links-sidebar';

export const ResetPassword: FC = () => {
	const dispatch = useDispatch();
	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get('token');
	const [successfully, setSuccessfully] = useState(false);
	const { pathname, search } = useLocation();
	const navigate = useNavigate();

	const handleResetPassSubmit = (value: IResetPass) => {
		dispatch(
			pathname.includes('invite_link')
				? createPassRequest({
						paramsApi: { password: value.new_password, invite_token: token || '' },
						setSuccessfully: setSuccessfully,
				  })
				: resetRequest({
						paramsApi: { ...value, reset_token: token || '' },
						setSuccessfully: setSuccessfully,
				  }),
		);
	};

	useEffect(() => {
		// const path = {
		// 	pathname: ELinksName.INVITE_LINK,
		// 	search: createSearchParams({ token: token || '' }).toString(),
		// };
		// dispatch(authInitState());
		// navigate(path);

		if (pathname.includes('invite_link')) {
			document.body.style.overflow = 'hidden';
			return () => {
				document.body.style.overflow = '';
			};
		}
	}, []);

	return (
		<div className="wrapper">
			<main className={`main ${pathname.includes('invite_link') ? 'absolute' : ''}`}>
				<Link to={ELinksName.ADMINS} className="arrow-left">
					<ArrowLeftSvg />
				</Link>
				<section className="authorization-section">
					<ResetPasswordForm resetPassSubmit={handleResetPassSubmit} successfully={successfully} />
				</section>
			</main>
		</div>
	);
};
