import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EGroupsTabs, EGroupsSubtabsUsers, ESubtabsUsers } from 'services/constants/lists-table';

export const UsersSubtabs: FC = () => {
	const params = useParams();

	const getLink = (num: number) => {
		switch (params.tab) {
			case 'members':
				return `${ELinksName.CHANNEL_DETAIL_USER}/${params.id}/${params.userId}/${
					EGroupsTabs.MEMBERS
				}/${
					num === 1 ? EGroupsSubtabsUsers.PERSONAL_PROFILE : EGroupsSubtabsUsers.BLOCKED_PROFILE
				}`;

			default:
				return `${ELinksName.USERS}/${params.tab || ''}/${
					num === 1 ? ESubtabsUsers.PERSONAL_PROFILE : ESubtabsUsers.BLOCKED_PROFILE
				}`;
		}
	};

	const onActive = (num: number) => {
		switch (params.tab) {
			case 'members':
				return num === 1
					? params.subTab === EGroupsSubtabsUsers.PERSONAL_PROFILE || !params.subTab
					: params.subTab === EGroupsSubtabsUsers.BLOCKED_PROFILE;

			default:
				return num === 1
					? params.subTab === ESubtabsUsers.PERSONAL_PROFILE || !params.subTab
					: params.subTab === ESubtabsUsers.BLOCKED_PROFILE;
		}
	};

	return (
		<div className="table-tabs table-tabs--type2 table-tabs-custom">
			<Link
				to={`${getLink(1)}`}
				className={classNames('table-tabs__item', {
					active: onActive(1),
				})}
			>
				Personal profile
			</Link>
			<Link
				to={`${getLink(2)}`}
				className={classNames('table-tabs__item', {
					active: onActive(2),
				})}
			>
				Business profile
			</Link>
		</div>
	);
};
