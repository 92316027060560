import { CardBalance } from 'components/DashboardComponents/CardBalance/CardBalance';
import { FC } from 'react';
import { SelectList } from 'ui/SelectList/SelectList';
import '../../../components/DashboardComponents/CardBalance/CardBalance.scss';
import { CardTop } from 'components/DashboardComponents/CardTop/CardTop';
import { card, topCard } from './data';
import { Link } from 'react-router-dom';
import { ChartjsLine } from 'ui/ChartjsLine/ChartjsLine';
import './styles.scss';
import { TotalCard } from 'components/DashboardComponents/TotalCard/TotalCard';

const dataBox = [
	{
		name: 'Total replenishment balance',
		amount: '856.00362',
		id: 1,
	},
	{
		name: 'The total earnings balance',
		amount: '856.00362',
		id: 2,
	},
	{
		name: 'Total withdrawal',
		amount: '856.00362',
		id: 3,
	},
	{
		name: 'The amount of the use of AI',
		amount: '856.00362',
		id: 4,
	},
];

export const options = {
	legend: {
		display: false,
	},
	responsive: true,
	maintainAspectRatio: false,
	// scales: {
	// 	yAxes: [
	// 		{
	// 			ticks: {
	// 				stepSize: 10,
	// 			},
	// 		},
	// 	],
	// },
	plugins: {
		legend: {
			position: 'top' as const,
			display: false,
		},
		title: {
			display: false,
			// text: 'Chart.js Line Chart',
		},
	},
};

const labels = ['0', '1', '2', '3', '4', '5', '6', '7'];

export const data = {
	labels,
	datasets: [
		{
			// label: 'Dataset 2',
			data: [0, 11, 45, 47, 85, 96, 2, 41],
			// borderColor: 'rgb(53, 162, 235)',
			// backgroundColor: 'rgba(53, 162, 235, 0.5)',
			borderColor: '#27AE60',
			borderWidth: 1,
			pointRadius: 3,
		},
		{
			// label: 'Dataset 2',
			data: [0, 114, 445, 7, 5, 196, 12, 1],
			// borderColor: 'rgb(53, 162, 235)',
			// backgroundColor: 'rgba(53, 162, 235, 0.5)',
			borderColor: '#EF4062',
			borderWidth: 1,
			pointRadius: 3,
		},
	],
};

export const Dashboard: FC = () => {
	return (
		<div className="main-content">
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title">Dashboard</p>
				</div>
			</div>
			<div className="main-content-color-w">
				<div className="platform-balances__filter-inner top grid-one-colum">
					<div className="card-balance-text">Balance on the platform</div>
					<div className="platform-balances__filter">
						<SelectList placeholder={'Time'} data={['All Time', 'Month']} maxWidth={'max-width'} />
					</div>
				</div>

				<CardBalance balance={dataBox} />

				<div className="card-top-wrapper">
					{topCard.map((item, index) => (
						<CardTop key={index} item={item} />
					))}
				</div>

				<div className="who-registered">
					<div className="platform-balances__box-right">
						<div className="">
							<div className="card-balance-text">The number of users who have registered</div>
							<div className="card-balance__subname">49 268</div>
						</div>
						<div id="canvas-container">
							<ChartjsLine options={options} data={data} height={90} />
						</div>
						<div className="chart-block">
							<div className="chart-block__left-box">
								<span className="green left">Successful login</span>
								<span className="green">39 268</span>
							</div>
							<div className="chart-block__right-box">
								<span className="red left">Failed login</span>
								<span className="red">1 000</span>
							</div>
						</div>
					</div>
					<div className="platform-balances__box-right">
						{card.map((item, index) => (
							<div style={{ background: item.color }} key={index} className="chart-block__card">
								<div className="chart-block__svg">{item.svg}</div>
								<div className="chart-block__text-wrap">
									<span className="card-balance-text">{item.text}</span>
									<span className="card-balance__subname">{item.number}</span>
								</div>
							</div>
						))}
					</div>
				</div>
				<TotalCard />
			</div>
		</div>
	);
};
