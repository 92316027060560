import { FC } from 'react';
import './DetailsWithdrawal.scss';
import { IWithdrawalDetails } from 'redux/reducers/finance/finance-types';

interface IProps {
	item: IWithdrawalDetails | null;
}

export const DetailsWithdrawal: FC<IProps> = ({ item }) => {
	return (
		<div className="details-withdrawal">
			<ul className="details-withdrawal__row">
				<li className="details-withdrawal__row-item line-bottom">
					<span>Type</span>
					<span>{item?.recipient_type || '-'}</span>
				</li>
				<li className="details-withdrawal__row-item">
					<span>Address</span>
					<span>{item?.address_info.address || '-'}</span>
				</li>
				<li className="details-withdrawal__row-item">
					<span>Street</span>
					<span>{item?.address_info.street || '-'}</span>
				</li>
				<li className="details-withdrawal__row-item">
					<span>City</span>
					<span>{item?.address_info.city || '-'}</span>
				</li>
				<li className="details-withdrawal__row-item">
					<span>State</span>
					<span>{item?.address_info.state || '-'}</span>
				</li>
				<li className="details-withdrawal__row-item line-bottom">
					<span>ZIP code</span>
					<span>{item?.address_info.zip_code || '-'}</span>
				</li>
				<li className="details-withdrawal__row-item">
					<span>First name</span>
					<span>{item?.person_info.first_name || '-'}</span>
				</li>
				<li className="details-withdrawal__row-item">
					<span>Last name</span>
					<span>{item?.person_info.last_name || '-'}</span>
				</li>
				<li className="details-withdrawal__row-item">
					<span>Phone number</span>
					<span>{item?.person_info.phone_number || '-'}</span>
				</li>
				<li className="details-withdrawal__row-item line-bottom">
					<span>Email</span>
					<span>{item?.email_for_withdraw_to || '-'}</span>
				</li>
				<li className="details-withdrawal__row-item">
					<span>Bank name</span>
					<span>{item?.bank_info.name || '-'}</span>
				</li>
				<li className="details-withdrawal__row-item">
					<span>Street</span>
					<span>{item?.bank_info.street || '-'}</span>
				</li>
				<li className="details-withdrawal__row-item">
					<span>City</span>
					<span>{item?.bank_info.city || '-'}</span>
				</li>
				<li className="details-withdrawal__row-item">
					<span>State</span>
					<span>{item?.bank_info.street || '-'}</span>
				</li>
				<li className="details-withdrawal__row-item">
					<span>ZIP code</span>
					<span>{item?.bank_info.zip_code || '-'}</span>
				</li>
			</ul>
		</div>
	);
};
