import { UserPartnerTabs } from 'components/UserPartnerComponent/UserPartnerTabs/UserPartnerTabs';
import { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react';
import { BigCopySvg, PlusSvg } from 'assets/svg-icon';
import { useParams } from 'react-router';
import { IDataFiltter } from '../Channels';
import { UsersWrapper } from 'components/UserPartnerComponent/UsersWrapper';
import { ETabsAmbassador } from 'services/constants/lists-table';
import { Total } from 'components/UserPartnerComponent/Total/Total';
import './UserPartner.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	getListLoyaltyRequest,
	getListUserPackageRequest,
	getPackageRequest,
	getGraphRequest as getGraph,
	referralReset,
	getSummaRequest,
	getSummaBonusesRequest,
	getSummaPartnersRequest,
	getLoyaltyUsersPartnerRequest,
} from 'redux/reducers/referral/reducer';
import { PopUpWrapper } from 'ui/PopUpWrapper/PopUpWrapper';
import { SelectList } from 'ui/SelectList/SelectList';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { notificationContainer } from 'untils/notificationContainer';
import {
	getGraphPartnersSelector,
	getGraphSelector,
	getListUsersDiscount,
	getPackages,
	getPackagesDetails,
	getReferral,
	getSummaBonusesSelector,
	getSummaPartnersSelector,
} from 'redux/reducers/referral/selectors';
import { typeGraph } from 'CONST/typeFile';
import { onPackage } from 'untils/onPackage';
import { Pagination } from 'ui/Pagination';
import { onGetUrl } from 'untils/onHttp';

export const UserPartner: FC = () => {
	const dispatch = useDispatch();
	const [activeBtnUsers, setActiveBtnUsers] = useState<string>('day');
	const [activeBtnPartners, setActiveBtnPartners] = useState<string>('day');
	const [search, setSearch] = useState<string>('');
	const [discount, setDiscount] = useState<string>('');
	const graph = useSelector(getGraphSelector);
	const graphPartners = useSelector(getGraphPartnersSelector);
	const summa_bonuses = useSelector(getSummaBonusesSelector);
	const summa_partners = useSelector(getSummaPartnersSelector);
	const [popup, setPopup] = useState<boolean>(false);
	const packages = useSelector(getPackages);
	const listUsers = useSelector(getListUsersDiscount);
	const packagesDetails = useSelector(getPackagesDetails);
	const referral = useSelector(getReferral);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const params = useParams<{
		subTab?: string;
		tab?: string;
	}>();

	const onClosePopup = () => {
		setPopup(!popup);
		dispatch(getPackageRequest());
		dispatch(referralReset(null));
		setDiscount('');
	};

	const handleLinkCopy = () => {
		notificationContainer('Link successfully copied!', 'info');
	};

	const [graphRequest, getGraphRequest] = useState({
		// limit: 0,
		// offset: 0,
		payment_system: 'paypal',
		period: 'day',
		type: 'deposit',
	});

	const [dataFiltter, setDataFiltter] = useState<IDataFiltter>({
		order_by: '',
		asc: undefined,
	});

	const onSearch = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		setSearch(value);
	};

	const submitFilter = (value: number) => {
		setCurrentPage(value);
	};

	const onActivBtnUsers = (event: MouseEvent) => {
		const { name } = event.target as HTMLButtonElement;
		setActiveBtnUsers(name);
		getGraphRequest((prev) => ({ ...prev, period: name }));
	};

	const onActivBtnPartners = (event: MouseEvent) => {
		const { name } = event.target as HTMLButtonElement;
		setActiveBtnPartners(name);
		getGraphRequest((prev) => ({ ...prev, period: name }));
	};

	useEffect(() => {
		dispatch(
			getGraph({
				group: typeGraph.indexOf(activeBtnUsers),
				type: 0,
			}),
		);
	}, [activeBtnUsers]);

	useEffect(() => {
		dispatch(
			getGraph({
				group: typeGraph.indexOf(activeBtnPartners),
				type: 1,
			}),
		);
	}, [activeBtnPartners]);

	useEffect(() => {
		dispatch(
			getSummaBonusesRequest({
				is_business: true,
			}),
		);

		dispatch(
			getSummaPartnersRequest({
				is_business: false,
			}),
		);
	}, []);

	const definePage = () => {
		switch (params.tab) {
			case ETabsAmbassador.DISCOUNT:
				return (
					<UsersWrapper
						listUsers={listUsers}
						onSearch={onSearch}
						search={search}
						dataFiltter={dataFiltter}
						setDataFiltter={setDataFiltter}
						params={params}
						id_package={packagesDetails?.[1].id}
						discount={'You and your referrals will receive a 10% discount on Ello commission.'}
					/>
				);
			case ETabsAmbassador.DISCOUNT1:
				return (
					<UsersWrapper
						listUsers={listUsers}
						onSearch={onSearch}
						search={search}
						dataFiltter={dataFiltter}
						setDataFiltter={setDataFiltter}
						params={params}
						id_package={packagesDetails?.[2].id}
						discount={
							'You and your referrals will receive a 10% discount on Ello commission. You will earn 1% from your referrals’ earnings.'
						}
					/>
				);
			case ETabsAmbassador.DISCOUNT5:
				return (
					<UsersWrapper
						listUsers={listUsers}
						onSearch={onSearch}
						search={search}
						dataFiltter={dataFiltter}
						setDataFiltter={setDataFiltter}
						params={params}
						id_package={packagesDetails?.[3].id}
						discount={
							'You and your referrals will receive a 10% discount on Ello commission. You will earn 5% from your referrals’ earnings.'
						}
					/>
				);
			case ETabsAmbassador.EARN7_DISCOUNT5:
				return (
					<UsersWrapper
						listUsers={listUsers}
						onSearch={onSearch}
						search={search}
						dataFiltter={dataFiltter}
						setDataFiltter={setDataFiltter}
						params={params}
						id_package={packagesDetails?.[3].id}
						discount={
							'Your referrals will receive a 5% discount on Ello commission. You will earn 7.5% from your referrals’ earnings.'
						}
					/>
				);
			case ETabsAmbassador.EARN5_DISCOUNT5:
				return (
					<UsersWrapper
						listUsers={listUsers}
						onSearch={onSearch}
						search={search}
						dataFiltter={dataFiltter}
						setDataFiltter={setDataFiltter}
						params={params}
						id_package={packagesDetails?.[3].id}
						discount={
							'Your referrals will receive a 5% discount on Ello commission. You will earn 5% from your referrals’ earnings.'
						}
					/>
				);
			case ETabsAmbassador.EARN5_DISCOUNT10:
				return (
					<UsersWrapper
						listUsers={listUsers}
						onSearch={onSearch}
						search={search}
						dataFiltter={dataFiltter}
						setDataFiltter={setDataFiltter}
						params={params}
						id_package={packagesDetails?.[3].id}
						discount={
							'Your referrals will receive a 10% discount on Ello commission. You will earn 5% from your referrals’ earnings.'
						}
					/>
				);
			case ETabsAmbassador.EARN7_DISCOUNT10:
				return (
					<UsersWrapper
						listUsers={listUsers}
						onSearch={onSearch}
						search={search}
						dataFiltter={dataFiltter}
						setDataFiltter={setDataFiltter}
						params={params}
						id_package={packagesDetails?.[3].id}
						discount={
							'Your referrals will receive a 10% discount on Ello commission. You will earn 7.5% from your referrals’ earnings.'
						}
					/>
				);
			default:
				return (
					<Total
						partners={summa_partners?.Summa || 0}
						bonuses={summa_bonuses?.Summa || 0}
						graph={graph}
						graphPartners={graphPartners}
						activeBtnUsers={activeBtnUsers}
						onActivBtnUsers={onActivBtnUsers}
						activeBtnPartners={activeBtnPartners}
						onActivBtnPartners={onActivBtnPartners}
					/>
				);
		}
	};

	const onDiscount = (value: string) => {
		setDiscount(value);

		dispatch(
			getListLoyaltyRequest({
				loyalty_package_id: packagesDetails?.find(
					(item) => item.name.toLowerCase() === value.replaceAll('_', ' '),
				)?.id as number,
			}),
		);
	};

	useEffect(() => {
		dispatch(getPackageRequest());
	}, []);

	const isDiscount = () => {
		if (params.tab === 'discount1') {
			return 3;
		} else if (params.tab === 'discount5') {
			return 4;
		} else if (params.tab === '7.5earn-discount10') {
			return 5;
		} else if (params.tab === '5earn-discount10') {
			return 6;
		} else if (params.tab === '5earn-discount5') {
			return 7;
		} else if (params.tab === '7.5earn-discount5') {
			return 8;
		} else {
			return 2;
		}
	};

	useEffect(() => {
		params.tab?.includes('discount') &&
			dispatch(
				getLoyaltyUsersPartnerRequest({
					per_page: 15,
					current_page: +location.search?.slice(1) || 1,
					business: params.subTab?.includes('business-profile') ? true : false,
					package_id: isDiscount(),
					search,
					...dataFiltter,
				}),
			);
	}, [params, search, dataFiltter, currentPage]);

	return (
		<div className="main-content">
			{popup && packages && (
				<PopUpWrapper isOpenModal={onClosePopup} title="" btnName="">
					<div className="discount-popup">
						<div className="discount-popup__title">Add User Partner</div>
						<div className="discount-popup__subtitle">Package</div>
						<SelectList
							onSelect={onDiscount}
							placeholder="Discount"
							data={packages.filter((item) => item !== 'Null')}
							className="input-item--pr50"
						/>

						<div className="discount-popup__text">
							{discount
								? discount?.toLowerCase()?.replaceAll('_', ' ')[0]?.toUpperCase() +
								  discount?.toLowerCase()?.replaceAll('_', ' ').slice(1)
								: 'This user in NOT a partner, he has normal loyalty system.'}{' '}
						</div>
						<div className="discount-popup__referal-link">
							<div className="discount-popup__referal-link-text">Referral code link</div>
							{referral?.code && (
								<div className="discount-popup__svg">
									<CopyToClipboard
										text={`${onGetUrl()}invite_referral?code=${referral?.code || ''}`}
										onCopy={handleLinkCopy}
									>
										<BigCopySvg />
									</CopyToClipboard>
								</div>
							)}
						</div>
						<div className="discount-popup__referal-code">
							<div>
								<div
									style={!referral?.code ? { marginBottom: 0 } : {}}
									className="discount-popup__referal-link-text-gray"
								>
									Referral code
								</div>
								<div className="discount-popup__referal-link-text">{referral?.code || ''}</div>
							</div>
							{referral?.code && (
								<div className="discount-popup__svg">
									<CopyToClipboard text={referral?.code || ''} onCopy={handleLinkCopy}>
										<BigCopySvg />
									</CopyToClipboard>
								</div>
							)}
						</div>
					</div>
				</PopUpWrapper>
			)}
			<div className="title-block-wrap">
				<div className="title-block">
					<div className="header-title">
						<p className="title">User Partner</p>
						<div className="add-btn-wrap">
							<button
								onClick={onClosePopup}
								style={{ display: 'flex', gap: '5px' }}
								className="add-btn"
								type="button"
							>
								<PlusSvg />
								Add new partner
							</button>
						</div>
					</div>
				</div>
			</div>
			<UserPartnerTabs tab={params.tab || ''} subTab={params.subTab || ''} />

			{definePage()}
			{listUsers?.loyally_full_users && listUsers?.count > 15 ? (
				<Pagination
					request={submitFilter}
					currentPage={currentPage}
					numberPage={Math.ceil(listUsers.count / 15)}
				/>
			) : null}
		</div>
	);
};
