import { ZipSvg } from 'assets/svg-icon';
import { FC, useEffect, useRef, useState } from 'react';
import { displayProtectedImage } from 'untils/getFile';
import { getTypeFile } from 'untils/getTypeFile';
import { onHttp } from 'untils/onHttp';

interface IProps {
	hash: string;
	endpoint?: string;
	type?: string;
}

export const File: FC<IProps> = ({ hash, type, endpoint = '/storage/photo' }) => {
	const refVideo = useRef<any>();
	const [loader, setLoader] = useState(false);
	const link = 'link';

	useEffect(() => {
		Number(hash) &&
			refVideo &&
			displayProtectedImage(
				refVideo,
				`${onHttp()}${endpoint}/${hash}`,
				localStorage.getItem('token') || '',
				setLoader,
				link,
			);
	}, [refVideo, hash]);

	return (
		<a ref={refVideo} download>
			{getTypeFile(type || '')}
		</a>
	);
};
