/* eslint-disable no-nested-ternary */
import { Block } from 'components/Popups/Block';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { EChannelUpgradeType } from 'services/constants/type';
import { IPropsChannelsBodyTable } from '../types';
import {
	addTopChannelsRequest,
	changeVerifiedRequest,
	moveTopChannelsRequest,
	removeTopChannelsRequest,
} from 'redux/reducers/channels/reducer';
import { getAllCountries } from 'redux/reducers/users/selectors';
import { ETabsChannel } from 'services/constants/lists-table';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IChannels } from 'redux/reducers/channels/types';
import { Table } from './Table/Table';
import { mocData } from 'CONST/channels-tabs';

export interface IOpenPopup {
	id: string;
	name: string;
}

export const ChannelsBodyTable: FC<IPropsChannelsBodyTable> = ({
	channels,
	top_channels,
	param,
	search,
	onIndexTop,
}) => {
	const location = useLocation();
	const [onChangeVerification, setOnChangeVerification] = useState<number | null>(null);
	const [channelsList, setChannelsList] = useState<Array<IChannels>>(
		+location.search.replace('?', '') <= 1 && param === ETabsChannel.RECOMMENDED_CHANNELS
			? [...(top_channels || []), ...channels]
			: channels,
	);
	const [isOpenPopup, setIsOpenPopup] = useState<IOpenPopup | null>(null);
	const countriesList = useSelector(getAllCountries);
	const [popup, setPopup] = useState<number>(0);
	const dispatch = useDispatch();
	const params = useParams();

	useEffect(() => {
		setChannelsList(
			+location.search.replace('?', '') <= 1 && param === ETabsChannel.RECOMMENDED_CHANNELS
				? [...(top_channels || []), ...channels]
				: channels,
		);
	}, [top_channels, channels]);

	// const photo = useSelector(getPhoto);
	// const [file, setFile] = useState<string>('');
	// const token = useSelector(getToken);

	const createAvataar = (title: string): string => {
		const array = title.trim().split(' ');
		return array.length >= 2
			? array[0][0]?.toUpperCase() + array[1][0]?.toUpperCase()
			: title?.slice(0, 2)?.toUpperCase();
	};

	const upgradeChannel = (event: MouseEvent, value: boolean) => {
		const target = event.currentTarget as HTMLButtonElement;
		(isOpenPopup || target.name !== EChannelUpgradeType.BLOCK) &&
			dispatch(
				changeVerifiedRequest({
					verify: value,
					channel_id: +target.id,
				}),
			);
		setOnChangeVerification(null);
	};

	const reorder = (list: any, startIndex: any, endIndex: any): Array<IChannels> => {
		const result: Array<IChannels> = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		onIndexTop(endIndex);

		if (!String(removed.id).includes('empty')) {
			endIndex <= 9
				? dispatch(addTopChannelsRequest({ channel_id: Number(removed.id), index: endIndex }))
				: dispatch(removeTopChannelsRequest({ channel_id: Number(removed.id) }));
		}

		return result;
	};

	const onDragEnd = (result: any) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		if (result.source.index <= 9 && result.destination.index <= 9) {
			dispatch(
				moveTopChannelsRequest({
					index_from: result.source.index + 1,
					index_to: result.destination.index + 1,
				}),
			);
			return;
		}

		const items: Array<IChannels> = reorder(
			channelsList,
			result.source.index,
			result.destination.index,
		);

		setChannelsList(items);
	};

	const onPopup = (value: number, index?: number) => {
		setPopup(value);

		if (popup) {
			Number(index) >= 9
				? dispatch(addTopChannelsRequest({ channel_id: popup, index: 10 }))
				: dispatch(removeTopChannelsRequest({ channel_id: popup }));
		}
	};

	return channelsList.length ? (
		<div className="table-body">
			{isOpenPopup && (
				<Block
					isOpenPopup={isOpenPopup}
					upgradeChannel={upgradeChannel}
					setIsOpenPopup={setIsOpenPopup}
				/>
			)}
			{param === ETabsChannel.RECOMMENDED_CHANNELS ? (
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}
								// style={getListStyle(snapshot.isDraggingOver)}
							>
								{channelsList?.map((channel, index) => {
									return (
										<Draggable key={channel.id} draggableId={String(channel.id)} index={index}>
											{(provided, snapshot) => (
												<Table
													page={+location.search.replace('?', '')}
													provided={provided}
													param={param as string}
													index={index}
													channel={channel}
													params={params}
													search={search}
													countriesList={countriesList}
													onChangeVerification={onChangeVerification}
													upgradeChannel={upgradeChannel}
													setOnChangeVerification={setOnChangeVerification}
													onPopup={onPopup}
													createAvataar={createAvataar}
													popup={popup}
												/>
											)}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			) : (
				channelsList?.map((channel, index) => {
					return (
						<Table
							key={channel.id}
							param={param as string}
							index={index}
							channel={channel}
							params={params}
							search={search}
							countriesList={countriesList}
							onChangeVerification={onChangeVerification}
							upgradeChannel={upgradeChannel}
							setOnChangeVerification={setOnChangeVerification}
							onPopup={onPopup}
							createAvataar={createAvataar}
							popup={popup}
						/>
					);
				})
			)}
		</div>
	) : null;
};
