import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';

export const CurrentUserBody: FC = () => {
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">#Number</p>
				<Link to={ELinksName.MANAGEMENT_DETAILS_PERSONAL_DATA}>Artur Saris</Link>
			</div>
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<Link to={ELinksName.MANAGEMENT_DETAILS_PERSONAL_DATA}>{`@L'Oréal`}</Link>
			</div>
			<div className="td">
				<p className="td-hidden-name"> Type</p>
				<Link to={ELinksName.MANAGEMENT_DETAILS_PERSONAL_DATA}>{`@L'Oréal`}</Link>
			</div>
			<div className="td td--fw-500 td--right-custom">
				<p className="td-hidden-name">Amount</p>
				<div className="green">
					<span>Sep 24, 2022</span> <div className="gray">3:06 PM</div>
				</div>
			</div>
			<div className="td td--right-custom">
				<p className="td-hidden-name">Services</p>
				<p>App</p>
			</div>
			<div className="td td--right td--right-custom">
				<p className="td-hidden-name">Status</p>
				<p>Pending</p>
			</div>
		</div>
	);
};
