import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IChannelsResponse, IListTypes, IPrivious, IReportDetails, IReportsState } from './types';

const getREportsState = (state: IStoreState): IReportsState => state.reports;

export const getChannelsSelector = createSelector(
	[getREportsState],
	(reports: IReportsState): null | IChannelsResponse => reports.channels,
);

export const getLoader = createSelector(
	[getREportsState],
	(reports: IReportsState): boolean => reports.reportsLoader,
);

export const getPostsSelector = createSelector(
	[getREportsState],
	(reports: IReportsState): null | IChannelsResponse => reports.posts,
);

export const getGroupsSelector = createSelector(
	[getREportsState],
	(reports: IReportsState): null | IChannelsResponse => reports.groups,
);

export const getMessagesSelector = createSelector(
	[getREportsState],
	(reports: IReportsState): null | IChannelsResponse => reports.messages,
);

export const getReportDetailsSelector = createSelector(
	[getREportsState],
	(reports: IReportsState): null | IReportDetails => reports.reportsDetails,
);

export const getReportsLoaderSelector = createSelector(
	[getREportsState],
	(reports: IReportsState): boolean => reports.reportsLoader,
);

export const getTypesSelector = createSelector(
	[getREportsState],
	(reports: IReportsState): Array<IListTypes> | null => reports.types,
);

export const getPreviousSelector = createSelector(
	[getREportsState],
	(reports: IReportsState): IPrivious | null => reports.previous,
);
