import { FC, useState } from 'react';
import Toggle from 'react-toggle';

import { Checkbox } from 'ui/Checkbox/Checkbox';
import { IBoxFirstProps } from '../types';

export const BoxFooter: FC<IBoxFirstProps> = ({ admin, onEnableOrDisable2Fa }) => {
	const [toggle, setToggle] = useState<boolean>(admin?.otp_enabled || false);

	const handleTofuChange = () => {
		setToggle(!toggle);
		admin && onEnableOrDisable2Fa?.(admin.email, !toggle ? false : true);
	};

	return (
		<ul className="admin-info__list">
			<li className="admin-info__item">
				<span className="admin-info__first-column">2FA-Authorization</span>
				<div className="user-data__toggle-wrapper left">
					<Toggle defaultChecked={toggle} icons={false} onChange={handleTofuChange} />
				</div>
			</li>
		</ul>
	);
};
