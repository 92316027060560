import { endpoint } from 'services/endpoint';
import { http, multipart } from 'services/http';
import { IApkFiles } from './types';
import { IApkListRequest, IUploadFile } from 'redux/reducers/apk-files/types';

export const apkFiles: IApkFiles = {
	getListApk: (payload: IApkListRequest) =>
		http.post(endpoint.apkFile.APK_LIST, payload).then((response) => response.data),
	addApk: (payload: IUploadFile) => {
		const { onPercent, size, file } = payload;
		const config = {
			onUploadProgress: (progress: any) => {
				// console.log(progress.loaded);
				onPercent(progress.loaded);
				// onPercent(Math.floor(progress.loaded / size) * 100);
			},
		};
		return multipart
			.post(endpoint.apkFile.APK_UPLOAD, file, config)
			.then((response) => response.data);
	},
	deleteApk: (payload: string) =>
		http.delete(endpoint.apkFile.APK_DELETE(payload)).then((response) => response.data),
};
