import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { IPostsResponse } from 'redux/reducers/channels/types';
import moment from 'moment';
import { Loader } from 'components/Loader';
import { createAvataar } from 'untils/createAvatar';
import { Avatar } from 'ui/Avatar/Avatar';
import { ETypesFile } from 'CONST/typeFile';
import { MusicSvg } from 'assets/svg-icon';
// import { Link } from 'react-router-dom';

interface IPropsPostsBody {
	posts: IPostsResponse;
	loader: boolean;
}

export const PostsBody: FC<IPropsPostsBody> = ({ posts, loader }) => {
	const params = useParams();

	console.log(posts);

	return (
		<div className="table-body">
			{loader ? (
				<Loader />
			) : (
				posts?.posts?.map((post) => {
					return (
						<div key={post.id} className="tr">
							<div className="td">
								<p className="td-hidden-name"> Content</p>
								<div className="avatar-wrapper">
									<div className="avatar avatar--square">
										{post.media_access_hash.length > 1 ? (
											post.message_filter_type === ETypesFile.MEDIA_MUSIC ||
											// post.message_filter_type === ETypesFile.MEDIA_PHOTOVIDEO ||
											post.message_filter_type === ETypesFile.MEDIA_FILE ||
											post.message_filter_type === ETypesFile.MEDIA_AUDIO ? (
												<MusicSvg />
											) : (
												<Avatar
													hash={String(post.media_access_hash)}
													endpoint={
														post.content_type.toLowerCase().includes('document')
															? '/storage/document'
															: '/storage/photo'
													}
												/>
											)
										) : (
											<div className="avatar-custom">
												{post.message_filter_type === ETypesFile.MEDIA_MUSIC ||
												post.message_filter_type === ETypesFile.MEDIA_AUDIO ||
												post.message_filter_type === ETypesFile.MEDIA_PHOTOVIDEO ? (
													<MusicSvg />
												) : (
													<span>{createAvataar(post.author)}</span>
												)}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="td">
								<p className="td-hidden-name">Post text</p>
								<Link to={`${ELinksName.POST_DETAILS}/${params.id}/${post.id}`}>
									{post.post_text || '-'}
								</Link>
							</div>
							<div className="td">
								<p className="td-hidden-name">Author</p>
								<p>{post.author}</p>
							</div>
							<div className="td td--right">
								<p className="td-hidden-name">Comments</p>
								<p>{post.comments_count}</p>
							</div>
							<div className="td td--right">
								<p className="td-hidden-name">Published</p>
								<p>{moment(post.published).format('MMM DD, YYYY')}</p>
								<p className="gray">{moment(post.published).format('h:mm A')}</p>
							</div>
						</div>
					);
				})
			)}
		</div>
	);
};
