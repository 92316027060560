import { FC } from 'react';
import { SvgArrowDownHeader, SvgArrowUpHeader } from 'ui/svg-image/SvgAllChannels';

export const MediaSalesHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Content</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Name</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Type</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Price</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Available quantity</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Sales</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Amount of earnings</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Created date</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
