import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import avatar from 'assets/images/avatar-square.png';
import { SvgUnverifiedAccount, SvgVerifiedAccount } from 'ui/svg-image/SvgAllChannels';
import { IChannelsResponse } from 'redux/reducers/users/types';
import { Loader } from 'components/Loader';
import { NotVerifiedGraySvg } from 'assets/svg-icon';
import { GET_PHOTO } from 'services/constants/env';
import { Avatar } from 'ui/Avatar/Avatar';
import { createAvataar } from 'untils/createAvatar';
import { IDataFiltter } from 'pages/Dashboard/Channels';

interface IProps {
	tableDate: IChannelsResponse;
	loader: boolean;
	setDataFiltter: (value: IDataFiltter) => void;
}

export const UserDetailsTableBody: FC<IProps> = ({ setDataFiltter, tableDate, loader }) => {
	const params = useParams();
	const channels = tableDate?.data?.channels;

	return (
		<div className="table-body">
			{loader ? (
				<Loader />
			) : (
				channels?.map((channel) => (
					<div key={channel.id} className="tr">
						<div className="td">
							<p className="td-hidden-name"> Avatar</p>
							<div className="avatar-wrapper">
								<div className="avatar avatar--circle">
									{Number(channel.photo_access_hash) ? (
										<Avatar hash={String(channel.photo_access_hash)} />
									) : (
										<div className="avatar-custom">
											<span>{createAvataar(channel.title)}</span>
										</div>
									)}
								</div>
							</div>
						</div>
						<div className="td">
							<p className="td-hidden-name"> Channel name</p>
							<p>{channel.title}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name"> Channel URL </p>
							<p>
								<Link
									to={`${ELinksName.USERS_USER_DETAILS}/${channel.id || ''}`}
									className="link link--type2"
								>
									{channel.link}
								</Link>
							</p>
						</div>
						<div className="td">
							<p className="td-hidden-name"> # Members</p>
							<p>{channel.about}</p>
						</div>

						<div className="td">
							<p className="td-hidden-name">Subscrption cost</p>
							<p>{channel.cost}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">Status</p>
							<p>{channel.pay_type}</p>
						</div>
						<div className="td">
							<p className="td-hidden-name">Subscription date</p>
							<p>{channel.joined_at}</p>
						</div>
						<div className="td td--righ-custom">
							<p className="td-hidden-name">Verified status</p>
							<p>{channel.verified ? <SvgVerifiedAccount /> : <NotVerifiedGraySvg />}</p>
						</div>
					</div>
				))
			)}
		</div>
	);
};
