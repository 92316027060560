import { PlusBlueSvg } from 'assets/svg-icon';
import { ChangeEvent, FC } from 'react';
import { Button } from 'ui/Button/Button';
import { InputDebounce } from 'ui/InputDebouns/InputDebounce';
import { SelectList } from 'ui/SelectList/SelectList';

const data = ['CSV'];

interface IProps {
	handleSearch: (event: ChangeEvent) => void;
	search: string;
	isOpenModal: () => void;
}

export const UsernameFilter: FC<IProps> = ({ handleSearch, search, isOpenModal }) => {
	return (
		<div className="username-filter">
			<div className="username-filter__select-wrap">
				<div className="username-filter__inner">
					{/* <SelectList data={data} placeholder="Download file" /> */}
				</div>
			</div>
			<div className="username-filter__search-wrap">
				<div className="username-filter__wrap-input">
					<InputDebounce value={search} handleChange={handleSearch} />
				</div>
				<Button
					onClick={isOpenModal}
					className="button-border width-content width-user-name-btn"
					type="button"
					name="Add new username"
				>
					<PlusBlueSvg />
				</Button>
			</div>
		</div>
	);
};
