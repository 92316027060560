import { channelsCategory, channelsTypes } from 'CONST/userFilter';
import { ChannelsBodyTable } from 'components/ChannelsComponents/ChannelsBodyTable';
import { ChannelsHeaderTable } from 'components/ChannelsComponents/ChannelsHeaderTable';
import { TabsChannels } from 'components/ChannelsComponents/TabsChannels';
import { Loader } from 'components/Loader';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	channelsListRequest,
	getStatisticRequest,
	getTopChannelsRequest,
} from 'redux/reducers/channels/reducer';
import {
	getChannelsListSelector,
	getChannelsLoaderSelector,
	getStatisticSelector,
	getTopChannelListSelector,
} from 'redux/reducers/channels/selectors';
import { ETabsChannel } from 'services/constants/lists-table';
import { Fillter } from 'ui/Fillter/Fillter';
import { NotFound } from 'ui/NotFound/NotFound';
import { Pagination } from 'ui/Pagination';
import { numeralSpaceFunc } from 'untils/numerals';

export interface IDataFiltter {
	asc?: boolean;
	category?: string;
	country_code?: string;
	current_page?: number;
	date_from?: string | Date;
	date_to?: string | Date;
	is_blocked?: boolean;
	per_page?: number;
	search?: string;
	type?: string;
	order_by?: string;
	sort?: string;
	only_adult?: boolean;
	created_date?: string;
	type_id?: string | undefined | number;
	participiant_type?: number;
	status?: string;
}

interface StateVariable {
	dataFiltter: IDataFiltter;
	setDataFiltter: (value: IDataFiltter) => void;
}

const Channels: FC = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const loader = useSelector(getChannelsLoaderSelector);
	const channelsList = useSelector(getChannelsListSelector);
	const top_channels = useSelector(getTopChannelListSelector);
	const statistic = useSelector(getStatisticSelector);
	const [dataFiltter, setDataFiltter] = useState<IDataFiltter>({});
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [indexTop, setIndexTop] = useState<number | null>(null);

	const onIndexTop = (num: number) => {
		setIndexTop(num);
	};

	const submitFilter = (value: number) => {
		setCurrentPage(value);

		const getData = (): boolean => {
			if (params.tab === ETabsChannel.DELETE_CHANNELS) {
				return true;
			}
			if (params.tab === ETabsChannel.RECOMMENDED_CHANNELS) {
				return true;
			}
			return params.tab === ETabsChannel.BLOCKED_CHANNELS ? true : false;
		};

		const getKey = (key: string) => {
			switch (key) {
				case ETabsChannel.DELETE_CHANNELS:
					return 'is_deleted';

				case ETabsChannel.BLOCKED_CHANNELS:
					return 'is_blocked';

				case ETabsChannel.RECOMMENDED_CHANNELS:
					return 'is_recommended';

				default:
					return 'is_recommended';
			}
		};

		dispatch(
			channelsListRequest({
				...dataFiltter,
				current_page: value,
				[getKey(params.tab as string)]: getData() || undefined,
				per_page: 15,
			}),
		);
	};

	useEffect(() => {
		submitFilter(+location.search?.slice(1) || 1);
	}, [params.tab, dataFiltter, indexTop, +location.search]);

	console.log(params.tab === 'recommended-channels' || null);

	useEffect(() => {
		dispatch(getTopChannelsRequest());
	}, [indexTop]);

	useEffect(() => {
		dispatch(
			getStatisticRequest({
				is_blocked: params.tab === 'blocked-channels' || null,
				is_deleted: params.tab === 'delete-channels' || null,
				is_recommended: params.tab === 'recommended-channels' || null,
			}),
		);
	}, [params.tab]);

	const onTotal = (value: string) => {
		switch (value) {
			case 'Total':
				return numeralSpaceFunc(statistic?.data.total || 0);
			case 'Private':
				return numeralSpaceFunc(statistic?.data.private || 0);
			case 'Public':
				return numeralSpaceFunc(statistic?.data.public || 0);
			case 'Paid':
				return numeralSpaceFunc(statistic?.data.paid || 0);

			default:
				return numeralSpaceFunc(statistic?.data.course || 0);
		}
	};

	console.log(channelsList);

	return (
		<div className="main-content">
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title">Channels Management</p>
				</div>
			</div>
			<TabsChannels />
			<div className="table-block">
				<Fillter
					type={channelsTypes}
					status={channelsCategory}
					setDataFiltter={setDataFiltter}
					typesName="Types"
					statusName="Status"
					keyStatus="status"
					keyType="type"
				/>
				<div className="filter-custom__info-card">
					{['Total', 'Private', 'Public', 'Paid', 'Course'].map((el) => (
						<div key={el} className="filter-custom__card">
							<p className="filter-custom__title">{el}</p>
							<p className="filter-custom__subtitle">{onTotal(el)}</p>
						</div>
					))}
				</div>
				<div className="table-wrapper">
					<div
						className={`table table--${
							params.tab === ETabsChannel.RECOMMENDED_CHANNELS
								? 'channels-recommended '
								: 'channels-all'
						} table--channels-loader`}
					>
						<ChannelsHeaderTable param={params?.tab as string} setDataFiltter={setDataFiltter} />
						{loader ? (
							<Loader />
						) : channelsList?.channels?.length && channelsList ? (
							<ChannelsBodyTable
								search={dataFiltter.search || ''}
								channels={
									params.tab === 'recommended-channels'
										? channelsList?.channels?.filter((channel) =>
												top_channels?.every((item) => {
													return channel.id !== item.id;
												}),
										  )
										: channelsList?.channels
								}
								top_channels={top_channels || []}
								param={params.tab}
								onIndexTop={onIndexTop}
							/>
						) : (
							<NotFound name={'transaction history'} />
						)}
					</div>
				</div>
			</div>
			{!loader && channelsList?.channels?.length && channelsList?.total >= 15 ? (
				<Pagination
					request={submitFilter}
					currentPage={currentPage}
					numberPage={Math.ceil(channelsList.total / 15)}
				/>
			) : null}
		</div>
	);
};

export default Channels;
