import avatar from '../../../assets/images/avatar.png';

export const mockDataUsers = {
	id: 1,
	username: 'Ann',
	photo: {
		String: avatar,
		Valid: true,
	},
	email: 'ann@gmail.com',
	registration_date: '26.07.2022',
	balance: {
		Float64: 1,
		Valid: true,
	},
	country_code: {
		String: '4554dff',
		Valid: true,
	},
	gender: {
		String: 'female',
		Valid: true,
	},
	verified: true,
	blocked: true,
};
