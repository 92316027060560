import { FC, MouseEvent, useEffect, useState } from 'react';
import { Select } from 'ui/Select/Select';
import Toggle from 'react-toggle';
import { DeleteSvg, LockRedSvg, LockSvg } from 'assets/svg-icon';
import { cardName, verified } from 'CONST/cardName';
import { DetailsUser } from './DetailsUser/DetailsUser';
import { ICountry, IUser } from 'redux/reducers/users/types';
import { useDispatch, useSelector } from 'react-redux';
import {
	blockUserRequest,
	deleteUserRequest,
	getUserSuccess,
	putUpgradeRequest,
} from 'redux/reducers/users/reducer';
import { SuccessfullyPopup } from 'ui/SuccessfullyPopup/SuccessfullyPopup';
import { PopUpAsk } from 'ui/PopUpAsk/PopUpAsk';
import { useNavigate, useParams } from 'react-router-dom';
import { getPhotos } from 'redux/reducers/users/selectors';
import { numeralFunc } from 'untils/numerals';
import { ETabsUsers } from 'services/constants/lists-table';

interface IProps {
	userDetails: IUser | undefined;
	countriesList: null | Array<ICountry>;
}

export const UserData: FC<IProps> = ({ userDetails, countriesList }) => {
	const [toggle, setToggle] = useState<boolean>(false);
	const [text, setText] = useState<string>('');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const images = useSelector(getPhotos);
	const params = useParams();

	const handleTofuChange = () => {
		setToggle(!toggle);
	};

	const upgradeChannel = (user_id: number, verify: boolean) => {
		dispatch(
			putUpgradeRequest({
				user_id,
				verify,
			}),
		);
	};

	const onBlock = (name: string, e?: MouseEvent) => {
		console.log(e);

		const { id } = e?.currentTarget as HTMLDivElement;
		setText(id || name);
	};

	const onRemoveUser = () => {
		console.log(text);
		text === 'delete'
			? dispatch(
					deleteUserRequest({
						paramsApi: {
							user_id: userDetails?.id || '',
							[ETabsUsers.DELETE_USERS === params.userId ? 'recover' : 'reasons']:
								ETabsUsers.DELETE_USERS === params.userId ? true : '',
						},
						navigate,
					}),
			  )
			: userDetails &&
			  dispatch(
					blockUserRequest({
						paramsApi: {
							[ETabsUsers.DELETE_USERS === params.userId ? 'recover' : 'block']:
								ETabsUsers.DELETE_USERS === params.userId
									? true
									: params.userId?.includes('blocked')
									? false
									: true,
							reasons: '',
							user_id: Number(userDetails.id),
						},
						navigate,
					}),
			  );
	};

	return (
		<div className="user-data">
			{text && (
				<PopUpAsk
					setText={setText}
					title={`Are you sure you want to ${text} this user?`}
					btnNameOne="Yes, I’m sure"
					btnNameTwo="Cancel"
					onClick={onRemoveUser}
				/>
			)}
			<div className="user-data__header">
				<div className="user-data__block left">
					{/* {userDetails && ( */}
					<Select
						data={verified}
						defaultType={userDetails?.verified}
						upgradeChannel={upgradeChannel}
					/>
					{/* )} */}
					<div className="user-data__toggle-wrapper left">
						<span className="user-data__toggle-text">Financial transactions</span>

						<Toggle defaultChecked={toggle} icons={false} onChange={handleTofuChange} />
					</div>
				</div>
				<div className="user-data__block right width">
					{params.userId === 'delete-users' ? (
						<div
							id="delete"
							onClick={(event: MouseEvent) => onBlock('delete', event)}
							className="user-data__toggle-wrapper right pointer"
						>
							<LockRedSvg />
							<span className={`user-data__toggle-text red`}>Recover user</span>
						</div>
					) : (
						<div
							onClick={(event: MouseEvent) => onBlock('block', event)}
							className="user-data__toggle-wrapper right pointer"
						>
							{params.userId?.includes('blocked') ? <LockSvg /> : <LockRedSvg />}
							<span
								className={`user-data__toggle-text ${
									params.userId?.includes('blocked') ? 'green' : 'red'
								}`}
							>
								{params.userId?.includes('blocked') ? 'Unblock' : 'Block'}
							</span>
						</div>
					)}
					{params.userId === 'delete-users' ? (
						<></>
					) : (
						// <div
						// 	onClick={() => onBlock('delete')}
						// 	className="user-data__toggle-wrapper right pointer"
						// >
						// 	<DeleteSvg />
						// 	<span className="user-data__toggle-text red">Irrevocably delete</span>
						// </div>
						<div
							onClick={(event: MouseEvent) => onBlock('delete', event)}
							className="user-data__toggle-wrapper right pointer"
						>
							<DeleteSvg />
							<span className="user-data__toggle-text red">Delete account</span>
						</div>
					)}
				</div>
			</div>
			<div className="user-data__body">
				<div className="user-data__card-wrapper">
					{cardName.map((key, i) => (
						<div key={key} className="user-data__card">
							<div className="user-data__title">{key}</div>
							<div className="user-data__subtitle">
								{userDetails &&
									`$${
										i === 0
											? numeralFunc(userDetails.wallet_balance) || 0
											: numeralFunc(userDetails.earn_wallet_balance) || 0
									}`}
							</div>
						</div>
					))}
				</div>
				<DetailsUser
					userDetails={userDetails}
					photos={images?.data}
					countriesList={countriesList}
				/>
			</div>
			<div className="user-data__footer">
				<p className="user-data__footer-title">Message to user</p>
				<textarea
					className="user-data__placeholder"
					placeholder="Enter text"
					name=""
					id=""
				></textarea>
				<div className="user-data__button-wrapper">
					<button type="button" className="user-data__button">
						Send
					</button>
				</div>
			</div>
		</div>
	);
};
