import { FC } from 'react';
import { Chart } from 'react-google-charts';

export const ColumnChart: FC<{ data: any; options?: any }> = ({ data, options }) => {
	const elementsRed: any = window.document.querySelectorAll('#reactgooglegraph-1');

	return (
		<div id="chart">
			<Chart chartType="Bar" width="100%" height="200px" data={data} options={options || {}} />
		</div>
	);
};
