import { ArrowBackSvg } from 'assets/svg-icon';
import { FC, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { NameExempleDetails } from '../NameExemple/NameExempleDetails/NameExempleDetails';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EChannelsTabs, EMediaSubtabs } from 'services/constants/lists-table';
import { Media } from './Media/Media';
import { useDispatch, useSelector } from 'react-redux';
import { getMediaFilesRequest, getMediaFilesSuccess } from 'redux/reducers/channels/reducer';
import { getMediaFilesSelector } from 'redux/reducers/channels/selectors';
import { Loader } from 'components/Loader';

export const MediaAndFilesDetails: FC = () => {
	const params = useParams();
	const location = useLocation();
	const dispatch = useDispatch();
	const fileDetails = useSelector(getMediaFilesSelector);

	console.log(params.tab);

	useEffect(() => {
		dispatch(getMediaFilesSuccess(null));
		dispatch(
			getMediaFilesRequest({
				channel_id: params.channelId || '',
				file_id: params.id || '',
			}),
		);
	}, []);

	return (
		<div className="main-content">
			<div className="title-block-wrap">
				<div className="title-block">
					<button className="back-button" type="button" onClick={() => window.history.go(-1)}>
						<ArrowBackSvg />
					</button>
					<p className="title">
						{params.tab ? params.tab.slice(0, 1).toUpperCase() + params.tab.slice(1) : null}
					</p>
				</div>
			</div>
			<div className="table-block table-block-wrapper">
				<div className="user-data">
					<p className="user-data__title">Content</p>
					<div className="user-data__body">
						{fileDetails ? <Media fileDetails={fileDetails} /> : <Loader />}
					</div>
				</div>
			</div>
		</div>
	);
};
