import { ArrowDownSvg, CheckSvg, FilterSvg, PlusSvg, SearchSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { inputData } from 'CONST/inputDate';
import { reportList } from 'CONST/selectList';
import moment from 'moment';
import { IDataFiltter } from 'pages/Dashboard/Channels';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'ui/Button/Button';
import { Calendar } from 'ui/Calendar/Calendar';
import { CountrySelect } from 'ui/Countries/Countries';
import { SelectList } from 'ui/SelectList/SelectList';
import { DebounceInput } from 'react-debounce-input';
import { countryShow, reportsListParams } from 'CONST/userFilter';
import { useDispatch, useSelector } from 'react-redux';
import { getTypesRequest } from 'redux/reducers/reports/reducer';

import { getTypesSelector } from 'redux/reducers/reports/selectors';
import { ESubTabsFinance } from 'services/constants/lists-table';

export const Fillter: FC<{
	type?: Array<string>;
	status?: Array<string>;
	other?: Array<string>;
	typesName?: string;
	statusName?: string;
	otherName?: string;
	keyType: string;
	keyStatus: string;
	grid?: string;
	setDataFiltter?: (value: IDataFiltter) => void;
	onSelect?: (value: IDataFiltter) => void;
	onImport?: () => void;
}> = ({
	type,
	status,
	other,
	grid,
	typesName,
	statusName,
	otherName,
	setDataFiltter,
	keyType,
	keyStatus,
	onImport,
}) => {
	const [country, setCountry] = useState<string>('');
	const [search, setSearch] = useState<string>('');
	const [typeAll, setTypeAll] = useState<Array<string>>([]);
	const [currencies, setСurrencies] = useState<Array<string>>([]);
	const [category, setCategory] = useState<Array<string>>([]);
	const [open, setOpen] = useState<boolean>(false);
	const [date, setDate] = useState<{ date_from?: Date | string; date_to?: Date | string }>({
		date_from: '',
		date_to: '',
	});
	const params = useParams();
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const typesList = useSelector(getTypesSelector);
	const navigate = useNavigate();

	const addCountry = (value: string) => {
		setCountry(value.toUpperCase());
	};

	const getTypes = (value: string) => {
		// @ts-ignore
		setDataFiltter?.((prev: IDataFiltter) => {
			return {
				...prev,
				type_id: typesList?.find((item) => value.replaceAll('_', ' ') === item.Title.toLowerCase())
					?.Id,
			};
		});
	};

	const onSearch = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		setSearch(value);
		navigate({
			search: '1',
		});
		// @ts-ignore
		setDataFiltter?.((prev: IDataFiltter) => {
			return {
				...prev,
				search: value,
			};
		});
	};

	const addDate = (date: string | Date, index: number) => {
		// @ts-ignore
		setDate((prev) => {
			return {
				...prev,
				[index === 0 ? 'date_from' : 'date_to']: moment(date).format('YYYY-MM-DD'),
			};
		});
	};

	const handleCheckbox = (event: ChangeEvent) => {
		const { name, id } = event.target as HTMLInputElement;
		if (id === statusName?.toLowerCase().replace(' ', '_')) {
			setCategory((prev) => {
				return prev.find((el) => el === name)
					? [...prev.filter((el) => el !== name)]
					: [...prev, name];
			});
		}
		if (id === typesName?.toLowerCase().replace(' ', '_')) {
			setTypeAll((prev) => {
				return prev.find((el) => el === name)
					? [...prev.filter((el) => el !== name)]
					: [...prev, name];
			});
		}

		if (id === otherName?.toLowerCase().replace(' ', '_')) {
			setСurrencies((prev) => {
				return prev.find((el) => el === name)
					? [...prev.filter((el) => el !== name)]
					: [...prev, name];
			});
		}
	};

	const onSave = () => {
		// @ts-ignore
		setDataFiltter?.((prev: IDataFiltter) => {
			return {
				...prev,
				...date,
				search,
				country_code: country,
				[keyStatus]: category?.join(',').toLowerCase().replaceAll(' ', '_'),
				[keyType]: typeAll?.join(',').toLowerCase().replaceAll(' ', '_'),
				currencies: currencies?.join(',').toLowerCase(),
			};
		});

		navigate({
			search: '1',
		});

		setOpen(false);
	};

	useEffect(() => {
		dispatch(getTypesRequest());
	}, []);

	return (
		<div className="filter-wrapper filter-custom">
			<div className="filter-custom__inputs-block filter-custom__inputs-block--grid">
				<div className="wraper-report">
					<div onClick={() => setOpen(!open)} className="filter-custom__btn pointer">
						<div className="filter-custom__btn-name">
							<FilterSvg />
							<span>Filters</span>
						</div>
						<ArrowDownSvg />
					</div>
					{pathname.includes('reports') && (
						<SelectList
							onImport={onImport}
							data={reportList}
							maxWidth={'max-width'}
							defaultSelect="Export"
						/>
					)}
				</div>
				<div className="active-btn">
					<div className="input input-custom input-search select-custom-width">
						<div className="input-wrapper">
							<DebounceInput
								className="input-item input-item--left-icon"
								placeholder="Search"
								debounceTimeout={500}
								value={search || ''}
								onChange={onSearch}
							/>
							<span className="input-icon">
								<SearchSvg />
							</span>
						</div>
					</div>
					{pathname.includes('monetization') && (
						<Button className="button-wrapper" type={'button'} name={'Add background'}>
							<PlusSvg />
						</Button>
					)}
				</div>
			</div>

			<div className={classNames('filter-custom__inputs-inner-custom', { 'open-custom': open })}>
				<div className="filter-custom__flex">
					<div className="filter-custom__calendar">
						{inputData.map((input, index) => (
							<div key={input?.name} className="input input-custom">
								<div className="input-wrapper">
									<Calendar addDate={addDate} index={index} />
									<div className="input-icon input-icon--bigger input-icon--right">
										<input.svg />
									</div>
								</div>
							</div>
						))}
					</div>
					{params.tab === ESubTabsFinance.STATISTICS ? null : reportsListParams.includes(
							params.tab || '',
					  ) || 'report' === pathname.slice(1, pathname.length - 1) ? (
						<div className="filter-custom__country-wrapper">
							<SelectList
								onSelect={getTypes}
								data={typesList?.map((item) => item.Title) || []}
								defaultSelect="Type"
							/>
						</div>
					) : (
						<div className="filter-custom__country-wrapper">
							{'user-transaction-history' !== params.tab && (
								<CountrySelect addCountry={addCountry} />
							)}
						</div>
					)}
				</div>
				{/* , [grid || '']: grid */}
				<div className={classNames('filter-custom__info-block', { none: !open })}>
					<div>
						<div className="filter-custom__header">
							<div className="filter-custom__title-table">{typesName}</div>
						</div>
						<div>
							<div className="filter-custom__check-grid">
								{type?.map((el) => (
									<div key={el} className="checkbox checkbox--mb-0 ">
										<label className="checkbox__label">
											<input
												type="checkbox"
												className="hidden"
												name={el}
												id={typesName?.toLowerCase().replace(' ', '_')}
												onChange={handleCheckbox}
												// checked={!!chart[onReplace(chartName)]}
											/>
											<span className="checkbox__item">
												<span className="checkbox__item-icon">
													<CheckSvg />
												</span>
											</span>
											<span className="checkbox__text">{el}</span>
										</label>
									</div>
								))}
							</div>
						</div>
					</div>

					{/* {other ? (
						<div>
							<div className="filter-custom__header">
								<div className="filter-custom__title-table">{otherName}</div>
							</div>
							<div>
								<div className="filter-custom__check-grid">
									{other?.map((el) => (
										<div key={el} className="checkbox checkbox--mb-0 ">
											<label className="checkbox__label">
												<input
													type="checkbox"
													className="hidden"
													name={el}
													id="currencies"
													onChange={handleCheckbox}
													// checked={!!chart[onReplace(chartName)]}
												/>
												<span className="checkbox__item">
													<span className="checkbox__item-icon">
														<CheckSvg />
													</span>
												</span>
												<span className="checkbox__text">{el}</span>
											</label>
										</div>
									))}
								</div>
							</div>
						</div>
					) : null} */}

					<div>
						<div className="filter-custom__header">
							<div className="filter-custom__title-table">{statusName}</div>
						</div>
						<div>
							<div className="filter-custom__check-grid">
								{status?.map((el) => (
									<div key={el} className="checkbox checkbox--mb-0 ">
										<label className="checkbox__label">
											<input
												type="checkbox"
												className="hidden"
												name={el}
												id={statusName?.toLowerCase().replace(' ', '_')}
												onChange={handleCheckbox}
												// checked={!!chart[onReplace(chartName)]}
											/>
											<span className="checkbox__item">
												<span className="checkbox__item-icon">
													<CheckSvg />
												</span>
											</span>
											<span className="checkbox__text">{el}</span>
										</label>
									</div>
								))}
							</div>
						</div>
					</div>

					<button onClick={onSave} type="button" className="filter-custom__btn-save">
						Save
					</button>
				</div>
			</div>
		</div>
	);
};
