import { ArrowBackSvg, DeleteSvg, ErrorSvg } from 'assets/svg-icon';
import { FC, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EChannelsTabs } from 'services/constants/lists-table';
import { NameExempleDetails } from './NameExempleDetails/NameExempleDetails';

export const NameExemple: FC = () => {
	const [toggle, setToggle] = useState(false);
	const params = useParams();

	const handleTofuChange = () => {
		setToggle(!toggle);
	};

	return (
		<div className="main-content">
			<div className="title-block-wrap">
				<div className="title-block">
					<Link
						className="back-button"
						to={`${ELinksName.CHANNEL_DETAIL_USER}/${params.id}/${EChannelsTabs.MEDIA_SALES}`}
					>
						<ArrowBackSvg />
					</Link>
					<p className="title">Name example</p>
				</div>
			</div>
			<div className="table-block table-block-wrapper">
				<div className="user-data">
					<div className="">
						<div className="user-data__block right width left-custom">
							<div className="user-data__toggle-wrapper right pointer">
								<ErrorSvg />
								<span className="user-data__toggle-text user-data__toggle-text-custom orange">
									Warn about violation
								</span>
							</div>
							<div className="user-data__toggle-wrapper right pointer">
								<DeleteSvg />
								<span className="user-data__toggle-text user-data__toggle-text-custom red show">
									Delete all media
								</span>
							</div>
						</div>
					</div>
					<div className="user-data__body">
						<NameExempleDetails />
					</div>
				</div>
			</div>
		</div>
	);
};
