import { PayloadAction } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import {
	blockUserRequest,
	deleteUserRequest,
	getChannelsRequest,
	getChannelsSuccess,
	getGroupsRequest,
	getGroupsSuccess,
	getPhotosRequest,
	getPhotosSuccess,
	getTransactionRequest,
	getTransactionSuccess,
	getUserRequest,
	getUserSuccess,
	getUsersListRequest,
	getUsersListSuccess,
	putUpgradeRequest,
	putUpgradeSuccess,
	setLimitRequest,
	setLimitSuccess,
	usersInitState,
} from './reducer';
import {
	IBlockUserRequest,
	IBlockUserSagaRequest,
	IChannelsResponse,
	IDeleteRequest,
	IDeleteSagaRequest,
	IGroupsResponse,
	ILimitRequest,
	IPhotosResponse,
	ITransactionResponse,
	IUserRequest,
	IUserResponse,
	IUsersRequest,
	IUsersResponse,
	IUsersVerifyRequest,
} from './types';
import { notificationContainer } from 'untils/notificationContainer';
import { ESubtabsUsers, ETabsUsers } from 'services/constants/lists-table';
import { ELinksName } from 'services/constants/list-links-sidebar';

function* getUsersListWorker(action: PayloadAction<IUsersRequest>) {
	const { payload } = action;

	try {
		const response: IUsersResponse = yield call(api.users.usersList, payload);
		yield put(getUsersListSuccess(response));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(usersInitState());
	}
}

function* upgradeUsersWorker(action: PayloadAction<IUsersVerifyRequest>) {
	const { payload } = action;

	try {
		yield call(api.users.usersUpgrade, payload);
		yield put(putUpgradeSuccess(payload));
	} catch (error) {
		if (isAxiosError(error)) responseErrors(error);
	} finally {
		yield put(usersInitState());
	}
}

function* getUserWorker(action: PayloadAction<IUserRequest>) {
	const { payload } = action;

	try {
		const response: IUserResponse = yield call(api.users.getUser, payload);

		yield put(getUserSuccess(response));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(usersInitState());
	}
}

function* blockUserWorker(action: PayloadAction<IBlockUserSagaRequest>) {
	const { paramsApi, navigate } = action.payload;

	try {
		yield call(api.users.blockUser, paramsApi);
		// yield notificationContainer('User deleted successfully', 'success');
		yield navigate(
			`${ELinksName.USERS}/${paramsApi.block ? ETabsUsers.BLOCKED_USERS : ETabsUsers.REGISTRED}/${
				ESubtabsUsers.PERSONAL_PROFILE
			}`,
		);
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(usersInitState());
	}
}

function* deleteUserWorker(action: PayloadAction<IDeleteSagaRequest>) {
	const { paramsApi, navigate } = action.payload;

	try {
		yield call(api.users.deleteUser, paramsApi);
		yield notificationContainer('User deleted successfully', 'success');
		yield navigate(`${ELinksName.USERS}/${ETabsUsers.REGISTRED}/${ESubtabsUsers.PERSONAL_PROFILE}`);
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(usersInitState());
	}
}

function* getPhotosWorker(action: PayloadAction<IUserRequest>) {
	const { payload } = action;

	try {
		const response: IPhotosResponse = yield call(api.users.getPhotos, payload);
		yield put(getPhotosSuccess(response));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getChannelsWorker(action: PayloadAction<IUserRequest>) {
	const { payload } = action;

	try {
		const response: IChannelsResponse = yield call(api.users.getChannels, payload);
		yield put(getChannelsSuccess(response));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getGroupsWorker(action: PayloadAction<IUserRequest>) {
	const { payload } = action;

	try {
		const response: IGroupsResponse = yield call(api.users.getGroups, payload);
		yield put(getGroupsSuccess(response));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getTransactionsWorker(action: PayloadAction<IUserRequest>) {
	const { payload } = action;

	try {
		const response: ITransactionResponse = yield call(api.users.getTransaction, payload);
		yield put(getTransactionSuccess(response));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* setLimitWorker(action: PayloadAction<ILimitRequest>) {
	const { payload } = action;

	try {
		yield call(api.users.setLimit, payload);
		yield put(setLimitSuccess(payload));
		yield notificationContainer('The limit has been changed', 'success');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* usersSaga() {
	yield takeEvery(getUsersListRequest.type, getUsersListWorker);
	yield takeEvery(putUpgradeRequest.type, upgradeUsersWorker);
	yield takeEvery(getUserRequest.type, getUserWorker);
	yield takeEvery(blockUserRequest.type, blockUserWorker);
	yield takeEvery(deleteUserRequest.type, deleteUserWorker);
	yield takeEvery(getPhotosRequest.type, getPhotosWorker);
	yield takeEvery(getChannelsRequest.type, getChannelsWorker);
	yield takeEvery(getGroupsRequest.type, getGroupsWorker);
	yield takeEvery(getTransactionRequest.type, getTransactionsWorker);
	yield takeEvery(setLimitRequest.type, setLimitWorker);
}
