import { ForgotPassForm } from 'components/Forms/ForgotPassForm/ForgotPassForm';
import { FC, useState } from 'react';
import { IRestFormSubmitValue } from './types';
import { useDispatch } from 'react-redux';
import { forgotRequest } from 'redux/reducers/auth/reducer';

export const ForgotPass: FC = () => {
	const dispatch = useDispatch();
	const [successfully, setSuccessfully] = useState(false);

	const handlePassSubmit = (value: IRestFormSubmitValue) => {
		dispatch(forgotRequest({ paramsApi: value, setSuccessfully: setSuccessfully }));
	};

	return (
		<div className="wrapper">
			<main className="main">
				<section className="authorization-section">
					<ForgotPassForm forgotPassSubmit={handlePassSubmit} successfully={successfully} />
				</section>
			</main>
		</div>
	);
};
