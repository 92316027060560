import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ESubTabsFinance, ETabsFinanceWithdrawal } from 'services/constants/lists-table';

export const SubTabs: FC = () => {
	const params = useParams();

	return (
		<div className="table-tabs table-tabs--type2 table-tabs-custom tab-up">
			<Link
				to={`${ELinksName.FINANCE}/${params.tab}/${ETabsFinanceWithdrawal.FIAT}`}
				className={classNames('table-tabs__item', {
					active: params.subTab === ETabsFinanceWithdrawal.FIAT || !params.subTab,
				})}
			>
				Fiat
			</Link>

			{/* <Link
				to={`${ELinksName.FINANCE}/${params.tab}/${ETabsFinanceWithdrawal.CRYPTO}`}
				className={classNames('table-tabs__item', {
					active: params.subTab === ETabsFinanceWithdrawal.CRYPTO,
				})}
			>
				Ctypto
			</Link> */}
		</div>
	);
};
