import { IUsersRequest } from 'redux/reducers/users/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { ITransactionApi } from './types';

export const transaction: ITransactionApi = {
	getWithdrawal: (params: IUsersRequest) =>
		http.get(endpoint.transactions.WITHDRAWAL, { params }).then((response) => response.data),
	getAdminTreasury: (params: IUsersRequest) =>
		http.get(endpoint.transactions.ADMIN_TREASURY, { params }).then((response) => response.data),
	transactionsApprove: (payload) =>
		http
			.put(endpoint.transactions.TRASACTION_APPROVE(String(payload.transaction_id)))
			.then((response) => response.data),
	transactionsReject: (payload) =>
		http
			.put(endpoint.transactions.TRASACTION_REJECT(String(payload.transaction_id)))
			.then((response) => response.data),
};
