import { FC, useEffect } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { SvgEmail } from 'ui/svg-image/SvgLogin';
import ErrorMsg from 'components/ErrorMsg';
import { useSelector } from 'react-redux';
import { getLoader } from 'redux/reducers/auth/selectors';
import { SmallLoader } from 'ui/SmallLoader';
import { SelectList } from 'ui/SelectList/SelectList';
import { roleList } from 'CONST/selectList';
import { IAddNewUser, IAddNewUserSubmit } from './type';
import { email, fullName, role } from 'untils/formValidation';

interface IProps {
	onAddAdmin: () => void;
	addAdminSubmit: (value: IAddNewUserSubmit) => void;
}

export const AddNewAdmins: FC<IProps> = ({ onAddAdmin, addAdminSubmit }) => {
	const loader = useSelector(getLoader);

	const initialValues = {
		email: '',
		fullName: '',
		role: 'Admin',
		send_link: true,
	};

	const validationSchema = yup.object().shape({
		email,
		fullName,
		role,
	});

	useEffect(() => {
		document.body.style.overflow = 'hidden';
		return () => {
			document.body.style.overflow = '';
		};
	}, []);

	return (
		<div className="authorization-form__body">
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values: IAddNewUser, { setSubmitting }) => {
					const data = {
						email: values.email,
						role: values.role,
						send_link: values.send_link,
						first_name: values.fullName.split(' ')[0],
						last_name: values.fullName.split(' ')[1],
					};
					setSubmitting(false);
					addAdminSubmit(data);
				}}
				validateOnBlur
				enableReinitialize
			>
				{({ isSubmitting, isValid, values, errors, setFieldValue }) => {
					const onAddRole = (value: string) => {
						setFieldValue('role', value);
					};

					return (
						<Form>
							<div className="authorization-form">
								<p className="form-title">Add new admin </p>
								<div className="authorization-form__body">
									<div className="input">
										<p className="input__name">Email</p>
										<div className="input-wrapper">
											<Field
												className="input-item input-item--left-icon"
												type="email"
												name="email"
												placeholder="Email"
											/>
											<span className="input-icon">
												<SvgEmail />
											</span>
										</div>
										{errors?.email ? <ErrorMsg msg={errors.email} /> : null}
									</div>
									<div className="input">
										<p className="input__name">Full name</p>
										<div className="input-wrapper">
											<Field
												className="input-item"
												type="text"
												name="fullName"
												placeholder="Full name"
											/>
										</div>
										{errors?.fullName ? <ErrorMsg msg={errors.fullName} /> : null}
									</div>
									<div className="input">
										<p className="input__name">Role</p>
										<SelectList
											onSelect={onAddRole}
											data={roleList}
											maxWidth={'max-width100'}
											placeholder="Role"
										/>
										{errors?.role ? <ErrorMsg msg={errors.role} /> : null}
									</div>
									<div className="wrapper-checkbox">
										<div className="checkbox-label">
											<input className="check__input" id="agreement" type="checkbox" />
											<label className="check__label flex" htmlFor="agreement">
												<span>Automatically send an account setup link to your email</span>
											</label>
										</div>
									</div>
									<div className="form-submit">
										<button
											// disabled={!(isValid && values.email && values.password) || isSubmitting}
											type="submit"
											className="button button--full-width"
										>
											{loader ? <SmallLoader /> : 'Add admin'}
										</button>
									</div>
									<div className="form-submit">
										<button
											onClick={onAddAdmin}
											type="button"
											className="button button--gray button--full-width"
										>
											{loader ? <SmallLoader /> : 'Cancel'}
										</button>
									</div>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};
