import HCaptcha from '@hcaptcha/react-hcaptcha';
import classNames from 'classnames';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { IProps } from './tepes';

export const Captcha: FC<IProps> = ({ onLoad, setToken, captchaRef }) => {
	const { pathname } = useLocation();
	return (
		<div
			className={classNames('captcha-center', { 'captcha-right': pathname.includes('contacts') })}
		>
			<HCaptcha
				sitekey="98722982-bc89-4408-8e36-e942f451adb1"
				onLoad={onLoad}
				onVerify={(token) => setToken(token)}
				onExpire={() => setToken('')}
				ref={captchaRef}
			/>
		</div>
	);
};
