import download from '../../../assets/images/Download.png';
import downloadPhone from '../../../assets/images/mobile-dow.png';
// import downloadTablet from '../../../assets/images/tablet-dow.png';

export const appearanceData: Array<IAppearanceData> = [
	{ id: 1, name: 'logo', title: 'Logo', subtitle: '', avatar: download },
	{
		id: 2,
		name: 'phone',
		title: 'Logo History',
		subtitle: '',
		avatar: downloadPhone,
	},
	{
		id: 3,
		name: 'phone',
		title: 'Upload background',
		subtitle:
			'Example Description. To upload an image as a chat background in the admin panel, you need to click on the corresponding button and select the desired file on your computer. ',
		avatar: downloadPhone,
	},
	// {
	// 	id: 3,
	// 	name: 'tablet',
	// 	title: 'Upload background for Tablet devices',
	// 	subtitle:
	// 		'Example Description. To upload an image as a chat background in the admin panel, you need to click on the corresponding button and select the desired file on your computer. ',
	// 	avatar: downloadTablet,
	// },
];

export interface IAppearanceData {
	id: number;
	name: string;
	title: string;
	subtitle: string;
	avatar: string;
}
