import Channels from 'pages/Dashboard/Channels';
import ChannelsDetailsUser from 'pages/Dashboard/ChannelsDetailsUser';
import Reports from 'pages/Dashboard/Reports';
import Transaction from 'pages/Dashboard/Transaction';
import UserDetails from 'pages/Dashboard/UserDetails';
import Users from 'pages/Dashboard/Users';
import { Navigate } from 'react-router-dom';
import { ELinksName, EParams } from 'services/constants/list-links-sidebar';

import AuthLayout from '../layouts/AuthLayout';
import ErrorRoutes from './ErrorRoutes';
import { Dashboard } from 'pages/Dashboard/Dashboard';
import { Groups } from 'pages/Dashboard/Groups';
import { Admins } from 'pages/Dashboard/Admins/Admins';
import { Finance } from 'pages/Dashboard/Finance/Finance';
import { Monetization } from 'pages/Dashboard/Monetization/Monetization';
import { NameExemple } from 'pages/Dashboard/NameExemple/NameExemple';
import { PostDetails } from 'pages/Dashboard/PostDetails/PostDetails';
import { MediaAndFilesDetails } from 'pages/Dashboard/MediaAndFilesDetails/MediaAndFilesDetails';
import { GroupsDetails } from 'pages/Dashboard/GroupsDetails/GroupsDetails';
import { AiServices } from 'pages/Dashboard/AiServices/AiServices';
import { MonetizationDetailsPage } from 'pages/Dashboard/MonetizationDetails/MonetizationDetails';
import { ResetPassword } from 'pages/Auth/ResetPassword/ResetPassword';
import { AdminDetails } from 'pages/Dashboard/AdminDetails/AdminDetails';
import Suport from 'pages/Dashboard/Suport/Suport';
import SuportLayout from 'layouts/SuportLayout/SuportLayout';

const SuportRoutes = {
	path: '/',
	element: <SuportLayout />,
	children: [
		{
			element: <Suport />,
			path: ELinksName.SUPORT + EParams.TAB,
		},
		{
			element: <Navigate to={ELinksName.SUPORT + EParams.TAB} replace />,
			path: '/',
		},
		ErrorRoutes,
	],
};

export default SuportRoutes;
