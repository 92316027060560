export const fileTypes = [
	'JPEG',
	'PNG',
	'GIF',
	'SVG',
	'JPG',
	'MP4',
	'DOC',
	'DOCX',
	'PDF',
	'XLSX',
	'CSV',
	'MP3',
	'WAV',
	'FLAC',
	'OGG',
	'AAC',
	'MPG',
	'MOV',
	'AVI',
	'WMV',
	'ZIP',
];
