import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IReactions,
	IReactionResponse,
	IReactionsState,
	IReactionRequest,
	IEditRequest,
	IAddRequest,
	IDeleteReaction,
	IActiveReaction,
} from './types';

export const initialState: IReactionsState = {
	reactions: null,
	reaction: null,
	reactionLoader: false,
};

const reactions = createSlice({
	name: '@@reactions',
	initialState,
	reducers: {
		getReactionsRequest: (state, action: PayloadAction<IReactionRequest>) => {
			const getReferralState = state;
			getReferralState.reactionLoader = true;
		},

		getReactionsSuccess: (state, action: PayloadAction<Array<IReactions>>) => {
			const getReactionsState = state;
			const { payload } = action;
			getReactionsState.reactionLoader = false;
			getReactionsState.reactions = payload;
		},

		getReactionRequest: (state, action: PayloadAction<string>) => {
			const getReferralState = state;
			getReferralState.reactionLoader = true;
		},

		getReactionSuccess: (state, action: PayloadAction<IReactionResponse>) => {
			const getReactionsState = state;
			const { payload } = action;
			getReactionsState.reactionLoader = false;
			getReactionsState.reaction = payload;
		},

		addReactionRequest: (state, action: PayloadAction<any>) => {
			const getReferralState = state;
			getReferralState.reactionLoader = true;
		},

		addReactionSuccess: (state, action: PayloadAction<IReactionResponse>) => {
			const getReactionsState = state;
			const { payload } = action;
			getReactionsState.reactionLoader = false;
			// getReactionsState.reaction = payload;
		},

		editReactionRequest: (state, action: PayloadAction<any>) => {
			const getReferralState = state;
			getReferralState.reactionLoader = true;
		},

		editReactionSuccess: (state, action: PayloadAction<IReactionResponse>) => {
			const getReactionsState = state;
			const { payload } = action;
			getReactionsState.reactionLoader = false;
			// getReactionsState.reaction = payload;
		},

		activeReactionRequest: (state, action: PayloadAction<IActiveReaction>) => {
			const getReactionsState = state;
			getReactionsState.reactionLoader = false;
		},

		activeReactionSuccess: (state, action: PayloadAction<IActiveReaction>) => {
			const getReactionsState = state;
			const { payload } = action;
			getReactionsState.reactionLoader = false;
			// getReactionsState.reaction = payload;
		},

		deleteReactionRequest: (state, action: PayloadAction<IDeleteReaction>) => {
			const getReferralState = state;
			getReferralState.reactionLoader = true;
		},

		deleteReactionSuccess: (state, action: PayloadAction<string>) => {
			const getReactionsState = state;
			const { payload } = action;
			getReactionsState.reactionLoader = false;
			// getReactionsState.reaction = payload;
		},

		reactionsInitState: (state) => {
			const getReactionsState = state;
			getReactionsState.reactionLoader = false;
		},
	},
});

export default reactions.reducer;
export const {
	getReactionsRequest,
	getReactionsSuccess,
	getReactionRequest,
	getReactionSuccess,
	addReactionRequest,
	addReactionSuccess,
	editReactionRequest,
	editReactionSuccess,
	activeReactionRequest,
	activeReactionSuccess,
	deleteReactionRequest,
	deleteReactionSuccess,
	reactionsInitState,
} = reactions.actions;
