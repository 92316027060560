import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { EMediaSubtabs } from 'services/constants/lists-table';
import { SvgArrowDownHeader, SvgArrowUpHeader } from 'ui/svg-image/SvgAllChannels';

export const MediaHeader: FC = () => {
	const params = useParams();

	const onShowImg = () => {
		switch (params.subTab) {
			case EMediaSubtabs.AUDIO:
				return 'Audio';

			case EMediaSubtabs.FILES:
				return 'Files';

			case EMediaSubtabs.LINKS:
				return 'Links';

			default:
				return 'Media';
		}
	};
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>{onShowImg()}</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Author</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Published</p>
						<div className="sort-td">
							<button type="button">
								<SvgArrowUpHeader />
							</button>
							<button type="button">
								<SvgArrowDownHeader />
							</button>
						</div>
					</div>
				</div>
				{EMediaSubtabs.LINKS === params.subTab && (
					<div className="td td--right">
						<div className="td-name">
							<p>Action</p>
							<div className="sort-td">
								<button type="button">
									<SvgArrowUpHeader />
								</button>
								<button type="button">
									<SvgArrowDownHeader />
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
