import { ArrowDownSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { useSelect } from 'hooks/useSelect';
import { FC, MouseEvent, useState } from 'react';
import { useParams } from 'react-router-dom';

interface IProps {
	data: { verified: string; not_verified: string; svgFirst?: any; svgSecond?: any };
	upgradeChannel?: (user_id: number, verify: boolean) => void;
	defaultType?: boolean;
}

export const Select: FC<IProps> = ({ data, upgradeChannel, defaultType }) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();
	const [text, setText] = useState<string>(defaultType ? data.verified : data.not_verified);
	const params = useParams();

	const handleSelect = (event: MouseEvent) => {
		const { name } = event.target as HTMLInputElement;
		upgradeChannel?.(Number(params.id), name === data.verified ? true : false);
		setText(name);
		setOpen(false);
	};

	return (
		<div className="select-custom max-width max-width-persent">
			<div className={classNames('select', { active: open })}>
				<div className="input input-custom">
					<div ref={triggerRef} onClick={toggleOpen} className="input-wrapper">
						<div className="input-icon input-icon--bigger input-icon--left">
							{data.svgFirst && (text === data.verified ? <data.svgFirst /> : <data.svgSecond />)}
						</div>
						<input
							readOnly
							className="input-item input-item--left-icon"
							type="text"
							name="country"
							value={text}
							placeholder="Country"
						/>
						<div className="input-icon input-icon--bigger input-icon--right">
							<ArrowDownSvg />
						</div>
					</div>
				</div>
				<div style={{ padding: '0' }} className="select__drop select__drop-position" ref={dropRef}>
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								<li className="countries-list">
									<button name={data.verified} onClick={handleSelect} type="button">
										{data.svgFirst && <data.svgFirst />}

										{data.verified}
									</button>
								</li>
								<li className="countries-list">
									<button name={data.not_verified} onClick={handleSelect} type="button">
										{data.svgSecond && <data.svgSecond />}

										{data.not_verified}
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
