import classNames from 'classnames';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import {
	EGroupsTabs,
	EGroupsSubtabsUsers,
	ESubtabsUsers,
	ESubTabsPartners,
	ETabsPartners,
} from 'services/constants/lists-table';

export const ElloPartnersSubTabs: FC = () => {
	const params = useParams();

	const getLink = (num: number) => {
		switch (params.tab) {
			case 'members':
				return `${ELinksName.ELLO_PARTNERS}/${params.tab}/${EGroupsTabs.MEMBERS}/${
					num === 1 ? ESubTabsPartners.CURRENT_USERS : ESubTabsPartners.BANNED_USERS
				}`;

			default:
				return `${ELinksName.ELLO_PARTNERS}/${params.tab}/${
					num === 1 ? ESubTabsPartners.CURRENT_USERS : ESubTabsPartners.BANNED_USERS
				}`;
		}
	};

	const onActive = (num: number) => {
		switch (params.tab) {
			case 'members':
				return num === 1
					? params.subTab === ESubTabsPartners.CURRENT_USERS || !params.subTab
					: params.subTab === ESubTabsPartners.BANNED_USERS;

			default:
				return num === 1
					? params.subTab === ESubTabsPartners.CURRENT_USERS || !params.subTab
					: params.subTab === ESubTabsPartners.BANNED_USERS;
		}
	};

	return (
		<div className="table-tabs table-tabs--type2 table-tabs-custom">
			<Link
				to={`${getLink(1)}`}
				className={classNames('table-tabs__item', {
					active: onActive(1),
				})}
			>
				Current users
			</Link>
			<Link
				to={`${getLink(2)}`}
				className={classNames('table-tabs__item', {
					active: onActive(2),
				})}
			>
				Banned users
			</Link>
		</div>
	);
};
