import { NotFound404 } from 'components/NotFound404/NotFound404';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getUserData } from 'redux/reducers/auth/selectors';

export const ProtectedRoute: FC<{ roles: Array<string>; children: JSX.Element }> = ({
	roles,
	children,
}) => {
	const user = useSelector(getUserData);

	if (!roles.includes(user?.role?.toLowerCase() as string)) {
		return <NotFound404 />;
	}

	return children;
};
