import { ChangeEvent, FC, KeyboardEvent, useState } from 'react';
import './Limits.scss';
import { useDispatch } from 'react-redux';
import { setLimitRequest } from 'redux/reducers/users/reducer';
import { notificationContainer } from 'untils/notificationContainer';

interface IProps {
	created: number | undefined;
	subscribed: number | undefined;
	user_id: number;
}

export const Limits: FC<IProps> = ({ created, subscribed, user_id }) => {
	const [maxCreated, setMaxCreated] = useState<string>(String(created));
	const [maxSubscribed, setMaxSubscribed] = useState<string>(String(subscribed));
	const [nameInput, setNameInput] = useState<string>('');
	const dispatch = useDispatch();

	const onHandleInput = (event: ChangeEvent) => {
		const { name, value } = event.target as HTMLInputElement;

		setNameInput(name);
		if (+value[0] !== 0) {
			name === 'created'
				? setMaxCreated(value.replaceAll('.', ''))
				: setMaxSubscribed(value.replaceAll('.', ''));
		}
	};

	const keyDownFunction = (event: KeyboardEvent<HTMLInputElement>) => {
		const { code } = event;

		if (Number(maxCreated) >= Number(maxSubscribed) && code === 'Enter') {
			notificationContainer('The upper value cannot be greater than the lower value', 'info');
			return;
		}

		if (code === 'Enter') {
			maxCreated !== String(created) || maxSubscribed !== String(subscribed)
				? dispatch(
						setLimitRequest({
							limit_channel_public_creator: Number(maxCreated),
							limit_channel_public_subscriber: Number(maxSubscribed),
							user_id,
						}),
				  )
				: notificationContainer(
						'You have not entered a new limit! Enter a new limit and try again',
						'info',
				  );
		}
	};

	const onBlurFunction = () => {
		maxCreated !== String(created) && setMaxCreated(String(created));
		maxSubscribed !== String(subscribed) && setMaxSubscribed(String(subscribed));
	};

	return (
		<div className="limits">
			<div className="limits__block">
				<div className="limits__name">
					Maximum number of public groups and channels that user created
				</div>
				<input
					title="After entering the new limit press enter"
					onChange={onHandleInput}
					onKeyDown={keyDownFunction}
					onBlur={onBlurFunction}
					value={maxCreated.replace('.', '')}
					name="created"
					placeholder="Number"
					type="number"
					className="limits__input"
				/>
			</div>
			<div className="limits__block">
				<div className="limits__name">
					Maximum number of public groups and channels that user subscribed
				</div>
				<input
					title="After entering the new limit press enter"
					onChange={onHandleInput}
					onKeyDown={keyDownFunction}
					onBlur={onBlurFunction}
					value={maxSubscribed.replace('.', '')}
					name="subscribed"
					placeholder="Number"
					type="number"
					className="limits__input"
				/>
			</div>
		</div>
	);
};
