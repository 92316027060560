import { ArrowDownSvg, LogoutSvg, UserSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { useSelect } from 'hooks/useSelect';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { logoutRequest } from 'redux/reducers/auth/reducer';

export const MenuPopup: FC = () => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const logOut = () => {
		dispatch(logoutRequest({ navigate }));
	};

	return (
		<>
			<button ref={triggerRef} onClick={toggleOpen} type="button" className="admin__logout">
				<ArrowDownSvg />
			</button>
			<div className="">
				<div className={classNames('select', { active: open })}>
					<div className="select__drop select__position select__drop-custom" ref={dropRef}>
						<div className="select__drop-scroll">
							<div className="select__drop-item">
								<ul>
									<li className="countries-list">
										<UserSvg />
										<button type="button">Admin Profile</button>
									</li>
									<li onClick={logOut} className="countries-list">
										<LogoutSvg />
										<button type="button">Log Out</button>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
