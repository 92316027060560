import { CalendarSvg } from 'assets/svg-icon';

export const inputData = [
	{
		name: 'dataFrom',
		svg: CalendarSvg,
		placeholder: 'Data From',
	},
	{
		name: 'dataTo',
		svg: CalendarSvg,
		placeholder: 'Data To',
	},
];
