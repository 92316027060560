import { isAxiosError } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
// import { notificationContainer } from 'untils/notificationContainer';
import {
	getCountriesRequest,
	getCountriesSuccess,
	getPhotoRequest,
	getPhotoSuccess,
} from './reducer';
import { ICountries } from './types';
import { PayloadAction } from '@reduxjs/toolkit';

function* getCountriesWorker() {
	try {
		const response: { data: ICountries } = yield call(api.utils.countries);
		yield put(getCountriesSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getPhotoWorker({ payload }: PayloadAction<string>) {
	try {
		const response: Blob = yield call(api.utils.photo, payload);

		const objectUrl = window.URL.createObjectURL(response);

		// yield put(getPhotoSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* utilsSaga() {
	yield takeEvery(getCountriesRequest.type, getCountriesWorker);
	yield takeEvery(getPhotoRequest.type, getPhotoWorker);
}
