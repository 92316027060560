import { DeleteSvg } from 'assets/svg-icon';
import { FC, ChangeEvent } from 'react';
import { Button } from 'ui/Button/Button';
import { Input } from 'ui/Input';
import { onLabel, onPlaceholder } from 'untils/popUpWrapperFunc';

interface IProps {
	handleInput: (event: ChangeEvent) => void;
	name: string;
	namePopup: string;
	exception: Array<string>;
	removeException: (value: string) => void;
	addException: () => void;
}

export const Exception: FC<IProps> = ({
	removeException,
	exception,
	handleInput,
	name,
	namePopup,
	addException,
}) => {
	return (
		<>
			<div className="input-btn-wrap">
				<Input
					handleInput={handleInput}
					value={name}
					type="text"
					name="word"
					placeholder={onPlaceholder(namePopup)}
					label={onLabel(namePopup)}
					className="padding-bottom"
				/>
				<Button
					onClick={addException}
					className="button-border width-content"
					type="button"
					name="Add"
				/>
			</div>
			<div className="exception-title">Exception for use</div>
			{exception.map((item, index) => (
				<div className="list-exception" key={index}>
					<div className="exception">{item}</div>
					<button onClick={() => removeException(item)} type="button">
						<DeleteSvg />
					</button>
				</div>
			))}
		</>
	);
};
