import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getIsAuth } from 'redux/reducers/auth/selectors';
import { ELinksName } from 'services/constants/list-links-sidebar';

const PublicLayout: FC = () => {
	const isAuth = useSelector(getIsAuth);
	const location = useLocation();
	const { pathname } = useLocation();

	if (pathname === '/')
		return <Navigate to={ELinksName.DASHBOARD} state={{ from: location }} replace />;

	if (isAuth) return <Navigate to={ELinksName.DASHBOARD} state={{ from: location }} replace />;

	return (
		<div className="wrapper">
			<main className="main">
				<section className="main-section">
					<Outlet />
				</section>
			</main>
		</div>
	);
};

export default PublicLayout;
