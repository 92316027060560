import { ArrowBackSvg } from 'assets/svg-icon';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
	changeAdminRequest,
	getAdminRequest,
	getAdminSuccess,
} from 'redux/reducers/admins/reducer';
import { getAdminSelector } from 'redux/reducers/admins/selectors';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { AdminInfo } from './AdminInfo/AdminInfo';
import { Button } from 'ui/Button/Button';
import { IChangeAdmin } from 'ui/AddNewAdmins/type';
import { checkDifferent } from 'untils/checkdifferent';
import { IAdminsResponse } from 'redux/reducers/admins/types';

export const AdminDetails: FC = () => {
	const admin = useSelector(getAdminSelector);
	const [changeAdmin, setChangeAdmin] = useState<IChangeAdmin | {}>({});
	const location = useLocation();

	const params = useParams();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAdminRequest({ email: params.id || '' }));

		return () => {
			dispatch(getAdminSuccess(null));
		};
	}, []);

	const onSubmitChange = () => {
		dispatch(changeAdminRequest(changeAdmin as IChangeAdmin));
	};

	return (
		<div className="main-content">
			<div className="url color-grey big-first-letter">
				{location.pathname
					.slice(1)
					.replaceAll('/', ' / ')
					.replace(params.id || '', `${admin?.first_name || ''} ${admin?.last_name || ''}`)}
			</div>
			<div className="title-block-wrap">
				<div className="title-block-grid">
					<div className="title-block-flex">
						<Link className="back-button" to={ELinksName.ADMINS}>
							<ArrowBackSvg />
						</Link>
						<p className="title big-first-letter">{`${admin?.first_name || ''} ${
							admin?.last_name || ''
						}`}</p>
					</div>

					<div className="header-title-grid">
						{admin?.id ? (
							<Button
								className="button-wrapper"
								disabled={
									admin && changeAdmin
										? !checkDifferent(admin as IAdminsResponse, changeAdmin as IChangeAdmin)
										: true
								}
								onClick={onSubmitChange}
								type={'button'}
								name={'Save'}
							></Button>
						) : null}
					</div>
				</div>
				{admin && (
					<AdminInfo admin={admin} setChangeAdmin={setChangeAdmin} changeAdmin={changeAdmin} />
				)}
			</div>
		</div>
	);
};
