import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IAiServicesStore,
	IGetWordsRequest,
	IWordResponse,
	IWordRquest,
	IWordsResponse,
} from './types';
import { IDeleteName } from '../reserved/types';

export const initialState: IAiServicesStore = {
	listWords: null,
	aiLoader: false,
};

const aiServicese = createSlice({
	name: '@@aiServices',
	initialState,
	reducers: {
		getBadWordsRequest: (state, action: PayloadAction<IGetWordsRequest>) => {
			const servicesState = state;
			servicesState.aiLoader = true;
		},
		getBadWordsSuccess: (state, action: PayloadAction<IWordsResponse>) => {
			const { payload } = action;
			const servicesState = state;
			servicesState.listWords = payload;
		},

		editBadWordRequest: (state, action: PayloadAction<any>) => {
			const servicesState = state;
			servicesState.aiLoader = true;
		},
		editBadWordSuccess: (state, action: PayloadAction<any>) => {
			const { payload } = action;
			const servicesState = state;
		},

		addBadWordRequest: (state, action: PayloadAction<IWordRquest>) => {
			const servicesState = state;
			servicesState.aiLoader = true;
		},
		addBadWordSuccess: (state, action: PayloadAction<IWordResponse>) => {
			const { payload } = action;
			const servicesState = state;
			if (servicesState.listWords?.words) {
				servicesState.listWords.words.unshift(payload);
				servicesState.listWords.total = servicesState.listWords.total + 1;
			} else {
				servicesState.listWords = { words: [payload], total: 1 };
			}
		},

		deleteBadWordRequest: (state, action: PayloadAction<IDeleteName>) => {
			const servicesState = state;
			servicesState.aiLoader = true;
		},
		deleteBadWordSuccess: (state, action: PayloadAction<IWordsResponse>) => {
			const { payload } = action;
			const servicesState = state;
			servicesState.listWords = payload;
		},

		aiServicesInitState: (state) => {
			const servicesInitStatee = state;
			servicesInitStatee.aiLoader = false;
		},
	},
});

export default aiServicese.reducer;
export const {
	getBadWordsRequest,
	getBadWordsSuccess,
	editBadWordRequest,
	editBadWordSuccess,
	addBadWordRequest,
	addBadWordSuccess,
	deleteBadWordRequest,
	deleteBadWordSuccess,
	aiServicesInitState,
} = aiServicese.actions;
