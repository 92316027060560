export enum ETabsChannel {
	ALL_CHANNELS = 'all-channels',
	BLOCKED_CHANNELS = 'blocked-channels',
	RECOMMENDED_CHANNELS = 'recommended-channels',
	DELETE_CHANNELS = 'delete-channels',
}

export enum EAdminsTabs {
	ACTIVE = 'active',
	BLOCKED = 'blocked',
}

export enum ETabsUsers {
	REGISTRED = 'registered',
	BLOCKED_USERS = 'blocked-users',
	DELETE_USERS = 'delete-users',
}

export enum ETabsPartners {
	MANAGEMENT = 'management',
	REQUEST = 'request',
}

export enum ESubTabsPartners {
	CURRENT_USERS = 'current-users',
	BANNED_USERS = 'banned-users',
}

export enum ETabsGroups {
	ALL_GROUPS = 'all-groups',
	BLOCKED_GROUPS = 'blocked-groups',
}

export enum ESubtabsUsers {
	PERSONAL_PROFILE = 'personal-profile',
	BLOCKED_PROFILE = 'business-profile',
}

export enum EGroupsSubtabsUsers {
	PERSONAL_PROFILE = 'personal-profile',
	BLOCKED_PROFILE = 'business-profile',
}

export enum ETabsMessagesChannel {
	LIST_OF_POSTS = 'list-of-posts',
	CONTENT = 'content',
}

export enum ESubtabsMessagesChannel {
	PHOTO = 'image',
	VIDEO = 'video',
	AUDIO = 'audio',
	VOICE = 'voice',
	FILES = 'file',
	LINKS = 'link',
}

export enum ETabsUsersDetails {
	CHANNELS = 'user-channels',
	USER_DATA = 'user-data',
	TRANSACTION_HISTORY = 'user-transaction-history',
	CONVERSATION = 'tab-groups',
	SUBSCRIPTIONS = 'subscriptions',
	FINANCE = 'tab-finance',
	LIMITS = 'tab-limits',
	AI_BOT = 'ai-bot',
	DISCOUNT = 'discount',
}

export enum ESubtabsUsersDetails {
	SUBSCRIPTION = 'subscription',
	CREATED = 'created',
	ADMIN = 'admin',
}

export enum ESubscriptionTabs {
	SUBSCRIPTION_CHANNELS = 'subscription',
	COURSE_CHANNEL = 'course-channel',
	BACKGROUND = 'backgrounds',
	AI = 'ai',
}

export enum ETabsReserved {
	REGISTRATION_REQUESTS = 'registration-requests',
	USERNAMES = 'usernames',
}

export enum ETabsTransaction {
	WITHDRAWALS = 'withdrawals',
	ADMIN_TREASURY = 'admin-treasury',
	TRANSACTION_HISTORY = 'transaction-history',
}

export enum ESubtabsTransaction {
	TRANSACTION = 'withdrawals-transactions',
	CHANNELS = 'withdrawals-channels',
}

export enum ESubtabsConversation {
	CONVERSATIONS_CHANNELS = 'member',
	CONVERSATIONS_GROUP = 'created',
	CONVERSATIONS_CHATS = 'tab-admin',
}

export enum EReportsTabs {
	CHANNELS = 'channel-report',
	GROUPS = 'group-report',
	CHANNEL_POSTS = 'channel-post-report',
	MESSAGES_IN_GROUPS = 'group-post-report',
}

export enum EChannelsTabs {
	CANNELS_DATA = 'channel-data',
	ADMINS = 'tab-admins',
	MEMBERS = 'members',
	MEDIA_SALES = 'media-sales',
	POST = 'posts',
	MEDIA_AND_FILES = 'media-and-files',
	REFUNDS = 'refunds',
}

export enum EGroupsTabs {
	GROUPS_DATA = 'groups-data',
	ADMINS = 'groups-admins',
	MEMBERS = 'members',
	MESSAGES = 'groups-messages',
	MEDIA_AND_FILES = 'groups-media-and-files',
}

export enum EMediaSubtabs {
	MEDIA = 'media',
	FILES = 'files',
	AUDIO = 'audio',
	LINKS = 'links',
}

export enum ESubTabsFinance {
	WITHDRAWALS = 'withdrawal-requests',
	COMMISSIONS = 'commissions',
	PAYMENT = 'payment-methods',
	STATISTICS = 'statistics',
	PLATFORM = 'platform-balances',
	CRYPTO = 'crypto-balances',
}

export enum ETabsFinanceWithdrawal {
	FIAT = 'fiat',
	CRYPTO = 'crypto',
}

export enum ETabsFinanceStatistics {
	SISTEMATIC = 'systemic',
	CUSTOMERS = 'user-transfers-internal',
}

export enum ETabsFinanceBalances {
	BALANCES = 'balances',
	TRANSACTION = 'admin-transactions',
}

export enum EMonetizationTabs {
	BACKGROUND = 'background',
	CASHBACK = 'cashback',
}

export enum EBackgroundTabs {
	SINGE = 'singe',
	PACK = 'pack',
}

export enum EAiServicesTabs {
	AI_MANAGEMENT = 'ai-management',
	OF_AI_INCOME = 'of-ai-income',
	AI_SETTINGS_AND_RESTRICTION = 'ai-settings-and-restrictions',
	AI_MONITORING_TOPICS = 'ai-monitoring-topics',
	USERS_VIOLATIONS = 'users-violations',
}

export enum EAiSubTabs {
	TEXT_AI = 'text-AI',
	PHOTO_AI = 'photo-AI',
	VIDEO_AI = 'video-AI',
	AUDIO_AI = 'audio-AI',
	GAMES_AI = 'games-AI',
}

export enum EAiRestrictionSubTabs {
	WORDS = 'words',
	POSES = 'poses',
	LINKS = 'links',
}

export enum EAiChatBotTabs {
	AI_BOTS = 'ai-bots',
	PRICING = 'pricing',
	BOTS_STATISTICS = 'bots-statistics',
	RECOMMENDED_AI_BOTS = 'recommended-ai-bots',
	LIST_CODES = 'list-codes',
}

export enum EEditAiChatBotTabs {
	MAIN = 'main',
	STRUCTURE = 'structure',
	APPEARANCE = 'appearance',
	TEXT_TEMPLATE = 'text-template',
	IMAGE_TEMPLATE = 'image-template',
}

export enum ETabsContent {
	TRACK = 'track',
	ALBUM = 'album',
}

export enum ETabsTerms {
	ENGLISH = 'english',
	POLISH = 'polish',
}

export enum ETabsManagement {
	PERSONAL_DATA = 'personal-data-tab',
	CONTENT = 'content-tab',
}

export enum ETabsBalances {
	DEPOSIT = 'deposit',
	WITHDRAWAL = 'withdrawal',
}

export enum ETabsAmbassador {
	TOTAL = 'total',
	DISCOUNT = 'discount',
	DISCOUNT1 = 'discount1',
	DISCOUNT5 = 'discount5',
	EARN7_DISCOUNT10 = '7.5earn-discount10',
	EARN5_DISCOUNT10 = '5earn-discount10',
	EARN7_DISCOUNT5 = '7.5earn-discount5',
	EARN5_DISCOUNT5 = '5earn-discount5',
}

export enum ETypeBot {
	CREATE_TEXT_BOT = 'Create_text_bot',
	CREATE_IMAGE_BOT = 'Create_image_bot',
	CREATE_EDIT_IMAGE_BOT = 'Create_Edit_image_bot',
	CREATE_TEXT_IMAGE_BOT = 'Create_text+image_bot',
}

export enum ETabsCreateBot {
	MAIN = 'main',
	STRUCTURE = 'structure',
	APPEARANCE = 'Appearance',
	TEMPLATE = 'Template',
}
