import { NotVerifiedGraySvg } from 'assets/svg-icon';
import { IOpenPopup } from 'components/ChannelsComponents/ChannelsBodyTable';
import { Block } from 'components/Popups/Block';
import moment from 'moment';
import { FC, useState, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { putUpgradeRequest } from 'redux/reducers/users/reducer';
import { IUsers } from 'redux/reducers/users/types';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { ETabsUsersDetails } from 'services/constants/lists-table';
import { EChannelUpgradeType } from 'services/constants/type';
import { NotFound } from 'ui/NotFound/NotFound';
import { SvgUnverifiedAccount, SvgVerifiedAccount } from 'ui/svg-image/SvgAllChannels';
import Highlighter from 'react-highlight-words';
import { getAllCountries } from 'redux/reducers/users/selectors';
import { findCountry } from 'untils/findCountry';
import { IMembers } from 'redux/reducers/channels/types';
import { numeralFunc } from 'untils/numerals';
import { GET_PHOTO } from 'services/constants/env';
import { Avatar } from 'ui/Avatar/Avatar';

interface IPropsUsersTableBody {
	members: Array<IMembers | IUsers>;
	param: string | undefined;
	search?: string;
}

export const RefundsTableBody: FC<IPropsUsersTableBody> = ({ search = '', members, param }) => {
	const [onChangeVerification, setOnChangeVerification] = useState<number | null>(null);
	const [isOpenPopup, setIsOpenPopup] = useState<IOpenPopup | null>(null);
	const dispatch = useDispatch();
	const [country, setCountry] = useState<string>('');
	const countriesList = useSelector(getAllCountries);
	const params = useParams();

	const createAvataar = (title: string): string => {
		const array = title.trim().split(' ');
		return array.length >= 2
			? array[0][0].toUpperCase() + array[1][0].toUpperCase()
			: title.slice(0, 2).toUpperCase();
	};

	const upgradeChannel = (event: MouseEvent, verify?: boolean) => {
		const target = event.currentTarget as HTMLButtonElement;
		(isOpenPopup || target.name !== EChannelUpgradeType.BLOCK) &&
			dispatch(
				putUpgradeRequest({
					user_id: +target.id,
					verify: !verify,
				}),
			);
		setOnChangeVerification(null);
	};

	return (
		<div className="table-body">
			{isOpenPopup && (
				<Block
					isOpenPopup={isOpenPopup}
					upgradeChannel={upgradeChannel}
					setIsOpenPopup={setIsOpenPopup}
				/>
			)}
			{members?.map((member) => (
				<div key={member.id} className="tr">
					<div className="td td-break">
						<p className="td-hidden-name"> Photo</p>
						<div className="avatar-wrapper">
							<div className="avatar avatar--s-size">
								{member?.photo_access_hash ? (
									<Avatar hash={String(member.photo_access_hash)} />
								) : (
									<div className="avatar-custom">
										<span>{createAvataar(member.username)}</span>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="td td-break">
						<p className="td-hidden-name"> Name </p>
						<p className="tooltip-custom">Cooper Bator</p>
					</div>
					<div className="td td-break">
						<p className="td-hidden-name"> User name </p>
						<p className="tooltip-custom">@Cooper</p>
					</div>
					<div className="td td-break">
						<p className="td-hidden-name"> Subscription date </p>
						<div>Sep 24, 2022</div>
						<div className="gray">3:06 PM</div>
					</div>
					<div className="td td-break">
						<p className="td-hidden-name">Amount per subscription</p>
						<p>$10.00</p>
					</div>
					<div className="td td-break td--right">
						<p className="td-hidden-name">Action</p>
						<p>Refund</p>
					</div>
				</div>
			))}
		</div>
	);
};
