import { mediaSales } from 'CONST/userFilter';
import { Loader } from 'components/Loader';
import { UsersSubtabs } from 'components/UsersComponents/UsersSubtabs';
import { UsersTableBody } from 'components/UsersComponents/UsersTableBody';
import { mockDataUsers } from 'components/UsersComponents/UsersTableBody/mockDataUsers';
import { UsersTableHeader } from 'components/UsersComponents/UsersTableHeader';
import { IDataFiltter } from 'pages/Dashboard/Channels';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLoader } from 'redux/reducers/auth/selectors';
import { getUsersList } from 'redux/reducers/users/selectors';
import { Fillter } from 'ui/Fillter/Fillter';
import { MediaSalesHeader } from './MediaSalesHeader/MediaSalesHeader';
import { MediaSalesBody } from './MediaSalesBody/MediaSalesBody';

export const MediaSales: FC = () => {
	const loader = useSelector(getLoader);
	const usersList = useSelector(getUsersList);
	const params = useParams();

	const [dataFiltter, setDataFiltter] = useState<IDataFiltter>({
		order_by: '',
		sort: '',
	});

	return (
		<>
			<div className="table-block">
				<Fillter
					keyStatus="status"
					keyType="types"
					type={mediaSales}
					status={[]}
					typesName="Types"
					statusName=""
				/>
				<div className="table-wrapper">
					<div className="table table--channels-private-media-sales">
						<MediaSalesHeader />
						{loader ? <Loader /> : (usersList?.data.users || mockDataUsers) && <MediaSalesBody />}
					</div>
				</div>
			</div>
			{/* {usersList?.page_count && usersList?.page_count > 1 ? (
				<Pagination
					request={submitFilter}
					currentPage={usersList.page}
					numberPage={usersList.page_count}
				/>
			) : null} */}
		</>
	);
};
