import { FC, MouseEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './AddReaction.scss';
import { PopUpWrapper } from 'ui/PopUpWrapper/PopUpWrapper';
import { AddReactionForms } from 'components/Forms/AddReactionForms/AddReactionForms';
import { IAddRequest, IAnimation } from 'redux/reducers/reactions/types';
import { useDispatch, useSelector } from 'react-redux';
import {
	addReactionRequest,
	deleteReactionRequest,
	editReactionRequest,
	getReactionRequest,
} from 'redux/reducers/reactions/reducer';

import { getReactionSelector } from 'redux/reducers/reactions/selectors';

export const AddReaction: FC = () => {
	const reactionAnimation: IAnimation = {
		center_icon: '',
		static_icon: '',
		appear_animation: '',
		activate_animation: '',
		select_animation: '',
		around_animation: '',
		effect_animation: '',
	};
	const [open, setOpen] = useState<string>('information');
	const [file, setFile] = useState<IAnimation>(reactionAnimation);
	const { pathname } = useLocation();
	const [popup, setPopup] = useState<boolean>(false);
	const { id, tab } = useParams();
	const [image, setImage] = useState<IAnimation>(reactionAnimation);
	const dispatch = useDispatch();
	const reactionData = useSelector(getReactionSelector);
	const navigation = useNavigate();

	const onOpen = (event: MouseEvent) => {
		const { id } = event.currentTarget as HTMLDivElement;

		setOpen(id);
	};

	const isOpenModal = () => {
		setPopup(!popup);
	};

	const removeReaction = () => {
		dispatch(deleteReactionRequest({ id: tab || '', navigation }));
	};

	const handleImage = async (img: any, key: string) => {
		// setFieldValue('image', await toBase64(img));
		// setSize(img.size);
		setFile((prev: IAnimation) => ({ ...prev, [key]: img }));

		// // @ts-ignore
		setImage((prev) => ({ ...prev, [key]: URL.createObjectURL(img) }));
	};

	const onSubmitReaction = (data: IAddRequest) => {
		const formData = new FormData();
		pathname.includes('edit') && formData.append('id', tab || '');
		formData.append('emoticon', data.emoticon);
		formData.append('title', data.title);
		formData.append('center_icon', data.center_icon);
		formData.append('static_icon', data.static_icon);
		formData.append('appear_animation', data.appear_animation);
		formData.append('activate_animation', data.activate_animation);
		formData.append('select_animation', data.select_animation);
		formData.append('around_animation', data.around_animation);
		formData.append('effect_animation', data.effect_animation);

		pathname.includes('edit')
			? dispatch(editReactionRequest({ data: formData, navigation }))
			: dispatch(addReactionRequest({ data: formData, navigation }));
	};

	useEffect(() => {
		pathname.includes('edit') && dispatch(getReactionRequest(tab as string));
	}, []);

	return (
		<div className="main-content">
			{reactionData || pathname.includes('add') ? (
				<AddReactionForms
					handleImage={handleImage}
					image={image}
					file={file}
					pathname={pathname}
					open={open}
					isOpenModal={isOpenModal}
					onOpen={onOpen}
					onSubmitReaction={onSubmitReaction}
					reactionData={reactionData}
				/>
			) : null}
			{popup && (
				<PopUpWrapper
					value={String(id)}
					handleButton={removeReaction}
					isOpenModal={isOpenModal}
					title="Are you sure you want to delete the reaction?"
					btnName="Yes, I’m sure"
					disablet={'true'}
				/>
			)}
		</div>
	);
};
