import { IReactions } from 'redux/reducers/reactions/types';

export const finedReactionActive = (reactions: Array<IReactions>) => {
	return reactions
		.map((reaction) => {
			if (!reaction.off) {
				return reaction.id;
			} else {
				return null;
			}
		})
		.filter((index) => index !== null);
};
