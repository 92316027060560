import { spawn } from 'redux-saga/effects';
import { authSaga } from 'redux/reducers/auth/saga';
import { channelsSaga } from 'redux/reducers/channels/saga';
import { usersSaga } from 'redux/reducers/users/saga';
import { transactionSaga } from 'redux/reducers/transaction/saga';
import { reportsSaga } from 'redux/reducers/reports/saga';
import { adminsSaga } from 'redux/reducers/admins/saga';
import { supportSaga } from './reducers/support/saga';
import { aiServicesSaga } from './reducers/ai-services/saga';
import { reservedUsernamesSaga } from './reducers/reserved/saga';
import { utilsSaga } from './reducers/utils/saga';
import { downloadSaga } from './reducers/download/saga';
import { financeSaga } from './reducers/finance/finance-saga';
import { referralSaga } from './reducers/referral/saga';
import { apkFilesSaga } from './reducers/apk-files/saga';
import { aiChatBotsSaga } from './reducers/ai-chat-bot/saga';
import { reactionsSaga } from './reducers/reactions/saga';

export default function* rootSagas() {
	yield spawn(authSaga);
	yield spawn(channelsSaga);
	yield spawn(usersSaga);
	yield spawn(transactionSaga);
	yield spawn(reportsSaga);
	yield spawn(adminsSaga);
	yield spawn(supportSaga);
	yield spawn(aiServicesSaga);
	yield spawn(reservedUsernamesSaga);
	yield spawn(utilsSaga);
	yield spawn(downloadSaga);
	yield spawn(financeSaga);
	yield spawn(referralSaga);
	yield spawn(apkFilesSaga);
	yield spawn(aiChatBotsSaga);
	yield spawn(reactionsSaga);
}
