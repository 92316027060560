import { DeleteSvg, ErrorSvg, SearchSvg } from 'assets/svg-icon';
import { ChangeEvent, FC } from 'react';
import { SelectList } from 'ui/SelectList/SelectList';
import { dataAmount } from 'CONST/selectList';
import { ICommentsResponse } from 'redux/reducers/channels/types';
import { onJsonParseBigin } from 'untils/jsonParse';
import { DebounceInput } from 'react-debounce-input';
import { Avatar } from 'ui/Avatar/Avatar';
import moment from 'moment';

interface IComments {
	comments: ICommentsResponse | null;
	onHandleSearch: (event: ChangeEvent) => void;
	search: string;
	setSelect: (value: string) => void;
	removePost: (postId: string, type: string) => void;
}

export const Comments: FC<IComments> = ({
	removePost,
	setSelect,
	comments,
	onHandleSearch,
	search,
}) => {
	return (
		<>
			<div className="details-user__header-comments comments">
				<p className="comments__header-title">Comments</p>
				<div className="comments__filter-wrapper">
					<SelectList onSelect={setSelect} data={dataAmount} maxWidth={'max-width'} />
					<div className="input input-custom input-search input-search-custom input-search-width380">
						<div className="input-wrapper input-wrapper-custom input-wrapper-custom100">
							<DebounceInput
								className="input-item input-item--left-icon"
								type="text"
								name="search"
								placeholder="Search"
								value={search || ''}
								onChange={onHandleSearch}
								debounceTimeout={500}
							/>
							<span className="input-icon">
								<SearchSvg />
							</span>
						</div>
					</div>
				</div>
			</div>
			<div className="comments__list">
				{comments?.comments?.map((comment) => {
					const hash = onJsonParseBigin(comment.message_data).media?.photo_FLAGPHOTO
						.access_hash as string;

					console.log(onJsonParseBigin(comment.message_data));
					return (
						<div key={comment.id} className="comments__item">
							<div className="wrapper-image">
								<Avatar
									//@ts-ignore
									hash={hash}
									endpoint={'/storage/photo'}
								/>
							</div>
							<div className="comments__text-wrapper">
								<div className="comments__matrix"></div>
								<div className="comments__wrapper">
									<div className="comments__name">{comment?.author}</div>
									<div className="comments__text">{comment?.message}</div>
									<div className="comments__time gray">
										{moment(comment.published).format('h:mm A')}
									</div>
								</div>
								<div className="comments__icon">
									<span>
										<ErrorSvg />
									</span>
									<span onClick={() => removePost(comment.id, 'comment')}>
										<DeleteSvg />
									</span>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};
