import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';

export const PartnersRequestBody: FC = () => {
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">RequestID</p>
				<p>Artur Saris</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Name</p>
				<Link to={ELinksName.REQUEST_DETAILS}>{`Cooper Bator`}</Link>
			</div>
			<div className="td">
				<p className="td-hidden-name">Username</p>
				<Link to={ELinksName.REQUEST_DETAILS}>{`@L'Oréal`}</Link>
			</div>
			<div className="td td--fw-500 td--right-custom">
				<p className="td-hidden-name">Request date</p>
				<div className="green">
					<span>Sep 24, 2022</span> <div className="gray">3:06 PM</div>
				</div>
			</div>
			<div className="td td--right-custom">
				<p className="td-hidden-name">Type</p>
				<p>App</p>
			</div>
			<div className="td td--right td--right-custom">
				<p className="td-hidden-name">Status</p>
				<p>Pending</p>
			</div>
		</div>
	);
};
