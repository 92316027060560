import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import avatar from 'assets/images/avatar-square.png';
import { IAdminsChannelsResponse } from 'redux/reducers/channels/types';
import moment from 'moment';
import { createAvataar } from 'untils/createAvatar';
import { GET_PHOTO } from 'services/constants/env';
import { Avatar } from 'ui/Avatar/Avatar';

interface IPropsChannelsBody {
	admins: IAdminsChannelsResponse;
}

export const ChannelsBody: FC<IPropsChannelsBody> = ({ admins }) => {
	const params = useParams();

	const onVerifyStatus = (is_blocked: boolean, is_deleted: boolean) => {
		if (is_blocked) {
			return 'blocked-users';
		} else if (is_deleted) {
			return 'delete-users';
		} else {
			return 'registered';
		}
	};

	return (
		<div className="table-body">
			{admins?.channel_admins?.map((admin) => (
				<div key={admin.user_id} className="tr">
					<div className="td">
						<p className="td-hidden-name">Avatar</p>
						<div className="avatar-wrapper">
							<div className="avatar avatar--circle">
								{admin.photo_access_hash ? (
									<Avatar hash={String(admin.photo_access_hash)} />
								) : (
									<div className="avatar-custom">
										<span>{createAvataar(admin?.name)}</span>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Name</p>
						<Link
							to={`${ELinksName.USER_DETAILS}/${admin.user_id || ''}/${onVerifyStatus(
								admin.is_blocked,
								admin.is_deleted,
							)}/user-data`}
							className="link link--type2"
						>
							{admin?.name}
						</Link>
					</div>
					<div className="td">
						<p className="td-hidden-name">Email</p>
						<p>
							<Link
								to={`${ELinksName.USER_DETAILS}/${admin.user_id || ''}/${onVerifyStatus(
									admin.is_blocked,
									admin.is_deleted,
								)}/user-data`}
								className="link link--type2"
							>
								{admin?.email}
							</Link>
						</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Custom tittle</p>
						<p>{admin?.custom_title}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">What can this admin do</p>
						<p>{`${admin?.active_rights_count} permits`}</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Date added</p>
						<p>{moment(admin.date_added).format('MMM DD, YYYY')}</p>
						<p className="gray">{moment(admin.date_added).format('h:mm A')}</p>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Last active</p>
						<p>{moment(admin.last_active).format('MMM DD, YYYY')}</p>
						<p className="gray">{moment(admin.last_active).format('h:mm A')}</p>
					</div>
				</div>
			))}
		</div>
	);
};
